// tailwind css
import "../css/output.css";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Axios from "axios";
import { View } from "components/lib";
import { Toaster } from "components/ui/toaster";
import i18n from "i18next";
// locales
import English from "locales/en/index";
import Spanish from "locales/es/index";
import { initReactI18next } from "react-i18next";
// components
import { BrowserRouter, Route, Routes } from "react-router-dom";
// routes
import AppRoutes from "routes/app";
import AuthRoutes from "routes/auth";
import AutomationsRoutes from "routes/automations";
import ContentCreationRoutes from "routes/contentCreation";
import ICPRoutes from "routes/icp";
import LeadLensRoutes from "routes/leadLens";
import OrganizationRoutes from "routes/organization";
import OrganizationGoalsRoutes from "routes/organizationGoals";
import SetupRoutes from "routes/setup";
import StatsRoutes from "routes/stats";
import TeamRoutes from "routes/team";
// settings
import Settings from "settings.json";
// views
import { NotFound } from "views/error/404";

import { AuthProvider, PrivateRoute } from "./auth";
const StripePromise = loadStripe(
	Settings[process.env.NODE_ENV].stripe.publishableAPIKey,
);

const routes = [
	...SetupRoutes,
	...OrganizationRoutes,
	...AppRoutes,
	...AuthRoutes,
	...ContentCreationRoutes,
	...TeamRoutes,
	...OrganizationGoalsRoutes,
	...StatsRoutes,
	...LeadLensRoutes,
	...ICPRoutes,
	...AutomationsRoutes,
];

// create a new query client
const queryClient = new QueryClient();

// Function to replace placeholders with actual values
function replacePlaceholders(obj, placeholders) {
	const str = JSON.stringify(obj);
	const replacedStr = str.replaceAll(
		/{{(.*?)}}/g,
		(_, key) => placeholders[key.trim()] || "",
	);
	return JSON.parse(replacedStr);
}

export default function App() {
	const user = JSON.parse(localStorage.getItem("user"));
	Axios.defaults.baseURL = Settings[import.meta.env.VITE_APP_ENV].server_url;

	if (user) {
		if (user.token) {
			// add auth token to api header calls
			Axios.defaults.headers.common.Authorization = `Bearer ${user.token}`;
			Axios.defaults.headers.common["Accept-Language"] = user.locale;
		}

		// set the color mode
		user.dark_mode
			? document.querySelector("#app").classList.add("dark")
			: document.querySelector("#app").classList.remove("dark");
	}

	const appName = Settings.appName;

	// Replace placeholders in locales
	const EnglishWithAppName = replacePlaceholders(English, { appName });
	const SpanishWithAppName = replacePlaceholders(Spanish, { appName });

	// config locale/language
	i18n
		.use(initReactI18next)
		.init({
			resources: {
				en: EnglishWithAppName,
				es: SpanishWithAppName,
			},
			lng: user?.locale,
			fallbackLng: "en",
			interpolation: {
				escapeValue: false,
			},
		})
		.catch((error) => {
			console.error("i18n error", error);
		});

	// render the routes
	return (
		<Elements stripe={StripePromise}>
			<QueryClientProvider client={queryClient}>
				<AuthProvider>
					<BrowserRouter>
						<Routes>
							{routes.map((route) => {
								return (
									<Route
										key={route.path}
										path={route.path}
										element={
											route.permission ? (
												<PrivateRoute permission={route.permission}>
													<View
														display={route.view}
														layout={route.layout}
														title={route.title}
													/>
												</PrivateRoute>
											) : (
												<View
													display={route.view}
													layout={route.layout}
													title={route.title}
												/>
											)
										}
									/>
								);
							})}

							{/* 404 */}
							<Route
								path="*"
								element={
									<View
										display={NotFound}
										layout="auth"
										title="404 Not Found"
									/>
								}
							/>
						</Routes>
					</BrowserRouter>
				</AuthProvider>
			</QueryClientProvider>
			<Toaster />
		</Elements>
	);
}
