import { useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { AuthContext } from "components/lib";
import { useContext } from "react";
import type { AuthContextType } from "types/authContext";
import type { PaginatedAudienceMatchesResponse } from "types/lead";
import { leadLensApi, type UseUserLeadsV2Options } from "../services/lead-lens-api";



interface UseUserLeadsV2Return {
	data: PaginatedAudienceMatchesResponse | null;
	loading: boolean;
	error: Error | null;
	refetch: () => Promise<void>;
	pageCount: number;
}


export const useUserLeadsV2 = (
	userId: string | undefined,
	options: UseUserLeadsV2Options,
): UseUserLeadsV2Return => {
	const {
		page = 1,
		limit = 10,
		sortBy = "score",
		sortDirection = "desc",
		searchTerm = "",
		matchFilter = "all",
		audienceId,
		engagementFilter,
	} = options;

	const auth = useContext<AuthContextType>(AuthContext);
	const queryClient = useQueryClient();

	const {
		data,
		isLoading,
		error,
		refetch: queryRefetch,
	} = useQuery({
		queryKey: [
			"userLeadsV2",
			userId,
			page,
			limit,
			sortBy,
			sortDirection,
			searchTerm,
			matchFilter,
			audienceId,
			engagementFilter,
		],
		queryFn: async () => {
			if (!userId || !auth.user?.token) return null;
			console.log(`Fetching V2 user leads for page ${page}, limit ${limit}`);
			return leadLensApi.fetchUserLeadsV2({
				userId,
				page,
				limit,
				sortBy,
				sortDirection,
				searchTerm,
				matchFilter,
				audienceId,
				engagementFilter,
				token: auth.user.token,
			});
		},
		enabled: !!userId && !!auth.user?.token,
		staleTime: 0,
		refetchOnWindowFocus: false,
	});

	const refetch = async (): Promise<void> => {
		if (!userId) return;
		console.log(
			`Invalidating and refetching V2 user leads for ${userId}, page ${page}`,
		);
		await queryClient.invalidateQueries({
			queryKey: ["userLeadsV2", userId],
			exact: false,
		});
		await queryRefetch();
	};

	const pageCount = data?.totalCount ? Math.ceil(data.totalCount / limit) : 1;

	return {
		data: data || null,
		loading: isLoading,
		error: error as Error | null,
		refetch,
		pageCount,
	};
};
