import {
	type Column,
	type SortingState,
	createColumnHelper,
	flexRender,
	getCoreRowModel,
	getSortedRowModel,
	useReactTable,
} from "@tanstack/react-table";
import { Button } from "components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "components/ui/card";
import { Checkbox } from "components/ui/checkbox";
import { Skeleton } from "components/ui/skeleton";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "components/ui/table";
import { ArrowDown, ArrowUp, ArrowUpDown } from "lucide-react";
import { useState } from "react";

interface Post {
	id: string;
	content: string;
	metrics: {
		likes: number;
		comments: number;
		shares: number;
		impressions: number;
		engagement: number;
	};
	hasImage?: boolean;
	isRecommended?: boolean;
}

interface TopPerformingPostsProps {
	posts: Post[];
	loading?: boolean;
	renderBadge?: (post: Post) => React.ReactNode;
	onPostSelect?: (postId: string, isSelected: boolean) => void;
	selectedPosts?: Set<string>;
	onSelectRecommended?: () => void;
	onGenerateBlogs?: () => void;
	showImages?: boolean;
	showEngagement?: boolean;
}

const columnHelper = createColumnHelper<Post>();

const handleSort = (column: Column<Post>) => {
	column.toggleSorting();
};

const SortIcon = ({ column }: { column: Column<Post> }) => {
	if (!column.getIsSorted()) return null;

	return column.getIsSorted() === "asc" ? (
		<ArrowUp className="ml-2 h-4 w-4" />
	) : (
		<ArrowDown className="ml-2 h-4 w-4" />
	);
};

const HeaderCell = ({
	children,
	column,
}: {
	children: React.ReactNode;
	column: Column<Post>;
}) => {
	return (
		<div
			role="button"
			tabIndex={0}
			className="flex items-center cursor-pointer group"
			onClick={() => handleSort(column)}
			onKeyDown={(e) => {
				if (e.key === "Enter" || e.key === " ") {
					e.preventDefault();
					handleSort(column);
				}
			}}
		>
			{children}
			<SortIcon column={column} />
			{!column.getIsSorted() && (
				<ArrowUpDown className="ml-2 h-4 w-4 opacity-0 group-hover:opacity-50 transition-opacity" />
			)}
		</div>
	);
};

export function TopPerformingPosts({
	posts,
	loading,
	renderBadge,
	onPostSelect,
	selectedPosts = new Set(),
	onSelectRecommended,
	onGenerateBlogs,
	showImages = false,
	showEngagement = true,
}: TopPerformingPostsProps) {
	const [sorting, setSorting] = useState<SortingState>([]);
	const columns = [
		columnHelper.display({
			id: "select",
			cell: ({ row }) =>
				onPostSelect && (
					<Checkbox
						checked={selectedPosts.has(row.original.id)}
						onCheckedChange={(checked) => {
							onPostSelect?.(row.original.id, checked as boolean);
						}}
					/>
				),
		}),
		columnHelper.accessor("content", {
			header: ({ column }) => (
				<HeaderCell column={column}>Contenido</HeaderCell>
			),
			cell: ({ row, getValue }) => (
				<div className="flex items-center gap-2">
					<a
						href={`https://www.linkedin.com/feed/update/urn:li:activity:${row.original.id}`}
						target="_blank"
						rel="noopener noreferrer"
						className="hover:text-blue-600"
					>
						<span className="line-clamp-2">{getValue()}</span>
					</a>
					{renderBadge?.(row.original)}
				</div>
			),
		}),
		...(showImages
			? [
					columnHelper.accessor((row) => (row.hasImage ? "Sí" : "No"), {
						id: "image",
						header: ({ column }) => (
							<HeaderCell column={column}>Imagen</HeaderCell>
						),
						cell: (info) => (
							<div className="text-center">{info.getValue()}</div>
						),
					}),
				]
			: []),
		columnHelper.accessor("metrics.impressions", {
			header: ({ column }) => (
				<HeaderCell column={column}>Impresiones</HeaderCell>
			),
			cell: (info) => (
				<div className="text-right">{info.getValue().toLocaleString()}</div>
			),
		}),
		columnHelper.accessor("metrics.likes", {
			header: ({ column }) => <HeaderCell column={column}>Likes</HeaderCell>,
			cell: (info) => (
				<div className="text-right">{info.getValue().toLocaleString()}</div>
			),
		}),
		columnHelper.accessor("metrics.comments", {
			header: ({ column }) => (
				<HeaderCell column={column}>Comentarios</HeaderCell>
			),
			cell: (info) => (
				<div className="text-right">{info.getValue().toLocaleString()}</div>
			),
		}),
		columnHelper.accessor("metrics.shares", {
			header: ({ column }) => (
				<HeaderCell column={column}>Compartidos</HeaderCell>
			),
			cell: (info) => (
				<div className="text-right">{info.getValue().toLocaleString()}</div>
			),
		}),
		...(showEngagement
			? [
					columnHelper.accessor(
						(row) => {
							const engagement =
								row.metrics.likes + row.metrics.comments + row.metrics.shares;
							const engagementRate =
								(engagement / row.metrics.impressions) * 100;
							return engagementRate;
						},
						{
							id: "engagement",
							header: ({ column }) => (
								<HeaderCell column={column}>Engagement %</HeaderCell>
							),
							cell: (info) => (
								<div className="text-right font-medium">
									{info.getValue().toFixed(2)}%
								</div>
							),
						},
					),
				]
			: []),
	];

	const table = useReactTable({
		data: posts,
		columns,
		state: {
			sorting,
		},
		onSortingChange: setSorting,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
	});

	if (loading) {
		return (
			<div className="space-y-2">
				<Skeleton className="h-4 w-full" />
				<Skeleton className="h-4 w-full" />
				<Skeleton className="h-4 w-full" />
			</div>
		);
	}

	return (
		<Card className="bg-white">
			<CardHeader>
				<CardTitle className="text-xl font-semibold">
					Top Performing Posts
				</CardTitle>
			</CardHeader>
			<CardContent>
				{onPostSelect && (
					<div className="flex items-center justify-between mb-4">
						<p className="text-sm text-gray-500">
							{selectedPosts.size} posts seleccionados
						</p>
						<div className="flex items-center gap-2">
							<Button
								variant="outline"
								size="sm"
								onClick={onSelectRecommended}
								disabled={!onSelectRecommended}
							>
								Seleccionar Recomendados
							</Button>
							<Button
								onClick={onGenerateBlogs}
								disabled={selectedPosts.size === 0 || !onGenerateBlogs}
							>
								Generar Blogs
							</Button>
						</div>
					</div>
				)}
				<div className="rounded-md border">
					<Table>
						<TableHeader>
							{table.getHeaderGroups().map((headerGroup) => (
								<TableRow key={headerGroup.id}>
									{headerGroup.headers.map((header) => (
										<TableHead
											key={header.id}
											className={header.column.id === "select" ? "w-12" : ""}
										>
											{flexRender(
												header.column.columnDef.header,
												header.getContext(),
											)}
										</TableHead>
									))}
								</TableRow>
							))}
						</TableHeader>
						<TableBody>
							{table.getRowModel().rows.map((row) => (
								<TableRow key={row.id}>
									{row.getVisibleCells().map((cell) => (
										<TableCell key={cell.id}>
											{flexRender(
												cell.column.columnDef.cell,
												cell.getContext(),
											)}
										</TableCell>
									))}
								</TableRow>
							))}
						</TableBody>
					</Table>
				</div>
			</CardContent>
		</Card>
	);
}
