import { zodResolver } from "@hookform/resolvers/zod";
import { defineStepper } from "@stepperize/react";
import { useToast } from "components/hooks/use-toast";
import { AuthContext, useNavigate } from "components/lib";
import { Form } from "components/ui/form";
import { Steps } from "components/ui/steps";
import { useContext, useMemo } from "react";
import { useForm } from "react-hook-form";
import type { AuthContextType } from "types/authContext";
import type { CreateAutomationPayload } from "types/automations";

import axios from "axios";
import { ExclusionsStep } from "./components/ExclusionsStep";
import { SenderStep } from "./components/SenderStep";
import { TemplateStep } from "./components/TemplateStep";
import { useCreateAutomation } from "./hooks/use-create-automation";
import { type AutomationFormValues, automationSchema } from "./schemas";

interface Step {
	id: string;
	title: string;
}

interface CreateAutomationPageProps {
	t: (key: string) => string;
}

export default function CreateAutomationPage({ t }: CreateAutomationPageProps) {
	const steps: Step[] = useMemo(
		() => [
			{ id: "sender", title: t("automations.create.steps.sender") },
			{ id: "exclusions", title: t("automations.create.steps.exclusions") },
			{ id: "template", title: t("automations.create.steps.template") },
		],
		[t],
	);

	const { useStepper } = defineStepper(...steps);
	const stepper = useStepper();

	const { user } = useContext<AuthContextType>(AuthContext);
	const navigate = useNavigate();
	const { toast } = useToast();

	const currentOrganization = user?.organizations?.find(
		(org) => org.id === user?.organization_id,
	);

	const { createAutomation, isCreating, error } = useCreateAutomation(
		currentOrganization?.id || "",
	);

	const form = useForm<AutomationFormValues>({
		resolver: zodResolver(automationSchema),
		defaultValues: {
			name: "",
			sender: "",
			exclusions: [""],
			templateMessage: "",
		},
	});

	const onSubmit = async (values: AutomationFormValues) => {
		try {
			const filteredExclusions = values.exclusions.filter(
				(ex) => ex.trim() !== "",
			);

			const payload: CreateAutomationPayload = {
				name: values.name,
				organizationId: currentOrganization?.id || "",
				config: {
					messageSender: values.sender,
					excludedCompanies: filteredExclusions,
					messageVariants: [values.templateMessage],
				},
			};

			await createAutomation(payload);

			toast({
				title: t("automations.success.created"),
				description: t("automations.success.description"),
			});

			navigate("/automations");
		} catch (err) {
			if (
				axios.isAxiosError(err) &&
				err.response?.data.errorCode === "SENDER_HAS_AUTOMATION"
			) {
				toast({
					title: t("automations.error.alreadyExists.title"),
					description: t("automations.error.alreadyExists.description"),
					variant: "destructive",
				});
			} else {
				toast({
					title: t("automations.error.title"),
					description: t("automations.error.description"),
					variant: "destructive",
				});
			}

			console.error("Error creating automation:", err);
		}
	};

	return (
		<div className="container py-10 max-w-3xl mx-auto">
			<div className="mb-8">
				<h1 className="text-3xl font-bold tracking-tight mb-2">
					{t("automations.create.title")}
				</h1>
				<p className="text-muted-foreground">
					{t("automations.create.description")}
				</p>
			</div>

			<Steps steps={steps} currentStep={steps.indexOf(stepper.current)} />

			<Form {...form}>
				<form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8 mt-8">
					{stepper.when("sender", () => (
						<SenderStep
							form={form}
							onNext={() => stepper.next()}
							currentOrganization={currentOrganization}
							t={t}
						/>
					))}

					{stepper.when("exclusions", () => (
						<ExclusionsStep
							form={form}
							onNext={() => stepper.next()}
							onPrevious={() => stepper.prev()}
							t={t}
						/>
					))}

					{stepper.when("template", () => (
						<TemplateStep
							form={form}
							onPrevious={() => stepper.prev()}
							onSubmit={form.handleSubmit(onSubmit)}
							isSubmitting={isCreating}
							t={t}
						/>
					))}
				</form>
			</Form>
		</div>
	);
}
