import { useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { AuthContext } from "components/lib";
import { useContext } from "react";
import type { AuthContextType } from "types/authContext";
import { appConfig } from "views/content-creation/config";
import type { PaginatedPostsResponse } from "../types";

interface UseUserPostsReturn {
	data: PaginatedPostsResponse | null;
	loading: boolean;
	error: Error | null;
	refetch: () => Promise<void>;
}

const getDbColumnName = (columnId: string): string => {
	if (columnId.startsWith("metrics_")) {
		return columnId.split("_")[1];
	}

	const columnMap: Record<string, string> = {
		totalInteractions: "total_interactions",
		content: "content",
		creation_time: "creation_time",
	};

	return columnMap[columnId] || columnId;
};

const fetchUserPosts = async ({
	userId,
	page,
	limit,
	sortBy,
	sortDirection,
	searchTerm,
	token,
}: {
	userId: string;
	page: number;
	limit: number;
	sortBy: string;
	sortDirection: "asc" | "desc";
	searchTerm?: string;
	token: string;
}) => {
	const mappedSortBy = getDbColumnName(sortBy);

	const { data } = await axios.get(
		`${appConfig.API_MAIN_URL}/api/post/paginated/${userId}`,
		{
			params: {
				page,
				limit,
				sortBy: mappedSortBy,
				sortDirection,
				searchTerm,
			},
			headers: {
				Authorization: `Bearer ${token}`,
			},
		},
	);
	return {
		...data,
		totalItems: data.total || 0,
	};
};

export const useUserPosts = (
	userId: string | undefined,
	page = 1,
	limit = 10,
	sortBy = "creation_time",
	sortDirection: "asc" | "desc" = "desc",
	searchTerm?: string,
): UseUserPostsReturn & {
	setLimit: (newLimit: number) => void;
} => {
	const auth = useContext<AuthContextType>(AuthContext);
	const queryClient = useQueryClient();

	const {
		data,
		isLoading,
		error,
		refetch: queryRefetch,
	} = useQuery({
		queryKey: [
			"userPosts",
			userId,
			page,
			limit,
			sortBy,
			sortDirection,
			searchTerm,
		],
		queryFn: async () => {
			if (!userId || !auth.user?.token) return null;
			return fetchUserPosts({
				userId,
				page,
				limit,
				sortBy,
				sortDirection,
				searchTerm,
				token: auth.user.token,
			});
		},
		enabled: !!userId && !!auth.user?.token,
		staleTime: 0,
		refetchOnWindowFocus: false,
	});

	const setLimit = (newLimit: number) => {
		if (!userId) return;
		queryClient.setQueryData(
			["userPosts", userId, page, limit, sortBy, sortDirection, searchTerm],
			(oldData: unknown) => ({
				...((oldData as Record<string, unknown>) || {}),
				limit: newLimit,
			}),
		);
	};

	const refetch = async (): Promise<void> => {
		if (!userId) return;
		await queryClient.invalidateQueries({
			queryKey: ["userPosts", userId],
			exact: false,
		});
		await queryRefetch();
	};

	return {
		data: data?.data || null,
		loading: isLoading,
		error: error as Error | null,
		refetch,
		setLimit,
	};
};
