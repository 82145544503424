import { zodResolver } from "@hookform/resolvers/zod";
import { ChevronDown } from "lucide-react";
import { useEffect, useState } from "react";
import { useContext } from "react";
import type { DateRange } from "react-day-picker";
import { useForm } from "react-hook-form";

import { Button } from "components/ui/button";
import { Card } from "components/ui/card";
import {
	Collapsible,
	CollapsibleContent,
	CollapsibleTrigger,
} from "components/ui/collapsible";
import { DatePickerWithRange } from "components/ui/date-range-picker";
import {
	Form,
	FormControl,
	FormDescription,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "components/ui/form";
import { Input } from "components/ui/input";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "components/ui/select";
import { Separator } from "components/ui/separator";
import { Switch } from "components/ui/switch";
import { Textarea } from "components/ui/textarea";

import { useToast } from "components/hooks/use-toast";
import { AuthContext } from "components/lib";
import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
	AlertDialogTrigger,
} from "components/ui/alert-dialog";
import type { AuthContextType } from "types/authContext";
import type { OrganizationICP } from "types/organization-icp";
import { useCreateICP } from "../hooks/use-create-icp";
import { useDeleteICP } from "../hooks/use-delete-icp";
import { useUpdateICP } from "../hooks/use-update-icp";
import { icpFormSchema } from "../schemas";
import type { ICPFormValues } from "../schemas";
import {
	convertICPFormToPayload,
	getICPFormDefaultValues,
	hasAdvancedICPFields,
} from "../utils/form-helpers";

interface ICPFormProps {
	onCancel: () => void;
	icpToEdit?: OrganizationICP | null;
	t: (key: string) => string;
}

export const ICPForm = ({ onCancel, icpToEdit, t }: ICPFormProps) => {
	const auth = useContext<AuthContextType>(AuthContext);
	const organizationId = auth.user?.organization_id ?? "";
	const { createICP, isCreating } = useCreateICP(organizationId);
	const { updateICP, isUpdating } = useUpdateICP();
	const { deleteICP, isDeleting } = useDeleteICP();
	const [showAdvanced, setShowAdvanced] = useState(false);
	const { toast } = useToast();

	const form = useForm<ICPFormValues>({
		resolver: zodResolver(icpFormSchema),
		defaultValues: getICPFormDefaultValues(),
	});

	useEffect(() => {
		if (icpToEdit) {
			form.reset(getICPFormDefaultValues(icpToEdit));

			if (hasAdvancedICPFields(icpToEdit)) {
				setShowAdvanced(true);
			}
		}
	}, [icpToEdit, form]);

	const onSubmit = async (data: ICPFormValues) => {
		try {
			const payload = convertICPFormToPayload(data, organizationId);

			if (icpToEdit) {
				await updateICP(icpToEdit.id, payload);
				toast({
					title: "Success",
					description: "ICP updated successfully",
				});
			} else {
				await createICP(payload);
				toast({
					title: "Success",
					description: "ICP created successfully",
				});
			}
			onCancel();
		} catch (error) {
			toast({
				title: "Error",
				description: icpToEdit
					? "Failed to update ICP"
					: "Failed to create ICP",
				variant: "destructive",
			});
		}
	};

	const handleDelete = async () => {
		try {
			if (icpToEdit) {
				await deleteICP(icpToEdit.id);
				toast({
					title: "Success",
					description: "ICP deleted successfully",
				});
				onCancel();
			} else {
				toast({
					title: "Error",
					description: "Failed to delete ICP",
					variant: "destructive",
				});
			}
		} catch (error) {
			toast({
				description: "Failed to delete ICP",
				variant: "destructive",
			});
		}
	};

	const isLoading = isCreating || isUpdating || isDeleting;

	return (
		<Card className="mx-auto max-w-3xl p-6">
			<div className="mb-6 flex items-center justify-between">
				<h2 className="text-2xl font-bold">
					{icpToEdit
						? t("icp.form.icp.editTitle")
						: t("icp.form.icp.createTitle")}
				</h2>
			</div>
			<Form {...form}>
				<form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
					<FormField
						control={form.control}
						name="name"
						render={({ field }) => (
							<FormItem>
								<FormLabel>{t("icp.form.icp.name.label")}</FormLabel>
								<FormControl>
									<Input
										placeholder={t("icp.form.icp.name.placeholder")}
										{...field}
									/>
								</FormControl>
								<FormMessage />
							</FormItem>
						)}
					/>

					<FormField
						control={form.control}
						name="targeting_criteria.description"
						render={({ field }) => (
							<FormItem>
								<FormLabel>Description</FormLabel>
								<FormControl>
									<Textarea
										placeholder="Describe your target companies..."
										{...field}
									/>
								</FormControl>
								<FormMessage />
							</FormItem>
						)}
					/>

					<div className="grid gap-4 md:grid-cols-2">
						<FormField
							control={form.control}
							name="targeting_criteria.locations"
							render={({ field }) => (
								<FormItem>
									<FormLabel>Countries</FormLabel>
									<FormControl>
										<Input placeholder="e.g. USA, Canada, Mexico" {...field} />
									</FormControl>
									<FormDescription>
										{t("icp.form.descriptions.comma_separated")}
									</FormDescription>
									<FormMessage />
								</FormItem>
							)}
						/>

						<FormField
							control={form.control}
							name="targeting_criteria.industries"
							render={({ field }) => (
								<FormItem>
									<FormLabel>Industries</FormLabel>
									<FormControl>
										<Input
											placeholder="e.g. Technology, Healthcare"
											{...field}
										/>
									</FormControl>
									<FormDescription>
										{t("icp.form.descriptions.comma_separated")}
									</FormDescription>
									<FormMessage />
								</FormItem>
							)}
						/>
					</div>

					<FormField
						control={form.control}
						name="targeting_criteria.specialities"
						render={({ field }) => (
							<FormItem>
								<FormLabel>Specialities</FormLabel>
								<FormControl>
									<Input
										placeholder="e.g. Cloud Computing, Digital Transformation"
										{...field}
									/>
								</FormControl>
								<FormDescription>
									{t("icp.form.descriptions.comma_separated")}
								</FormDescription>
								<FormMessage />
							</FormItem>
						)}
					/>

					<FormField
						control={form.control}
						name="targeting_criteria.postsTopics"
						render={({ field }) => (
							<FormItem>
								<FormLabel>Post Topics</FormLabel>
								<FormControl>
									<Input
										placeholder="e.g. AI, Machine Learning, Data Science"
										{...field}
									/>
								</FormControl>
								<FormDescription>
									Topics that this ICP typically posts about
								</FormDescription>
								<FormMessage />
							</FormItem>
						)}
					/>

					<div className="grid gap-4 md:grid-cols-2">
						<FormField
							control={form.control}
							name="targeting_criteria.minStaffCount"
							render={({ field }) => (
								<FormItem>
									<FormLabel>Min Employee Count</FormLabel>
									<FormControl>
										<Input type="number" placeholder="e.g. 50" {...field} />
									</FormControl>
									<FormMessage />
								</FormItem>
							)}
						/>

						<FormField
							control={form.control}
							name="targeting_criteria.maxStaffCount"
							render={({ field }) => (
								<FormItem>
									<FormLabel>Max Employee Count</FormLabel>
									<FormControl>
										<Input type="number" placeholder="e.g. 1000" {...field} />
									</FormControl>
									<FormMessage />
								</FormItem>
							)}
						/>
					</div>

					<FormField
						control={form.control}
						name="targeting_criteria.type"
						render={({ field }) => (
							<FormItem>
								<FormLabel>Company Type</FormLabel>
								<Select onValueChange={field.onChange} value={field.value}>
									<FormControl>
										<SelectTrigger>
											<SelectValue placeholder="Select company type" />
										</SelectTrigger>
									</FormControl>
									<SelectContent>
										<SelectItem value="private">Private</SelectItem>
										<SelectItem value="public">Public</SelectItem>
									</SelectContent>
								</Select>
								<FormMessage />
							</FormItem>
						)}
					/>

					<Separator className="my-4" />

					<div className="flex items-center space-x-2">
						<Switch checked={showAdvanced} onCheckedChange={setShowAdvanced} />
						<div className="space-y-0.5">
							<h4 className="text-sm font-medium">Show Advanced Options</h4>
						</div>
					</div>

					<Collapsible open={showAdvanced}>
						<CollapsibleContent className="space-y-4">
							<Separator />
							<h3 className="text-lg font-medium">Advanced Options</h3>

							<div className="grid gap-4 md:grid-cols-2">
								<FormField
									control={form.control}
									name="targeting_criteria.minFollowerCount"
									render={({ field }) => (
										<FormItem>
											<FormLabel>Min Followers</FormLabel>
											<FormControl>
												<Input
													type="number"
													placeholder="e.g. 1000"
													{...field}
												/>
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>

								<FormField
									control={form.control}
									name="targeting_criteria.maxFollowerCount"
									render={({ field }) => (
										<FormItem>
											<FormLabel>Max Followers</FormLabel>
											<FormControl>
												<Input
													type="number"
													placeholder="e.g. 10000"
													{...field}
												/>
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>
							</div>

							<div className="grid gap-4 md:grid-cols-2">
								<FormField
									control={form.control}
									name="targeting_criteria.minFoundingYear"
									render={({ field }) => (
										<FormItem>
											<FormLabel>Min Founding Year</FormLabel>
											<FormControl>
												<Input
													type="number"
													placeholder="e.g. 2020"
													{...field}
												/>
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>

								<FormField
									control={form.control}
									name="targeting_criteria.maxFoundingYear"
									render={({ field }) => (
										<FormItem>
											<FormLabel>Max Founding Year</FormLabel>
											<FormControl>
												<Input
													type="number"
													placeholder="e.g. 2023"
													{...field}
												/>
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>
							</div>

							<div className="grid gap-4 md:grid-cols-2">
								<FormField
									control={form.control}
									name="targeting_criteria.minAverageInteractionPerPostCount"
									render={({ field }) => (
										<FormItem>
											<FormLabel>Min Interactions</FormLabel>
											<FormControl>
												<Input type="number" placeholder="e.g. 10" {...field} />
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>

								<FormField
									control={form.control}
									name="targeting_criteria.maxAverageInteractionPerPostCount"
									render={({ field }) => (
										<FormItem>
											<FormLabel>Max Interactions</FormLabel>
											<FormControl>
												<Input
													type="number"
													placeholder="e.g. 100"
													{...field}
												/>
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>
							</div>

							<FormField
								control={form.control}
								name="targeting_criteria.fundingData.minLastFundingType"
								render={({ field }) => (
									<FormItem>
										<FormLabel>Funding Type</FormLabel>
										<Select onValueChange={field.onChange} value={field.value}>
											<FormControl>
												<SelectTrigger>
													<SelectValue placeholder="Select funding type" />
												</SelectTrigger>
											</FormControl>
											<SelectContent>
												<SelectItem value="seed">Seed</SelectItem>
												<SelectItem value="seriesA">Series A</SelectItem>
												<SelectItem value="seriesB">Series B</SelectItem>
												<SelectItem value="seriesC">Series C+</SelectItem>
											</SelectContent>
										</Select>
										<FormMessage />
									</FormItem>
								)}
							/>

							<div className="grid gap-4 md:grid-cols-2">
								<FormField
									control={form.control}
									name="targeting_criteria.fundingData.minLastMoneyRaised"
									render={({ field }) => (
										<FormItem>
											<FormLabel>Min Funding</FormLabel>
											<FormControl>
												<Input
													type="number"
													placeholder="e.g. 1000000"
													{...field}
												/>
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>

								<FormField
									control={form.control}
									name="targeting_criteria.fundingData.maxLastMoneyRaised"
									render={({ field }) => (
										<FormItem>
											<FormLabel>Max Funding</FormLabel>
											<FormControl>
												<Input
													type="number"
													placeholder="e.g. 10000000"
													{...field}
												/>
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>
							</div>

							<div className="grid gap-4 md:grid-cols-2">
								<FormField
									control={form.control}
									name="targeting_criteria.fundingData.minLastFundingYear"
									render={({ field }) => (
										<FormItem>
											<FormLabel>Min Funding Year</FormLabel>
											<FormControl>
												<Input
													type="number"
													placeholder="e.g. 2020"
													{...field}
												/>
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>

								<FormField
									control={form.control}
									name="targeting_criteria.fundingData.maxLastFundingYear"
									render={({ field }) => (
										<FormItem>
											<FormLabel>Max Funding Year</FormLabel>
											<FormControl>
												<Input
													type="number"
													placeholder="e.g. 2030"
													{...field}
												/>
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>
							</div>
						</CollapsibleContent>
					</Collapsible>

					<div className="flex w-full gap-4">
						<Button
							type="button"
							variant="outline"
							onClick={onCancel}
							className="w-[15%]"
							disabled={isLoading}
						>
							{t("icp.form.buttons.cancel")}
						</Button>
						{icpToEdit && (
							<AlertDialog>
								<AlertDialogTrigger asChild>
									<Button
										type="button"
										variant="destructive"
										className="w-[15%]"
										disabled={
											isLoading ||
											(auth.user?.permission !== "admin" &&
												auth.user?.permission !== "owner")
										}
									>
										{t("icp.form.buttons.delete")}
									</Button>
								</AlertDialogTrigger>
								<AlertDialogContent>
									<AlertDialogHeader>
										<AlertDialogTitle>{t("icp.delete.title")}</AlertDialogTitle>
										<AlertDialogDescription>
											{t("icp.delete.description.icp")}
										</AlertDialogDescription>
									</AlertDialogHeader>
									<AlertDialogFooter>
										<AlertDialogCancel>
											{t("icp.delete.cancel")}
										</AlertDialogCancel>
										<AlertDialogAction
											onClick={handleDelete}
											className="bg-destructive text-destructive-foreground hover:bg-destructive/90"
										>
											{t("icp.delete.confirm")}
										</AlertDialogAction>
									</AlertDialogFooter>
								</AlertDialogContent>
							</AlertDialog>
						)}
						<Button
							type="submit"
							className={icpToEdit ? "w-[70%]" : "w-[85%]"}
							disabled={
								isLoading ||
								(auth.user?.permission !== "admin" &&
									auth.user?.permission !== "owner")
							}
						>
							{isLoading
								? icpToEdit
									? t("icp.form.buttons.updating")
									: t("icp.form.buttons.creating")
								: icpToEdit
									? t("icp.form.buttons.update")
									: t("icp.form.buttons.create")}
						</Button>
					</div>
				</form>
			</Form>
		</Card>
	);
};
