import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Animate, AuthContext } from "components/lib";
import { Card } from "components/ui/card";
import { UnipileAuthProvider } from "contexts/unipile-auth.context";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import type { AuthContextType } from "types/authContext";
import { EmptyErrorState } from "./_components/v2/empty-error-state";
import { LeadTrackingTableV2 } from "./_components/v2/lead-tracking-table-v2";
import { UserSelectionDropdown } from "./_components/v2/user-selection-dropdown";
import { useLeadLensEvents } from "./events";

interface OrganizationUser {
	id: string;
	name?: string;
	email?: string;
}

// Direct API call to fetch organization users as a fallback
const fetchOrganizationUsers = async (
	organizationId: string | undefined,
): Promise<OrganizationUser[]> => {
	if (!organizationId) {
		return [];
	}

	try {
		const { data } = await axios.get<{ data: OrganizationUser[] }>(
			`/api/organization/${organizationId}/users`,
		);
		return data.data || [];
	} catch (error) {
		console.error("Error fetching organization users:", error);
		return [];
	}
};

export const LeadLensV2 = () => {
	const auth: AuthContextType = useContext(AuthContext);
	const events = useLeadLensEvents();
	const [selectedUserId, setSelectedUserId] = useState<string | undefined>(
		auth?.user?.id,
	);
	const organizationId = auth?.user?.organization_id;
	const [loadingError, setLoadingError] = useState<boolean>(false);

	const {
		data: fallbackUsers,
		isLoading: isLoadingFallbackUsers,
		error: usersError,
		refetch: refetchUsers,
	} = useQuery({
		queryKey: ["organizationUsers", organizationId],
		queryFn: () => fetchOrganizationUsers(organizationId),
		enabled: !!organizationId,
		staleTime: 5 * 60 * 1000, // 5 minutes
		retry: 2,
	});

	// Set loading error if there's an error or if users are empty when they should exist
	useEffect(() => {
		if (
			usersError ||
			(organizationId &&
				!isLoadingFallbackUsers &&
				(!fallbackUsers || fallbackUsers.length === 0))
		) {
			setLoadingError(true);
		} else if (fallbackUsers && fallbackUsers.length > 0) {
			setLoadingError(false);
		}
	}, [usersError, fallbackUsers, isLoadingFallbackUsers, organizationId]);

	// Combine results from both sources
	const users = useMemo(() => {
		if (fallbackUsers && fallbackUsers.length > 0) {
			// Transform fallback users to match the expected format
			return fallbackUsers.map((user: OrganizationUser) => ({
				user_id: user.id,
				user_name: user.name || user.email || user.id,
			}));
		}

		return [];
	}, [fallbackUsers]);

	const loading = isLoadingFallbackUsers;

	useEffect(() => {
		if (auth?.user) {
			events.trackPageView({
				user_id: auth.user.id,
				source: window.location.pathname,
			});
		}
	}, [auth?.user, events]);

	const handleUserChange = (newUserId: string) => {
		setSelectedUserId(newUserId);
	};

	// Handle lead fetch failure by selecting the first user in the list
	const handleLeadFetchFailure = useCallback(() => {
		// Only proceed if we have users and the current selection is not working
		if (users.length > 0 && selectedUserId) {
			const firstUserId = users[0].user_id;
			// Only change if we're not already on the first user
			if (firstUserId !== selectedUserId) {
				setSelectedUserId(firstUserId);
			}
		} else {
			setLoadingError(true);
		}
	}, [users, selectedUserId]);

	const handleRetry = () => {
		setLoadingError(false);
		refetchUsers();
	};

	if (!auth?.user) return null;

	return (
		<Animate type="pop">
			<div className="mx-auto max-w py-6 space-y-4">
				<Card className="p-6 bg-white">
					<UserSelectionDropdown
						selectedUserId={selectedUserId}
						currentUserId={auth?.user?.id}
						users={users}
						loading={loading}
						onUserChange={handleUserChange}
					/>
				</Card>

				{loadingError ? (
					<EmptyErrorState onRetry={handleRetry} />
				) : (
					<UnipileAuthProvider>
						<LeadTrackingTableV2
							selectedUserId={selectedUserId}
							onLeadFetchFailure={handleLeadFetchFailure}
						/>
					</UnipileAuthProvider>
				)}
			</div>
		</Animate>
	);
};
