import { useQueryClient } from "@tanstack/react-query";
import { deleteAudience } from "api/audience";
import { useAxiosMutation } from "hooks/useAxiosMutation";

export const useDeleteAudience = () => {
	const queryClient = useQueryClient();

	const mutation = useAxiosMutation({
		mutationFn: (id: number) => deleteAudience(id),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ["audiences"] });
			queryClient.invalidateQueries({ queryKey: ["userAudiences"] });
		},
	});

	return {
		deleteAudience: mutation.mutateAsync,
		isDeleting: mutation.isPending,
		error: mutation.error,
	};
};
