import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { AuthContext } from "components/lib";
import { useContext } from "react";
import type { Audience } from "types/audience";
import type { AuthContextType } from "types/authContext";
import { appConfig } from "views/content-creation/config";
import type { IcpSettings } from "../types";

export const useUserSettings = () => {
	const auth = useContext<AuthContextType>(AuthContext);

	const {
		data: settings,
		isLoading,
		error,
		refetch,
	} = useQuery<IcpSettings>({
		queryKey: ["userSettings", auth.user?.id],
		queryFn: async () => {
			const { data } = await axios.get(
				`${appConfig.API_MAIN_URL}/api/user-settings`,
				{
					params: { user_id: auth.user?.id },
					headers: {
						Authorization: `Bearer ${auth.user?.token}`,
					},
				},
			);
			return data;
		},
		enabled: !!auth.user?.token && !!auth.user?.id,
	});

	return {
		settings,
		isLoading,
		error,
		refetch,
	};
};

export const useUserAudiences = () => {
	const auth = useContext<AuthContextType>(AuthContext);

	const {
		data: apiResponse,
		isLoading,
		error,
		refetch,
	} = useQuery<{ data?: Audience[] }>({
		queryKey: ["userAudiences", auth.user?.id],
		queryFn: async () => {
			const response = await axios.get(
				`${appConfig.API_MAIN_URL}/api/audience/users`,
				{
					params: { user_id: auth.user?.id },
					headers: {
						Authorization: `Bearer ${auth.user?.token}`,
					},
				},
			);
			return response.data;
		},
		enabled: !!auth.user?.token && !!auth.user?.id,
	});

	// Make sure we're returning an array even if the API returns { data: [...] }
	const data = Array.isArray(apiResponse)
		? apiResponse
		: apiResponse?.data && Array.isArray(apiResponse.data)
			? apiResponse.data
			: [];

	return {
		data,
		isLoading,
		error,
		refetch,
	};
};
