import {
	type ColumnDef,
	type Row,
	flexRender,
	getCoreRowModel,
	getSortedRowModel,
	useReactTable,
} from "@tanstack/react-table";
import { useToast } from "components/hooks/use-toast";
import { Button } from "components/ui/button.tsx";
import {
	Card,
	CardContent,
	CardHeader,
	CardTitle,
} from "components/ui/card.tsx";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import { Skeleton } from "components/ui/skeleton.tsx";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "components/ui/table.tsx";
import { Loader2, MoreVertical, PlayIcon, TrashIcon } from "lucide-react";
import type React from "react";
import { useState } from "react";
import type { AutomationWithMessages } from "types/automations";

export interface AutomationsTableProps {
	automations: AutomationWithMessages[] | undefined;
	onDeleteAutomation: (automationId: number) => void;
	isLoading: boolean;
	deleteLoading: boolean;
	onSelectAutomation: (automationId: number) => void;
	t: (key: string) => string;
	onRunAutomation: (automationId: number) => Promise<void>;
	automationRunLoading: boolean;
}

export const AutomationsTable = (props: AutomationsTableProps) => {
	const [openMenuRow, setOpenMenuRow] = useState<number | null>(null);
	const { toast } = useToast();
	const toggleMenu = (e: React.MouseEvent, rowId: number) => {
		e.stopPropagation();
		setOpenMenuRow(openMenuRow === rowId ? null : rowId);
	};

	const { t } = props;

	const columns: ColumnDef<AutomationWithMessages>[] = [
		{
			id: "name",
			header: t("automations.view.automationsTable.columns.name"),
			cell: ({ row }: { row: Row<AutomationWithMessages> }) => {
				return (
					<div className="text-gray-700">
						<span>{row.original.name}</span>
					</div>
				);
			},
		},
		{
			id: "messagesSent",
			header: t("automations.view.automationsTable.columns.sent"),
			cell: ({ row }: { row: Row<AutomationWithMessages> }) => {
				return (
					<div className="text-gray-700">
						<span>
							{
								row.original.messages.filter(
									(message) => message.status === "sent",
								).length
							}
						</span>
					</div>
				);
			},
		},
		{
			id: "scheduledMessages",
			header: t("automations.view.automationsTable.columns.scheduled"),
			cell: ({ row }: { row: Row<AutomationWithMessages> }) => {
				return (
					<div className="text-gray-700">
						<span>
							{
								row.original.messages.filter(
									(message) => message.status === "scheduled",
								).length
							}
						</span>
					</div>
				);
			},
		},
		{
			id: "errors",
			header: t("automations.view.automationsTable.columns.errors"),
			cell: ({ row }: { row: Row<AutomationWithMessages> }) => {
				const errors = 0;
				return (
					<div className={`text-${errors === 0 ? "gray" : "red"}-600`}>
						<span>
							{
								row.original.messages.filter(
									(message) => message.status === "failed",
								).length
							}
						</span>
					</div>
				);
			},
		},
		{
			id: "actions",
			header: t("automations.view.automationsTable.columns.actions"),
			cell: ({ row }: { row: Row<AutomationWithMessages> }) => {
				return (
					// biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
					<div
						className="flex items-center justify-end actions-cell relative"
						onClick={(e) => e.stopPropagation()}
					>
						<DropdownMenu>
							<DropdownMenuTrigger>
								<Button
									variant="ghost"
									className="h-8 w-8 p-0 hover:bg-muted"
									onClick={(e) => toggleMenu(e, row.original.id)}
								>
									<MoreVertical className="h-4 w-4" />
								</Button>
							</DropdownMenuTrigger>
							<DropdownMenuContent>
								<DropdownMenuItem
									onClick={async () => {
										try {
											props
												.onRunAutomation(row.original.id)
												.then(() => {
													toast({
														title: "Running Automation",
														description: `Running automation ${row.original.name}`,
														variant: "default",
													});
												})
												.catch((e) => {
													if (e.message === "AUTOMATION_RUN_TODAY") {
														toast({
															title: "Automation already run today",
															description:
																"Wait for tomorrow to run the automation again",
															variant: "destructive",
														});
													} else {
														toast({
															title: "Error running automation",
															description: "Automation failed to run correctly",
															variant: "destructive",
														});
													}
												});
											toast({
												title: "Running Automation",
												description: `Running automation ${row.original.name}`,
												variant: "default",
											});
										} finally {
											setOpenMenuRow(null);
										}
									}}
								>
									{props.automationRunLoading ? (
										<Loader2 className="mr-2 h-4 w-4 animate-spin" />
									) : (
										<PlayIcon className="mr-2 h-4 w-4" />
									)}
									<span>
										{t("automations.view.automationsTable.actions.execute")}
									</span>
								</DropdownMenuItem>
								<DropdownMenuItem
									onClick={(e) => {
										e.stopPropagation();
										props.onDeleteAutomation(row.original.id);
									}}
								>
									{props.deleteLoading ? (
										<Loader2 className="mr-2 h-4 w-4 animate-spin" />
									) : (
										<TrashIcon className="mr-2 h-4 w-4 text-red-600" />
									)}
									<span className={"text-red-600"}>
										{t("automations.view.automationsTable.actions.delete")}
									</span>
								</DropdownMenuItem>
							</DropdownMenuContent>
						</DropdownMenu>
					</div>
				);
			},
		},
	];

	const table = useReactTable({
		data: props.automations || [],
		columns,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
	});

	return (
		<>
			<Card>
				<CardHeader>
					<CardTitle>{t("automations.view.automationsTable.title")}</CardTitle>
				</CardHeader>
				<CardContent>
					<Table className={"overflow-visible"}>
						<TableHeader>
							{table.getHeaderGroups().map((headerGroup) => (
								<TableRow key={headerGroup.id}>
									{headerGroup.headers.map((header) => (
										<TableHead key={header.id} className="text-center">
											{header.isPlaceholder
												? null
												: flexRender(
														header.column.columnDef.header,
														header.getContext(),
													)}
										</TableHead>
									))}
								</TableRow>
							))}
						</TableHeader>
						<TableBody className={"overflow-visible"}>
							{props.isLoading ? (
								<>
									{[...Array(3)].map((_, i) => (
										<TableRow key={`skeleton-row-${Date.now()}-${i}`}>
											<TableCell className="min-w-[120px] text-center">
												<Skeleton className="h-4 w-28 mx-auto" />
											</TableCell>
											<TableCell className="min-w-[120px] text-center">
												<Skeleton className="h-4 w-28 mx-auto" />
											</TableCell>
											<TableCell className="min-w-[120px] text-center">
												<Skeleton className="h-4 w-28 mx-auto" />
											</TableCell>
											<TableCell className="min-w-[120px] text-center">
												<Skeleton className="h-4 w-28 mx-auto" />
											</TableCell>
											<TableCell className="min-w-[120px] text-center">
												<Skeleton className="h-4 w-28 mx-auto" />
											</TableCell>
										</TableRow>
									))}
								</>
							) : (
								table.getRowModel().rows.map((row) => (
									<TableRow
										key={row.id}
										className="hover:bg-muted/50 cursor-pointer overflow-visible"
										onClick={(e) => {
											const target = e.target as HTMLElement;
											if (target.closest(".actions-cell")) {
												return;
											}

											props.onSelectAutomation(row.original.id);
										}}
									>
										{row.getVisibleCells().map((cell) => (
											<TableCell
												key={cell.id}
												className="text-center overflow-visible"
											>
												{flexRender(
													cell.column.columnDef.cell,
													cell.getContext(),
												)}
											</TableCell>
										))}
									</TableRow>
								))
							)}
						</TableBody>
					</Table>
				</CardContent>
			</Card>
		</>
	);
};
