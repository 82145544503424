import axios from "axios";
import { Avatar, AvatarFallback, AvatarImage } from "components/ui/avatar";
import { Badge } from "components/ui/badge";
import { Button } from "components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "components/ui/dialog";
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "components/ui/tooltip";
import { useUnipileAuth } from "contexts/unipile-auth.context";
import { differenceInDays, formatDistanceToNow } from "date-fns";
import { Loader2, RefreshCw } from "lucide-react";
import { AlertCircle, AlertTriangle, CircleCheck, XCircle } from "lucide-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { toast } from "sonner";
import { leadLensApi } from "../../lead-lens/services/lead-lens-api";

export const UnipileConnectionModal = ({
	isOpen,
	onClose,
	userId,
	userName,
	userAvatar,
	syncStatus,
	lastSyncDate,
	lastSuccessDate,
}) => {
	const [isConnected, setIsConnected] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);
	const [linkedinProfileId, setLinkedinProfileId] = useState(null);
	const { generateAuthUrl, accountId, findMatchingAccount } = useUnipileAuth();
	const [authUrl, setAuthUrl] = useState(null);
	const [isAuthenticating, setIsAuthenticating] = useState(false);
	const [authWindow, setAuthWindow] = useState(null);
	const [connectionSuccessful, setConnectionSuccessful] = useState(false);
	const [isSyncing, setIsSyncing] = useState(false);
	const [unipileAccountId, setUnipileAccountId] = useState(null);

	// Use ref to track previous connection state
	const wasConnectedRef = useRef(false);

	// Calculate days since last sync for display
	const daysSinceLastSuccessSync = lastSuccessDate
		? differenceInDays(new Date(), new Date(lastSuccessDate))
		: null;

	const daysSinceLastSync = lastSyncDate
		? differenceInDays(new Date(), new Date(lastSyncDate))
		: null;

	// Format sync date for display
	const formatSyncDate = (date, isError = false) => {
		if (!date) return "Never synced";
		if (isError && lastSuccessDate) {
			return `Sync failed, last successful sync ${formatDistanceToNow(new Date(lastSuccessDate), { addSuffix: true })}`;
		}
		return `Last synced ${formatDistanceToNow(new Date(date), { addSuffix: true })}`;
	};

	// Get sync status badge details
	const getSyncStatusBadge = () => {
		if (syncStatus === "Never synced" || !lastSyncDate) {
			return {
				icon: <XCircle className="w-4 h-4" />,
				variant: "destructive",
				tooltip: formatSyncDate(lastSyncDate),
				isError: false,
			};
		}

		if (syncStatus === "error" && daysSinceLastSuccessSync !== null) {
			if (daysSinceLastSuccessSync < 1) {
				return {
					icon: <AlertTriangle className="w-4 h-4" />,
					variant: "success",
					tooltip: formatSyncDate(lastSyncDate, true),
					isError: true,
				};
			}
			if (daysSinceLastSuccessSync < 3) {
				return {
					icon: <AlertTriangle className="w-4 h-4" />,
					variant: "warning",
					tooltip: formatSyncDate(lastSyncDate, true),
					isError: true,
				};
			}
			return {
				icon: <AlertCircle className="w-4 h-4" />,
				variant: "destructive",
				tooltip: formatSyncDate(lastSyncDate, true),
				isError: true,
			};
		}

		if (syncStatus === "success") {
			if (daysSinceLastSync < 1) {
				return {
					icon: <CircleCheck className="w-4 h-4" />,
					variant: "success",
					tooltip: formatSyncDate(lastSyncDate),
					isError: false,
				};
			}
			if (daysSinceLastSync < 3) {
				return {
					icon: <AlertCircle className="w-4 h-4" />,
					variant: "warning",
					tooltip: formatSyncDate(lastSyncDate),
					isError: false,
				};
			}
			return {
				icon: <AlertCircle className="w-4 h-4" />,
				variant: "destructive",
				tooltip: formatSyncDate(lastSyncDate),
				isError: false,
			};
		}

		return {
			icon: <XCircle className="w-4 h-4" />,
			variant: "destructive",
			tooltip: formatSyncDate(lastSyncDate),
			isError: false,
		};
	};

	// Check connection status
	const checkConnection = useCallback(async () => {
		if (!userId || !isOpen) return;

		setIsLoading(true);
		setError(null);

		try {
			// Get user's LinkedIn profile ID
			const profileResponse = await leadLensApi.getProfileIdByUserId({
				userId: userId,
			});

			if (profileResponse?.data?.urn) {
				setLinkedinProfileId(profileResponse.data.urn);

				// Check if this LinkedIn profile is connected to Unipile
				try {
					const response = await axios.get("/api/messaging/accounts");
					const data = response.data;

					// Find the matching account with this LinkedIn profile
					const matchingAccount = data.items.find((account) => {
						return (
							account.connection_params?.im?.id === profileResponse.data.urn
						);
					});

					const currentlyConnected = !!matchingAccount;

					// Store the account ID if we found a matching account
					if (currentlyConnected && matchingAccount) {
						// Update the account ID in the context by using findMatchingAccount
						setUnipileAccountId(matchingAccount.id);
					}

					// Check if we've newly connected and need to show success
					if (currentlyConnected && !wasConnectedRef.current) {
						setConnectionSuccessful(true);
					}

					// Update our ref for the next check
					wasConnectedRef.current = currentlyConnected;
					setIsConnected(currentlyConnected);
				} catch (err) {
					console.error("Error checking Unipile account:", err);
					setIsConnected(false);
				}
			} else {
				setIsConnected(false);
			}
		} catch (err) {
			console.error("Error fetching LinkedIn profile:", err);
			setError("Failed to check LinkedIn connection status");
			setIsConnected(false);
		} finally {
			setIsLoading(false);
		}
	}, [userId, isOpen]);

	// Initial check when modal opens
	useEffect(() => {
		if (isOpen) {
			checkConnection();
		}
	}, [checkConnection, isOpen]);

	// Generate auth URL for connection
	const fetchAuthUrl = async () => {
		try {
			setIsLoading(true);
			const url = await generateAuthUrl();
			setAuthUrl(url);
			return url;
		} catch (error) {
			console.error("Error generating URL:", error);
			setError("Failed to generate connection URL");
			toast.error("Failed to generate LinkedIn connection URL");
			return null;
		} finally {
			setIsLoading(false);
		}
	};

	// Monitor the auth window
	useEffect(() => {
		let checkWindowInterval;

		if (authWindow && isAuthenticating) {
			checkWindowInterval = setInterval(() => {
				// Check if the window was closed
				if (authWindow.closed) {
					clearInterval(checkWindowInterval);
					setIsAuthenticating(false);
					setAuthWindow(null);

					// After the window closes, check if the account was connected
					setTimeout(() => {
						checkConnection();
					}, 1000);
				}
			}, 500);
		}

		return () => {
			if (checkWindowInterval) {
				clearInterval(checkWindowInterval);
			}
		};
	}, [authWindow, isAuthenticating, checkConnection]);

	// Handle connect button click
	const handleConnect = async (e) => {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}

		setIsAuthenticating(true);

		// Fetch new auth URL if not available
		let connectUrl = authUrl;
		if (!connectUrl) {
			connectUrl = await fetchAuthUrl();
			if (!connectUrl) {
				setIsAuthenticating(false);
				return;
			}
		}

		// Open the auth window
		const newWindow = window.open(connectUrl, "_blank", "width=600,height=600");
		setAuthWindow(newWindow);

		// If window fails to open
		if (
			!newWindow ||
			newWindow.closed ||
			typeof newWindow.closed === "undefined"
		) {
			toast.error("Popup blocked. Please allow popups for this site.");
			setIsAuthenticating(false);
		}
	};

	// Handle sync posts button click
	const handleSyncPosts = async (e) => {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}

		console.log("Account ID:", unipileAccountId);
		console.log("LinkedIn Profile ID:", linkedinProfileId);

		if (!isConnected) {
			toast.error("LinkedIn account not connected");
			return;
		}

		if (!unipileAccountId) {
			// If account ID is missing, try to find it by checking connection again
			await checkConnection();

			// If still not found, show error
			if (!unipileAccountId) {
				toast.error("Could not retrieve LinkedIn account ID");
				return;
			}
		}

		if (!linkedinProfileId) {
			toast.error("LinkedIn profile ID not found");
			return;
		}

		setIsSyncing(true);

		try {
			// Call the API to sync posts with linkedin_internal_id
			const response = await axios.post("/api/unipile/posts/sync", {
				account_id: unipileAccountId,
				user_id: userId,
				linkedin_internal_id: linkedinProfileId,
			});

			if (response.data.success) {
				toast.success("LinkedIn posts synced successfully");
			} else {
				toast.error(response.data.message || "Failed to sync LinkedIn posts");
			}
		} catch (error) {
			console.error("Error syncing LinkedIn posts:", error);
			toast.error(
				error.response?.data?.message ||
					"Failed to sync LinkedIn posts. Please try again later.",
			);
		} finally {
			setIsSyncing(false);
		}
	};

	// Handle close with propagation prevention
	const handleClose = (e) => {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}
		// Pass connection success status back to parent
		onClose(e, connectionSuccessful);
	};

	// Prevent propagation on dialog content click
	const handleDialogClick = (e) => {
		e.stopPropagation();
	};

	// Additional effect to ensure account ID is set when connection is successful
	useEffect(() => {
		if (isConnected && linkedinProfileId) {
			// Get accounts and find the matching one
			const getAccountId = async () => {
				try {
					const response = await axios.get("/api/messaging/accounts");
					const matchingAccount = response.data.items.find((account) => {
						return account.connection_params?.im?.id === linkedinProfileId;
					});

					if (matchingAccount) {
						findMatchingAccount(matchingAccount.id);
					}
				} catch (err) {
					console.error("Error retrieving account ID:", err);
				}
			};

			getAccountId();
		}
	}, [isConnected, linkedinProfileId, findMatchingAccount]);

	return (
		<Dialog open={isOpen} onOpenChange={handleClose}>
			<DialogContent className="sm:max-w-[425px]" onClick={handleDialogClick}>
				<DialogHeader>
					<DialogTitle>LinkedIn Connection Status</DialogTitle>
					<DialogDescription>
						Check and manage LinkedIn connection for this user
					</DialogDescription>
				</DialogHeader>

				<div className="py-4 flex items-center gap-4">
					<Avatar className="h-12 w-12">
						<AvatarImage src={userAvatar} alt={userName} />
						<AvatarFallback>{userName?.charAt(0) || "U"}</AvatarFallback>
					</Avatar>
					<div className="flex flex-col">
						<span className="font-semibold">{userName || "User"}</span>
						<div className="flex items-center gap-2">
							{isLoading ? (
								<span className="text-sm text-muted-foreground flex items-center gap-2">
									<Loader2 className="h-3 w-3 animate-spin" />
									Checking connection...
								</span>
							) : (
								<>
									<div
										className={`h-3 w-3 rounded-full ${
											isConnected
												? "bg-green-500 animate-pulse shadow-[0_0_8px_rgba(34,197,94,0.7)]"
												: "bg-red-500 animate-pulse shadow-[0_0_8px_rgba(239,68,68,0.7)]"
										}`}
									/>
									<span className="text-sm text-muted-foreground">
										{isConnected
											? "LinkedIn Connected"
											: "LinkedIn Not Connected"}
									</span>
								</>
							)}
						</div>
					</div>
				</div>

				{error && <div className="text-sm text-red-500 my-2">{error}</div>}

				{/* Sync Status Section - Always shown, regardless of connection status */}
				<div className="border rounded-md p-4 my-2">
					<h3 className="text-sm font-medium mb-2">Sync Status</h3>
					<div className="flex items-center justify-between">
						<div className="flex items-center gap-2">
							<TooltipProvider>
								<Tooltip>
									<TooltipTrigger asChild>
										<Badge variant={getSyncStatusBadge().variant}>
											{getSyncStatusBadge().icon}
										</Badge>
									</TooltipTrigger>
									<TooltipContent>
										<p>{getSyncStatusBadge().tooltip}</p>
									</TooltipContent>
								</Tooltip>
							</TooltipProvider>
							<span className="text-sm">
								{lastSyncDate
									? formatDistanceToNow(new Date(lastSyncDate), {
											addSuffix: true,
										})
									: "Never synced"}
							</span>
						</div>
						{getSyncStatusBadge().isError && (
							<span className="text-xs text-red-500">Sync failed</span>
						)}
					</div>
					{lastSuccessDate && lastSyncDate && getSyncStatusBadge().isError && (
						<p className="text-xs text-muted-foreground mt-1">
							Last successful sync:{" "}
							{formatDistanceToNow(new Date(lastSuccessDate), {
								addSuffix: true,
							})}
						</p>
					)}
				</div>

				<DialogFooter className="flex flex-col sm:flex-row gap-2">
					{isConnected && (
						<Button
							onClick={handleSyncPosts}
							disabled={isSyncing || isLoading}
							variant="outline"
							className="w-full sm:w-auto"
						>
							{isSyncing ? (
								<>
									<Loader2 className="mr-2 h-4 w-4 animate-spin" />
									Syncing Posts...
								</>
							) : (
								<>
									<RefreshCw className="mr-2 h-4 w-4" />
									Sync LinkedIn Posts
								</>
							)}
						</Button>
					)}
					<div className="flex gap-2 w-full sm:w-auto">
						<Button
							type="button"
							variant="outline"
							onClick={handleClose}
							className="flex-1"
						>
							Close
						</Button>
						{!isConnected && (
							<Button
								onClick={handleConnect}
								disabled={isLoading || isAuthenticating}
								className="flex-1"
							>
								{isAuthenticating ? (
									<>
										<Loader2 className="mr-2 h-4 w-4 animate-spin" />
										Connecting...
									</>
								) : isLoading ? (
									<>
										<Loader2 className="mr-2 h-4 w-4 animate-spin" />
										Loading...
									</>
								) : (
									"Connect to LinkedIn"
								)}
							</Button>
						)}
					</div>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};
