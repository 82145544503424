/***
 *
 *   ANIMATE
 *   Wrapper component to animate children
 *
 *   PROPS
 *   children: children to render (component, required)
 *   type: slideup, slidedown, pop (string, optional, default: slideup)
 *   timeout: timeout time (integer, optional, default: 300)
 *
 **********/

import "./animate.scss";

import { useRef } from "react";
import { CSSTransition } from "react-transition-group";

export function Animate(props) {
	const type = props.type || "slideup";
	const nodeRef = useRef(null);

	return (
		<CSSTransition
			in
			appear
			timeout={props.timeout || 300}
			classNames={`animate ${type}`}
			nodeRef={nodeRef}
		>
			<div ref={nodeRef} className={`animate ${type}`}>
				{props.children}
			</div>
		</CSSTransition>
	);
}
