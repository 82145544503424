import { AuthContext } from "components/lib";
import { Button } from "components/ui/button";
import { EmptyCard } from "components/ui/empty-card";
import { PlusIcon, Settings2Icon } from "lucide-react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import type { AuthContextType } from "types/authContext";
import { AutomationsTable } from "views/automations/components/automations-table.tsx";
import { useAutomations } from "views/automations/hooks/use-automations.ts";
import { useRunAutomation } from "views/automations/hooks/use-run-automation.ts";

interface AutomationsPageProps {
	t: (key: string) => string;
}

export function AutomationsPage({ t }: AutomationsPageProps) {
	const navigate = useNavigate();
	const { user } = useContext<AuthContextType>(AuthContext);
	const { onRunAutomation, isRunAutomationLoading } = useRunAutomation();
	const currentOrganization = user?.organizations?.find(
		(org) => org.id === user?.organization_id,
	);

	const { automations, fetchLoading, deleteAutomation, deleteLoading } =
		useAutomations(currentOrganization?.id ?? "");

	const handleCreateAutomation = () => {
		navigate("/automations/create");
	};

	const handleAutomationClick = (automationId: number) => {
		navigate(`/automations/${automationId}`);
	};

	return (
		<div className="container py-10 space-y-8">
			<div className="flex justify-between items-center">
				<h2 className="text-3xl font-medium tracking-tight">
					<span className="text-muted-foreground">
						{currentOrganization?.name}
					</span>{" "}
					{t("automations.title")}
				</h2>
				<Button onClick={handleCreateAutomation}>
					<PlusIcon className="mr-2 h-4 w-4" />
					{t("automations.create.title")}
				</Button>
			</div>
			{!fetchLoading && automations && automations.length === 0 && (
				<EmptyCard
					title={t("automations.empty.title")}
					description={t("automations.empty.description")}
					icon={Settings2Icon}
					className="mt-8"
					action={
						<Button onClick={handleCreateAutomation} className="mt-4">
							<PlusIcon className="mr-2 h-4 w-4" />
							{t("automations.empty.button")}
						</Button>
					}
				/>
			)}
			<AutomationsTable
				automations={automations}
				onDeleteAutomation={deleteAutomation}
				deleteLoading={deleteLoading}
				isLoading={fetchLoading}
				onSelectAutomation={handleAutomationClick}
				t={t}
				onRunAutomation={onRunAutomation}
				automationRunLoading={isRunAutomationLoading !== undefined}
			/>
		</div>
	);
}
