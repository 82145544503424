import { useCallback, useEffect, useState } from "react";
import type { Post, PostInteraction } from "../types";

interface UseLeadTrackingState {
	searchTerm: string;
	showLeadsPerPost: boolean;
	icpFilter: "all" | "high" | "medium" | "low";
	selectedLead: PostInteraction | null;
	selectedPost: Post | null;
	page: number;
	limit: number;
	syncingPosts: Set<string>;
	syncingProfiles: Set<string>;
	isSubmitting: boolean;
	setSearchTerm: (term: string) => void;
	setShowLeadsPerPost: (show: boolean) => void;
	setIcpFilter: (filter: "all" | "high" | "medium" | "low") => void;
	setSelectedLead: (lead: PostInteraction | null) => void;
	setSelectedPost: (post: Post | null) => void;
	setPage: (page: number) => void;
	setLimit: (limit: number) => void;
	addSyncingPost: (postId: string) => void;
	removeSyncingPost: (postId: string) => void;
	setIsSubmitting: (isSubmitting: boolean) => void;
	setSyncingProfiles: React.Dispatch<React.SetStateAction<Set<string>>>;
	sortBy: string;
	setSortBy: React.Dispatch<React.SetStateAction<string>>;
	sortDirection: "asc" | "desc";
	setSortDirection: React.Dispatch<React.SetStateAction<"asc" | "desc">>;
}

export const useLeadTrackingState = (): UseLeadTrackingState => {
	const [searchTerm, setSearchTerm] = useState("");
	const [showLeadsPerPost, setShowLeadsPerPost] = useState(false);
	const [icpFilter, setIcpFilter] = useState<"all" | "high" | "medium" | "low">(
		"all",
	);
	const [selectedLead, setSelectedLead] = useState<
		PostInteraction | null
	>(null);
	const [selectedPost, setSelectedPost] = useState<Post | null>(null);
	const [page, setPageInternal] = useState(1);
	const [limit, setLimit] = useState(10);
	const [syncingPosts, setSyncingPosts] = useState<Set<string>>(new Set());
	const [syncingProfiles, setSyncingProfiles] = useState<Set<string>>(
		new Set(),
	);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [sortBy, setSortBy] = useState("creation_time");
	const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");

	// Use the most recent updated value for debugging
	const [lastPageSet, setLastPageSet] = useState<number | null>(null);

	// Use a memoized callback to ensure setPage is stable and properly updates state
	const setPage = useCallback((newPage: number) => {
		console.log("Setting page to:", newPage);
		setLastPageSet(newPage);
		// Force a synchronous state update by using a functional update
		setPageInternal((current) => {
			console.log("Current page:", current, "New page:", newPage);
			return newPage;
		});
	}, []);

	// Debug effect to verify page changes
	useEffect(() => {
		console.log("Page state changed to:", page);
		if (lastPageSet !== null && page !== lastPageSet) {
			console.warn(
				"Page mismatch detected. Expected:",
				lastPageSet,
				"Actual:",
				page,
			);
		}
	}, [page, lastPageSet]);

	const addSyncingPost = (postId: string) => {
		setSyncingPosts((prev) => new Set(prev).add(postId));
	};

	const removeSyncingPost = (postId: string) => {
		setSyncingPosts((prev) => {
			const newSet = new Set(prev);
			newSet.delete(postId);
			return newSet;
		});
	};

	return {
		searchTerm,
		showLeadsPerPost,
		icpFilter,
		selectedLead,
		selectedPost,
		page,
		limit,
		syncingPosts,
		syncingProfiles,
		isSubmitting,
		setSearchTerm,
		setShowLeadsPerPost,
		setIcpFilter,
		setSelectedLead,
		setSelectedPost,
		setPage,
		setLimit,
		addSyncingPost,
		removeSyncingPost,
		setSyncingProfiles,
		setIsSubmitting,
		sortBy,
		setSortBy,
		sortDirection,
		setSortDirection,
	};
};
