import { FormLabelWithTooltip } from "components/form/form-label-with-tooltip";
import { useToast } from "components/hooks/use-toast";
import { Button } from "components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "components/ui/dialog";
import { Form, FormControl, FormField, FormItem } from "components/ui/form";
import { Textarea } from "components/ui/textarea";
import { Loader2, Settings } from "lucide-react";
import { useState } from "react";
import { useIcpForm } from "../_hooks/use-icp-form";
import type { IcpFormData } from "../schemas";

interface IcpSettingsDialogProps {
	userId: string;
	token: string;
}

export const IcpSettingsDialog = ({
	userId,
	token,
}: IcpSettingsDialogProps) => {
	const { form, settingsLoading, handleSubmit } = useIcpForm(userId, token);
	const { toast } = useToast();
	const [open, setOpen] = useState(false);

	const onSubmit = async (data: IcpFormData) => {
		const success = await handleSubmit(data);
		if (success) {
			toast({
				title: "Configuración guardada",
				description: "Los ajustes de ICP se han actualizado correctamente",
				variant: "default",
			});
			setOpen(false);
		} else {
			toast({
				title: "Error",
				description:
					"No se pudieron guardar los ajustes. Por favor, intenta de nuevo.",
				variant: "destructive",
			});
		}
	};

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogTrigger asChild>
				<Button variant="outline">
					<Settings className="w-4 h-4 mr-2" />
					ICP Settings
				</Button>
			</DialogTrigger>
			<DialogContent className="max-w-[600px]">
				<DialogHeader>
					<DialogTitle>ICP Configuration</DialogTitle>
				</DialogHeader>
				<Form {...form}>
					<form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
						<FormField
							control={form.control}
							name="icp_description"
							render={({ field }) => (
								<FormItem>
									<FormLabelWithTooltip
										label="Descripción"
										tooltip="General description of your ideal customer profile, including key characteristics and pain points."
									/>
									<FormControl>
										<Textarea
											{...field}
											placeholder="Ej: Empresas B2B de tecnología en crecimiento que necesitan automatizar sus procesos de ventas. Buscan aumentar su eficiencia operativa y tienen un equipo de ventas de al menos 5 personas. Sus principales desafíos son la gestión manual de leads y la falta de visibilidad en el pipeline de ventas."
										/>
									</FormControl>
								</FormItem>
							)}
						/>
						<FormField
							control={form.control}
							name="icp_target_industries"
							render={({ field }) => (
								<FormItem>
									<FormLabelWithTooltip
										label="Industrias Objetivo"
										tooltip="Specific industries or business sectors your product/service is best suited for."
									/>
									<FormControl>
										<Textarea
											{...field}
											placeholder="Ej: Software/SaaS, Servicios Financieros, E-commerce, Marketing Digital..."
										/>
									</FormControl>
								</FormItem>
							)}
						/>
						<FormField
							control={form.control}
							name="icp_target_roles"
							render={({ field }) => (
								<FormItem>
									<FormLabelWithTooltip
										label="Roles Objetivo"
										tooltip="Job titles, positions, or decision-makers you typically sell to."
									/>
									<FormControl>
										<Textarea
											{...field}
											placeholder="Ej: CEO, Director de Marketing, VP de Ventas, Gerente de Recursos Humanos..."
										/>
									</FormControl>
								</FormItem>
							)}
						/>
						<FormField
							control={form.control}
							name="icp_company_size"
							render={({ field }) => (
								<FormItem>
									<FormLabelWithTooltip
										label="Tamaño de Empresa"
										tooltip="Preferred company size range in terms of employees."
									/>
									<FormControl>
										<Textarea
											{...field}
											placeholder="Ej: 50-200 empleados, $1M-$10M en ingresos anuales, Startups en Serie A..."
										/>
									</FormControl>
								</FormItem>
							)}
						/>
						<FormField
							control={form.control}
							name="icp_geography"
							render={({ field }) => (
								<FormItem>
									<FormLabelWithTooltip
										label="Ubicación Geográfica"
										tooltip="Target geographical locations, regions, or markets."
									/>
									<FormControl>
										<Textarea
											{...field}
											placeholder="Ej: LATAM, España, México, Colombia, Ciudades principales de Sudamérica..."
										/>
									</FormControl>
								</FormItem>
							)}
						/>
						<FormField
							control={form.control}
							name="icp_goals"
							render={({ field }) => (
								<FormItem>
									<FormLabelWithTooltip
										label="Objetivos"
										tooltip="What is your goal with these leads? (e.g., recruiting, selling your product/service, partnerships)"
									/>
									<FormControl>
										<Textarea
											{...field}
											placeholder="Ej: Vender servicios de consultoría, Reclutar talento tech, Establecer alianzas estratégicas..."
										/>
									</FormControl>
								</FormItem>
							)}
						/>
						<Button type="submit" disabled={settingsLoading} className="w-full">
							{settingsLoading ? (
								<>
									<Loader2 className="mr-2 h-4 w-4 animate-spin" />
									Guardando...
								</>
							) : (
								<>
									<Settings className="mr-2 h-4 w-4" />
									Guardar Settings
								</>
							)}
						</Button>
					</form>
				</Form>
			</DialogContent>
		</Dialog>
	);
};
