import { z } from "zod";

export const automationSchema = z.object({
	name: z.string({
		required_error: "Please enter a name for your automation",
	}),
	sender: z.string({
		required_error: "Please select a sender",
	}),
	exclusions: z.array(z.string()),
	templateMessage: z
		.string({
			required_error: "Please provide a message template",
		})
		.min(10, "Message template must be at least 10 characters"),
});

export type AutomationFormValues = z.infer<typeof automationSchema>;
