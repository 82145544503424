import { Badge } from "components/ui/badge";
import type { OrganizationICP } from "types/organization-icp";

interface ICPCardProps {
	icp: OrganizationICP;
}

export const ICPCard = ({ icp }: ICPCardProps) => {
	const industries = icp.targeting_criteria.industries
		? icp.targeting_criteria.industries.split(",").map((i) => i.trim())
		: ["No Industry"];

	const size =
		icp.targeting_criteria.minStaffCount && icp.targeting_criteria.maxStaffCount
			? `${icp.targeting_criteria.minStaffCount}-${icp.targeting_criteria.maxStaffCount}`
			: "Any Size";

	return (
		<div className="cursor-pointer rounded-lg border bg-card p-3 transition-colors hover:bg-accent/50">
			<h4 className="font-medium">{icp.name}</h4>

			{icp.targeting_criteria.description && (
				<p className="mt-2 text-sm text-muted-foreground line-clamp-2">
					{icp.targeting_criteria.description}
				</p>
			)}

			<div className="mt-2 flex flex-wrap gap-1">
				{industries.map((industry) => (
					<Badge key={industry} variant="secondary">
						{industry}
					</Badge>
				))}
			</div>

			<div className="mt-2">
				<Badge variant="outline">{size} employees</Badge>
			</div>
		</div>
	);
};
