import * as z from "zod";

export const icpFormSchema = z.object({
	name: z.string().min(1, "Name is required"),
	targeting_criteria: z.object({
		locations: z.string().optional(),
		description: z.string().optional(),
		type: z.string().optional(),
		industries: z.string().optional(),
		specialities: z.string().optional(),
		minFollowerCount: z.string().optional(),
		maxFollowerCount: z.string().optional(),
		minStaffCount: z.string().optional(),
		maxStaffCount: z.string().optional(),
		minFoundingYear: z.string().optional(),
		maxFoundingYear: z.string().optional(),
		fundingData: z
			.object({
				minLastMoneyRaised: z.string().optional(),
				maxLastMoneyRaised: z.string().optional(),
				minLastFundingYear: z.string().optional(),
				maxLastFundingYear: z.string().optional(),
				minLastFundingType: z.string().optional(),
			})
			.optional(),
		postsTopics: z.string().optional(),
		minAverageInteractionPerPostCount: z.string().optional(),
		maxAverageInteractionPerPostCount: z.string().optional(),
	}),
});

export type ICPFormValues = z.infer<typeof icpFormSchema>;
