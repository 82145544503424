import axios from "axios";
import type { ApiResponse } from "types/api";
import type { LinkedinInvite } from "types/linkedin-invite";

export const getLinkedinInvitesByAutomationId = async (
	automationId: number,
): Promise<ApiResponse<LinkedinInvite[]>> => {
	const { data } = await axios.get<ApiResponse<LinkedinInvite[]>>(
		`/api/linkedin-invites/automation/${automationId}`,
	);
	return data;
};
