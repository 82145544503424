import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { useContext } from "react";
import { useForm } from "react-hook-form";

import { useToast } from "components/hooks/use-toast";
import { AuthContext } from "components/lib";
import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
	AlertDialogTrigger,
} from "components/ui/alert-dialog";
import { Button } from "components/ui/button";
import { Collapsible, CollapsibleContent } from "components/ui/collapsible";
import {
	Form,
	FormControl,
	FormDescription,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "components/ui/form";
import { Input } from "components/ui/input";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "components/ui/select";
import { Separator } from "components/ui/separator";
import { Switch } from "components/ui/switch";
import { Textarea } from "components/ui/textarea";
import { useCreateAudience } from "hooks/use-create-audience";
import { useDeleteAudience } from "hooks/use-delete-audience";
import { useUpdateAudience } from "hooks/use-update-audience";
import type { AuthContextType } from "types/authContext";
import { convertAudienceFormToPayload } from "utils/form-helpers";

import type { AudienceFormValues } from "schemas/audience-schemas";
import { audienceFormSchema } from "schemas/audience-schemas";
import type { Audience } from "types/audience";
import type { OrganizationICP } from "types/organization-icp";
import {
	getAudienceFormValues,
	hasAdvancedAudienceFields,
} from "utils/form-helpers";

interface AudienceFormProps {
	onSuccess?: () => void;
	onCancel?: () => void;
	icps: OrganizationICP[];
	audienceToEdit?: Audience | null;
	owner_user_id?: string;
	t: (key: string) => string;
	organizationId?: string;
}

export function AudienceForm({
	onSuccess,
	onCancel,
	icps,
	audienceToEdit,
	owner_user_id,
	t,
	organizationId,
}: AudienceFormProps) {
	const auth = useContext<AuthContextType>(AuthContext);
	const effectiveOrgId = organizationId || auth.user?.organization_id || "";

	const { toast } = useToast();
	const { createAudience, isCreating } = useCreateAudience(
		effectiveOrgId,
		owner_user_id,
	);
	const { updateAudience, isUpdating } = useUpdateAudience();
	const { deleteAudience, isDeleting } = useDeleteAudience();

	const isSubmitting = audienceToEdit ? isUpdating : isCreating;

	const [showAdvanced, setShowAdvanced] = useState(false);
	const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

	useEffect(() => {
		if (audienceToEdit && hasAdvancedAudienceFields(audienceToEdit)) {
			setShowAdvanced(true);
		}
	}, [audienceToEdit]);

	const form = useForm<AudienceFormValues>({
		resolver: zodResolver(audienceFormSchema),
		defaultValues: getAudienceFormValues(audienceToEdit),
	});

	useEffect(() => {
		if (audienceToEdit) {
			form.reset(getAudienceFormValues(audienceToEdit));
		}
	}, [audienceToEdit, form]);

	const handleSubmit = form.handleSubmit(async (data) => {
		try {
			const payload = convertAudienceFormToPayload(data);

			if (audienceToEdit) {
				await updateAudience(audienceToEdit.id, payload);
				toast({
					title: t("organization.audiences.update_success_title"),
					description: t("organization.audiences.update_success_description"),
				});
			} else {
				await createAudience(payload);
				toast({
					title: t("organization.audiences.create_success_title"),
					description: t("organization.audiences.create_success_description"),
				});
			}

			if (onSuccess) {
				onSuccess();
			}
		} catch (error) {
			console.error(error);
			toast({
				title: audienceToEdit
					? t("organization.audiences.update_error_title")
					: t("organization.audiences.create_error_title"),
				description: audienceToEdit
					? t("organization.audiences.update_error_description")
					: t("organization.audiences.create_error_description"),
				variant: "destructive",
			});
		}
	});

	const handleDelete = async () => {
		if (!audienceToEdit) return;

		try {
			await deleteAudience(audienceToEdit.id);
			toast({
				title: t("organization.audiences.delete_success_title"),
				description: t("organization.audiences.delete_success_description"),
			});
			if (onSuccess) {
				onSuccess();
			}
		} catch (error) {
			console.error(error);
			toast({
				title: t("organization.audiences.delete_error_title"),
				description: t("organization.audiences.delete_error_description"),
				variant: "destructive",
			});
		}
	};

	return (
		<Form {...form}>
			<div className="mb-6 flex items-center justify-between">
				<h2 className="text-2xl font-bold">
					{audienceToEdit
						? t("organization.audiences.edit_dialog.title")
						: t("organization.audiences.create_dialog.title")}
				</h2>
			</div>
			<form onSubmit={handleSubmit} className="space-y-6">
				<FormField
					control={form.control}
					name="name"
					render={({ field }) => (
						<FormItem>
							<FormLabel>
								{t("organization.audiences.create_dialog.name_label")}
							</FormLabel>
							<FormControl>
								<Input
									placeholder={t(
										"organization.audiences.create_dialog.name_placeholder",
									)}
									{...field}
								/>
							</FormControl>
							<FormMessage />
						</FormItem>
					)}
				/>

				<FormField
					control={form.control}
					name="organization_icp_id"
					render={({ field }) => (
						<FormItem>
							<FormLabel>
								{t("organization.audiences.create_dialog.icp_label")}
							</FormLabel>
							<Select
								onValueChange={(value) => {
									field.onChange(value === "none" ? "" : value);
								}}
								value={field.value || "none"}
							>
								<FormControl>
									<SelectTrigger>
										<SelectValue
											placeholder={t(
												"organization.audiences.create_dialog.icp_placeholder",
											)}
										/>
									</SelectTrigger>
								</FormControl>
								<SelectContent>
									<SelectItem value="none">
										{t("organization.audiences.icp.none")}
									</SelectItem>
									{icps.map((icp) => (
										<SelectItem key={icp.id} value={icp.id.toString()}>
											{icp.name}
										</SelectItem>
									))}
								</SelectContent>
							</Select>
							<FormMessage />
						</FormItem>
					)}
				/>

				<FormField
					control={form.control}
					name="targeting_criteria.description"
					render={({ field }) => (
						<FormItem>
							<FormLabel>
								{t("organization.audiences.create_dialog.description_label")}
							</FormLabel>
							<FormControl>
								<Textarea
									placeholder={t(
										"organization.audiences.create_dialog.description_placeholder",
									)}
									{...field}
								/>
							</FormControl>
							<FormMessage />
						</FormItem>
					)}
				/>

				<div className="grid gap-4 md:grid-cols-2">
					<FormField
						control={form.control}
						name="targeting_criteria.currentRole"
						render={({ field }) => (
							<FormItem>
								<FormLabel>
									{t("organization.audiences.create_dialog.current_role_label")}
								</FormLabel>
								<FormControl>
									<Input
										placeholder={t(
											"organization.audiences.create_dialog.current_role_placeholder",
										)}
										{...field}
									/>
								</FormControl>
								<FormDescription>
									{t("organization.audiences.create_dialog.comma_separated")}
								</FormDescription>
								<FormMessage />
							</FormItem>
						)}
					/>

					<FormField
						control={form.control}
						name="targeting_criteria.location"
						render={({ field }) => (
							<FormItem>
								<FormLabel>
									{t("organization.audiences.create_dialog.location_label")}
								</FormLabel>
								<FormControl>
									<Input
										placeholder={t(
											"organization.audiences.create_dialog.location_placeholder",
										)}
										{...field}
									/>
								</FormControl>
								<FormDescription>
									{t("organization.audiences.create_dialog.comma_separated")}
								</FormDescription>
								<FormMessage />
							</FormItem>
						)}
					/>
				</div>

				<div className="grid gap-4 md:grid-cols-2">
					<FormField
						control={form.control}
						name="targeting_criteria.education"
						render={({ field }) => (
							<FormItem>
								<FormLabel>
									{t("organization.audiences.create_dialog.education_label")}
								</FormLabel>
								<Select
									onValueChange={field.onChange}
									defaultValue={field.value}
								>
									<FormControl>
										<SelectTrigger>
											<SelectValue
												placeholder={t(
													"organization.audiences.create_dialog.education_placeholder",
												)}
											/>
										</SelectTrigger>
									</FormControl>
									<SelectContent>
										<SelectItem value="highSchool">
											{t("organization.audiences.education.options.highSchool")}
										</SelectItem>
										<SelectItem value="associates">
											{t("organization.audiences.education.options.associates")}
										</SelectItem>
										<SelectItem value="bachelors">
											{t("organization.audiences.education.options.bachelors")}
										</SelectItem>
										<SelectItem value="masters">
											{t("organization.audiences.education.options.masters")}
										</SelectItem>
										<SelectItem value="doctorate">
											{t("organization.audiences.education.options.doctorate")}
										</SelectItem>
										<SelectItem value="professional">
											{t(
												"organization.audiences.education.options.professional",
											)}
										</SelectItem>
									</SelectContent>
								</Select>
								<FormMessage />
							</FormItem>
						)}
					/>

					<FormField
						control={form.control}
						name="targeting_criteria.languages"
						render={({ field }) => (
							<FormItem>
								<FormLabel>
									{t("organization.audiences.create_dialog.languages_label")}
								</FormLabel>
								<FormControl>
									<Input
										placeholder={t(
											"organization.audiences.create_dialog.languages_placeholder",
										)}
										{...field}
									/>
								</FormControl>

								<FormDescription>
									{t("organization.audiences.create_dialog.comma_separated")}
								</FormDescription>
								<FormMessage />
							</FormItem>
						)}
					/>
				</div>

				<Separator className="my-4" />

				<div className="flex items-center space-x-2">
					<Switch
						checked={showAdvanced}
						onCheckedChange={setShowAdvanced}
						id="show-advanced"
					/>
					<label
						htmlFor="show-advanced"
						className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 cursor-pointer"
					>
						{t("organization.audiences.form.show_advanced")}
					</label>
				</div>

				<Collapsible open={showAdvanced}>
					<CollapsibleContent className="space-y-4">
						<div className="grid gap-4 md:grid-cols-2">
							<FormField
								control={form.control}
								name="targeting_criteria.minFollowerCount"
								render={({ field }) => (
									<FormItem>
										<FormLabel>
											{t(
												"organization.audiences.create_dialog.followers_min_label",
											)}
										</FormLabel>
										<FormControl>
											<Input
												type="number"
												placeholder={t(
													"organization.audiences.create_dialog.followers_min_placeholder",
												)}
												{...field}
											/>
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>
							<FormField
								control={form.control}
								name="targeting_criteria.maxFollowerCount"
								render={({ field }) => (
									<FormItem>
										<FormLabel>
											{t(
												"organization.audiences.create_dialog.followers_max_label",
											)}
										</FormLabel>
										<FormControl>
											<Input
												type="number"
												placeholder={t(
													"organization.audiences.create_dialog.followers_max_placeholder",
												)}
												{...field}
											/>
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>
						</div>

						<div className="grid gap-4 md:grid-cols-2">
							<FormField
								control={form.control}
								name="targeting_criteria.minAverageInteractionPerPostCount"
								render={({ field }) => (
									<FormItem>
										<FormLabel>
											{t(
												"organization.audiences.create_dialog.interactions_min_label",
											)}
										</FormLabel>
										<FormControl>
											<Input
												type="number"
												placeholder={t(
													"organization.audiences.create_dialog.interactions_min_placeholder",
												)}
												{...field}
											/>
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>
							<FormField
								control={form.control}
								name="targeting_criteria.maxAverageInteractionPerPostCount"
								render={({ field }) => (
									<FormItem>
										<FormLabel>
											{t(
												"organization.audiences.create_dialog.interactions_max_label",
											)}
										</FormLabel>
										<FormControl>
											<Input
												type="number"
												placeholder={t(
													"organization.audiences.create_dialog.interactions_max_placeholder",
												)}
												{...field}
											/>
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>
						</div>

						<FormField
							control={form.control}
							name="targeting_criteria.skills"
							render={({ field }) => (
								<FormItem>
									<FormLabel>
										{t("organization.audiences.create_dialog.skills_label")}
									</FormLabel>
									<FormControl>
										<Textarea
											placeholder={t(
												"organization.audiences.create_dialog.skills_placeholder",
											)}
											{...field}
										/>
									</FormControl>
									<FormDescription>
										{t("organization.audiences.create_dialog.comma_separated")}
									</FormDescription>
									<FormMessage />
								</FormItem>
							)}
						/>

						<FormField
							control={form.control}
							name="targeting_criteria.postsTopics"
							render={({ field }) => (
								<FormItem>
									<FormLabel>
										{t(
											"organization.audiences.create_dialog.posts_about_label",
										)}
									</FormLabel>
									<FormControl>
										<Textarea
											placeholder={t(
												"organization.audiences.create_dialog.posts_about_placeholder",
											)}
											{...field}
										/>
									</FormControl>
									<FormDescription>
										{t("organization.audiences.create_dialog.comma_separated")}
									</FormDescription>
									<FormMessage />
								</FormItem>
							)}
						/>
					</CollapsibleContent>
				</Collapsible>

				<div className="flex w-full gap-4">
					{onCancel && (
						<Button
							type="button"
							variant="outline"
							onClick={onCancel}
							className="w-[15%]"
							disabled={isSubmitting || isDeleting}
						>
							{t("organization.audiences.form.cancel")}
						</Button>
					)}

					{audienceToEdit && (
						<AlertDialog
							open={showDeleteConfirm}
							onOpenChange={setShowDeleteConfirm}
						>
							<AlertDialogTrigger asChild>
								<Button
									type="button"
									variant="destructive"
									className="w-[15%]"
									disabled={isSubmitting || isDeleting}
								>
									{t("organization.audiences.delete_button")}
								</Button>
							</AlertDialogTrigger>
							<AlertDialogContent>
								<AlertDialogHeader>
									<AlertDialogTitle>
										{t("organization.audiences.delete_confirm.title")}
									</AlertDialogTitle>
									<AlertDialogDescription>
										{t("organization.audiences.delete_confirm.description")}
									</AlertDialogDescription>
								</AlertDialogHeader>
								<AlertDialogFooter>
									<AlertDialogCancel>
										{t("organization.audiences.delete_confirm.cancel")}
									</AlertDialogCancel>
									<AlertDialogAction
										onClick={handleDelete}
										className="bg-destructive text-destructive-foreground hover:bg-destructive/90"
									>
										{isDeleting
											? t("organization.audiences.delete_confirm.deleting")
											: t("organization.audiences.delete_confirm.confirm")}
									</AlertDialogAction>
								</AlertDialogFooter>
							</AlertDialogContent>
						</AlertDialog>
					)}

					<Button
						type="submit"
						className={
							onCancel ? (audienceToEdit ? "w-[70%]" : "w-[85%]") : "w-full"
						}
						disabled={isSubmitting || isDeleting}
					>
						{isSubmitting
							? audienceToEdit
								? t("organization.audiences.form.updating")
								: t("organization.audiences.form.creating")
							: audienceToEdit
								? t("organization.audiences.form.update")
								: t("organization.audiences.form.create")}
					</Button>
				</div>
			</form>
		</Form>
	);
}
