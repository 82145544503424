import { DateRangeSelector } from "components/date/DateRangeSelector";
import { Animate, AuthContext } from "components/lib";
import { Card } from "components/ui/card";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectLabel,
	SelectTrigger,
	SelectValue,
} from "components/ui/select";
import { endOfDay, format, subDays } from "date-fns";
import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import type { AuthContextType } from "types/authContext";
import { SkeletonSelect } from "views/dashboard/_components/skeleton-select";
import { sortedTableTimeFrameOptions } from "views/dashboard/utils";
import { ConsistencyTracker } from "./_components/consistency-tracker";
import { MetricCards } from "./_components/metric-cards";
import { MetricChart } from "./_components/metric-chart";
import { TopPerformingPosts } from "./_components/top-performing-posts";
import { useOrganizationUsers } from "./_hooks/use-organization-users";
import { useUserDetailedMetrics } from "./_hooks/use-user-detailed-metrics";

type Props = {
	t: (key: string) => string;
};

type TimeFrameOption = {
	label: string;
	value: string | number;
	type?: string;
	from?: string;
	to?: string;
};

export const Stats: React.FC<Props> = ({ t }) => {
	const { userId } = useParams();
	const navigate = useNavigate();
	const auth: AuthContextType = useContext(AuthContext);
	const organizationId = auth?.user?.organization_id;

	// Time frame and date range state
	const [timeFrame, setTimeFrame] = useState<TimeFrameOption>({
		...sortedTableTimeFrameOptions[1],
		value: String(sortedTableTimeFrameOptions[1].value),
	});
	const [dateRange, setDateRange] = useState<{
		from: Date | null;
		to: Date | null;
	}>({ from: null, to: null });
	const [showDatePicker, setShowDatePicker] = useState(false);
	// Initialize selectedUserId from URL param or auth
	const [selectedUserId, setSelectedUserId] = useState<string | undefined>(
		userId || auth?.user?.id,
	);
	const [selectedMetric, setSelectedMetric] = useState<string | null>(
		"Followers",
	);

	// Calculate start and end dates
	const startDate = useMemo(() => {
		if (timeFrame.value !== "custom") {
			return format(
				subDays(new Date(), Number(timeFrame.value)),
				"yyyy-MM-dd'T'HH:mm:ssxxx",
			);
		}
		return dateRange.from
			? format(dateRange.from, "yyyy-MM-dd'T'HH:mm:ssxxx")
			: format(new Date(), "yyyy-MM-dd'T'HH:mm:ssxxx");
	}, [timeFrame, dateRange.from]);

	const endDate = useMemo(() => {
		if (timeFrame.value !== "custom") {
			return format(new Date(), "yyyy-MM-dd'T'HH:mm:ssxxx");
		}
		return dateRange.to
			? format(dateRange.to, "yyyy-MM-dd'T'HH:mm:ssxxx")
			: format(new Date(), "yyyy-MM-dd'T'HH:mm:ssxxx");
	}, [timeFrame, dateRange.to]);

	const { users, loading, error } = useOrganizationUsers(
		organizationId,
		startDate,
		endDate,
		timeFrame.type || "days",
	);

	// Update URL when user changes
	const handleUserChange = (newUserId: string) => {
		setSelectedUserId(newUserId);
		navigate(`/stats/${newUserId}`);
	};

	// Update selectedUserId when URL param changes
	useEffect(() => {
		if (userId) {
			setSelectedUserId(userId);
		}
	}, [userId]);

	const handleDateChange = ({
		startDate,
		endDate,
		timeFrame: newTimeFrame,
	}: {
		startDate: string;
		endDate: string;
		timeFrame?: TimeFrameOption;
	}) => {
		const from = new Date(startDate);
		const to = endOfDay(new Date(endDate));
		setDateRange({ from, to });

		if (newTimeFrame) {
			if (newTimeFrame.value === "custom") {
				setTimeFrame({
					...newTimeFrame,
					label: `${format(from, "MMM d")} - ${format(to, "MMM d")}`,
					from: from.toISOString(),
					to: to.toISOString(),
				});
			} else {
				setTimeFrame({
					...newTimeFrame,
					label:
						sortedTableTimeFrameOptions.find(
							(opt) => opt.value === newTimeFrame.value,
						)?.label || String(newTimeFrame.value),
				});
			}
		}

		setShowDatePicker(false);
	};

	const { metrics: detailedMetrics, loading: detailedLoading } =
		useUserDetailedMetrics(selectedUserId, startDate, endDate);

	// Transform time series data for metrics
	const metricsData = useMemo(() => {
		if (!detailedMetrics?.metrics)
			return {
				posts: [],
				interactions: [],
				engagement: [],
				comments: [],
				shares: [],
				impressions: [],
				blogs: [],
				followers: [],
			};

		const allFollowers = detailedMetrics.metrics
			.map((d) => d.linkedin.followers)
			.filter((value) => ![0, 2, 43, 6].includes(value));

		return {
			posts: detailedMetrics.metrics.map((d) => d.linkedin.posts),
			interactions: detailedMetrics.metrics.map((d) => d.linkedin.interactions),
			engagement: detailedMetrics.metrics.map((d) => d.linkedin.engagement),
			comments: detailedMetrics.metrics.map((d) => d.linkedin.comments),
			shares: detailedMetrics.metrics.map((d) => d.linkedin.shares),
			impressions: detailedMetrics.metrics.map((d) => d.linkedin.impressions),
			blogs: detailedMetrics.metrics.map((d) => d.wordpress.posts),
			followers: allFollowers,
		};
	}, [detailedMetrics]);

	// Transform posts data for the table
	const transformedPosts = useMemo(() => {
		if (!detailedMetrics?.post_details?.posts) return [];

		return detailedMetrics.post_details.posts.map((post) => ({
			id: post.id,
			date: post.date,
			content: post.content,
			url: post.url,
			metrics: {
				likes: post.metrics.likes,
				comments: post.metrics.comments,
				shares: post.metrics.shares,
				impressions: post.metrics.impressions,
				engagement: post.metrics.engagement,
			},
		}));
	}, [detailedMetrics]);

	return (
		<Animate type="pop">
			<div className="mx-auto max-w-6xl py-6 space-y-4">
				<Card className="p-6 bg-white">
					{loading ? (
						<SkeletonSelect />
					) : (
						<div className="flex justify-between items-start">
							<div className="flex-grow">
								<DateRangeSelector
									t={t}
									onDateChange={handleDateChange}
									initialTimeFrame={timeFrame}
								/>
							</div>
							<div className="flex-shrink-0 ml-4">
								<Select value={selectedUserId} onValueChange={handleUserChange}>
									<SelectTrigger className="max-w-60 bg-white">
										<SelectValue>
											{users?.find((user) => user.user_id === selectedUserId)
												?.user_name || "Select User"}
										</SelectValue>
									</SelectTrigger>
									<SelectContent>
										<SelectGroup>
											<SelectLabel>Users</SelectLabel>
											{users?.map((user) => (
												<SelectItem key={user.user_id} value={user.user_id}>
													{user.user_name}
												</SelectItem>
											))}
										</SelectGroup>
									</SelectContent>
								</Select>
							</div>
						</div>
					)}
				</Card>
				<MetricCards
					user={users?.find((user) => user.user_id === selectedUserId)}
					loading={loading || detailedLoading}
					selectedMetric={selectedMetric}
					onMetricSelect={setSelectedMetric}
					metricsData={metricsData}
				/>

				{detailedMetrics?.metrics && selectedMetric && (
					<MetricChart
						selectedMetric={selectedMetric}
						metrics={detailedMetrics.metrics}
						loading={loading || detailedLoading}
					/>
				)}

				{detailedMetrics?.post_details?.daily_stats && (
					<ConsistencyTracker
						loading={loading || detailedLoading}
						yearlyStats={detailedMetrics.post_details.daily_stats}
					/>
				)}

				{transformedPosts && (
					<TopPerformingPosts
						posts={transformedPosts}
						loading={loading || detailedLoading}
					/>
				)}
			</div>
		</Animate>
	);
};

export default Stats;
