import axios from "axios";
import { useToast } from "components/hooks/use-toast";
import { AuthContext } from "components/lib";
import { Button } from "components/ui/button";
import { Plus } from "lucide-react";
import { useCallback, useContext, useEffect, useState } from "react";
import { CreateTeamDialog } from "./_components/create-team-dialog";
import { SkeletonTeamCard } from "./_components/skeleton-team-card";
import { TeamCard } from "./_components/team-card";

export interface Team {
	id: number;
	name: string;
}

type TeamUser = {
	id: string;
	name: string;
	email: string;
};

export const Teams = () => {
	// biome-ignore lint/suspicious/noExplicitAny: <auth>
	const auth: any = useContext(AuthContext);
	const organization = auth.user?.organizations.find(
		// biome-ignore lint/suspicious/noExplicitAny: <org type>
		(org: any) => org.id === auth.user?.organization_id,
	);
	const organizationId = organization?.id;

	const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
	const [teams, setTeams] = useState<Team[]>([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const [organizationUsers, setOrganizationUsers] = useState<TeamUser[]>([]);

	const { toast } = useToast();

	const fetchTeams = useCallback(async () => {
		if (!organizationId) return;

		setLoading(true);
		setError(null);
		try {
			const response = await axios.get(`/api/team/${organizationId}`);
			setTeams(response.data || []);
		} catch (err) {
			setError("Error loading teams");
			console.error(err);
			setTeams([]);
		} finally {
			setLoading(false);
		}
	}, [organizationId]);

	useEffect(() => {
		fetchTeams();
	}, [fetchTeams]);

	useEffect(() => {
		const fetchOrganizationUsers = async () => {
			try {
				const response = await axios.get("/api/organization/users");
				const users = response.data.data.users.filter(
					(user: TeamUser) =>
						// remove SoporteIBT
						user.id !== "a8437da7-b295-4d2f-8477-2b4c745da212",
				);
				setOrganizationUsers(users);
			} catch (error) {
				console.error("Error fetching organization users:", error);
				toast({
					title: "Error",
					description: "Failed to fetch organization users.",
					variant: "destructive",
				});
			}
		};

		fetchOrganizationUsers();
	}, [toast]);

	const handleCreateTeam = async (name: string, userIds: string[]) => {
		try {
			await axios.post("/api/team", { name, organizationId, userIds });
			setIsCreateModalOpen(false);
			fetchTeams();
			toast({
				title: "Team created",
				description: `Team "${name}" has been successfully created.`,
				variant: "default",
			});
		} catch (err) {
			console.error("Error creating team:", err);
			toast({
				title: "Error",
				description: "Failed to create team. Please try again.",
				variant: "destructive",
			});
		}
	};

	if (!organizationId) {
		return null;
	}

	return (
		<main className="w-full py-12 md:py-24 lg:py-32">
			<div className="container px-4 md:px-6">
				<div className="space-y-2 text-center">
					<h1 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl">
						{organization.name} Teams
					</h1>
					<p className="max-w-[700px] mx-auto text-muted-foreground md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
						Manage and collaborate with your organization's teams.
					</p>
				</div>
				<div className="flex justify-end mt-8">
					<Button onClick={() => setIsCreateModalOpen(true)}>
						<Plus className="mr-2 h-4 w-4" />
						Create Team
					</Button>
				</div>
				<div className="mt-8">
					{loading ? (
						<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
							<SkeletonTeamCard />
							<SkeletonTeamCard />
							<SkeletonTeamCard />
						</div>
					) : error ? (
						<div>{error}</div>
					) : teams && teams.length > 0 ? (
						<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
							{teams.map((team) => (
								<TeamCard
									key={team.id}
									team={team}
									organizationUsers={organizationUsers}
								/>
							))}
						</div>
					) : (
						<div className="text-center">
							<h2 className="text-2xl font-bold">No teams found</h2>
							<p className="text-muted-foreground mt-2">
								You haven't created any teams yet. Click the "Create Team"
								button to get started.
							</p>
						</div>
					)}
				</div>
				{organizationUsers.length > 0 && (
					<CreateTeamDialog
						isOpen={isCreateModalOpen}
						onClose={() => setIsCreateModalOpen(false)}
						onCreateTeam={handleCreateTeam}
						organizationName={organization.name}
						organizationUsers={organizationUsers}
					/>
				)}
			</div>
		</main>
	);
};
