import axios from "axios";
import { AuthContext } from "components/lib";
import { Badge } from "components/ui/badge";
import { Button } from "components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "components/ui/dialog";
import { useUnipileAuth } from "contexts/unipile-auth.context";
import { MessageSquare, Share2, ThumbsUp } from "lucide-react";
import { Loader2 } from "lucide-react";
import { InfoIcon } from "lucide-react";
import { useContext, useState } from "react";
import React from "react";
import type {
	AuthContextType,
	UnipileAuthContextType,
} from "types/authContext";
import { appConfig } from "views/content-creation/config";
import { usePostReactions } from "../_hooks/use-post-reactions";
import { leadLensApi } from "../services/lead-lens-api";
import type { Post, PostReactionInteraction } from "../types";
import { LeadProfileDialog } from "./lead-profile-dialog";
import { LeadsTable } from "./leads-table/component";

const TextTruncate = ({ text }: { text: string }) => {
	const [showFullText, setShowFullText] = useState(false);

	const toggleFullText = () => {
		setShowFullText(!showFullText);
	};

	const truncatedText = `${text.split("\n").slice(0, 3).join("\n")}...`;

	return (
		<div>
			<p className="text-ellipsis whitespace-pre-line">
				{showFullText ? text : truncatedText}
			</p>
			{text.split("\n").length > 3 && (
				<span
					onClick={toggleFullText}
					className="text-gray-500 cursor-pointer hover:text-gray-700"
					onKeyDown={(e) => {
						if (e.key === "Enter") {
							toggleFullText();
						}
					}}
				>
					{showFullText ? "View less" : "View more"}
				</span>
			)}
		</div>
	);
};

interface PostLeadsDialogProps {
	post: Post | null;
	onClose: () => void;
	refetchPosts: () => Promise<void>;
	syncingProfiles: Set<string>;
	setSyncingProfiles: (
		value: Set<string> | ((prev: Set<string>) => Set<string>),
	) => void;
	onGenerateIcebreaker: (linkedinId: string) => Promise<void>;
	selectedUserId?: string;
}

export const PostLeadsDialog = ({
	post,
	onClose,
	refetchPosts,
	syncingProfiles,
	setSyncingProfiles,
	onGenerateIcebreaker,
	selectedUserId,
}: PostLeadsDialogProps) => {
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [sortBy, setSortBy] = useState("icpScore");
	const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");
	const auth = useContext<AuthContextType>(AuthContext);
	const unipileAuth = useUnipileAuth();
	const dialogRef = React.useRef<HTMLDivElement>(null);

	const {
		data: reactionsData,
		isLoading,
		refetch: refetchReactions,
	} = usePostReactions(
		post?.linkedin_id || null,
		currentPage,
		pageSize,
		sortBy,
		sortDirection,
		selectedUserId,
	);

	const [selectedInteraction, setSelectedInteraction] =
		useState<PostReactionInteraction | null>(null);

	const handlePageChange = (page: number) => {
		setCurrentPage(page);
	};

	const handlePageSizeChange = (newSize: number) => {
		setPageSize(newSize);
		setCurrentPage(1);
	};

	const handleSortChange = (
		newSortBy: string,
		newSortDirection: "asc" | "desc",
	) => {
		setSortBy(newSortBy);
		setSortDirection(newSortDirection);
		setCurrentPage(1);
	};

	const handleGenerateIcebreaker = async (linkedinId: string) => {
		setSyncingProfiles((prev: Set<string>) => {
			const newSet = new Set(prev);
			newSet.add(linkedinId);
			return newSet;
		});

		await leadLensApi.generateIcebreaker({
			linkedinId: linkedinId,
			userId: auth.user?.id || "",
			token: auth.user?.token || "",
		});

		setSyncingProfiles((prev) => {
			const newSet = new Set(prev);
			newSet.delete(linkedinId);
			return newSet;
		});

		await refetchReactions();
	};

	const handleSendMessage = async (linkedinId: string, message: string) => {
		// Only allow sending messages when viewing your own data
		if (selectedUserId && selectedUserId !== auth.user?.id) {
			// Show a toast or alert that you can't send messages as another user
			console.warn("Cannot send messages when viewing as another user");
			return;
		}

		try {
			const response = await axios.post(
				`${appConfig.API_MAIN_URL}/api/lead-lens/message`,
				{
					linkedinId,
					message,
				},
				{
					headers: {
						Authorization: `Bearer ${auth.user?.token}`,
					},
				},
			);
			return response.data;
		} catch (error) {
			console.error("Error sending message:", error);
			throw error;
		}
	};

	if (!post) return null;

	// Handle dialog close event with proper focus management
	const handleDialogClose = (open: boolean) => {
		if (!open) {
			// When dialog is closed, ensure focus is managed properly
			onClose();
		}
	};

	// Handle key down events in the dialog
	const handleDialogKeyDown = (e: React.KeyboardEvent) => {
		// If Escape key is pressed within a dropdown menu, prevent the dialog from closing
		if (e.key === "Escape") {
			const dropdownMenu = document.querySelector('[role="menu"]');
			if (dropdownMenu) {
				e.stopPropagation();
			}
		}
	};

	return (
		<Dialog open={!!post} onOpenChange={handleDialogClose}>
			<DialogContent
				className="max-w-[90vw] max-h-[90vh] flex flex-col overflow-visible"
				onKeyDown={handleDialogKeyDown}
				ref={dialogRef}
			>
				<DialogHeader>
					<DialogTitle>
						Post Interactions
						{selectedUserId && selectedUserId !== auth.user?.id && (
							<Badge variant="outline" className="ml-2">
								Viewing as another user
							</Badge>
						)}
					</DialogTitle>
				</DialogHeader>

				<div className="mt-4 flex-1 overflow-y-auto pr-2 relative">
					<div className="bg-muted/50 p-4 rounded-lg mb-6">
						<h3 className="text-lg font-semibold mb-2">Post Content</h3>
						<TextTruncate text={post.content} />
						<div className="flex items-center gap-6 mt-4">
							<div className="flex items-center gap-2">
								<ThumbsUp className="h-4 w-4 text-blue-500" />
								<span>{post.metrics.likes} likes</span>
							</div>
							<div className="flex items-center gap-2">
								<MessageSquare className="h-4 w-4 text-green-500" />
								<span>{post.metrics.comments} comments</span>
							</div>
							<div className="flex items-center gap-2">
								<Share2 className="h-4 w-4 text-purple-500" />
								<span>{post.metrics.shares} shares</span>
							</div>
							<Badge variant="secondary">
								{post.qualifiedLeads} Qualified Leads
							</Badge>
						</div>
					</div>

					{isLoading ? (
						<div className="flex items-center justify-center p-4">
							<Loader2 className="h-6 w-6 animate-spin" />
						</div>
					) : (
						<>
							<LeadsTable
								leads={reactionsData?.data.interactions || []}
								onLeadClick={setSelectedInteraction}
								currentPage={currentPage}
								totalPages={reactionsData?.data.totalPages || 1}
								onPageChange={handlePageChange}
								pageSize={pageSize}
								onPageSizeChange={handlePageSizeChange}
								totalItems={reactionsData?.data.totalItems || 0}
								sortBy={sortBy}
								sortDirection={sortDirection}
								onSortChange={handleSortChange}
								onGenerateIcebreaker={handleGenerateIcebreaker}
								syncingProfiles={syncingProfiles}
								onSendMessage={(linkedinId: string, message: string) =>
									handleSendMessage(linkedinId, message)
								}
								icpFilter="all"
								disableSendMessage={
									selectedUserId !== undefined &&
									selectedUserId !== auth.user?.id
								}
							/>
							{selectedInteraction && (
								<LeadProfileDialog
									interaction={selectedInteraction}
									onClose={() => setSelectedInteraction(null)}
									onGenerateIcebreaker={handleGenerateIcebreaker}
									isSyncing={syncingProfiles.has(
										selectedInteraction.profile.linkedinId,
									)}
								/>
							)}
						</>
					)}
				</div>
				<DialogFooter>
					<Button variant="outline" onClick={onClose}>
						Close
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};
