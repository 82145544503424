import { Card, CardContent, CardHeader, CardTitle } from "components/ui/card";
import { ArrowDownIcon, ArrowUpIcon } from "lucide-react";
import { useState } from "react";
import {
	Line,
	LineChart,
	ReferenceLine,
	ResponsiveContainer,
	YAxis,
} from "recharts";
import type { User } from "../_hooks/use-organization-users";
import { SkeletonMetricCard } from "./skeleton-metric-card";

interface MetricsData {
	followers: number[];
	posts: number[];
	interactions: number[];
	engagement: number[];
	comments: number[];
	shares: number[];
	impressions: number[];
	blogs: number[];
}

interface MetricCardProps {
	title: string;
	value: number;
	change: number;
	metricData: number[];
	onSelect: (metric: string) => void;
	isSelected: boolean;
}

function MetricCard({
	title,
	value,
	change,
	metricData,
	onSelect,
	isSelected,
}: MetricCardProps) {
	// Filtrar los valores cero y preparar datos para el gráfico
	const filteredData = metricData.filter((val) => val !== 0);
	const chartData = filteredData.map((value, index) => ({ value, index }));

	// Calcular dominio personalizado para amplificar cambios pequeños
	const getCustomDomain = () => {
		if (filteredData.length <= 1) return undefined;

		const min = Math.min(...filteredData);
		const max = Math.max(...filteredData);

		// Usar un zoom ultra-agresivo para seguidores (que típicamente son valores altos)
		if (title === "Followers") {
			// Si los valores son muy cercanos, hacer zoom extremo
			if ((max - min) / max < 0.05) {
				// Si la diferencia es menor al 5%
				const buffer = Math.max(1, Math.floor(min * 0.001)); // 0.1% o al menos 1
				const minDomain = Math.floor(Math.max(0, min - buffer));
				const maxDomain = Math.ceil(max + buffer);
				return [minDomain, maxDomain];
			}
		}

		// Para seguidores y valores grandes > 100, usamos un zoom muy ajustado
		if (min > 100) {
			const buffer = Math.max(2, Math.floor(min * 0.001)); // 0.1% para valores altos, mínimo 2
			const minDomain = Math.floor(Math.max(0, min - buffer));
			const maxDomain = Math.ceil(max + buffer);
			return [minDomain, maxDomain];
		}

		// Para valores pequeños, un zoom menos agresivo
		const minDomain = Math.floor(Math.max(0, min * 0.95));
		const maxDomain = Math.ceil(max * 1.05);
		return [minDomain, maxDomain];
	};

	const chartColor =
		change > 0 ? "#10b981" : change < 0 ? "#ef4444" : "#9ca3af";

	// Determinar si mostrar etiquetas de valores y referencias
	const shouldShowReferences = title === "Followers" && filteredData.length > 1;
	const minValue = filteredData.length ? Math.min(...filteredData) : 0;
	const maxValue = filteredData.length ? Math.max(...filteredData) : 0;

	return (
		<Card
			className={`cursor-pointer hover:shadow-lg transition-shadow bg-white ${
				isSelected ? "ring-2 ring-primary" : ""
			}`}
			onClick={() => onSelect(title)}
		>
			<CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
				<CardTitle className="text-sm font-medium">{title}</CardTitle>
			</CardHeader>
			<CardContent>
				<div className="text-2xl font-bold">{value.toLocaleString()}</div>
				<div className="flex items-center space-x-2">
					{change > 0 ? (
						<ArrowUpIcon className="w-4 h-4 text-emerald-500" />
					) : change < 0 ? (
						<ArrowDownIcon className="w-4 h-4 text-red-500" />
					) : (
						<ArrowDownIcon className="w-4 h-4 text-gray-400" />
					)}
					<span
						className={
							change > 0
								? "text-emerald-500"
								: change < 0
									? "text-red-500"
									: "text-gray-400"
						}
					>
						{Math.abs(change)}%
					</span>
					<span className="text-muted-foreground">vs. previous period</span>
				</div>
				<div className="h-[50px] mt-4">
					<ResponsiveContainer width="100%" height="100%">
						<LineChart
							data={chartData}
							margin={{ top: 5, right: 5, bottom: 5, left: 5 }}
						>
							<YAxis
								hide
								domain={getCustomDomain()}
								allowDataOverflow={false}
								tickFormatter={(value) => Math.round(value).toLocaleString()}
							/>
							{shouldShowReferences && (
								<>
									<ReferenceLine
										y={minValue}
										stroke="#f0f0f0"
										strokeDasharray="3 3"
									/>
									<ReferenceLine
										y={maxValue}
										stroke="#f0f0f0"
										strokeDasharray="3 3"
									/>
								</>
							)}
							<Line
								type="monotone"
								dataKey="value"
								stroke={chartColor}
								strokeWidth={1.5}
								dot={{
									r: 3,
									fill: chartColor,
									stroke: "white",
									strokeWidth: 1,
								}}
								isAnimationActive={false}
								connectNulls={true}
							/>
						</LineChart>
					</ResponsiveContainer>
				</div>
			</CardContent>
		</Card>
	);
}

interface MetricCardsProps {
	user: User | undefined;
	loading?: boolean;
	selectedMetric: string | null;
	onMetricSelect: (metric: string) => void;
	metricsData: MetricsData;
}

export const MetricCards = ({
	user,
	loading,
	selectedMetric,
	onMetricSelect,
	metricsData,
}: MetricCardsProps) => {
	if (loading || !user) {
		return (
			<div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
				{Array.from({ length: 8 }).map(() => (
					<SkeletonMetricCard key={crypto.randomUUID()} />
				))}
			</div>
		);
	}

	const metrics = [
		{
			title: "Followers",
			value: user.linkedin.followers.current,
			change: user.linkedin.followers.change,
			data: metricsData?.followers || [],
		},
		{
			title: "Engagement",
			value: user.linkedin.engagement.current,
			change: user.linkedin.engagement.change,
			data: metricsData?.engagement || [],
		},
		{
			title: "Posts",
			value: user.linkedin.posts.current,
			change: user.linkedin.posts.change,
			data: metricsData?.posts || [],
		},
		{
			title: "Comments",
			value: user.linkedin.comments.current,
			change: user.linkedin.comments.change,
			data: metricsData?.comments || [],
		},
		{
			title: "Impressions",
			value: user.linkedin.impressions.current,
			change: user.linkedin.impressions.change,
			data: metricsData?.impressions || [],
		},
		{
			title: "Interactions",
			value: user.linkedin.interactions.current,
			change: user.linkedin.interactions.change,
			data: metricsData?.interactions || [],
		},
		{
			title: "Shares",
			value: user.linkedin.shares.current,
			change: user.linkedin.shares.change,
			data: metricsData?.shares || [],
		},
		{
			title: "Blogs",
			value: user.wordpress.blogs.current,
			change: user.wordpress.blogs.change,
			data: metricsData?.blogs || [],
		},
	];

	return (
		<div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
			{metrics.map((metric) => (
				<MetricCard
					key={metric.title}
					title={metric.title}
					value={metric.value}
					change={metric.change}
					metricData={metric.data}
					onSelect={onMetricSelect}
					isSelected={selectedMetric === metric.title}
				/>
			))}
		</div>
	);
};
