import axios from "axios";
import type {
	Audience,
	CreateAudiencePayload,
	UpdateAudiencePayload,
} from "types/audience";

export const getAudiences = async (params?: {
	organization_id: string;
}) => {
	const { data } = await axios.get<Audience[]>("/api/audience", { params });
	return data;
};

export const getAudience = async (
	id: number,
	organization_id: string,
): Promise<Audience> => {
	const { data } = await axios.get(`/api/audience/${id}`, {
		params: { organization_id },
	});
	return data;
};

export const createAudience = async (
	payload: CreateAudiencePayload,
): Promise<Audience> => {
	console.log("payload before sending", payload);
	const { data } = await axios.post("/api/audience", payload);
	return data;
};

export const updateAudience = async (
	id: number,
	payload: UpdateAudiencePayload,
): Promise<Audience> => {
	const { data } = await axios.put(`/api/audience/${id}`, payload);
	return data;
};

export const deleteAudience = async (id: number): Promise<void> => {
	await axios.delete(`/api/audience/${id}`);
};

export const getUserAudiences = async (userId: string): Promise<Audience[]> => {
	const { data } = await axios.get("/api/audience/users", {
		params: { user_id: userId },
	});
	return data;
};

export const assignAudienceToUser = async (
	audienceId: number,
	userId: string,
): Promise<void> => {
	await axios.post(`/api/audience/${audienceId}/users`, { user_id: userId });
};

export const removeAudienceFromUser = async (
	audienceId: number,
	userId: string,
): Promise<void> => {
	await axios.delete(`/api/audience/${audienceId}/users/${userId}`);
};
