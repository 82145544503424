import { Button } from "components/ui/button";
import {
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "components/ui/form";
import { Input } from "components/ui/input";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "components/ui/select";
import { useOrganizationUsers } from "hooks/use-organization-users";
import type { UseFormReturn } from "react-hook-form";
import type { UserAuthOrganization } from "types/authContext";
import type { AutomationFormValues } from "../schemas";

export interface SenderStepProps {
	form: UseFormReturn<AutomationFormValues>;
	onNext: () => void;
	currentOrganization?: UserAuthOrganization;
	t: (key: string) => string;
}

export function SenderStep({
	form,
	onNext,
	currentOrganization,
	t,
}: SenderStepProps) {
	const { users, isFetching: isFetchingUsers } = useOrganizationUsers(
		currentOrganization?.id ?? "",
	);

	return (
		<div className="space-y-6">
			<FormField
				control={form.control}
				name="name"
				render={({ field, fieldState }) => (
					<FormItem>
						<FormLabel>{t("automations.name.label")}</FormLabel>
						<FormControl>
							<Input
								placeholder={t("automations.name.placeholder")}
								{...field}
							/>
						</FormControl>
						{fieldState.error && (
							<FormMessage>
								{t("automations.validation.name.required")}
							</FormMessage>
						)}
					</FormItem>
				)}
			/>

			<FormField
				control={form.control}
				name="sender"
				render={({ field, fieldState }) => (
					<FormItem>
						<FormLabel>{t("automations.sender.label")}</FormLabel>
						<Select onValueChange={field.onChange} defaultValue={field.value}>
							<FormControl>
								<SelectTrigger>
									<SelectValue
										placeholder={
											isFetchingUsers
												? t("automations.sender.placeholder.loading")
												: t("automations.sender.placeholder.select")
										}
									/>
								</SelectTrigger>
							</FormControl>
							{users && (
								<SelectContent>
									{/* biome-ignore lint/suspicious/noExplicitAny: <TODO: type this when we stop using the old linkedin profile> */}
									{users.map((user: any) => (
										<SelectItem key={user.id} value={user.id}>
											{user.full_name || user.name}
										</SelectItem>
									))}
								</SelectContent>
							)}
						</Select>
						{fieldState.error && (
							<FormMessage>
								{t("automations.validation.sender.required")}
							</FormMessage>
						)}
					</FormItem>
				)}
			/>

			<div className="flex justify-end">
				<Button
					type="button"
					onClick={onNext}
					disabled={!form.getValues("sender") || !form.getValues("name")}
				>
					{t("automations.create.buttons.next")}
				</Button>
			</div>
		</div>
	);
}
