import { SidebarTrigger } from "components/ui/sidebar";
import { cn } from "lib/utils";
import type React from "react";

interface HeaderProps {
	title?: string;
	className?: string;
	children?: React.ReactNode;
}

export function Header({
	title,
	className,
	children,
}: HeaderProps): React.ReactElement {
	return (
		<header
			className={cn(
				"relative bg-white text-center font-semibold z-3 p-4 md:p-6 sm:text-xl dark:bg-slate-900 dark:border-dotted dark:border-b dark:border-slate-800 md:text-left border-b border-solid border-gray-300 flex items-center",
				className,
			)}
		>
			<div className="flex items-center gap-2">
				<SidebarTrigger />
				{title && <h2 className="text-gray-500">{title}</h2>}
			</div>
			<div className="flex-grow" />
			{children}
		</header>
	);
}
