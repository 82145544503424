"use client";

import { Search } from "lucide-react";
import { useContext, useEffect, useState } from "react";

import { useToast } from "components/hooks/use-toast";
import { Input } from "components/ui/input";
import { ScrollArea } from "components/ui/scroll-area";

import { AuthContext } from "components/lib";
import { useOrganizationAudiences } from "hooks/use-organization-audiences";
import { useOrganizationICPs } from "hooks/use-organization-icps";
import { useUserAudiences } from "hooks/use-user-audiences";
import type { Audience } from "types/audience";
import type { AuthContextType } from "types/authContext";
import { AudienceCard } from "../../views/organization/audiences/components/AudienceCard";
import { CreateAudienceDialog } from "../../views/organization/audiences/components/CreateAudienceDialog";
import { useToggleAudience } from "../../views/organization/audiences/hooks/use-toggle-audience";

interface AudienceSelectorProps {
	t: (key: string) => string;
	showTitle?: boolean;
	onAudienceChange?: (selectedAudienceIds: number[]) => void;
}

export const AudienceSelector = ({
	t,
	showTitle = true,
	onAudienceChange,
}: AudienceSelectorProps) => {
	const { user } = useContext<AuthContextType>(AuthContext);
	const organizationId = user?.organization_id || "";
	const { toast } = useToast();
	const [searchQuery, setSearchQuery] = useState("");

	const [localUserAudienceIds, setLocalUserAudienceIds] = useState<Set<number>>(
		new Set(),
	);

	const { icps, isFetching: isLoadingICPs } = useOrganizationICPs(
		organizationId ?? "",
	);

	const { audiences, isFetching: isLoadingAudiences } =
		useOrganizationAudiences({ organization_id: organizationId });

	const { userAudiences, isFetching: isLoadingUserAudiences } =
		useUserAudiences(user?.id);

	useEffect(() => {
		if (userAudiences.length > 0) {
			setLocalUserAudienceIds(
				new Set(userAudiences.map((audience) => audience.id)),
			);
		}
	}, [userAudiences]);

	useEffect(() => {
		if (!isLoadingAudiences) {
			const existingAudienceIds = new Set(audiences.map((a) => a.id));
			setLocalUserAudienceIds((prev) => {
				const newIds = new Set(
					Array.from(prev).filter((id) => existingAudienceIds.has(id)),
				);
				return newIds;
			});
		}
	}, [audiences, isLoadingAudiences]);

	useEffect(() => {
		if (onAudienceChange) {
			onAudienceChange(Array.from(localUserAudienceIds));
		}
	}, [localUserAudienceIds, onAudienceChange]);

	const { assignAudience, removeAudience, isAssigning, isRemoving } =
		useToggleAudience(user?.id);

	const filteredAudiences = audiences.filter((audience) =>
		audience.name.toLowerCase().includes(searchQuery.toLowerCase()),
	);

	const isLoading = isLoadingAudiences || isLoadingUserAudiences;
	const isToggling = isAssigning || isRemoving;

	const handleAudienceToggle = async (audience: Audience) => {
		if (!user?.id) {
			toast({
				title: t("organization.audiences.auth_required_title"),
				description: t("organization.audiences.auth_required_description"),
				variant: "destructive",
			});
			return;
		}

		try {
			const isSelected = localUserAudienceIds.has(audience.id);

			if (isSelected) {
				await removeAudience({ audienceId: audience.id });

				const newLocalIds = new Set(localUserAudienceIds);
				newLocalIds.delete(audience.id);
				setLocalUserAudienceIds(newLocalIds);

				toast({
					title: t("organization.audiences.remove_success_title"),
					description: t("organization.audiences.remove_success_description"),
				});
			} else {
				await assignAudience({ audienceId: audience.id });

				const newLocalIds = new Set(localUserAudienceIds);
				newLocalIds.add(audience.id);
				setLocalUserAudienceIds(newLocalIds);

				toast({
					title: t("organization.audiences.assign_success_title"),
					description: t("organization.audiences.assign_success_description"),
				});
			}
		} catch (error) {
			console.error(error, "Failed to toggle audience selection");
			toast({
				title: localUserAudienceIds.has(audience.id)
					? t("organization.audiences.remove_error_title")
					: t("organization.audiences.assign_error_title"),
				description: t("organization.audiences.toggle_error_description"),
				variant: "destructive",
			});
		}
	};

	return (
		<div className="space-y-4">
			{showTitle && (
				<div className="flex items-center justify-between">
					<div className="space-y-1">
						<h3 className="text-xl font-medium text-muted-foreground">
							{t("organization.audiences.description")}
						</h3>
					</div>
				</div>
			)}
			<div className="space-y-4">
				<div className="flex items-center justify-between gap-2">
					<div className="relative w-full max-w-sm">
						<Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
						<Input
							placeholder={t("organization.audiences.search_placeholder")}
							className="pl-8 w-full"
							value={searchQuery}
							onChange={(e) => setSearchQuery(e.target.value)}
						/>
					</div>
					<CreateAudienceDialog
						t={t}
						organizationId={organizationId}
						owner_user_id={user?.id || ""}
						icps={icps}
					/>
				</div>
				<ScrollArea className="h-full rounded-md border p-4">
					{isLoading ? (
						<p className="text-center py-8 text-muted-foreground">
							{t("organization.audiences.loading")}
						</p>
					) : filteredAudiences.length === 0 ? (
						<p className="text-center py-8 text-muted-foreground">
							{t("organization.audiences.no_audiences")}
						</p>
					) : (
						<div className="grid grid-cols-[repeat(auto-fit,minmax(350px,1fr))] gap-6">
							{filteredAudiences.map((audience) => {
								const matchingIcp = icps.find(
									(icp) => icp.id === audience.organization_icp_id,
								);

								return (
									<AudienceCard
										key={audience.id}
										audience={audience}
										icp={matchingIcp}
										isSelected={localUserAudienceIds.has(audience.id)}
										isToggling={isToggling}
										onToggle={handleAudienceToggle}
										icps={icps}
										canEdit={user?.id === audience.owner_user_id}
										t={t}
									/>
								);
							})}
						</div>
					)}
				</ScrollArea>
			</div>
		</div>
	);
};
