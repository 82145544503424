import { getAudiences } from "api/audience";
import { useAxiosQuery } from "hooks/useAxiosQuery";

export const useOrganizationAudiences = (params: {
	organization_id: string;
}) => {
	const {
		data: audiences,
		isFetching,
		error,
	} = useAxiosQuery({
		queryKey: ["audiences"],
		queryFn: () => getAudiences(params),
		enabled: !!params.organization_id,
	});

	return {
		audiences: audiences ?? [],
		isFetching,
		error,
	};
};
