import { Button } from "components/ui/button";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "components/ui/select";
import { Skeleton } from "components/ui/skeleton";
import { Building2, Filter, Users } from "lucide-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import type { AudienceMatch } from "types/lead";
import { useUserAudiences } from "../../_hooks/use-user-settings";
import {
	EngagementFilter,
	MatchFilter,
	getEngagementFilterFromText,
	getEngagementFilterRange,
	getEngagementLevel,
	getEngagementThreshold,
	getMatchFilterRange,
	getMatchThreshold,
} from "./lead-table-helpers";
const SkeletonSelect = () => {
	return <Skeleton className="h-9 w-[200px]" />;
};

interface LeadTableFiltersProps {
	leads: AudienceMatch[];
	audienceId: number | undefined;
	engagementFilter: EngagementFilter;
	matchFilter: MatchFilter;
	onFilterChange: (
		filterType: string,
		value: EngagementFilter | MatchFilter | "all" | number,
	) => void;
	onLeadsFiltered: (filteredLeads: AudienceMatch[]) => void;
}

export const LeadTableFilters = ({
	leads,
	audienceId,
	engagementFilter,
	matchFilter,
	onFilterChange,
	onLeadsFiltered,
}: LeadTableFiltersProps) => {
	const { data: audiences, isLoading: isLoadingAudiences } = useUserAudiences();
	const [audienceGroupFilter, setAudienceGroupFilter] = useState<
		"all" | number
	>(audienceId || "all");
	const navigate = useNavigate();

	// Sync audienceGroupFilter with audienceId prop when it changes
	useEffect(() => {
		if (audienceId !== undefined) {
			setAudienceGroupFilter(audienceId === 0 ? "all" : audienceId);
		}
	}, [audienceId]);

	// Handle filter changes through parent component
	const handleEngagementFilterChange = (value: EngagementFilter) => {
		onFilterChange("engagement", value);
	};

	const handleMatchFilterChange = (value: MatchFilter) => {
		onFilterChange("match", value);
	};

	const handleAudienceGroupFilterChange = (value: string) => {
		const newValue = value === "all" ? "all" : Number.parseInt(value, 10);
		setAudienceGroupFilter(newValue);

		// Pass the audience ID to the parent component for backend filtering
		onFilterChange("audienceId", newValue);
	};

	// Filter leads based on criteria
	useEffect(() => {
		// Ensure leads is an array before spreading
		if (!Array.isArray(leads)) {
			// If leads is not an array, just pass an empty array to onLeadsFiltered
			onLeadsFiltered([]);
			return;
		}

		let result = [...leads];

		// Apply audience group filter from audience selection dropdown
		if (audienceGroupFilter !== "all") {
			result = result.filter((lead) => lead.audienceId === audienceGroupFilter);
		}

		// Apply engagement filter
		if (engagementFilter !== EngagementFilter.ALL) {
			// Obtenemos el rango de filtrado de engagement
			const engagementRange = getEngagementFilterRange(engagementFilter);
			if (engagementRange) {
				result = result.filter((lead) => {
					const interactionCount = lead.interactions.count || 0;
					// Aplicamos filtro con rangos numéricos
					if (
						engagementRange.min !== undefined &&
						interactionCount < engagementRange.min
					) {
						return false;
					}
					if (
						engagementRange.max !== undefined &&
						interactionCount >= engagementRange.max
					) {
						return false;
					}
					return true;
				});
			}
		}

		// Apply match filter
		if (matchFilter !== MatchFilter.ALL) {
			// Obtenemos el rango de filtrado de match
			const matchRange = getMatchFilterRange(matchFilter);
			if (matchRange) {
				result = result.filter((lead) => {
					const matchScore = lead.score || 0;
					// Aplicamos filtro con rangos numéricos
					if (matchRange.min !== undefined && matchScore < matchRange.min) {
						return false;
					}
					if (matchRange.max !== undefined && matchScore >= matchRange.max) {
						return false;
					}
					return true;
				});
			}
		}

		onLeadsFiltered(result);
	}, [
		leads,
		audienceGroupFilter,
		engagementFilter,
		matchFilter,
		onLeadsFiltered,
	]);

	return (
		<div className="flex flex-wrap items-center gap-4 mb-4 bg-gray-50 p-4 rounded-lg shadow-sm">
			<div className="flex items-center gap-2">
				<Users className="h-4 w-4 text-gray-500" />
				<span className="text-sm font-medium text-gray-700">Audiencia:</span>
				{isLoadingAudiences ? (
					<SkeletonSelect />
				) : (
					<Select
						value={
							audienceGroupFilter === "all"
								? "all"
								: audienceGroupFilter.toString()
						}
						onValueChange={handleAudienceGroupFilterChange}
					>
						<SelectTrigger className="w-[200px] bg-white border-gray-200 hover:border-primary/80 transition-colors focus:ring-2 focus:ring-primary/20 shadow-sm">
							<SelectValue placeholder="Filtrar por audiencia" />
						</SelectTrigger>
						<SelectContent className="bg-white border border-gray-200 shadow-md rounded-md">
							<SelectItem
								value="all"
								className="hover:bg-gray-50 cursor-pointer"
							>
								Todos los grupos
							</SelectItem>
							{audiences?.map((audience) => (
								<SelectItem
									key={audience.id}
									value={audience.id.toString()}
									className="hover:bg-gray-50 cursor-pointer"
								>
									{audience.name}
								</SelectItem>
							))}
						</SelectContent>
					</Select>
				)}
			</div>

			<div className="flex items-center gap-2">
				<Filter className="h-4 w-4 text-gray-500" />
				<span className="text-sm font-medium text-gray-700">Engagement:</span>
				<Select
					value={engagementFilter}
					onValueChange={handleEngagementFilterChange}
				>
					<SelectTrigger className="w-[200px] bg-white border-gray-200 hover:border-primary/80 transition-colors focus:ring-2 focus:ring-primary/20 shadow-sm">
						<SelectValue placeholder="Filtrar por engagement" />
					</SelectTrigger>
					<SelectContent className="bg-white border border-gray-200 shadow-md rounded-md">
						<SelectItem
							value={EngagementFilter.ALL}
							className="hover:bg-gray-50 cursor-pointer"
						>
							Todos los niveles
						</SelectItem>
						<SelectItem
							value={EngagementFilter.HIGH}
							className="hover:bg-gray-50 cursor-pointer"
						>
							Alto engagement
						</SelectItem>
						<SelectItem
							value={EngagementFilter.MEDIUM}
							className="hover:bg-gray-50 cursor-pointer"
						>
							Medio engagement
						</SelectItem>
						<SelectItem
							value={EngagementFilter.LOW}
							className="hover:bg-gray-50 cursor-pointer"
						>
							Bajo engagement
						</SelectItem>
					</SelectContent>
				</Select>
			</div>

			<div className="flex items-center gap-2">
				<Building2 className="h-4 w-4 text-gray-500" />
				<span className="text-sm font-medium text-gray-700">Puntaje:</span>
				<Select value={matchFilter} onValueChange={handleMatchFilterChange}>
					<SelectTrigger className="w-[200px] bg-white border-gray-200 hover:border-primary/80 transition-colors focus:ring-2 focus:ring-primary/20 shadow-sm">
						<SelectValue placeholder="Filtrar por puntaje" />
					</SelectTrigger>
					<SelectContent className="bg-white border border-gray-200 shadow-md rounded-md">
						<SelectItem
							value={MatchFilter.ALL}
							className="hover:bg-gray-50 cursor-pointer"
						>
							Todos los niveles
						</SelectItem>
						<SelectItem
							value={MatchFilter.HIGH}
							className="hover:bg-gray-50 cursor-pointer"
						>
							Alta/Muy alta
						</SelectItem>
						<SelectItem
							value={MatchFilter.MEDIUM}
							className="hover:bg-gray-50 cursor-pointer"
						>
							Media
						</SelectItem>
						<SelectItem
							value={MatchFilter.LOW}
							className="hover:bg-gray-50 cursor-pointer"
						>
							Baja/Muy baja
						</SelectItem>
					</SelectContent>
				</Select>
			</div>
		</div>
	);
};
