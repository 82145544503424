import { z } from "zod";

export const audienceFormSchema = z.object({
	name: z.string().min(1, "Name is required"),
	organization_icp_id: z.string().optional(),
	targeting_criteria: z.object({
		description: z.string().optional(),
		location: z.string().optional(),
		currentRole: z.string().optional(),
		education: z.string().optional(),
		languages: z.string().optional(),
		minFollowerCount: z.string().optional(),
		maxFollowerCount: z.string().optional(),
		skills: z.string().optional(),
		postsTopics: z.string().optional(),
		minAverageInteractionPerPostCount: z.string().optional(),
		maxAverageInteractionPerPostCount: z.string().optional(),
	}),
});

export type AudienceFormValues = z.infer<typeof audienceFormSchema>;
