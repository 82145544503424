"use client";

import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useToast } from "components/hooks/use-toast";
import { AuthContext } from "components/lib";
import { Alert, AlertDescription } from "components/ui/alert";
import { Avatar, AvatarFallback, AvatarImage } from "components/ui/avatar";
import { Badge } from "components/ui/badge";
import { Button } from "components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "components/ui/card";
import { Input } from "components/ui/input";
import { Progress } from "components/ui/progress";
import { Loader2 } from "lucide-react";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { fetchLinkedInPosts } from "views/content-creation/_components/tools/SalesDemo/api/salesDemo";
import { TopPerformingPosts } from "views/stats/_components/top-performing-posts";
import { z } from "zod";
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "../../../../../components/ui/form";
import type {
	LinkedInPost,
	SalesDemoForm,
	SalesDemoResponse,
	SalesDemoState,
} from "./types";

const STORAGE_KEY = "sales-demo-state";

const formSchema = z.object({
	linkedinUrl: z.string().url("URL inválida").includes("linkedin.com/in/", {
		message: "Debe ser una URL de perfil de LinkedIn válida",
	}),
});

type FormValues = z.infer<typeof formSchema>;

interface User {
	id: string;
	organizationId?: string;
}

export default function SalesDemo() {
	const auth: any = useContext(AuthContext);
	const userId = auth?.user?.id;

	const [state, setState] = useState<SalesDemoState>(() => ({
		isLoading: false,
		error: null,
		progress: 0,
		posts: null,
		result: null,
		step: "initial",
	}));

	const [selectedPosts, setSelectedPosts] = useState<Set<string>>(new Set());

	const form = useForm<FormValues>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			linkedinUrl: "",
		},
	});

	const fetchPostsMutation = useMutation<LinkedInPost[], Error, string>({
		mutationFn: fetchLinkedInPosts,
		onMutate: (linkedinUrl) => {
			setState((prev) => ({ ...prev, isLoading: true, error: null }));
		},
		onSuccess: (posts) => {
			const transformedPosts = transformPostsWithRecommendation(posts);
			const recommendedPosts = transformedPosts
				.filter((post) => post.isRecommended)
				.map((post) => post.id);

			setSelectedPosts(new Set(recommendedPosts));
			setState((prev) => ({
				...prev,
				isLoading: false,
				posts: transformedPosts,
				step: "preview",
			}));
		},
		onError: (error) => {
			setState((prev) => ({
				...prev,
				isLoading: false,
				error: error.message,
			}));
		},
	});

	const generateBlogsMutation = useMutation<
		SalesDemoResponse,
		Error,
		{ linkedinUrl: string; posts: LinkedInPost[]; userId: string }
	>({
		mutationFn: async ({ linkedinUrl, posts, userId }) => {
			const { data } = await axios.post("/api/sales-demo/generate", {
				linkedinUrl,
				posts,
				userId,
			});

			return data;
		},
		onMutate: (variables) => {
			setState((prev) => ({
				...prev,
				isLoading: true,
				error: null,
				step: "generating",
			}));
		},
		onSuccess: (result) => {
			setState((prev) => ({
				...prev,
				isLoading: false,
				result,
				progress: 100,
				step: "completed",
			}));
		},
		onError: (error) => {
			setState((prev) => ({
				...prev,
				isLoading: false,
				error: error.message,
				step: "preview",
			}));
		},
	});

	const handleFetchPosts = form.handleSubmit(async (data) => {
		await fetchPostsMutation.mutateAsync(data.linkedinUrl);
	});

	const handlePostSelect = (postId: string, isSelected: boolean) => {
		setSelectedPosts((prev) => {
			const newSelection = new Set(prev);
			if (isSelected) {
				newSelection.add(postId);
			} else {
				newSelection.delete(postId);
			}
			return newSelection;
		});
	};

	const handleSelectRecommended = () => {
		if (!state.posts) return;

		const recommendedPosts = state.posts
			.filter((post) => post.isRecommended)
			.map((post) => post.id);
		setSelectedPosts(new Set(recommendedPosts));
	};

	const handleGenerateBlogs = async () => {
		if (!state.posts) return;

		if (!userId) {
			setState((prev) => ({
				...prev,
				error: "Debes estar autenticado para generar blogs",
			}));
			return;
		}

		const selectedPostsArray = state.posts.filter((post) =>
			selectedPosts.has(post.id),
		);

		if (selectedPostsArray.length === 0) {
			setState((prev) => ({
				...prev,
				error: "Por favor, selecciona al menos un post para generar blogs",
			}));
			return;
		}

		await generateBlogsMutation.mutateAsync({
			linkedinUrl: form.getValues("linkedinUrl"),
			posts: selectedPostsArray,
			userId: userId,
		});
	};

	const handleReset = () => {
		form.reset();
		setSelectedPosts(new Set());
		setState({
			isLoading: false,
			error: null,
			progress: 0,
			posts: null,
			result: null,
			step: "initial",
		});
	};

	const transformPostsWithRecommendation = (posts: LinkedInPost[]) => {
		const postsWithImage = posts.filter((post) => {
			const hasImage = Array.isArray(post.image) && post.image.length > 0;
			return hasImage;
		});

		const sortedPosts = [...postsWithImage].sort((a, b) => {
			const scoreA = a.metrics.likes + a.metrics.comments;
			const scoreB = b.metrics.likes + b.metrics.comments;
			return scoreB - scoreA;
		});

		const topPosts = new Set(sortedPosts.slice(0, 3).map((post) => post.id));

		return posts.map((post) => {
			const images = Array.isArray(post.image) ? post.image : [];

			const hasImage = images.length > 0;

			const firstImageUrl =
				images.length > 0 ? images[0].url.split("image/")[1] : null;

			const transformedPost = {
				...post,
				linkedin_id: post.id,
				user_id: userId,
				organization_id: auth?.user?.default_organization,
				likes: post.metrics.likes,
				shares: post.metrics.shares,
				comments: post.metrics.comments,
				impressions: post.metrics.impressions,
				content: post.content,
				creation_time: post.date
					? new Date(post.date).toISOString()
					: new Date().toISOString(),
				last_updated: post.date
					? new Date(post.date).toISOString()
					: new Date().toISOString(),
				image_id: firstImageUrl,
				additional_images: [],
				isRecommended: topPosts.has(post.id),
				hasImage,
			};
			return transformedPost as LinkedInPost;
		});
	};

	const renderForm = () => (
		<Form {...form}>
			<form onSubmit={handleFetchPosts} className="space-y-4">
				<FormField
					control={form.control}
					name="linkedinUrl"
					render={({ field }) => (
						<FormItem>
							<FormLabel>URL del Perfil de LinkedIn</FormLabel>
							<FormControl>
								<Input
									placeholder="https://www.linkedin.com/in/..."
									{...field}
								/>
							</FormControl>
							<FormMessage />
						</FormItem>
					)}
				/>
				<Button type="submit" disabled={state.isLoading} className="w-full">
					{state.isLoading ? (
						<Loader2 className="mr-2 h-4 w-4 animate-spin" />
					) : (
						"Obtener Posts"
					)}
				</Button>
			</form>
		</Form>
	);

	const renderStep = () => {
		switch (state.step) {
			case "preview":
				return (
					<div className="space-y-6">
						<div className="max-w-xl">{renderForm()}</div>
						<div className="space-y-4">
							<div className="flex items-center justify-between mb-2">
								<div className="flex items-center gap-2">
									<h2 className="text-lg font-semibold">Posts Encontrados</h2>
									<Badge variant="secondary" className="text-xs">
										Los 3 posts con más engagement y con imagen están marcados
										como recomendados
									</Badge>
								</div>
							</div>
							<TopPerformingPosts
								posts={state.posts || []}
								loading={state.isLoading}
								renderBadge={(post) =>
									post.isRecommended && (
										<Badge className="bg-gradient-to-r from-purple-500 to-pink-500 hover:from-purple-600 hover:to-pink-600 text-white border-0">
											Recomendado
										</Badge>
									)
								}
								onPostSelect={handlePostSelect}
								selectedPosts={selectedPosts}
								onSelectRecommended={handleSelectRecommended}
								onGenerateBlogs={handleGenerateBlogs}
								showImages={true}
								showEngagement={false}
							/>
						</div>
					</div>
				);

			case "generating":
			case "completed":
				return (
					<div className="space-y-4">
						{state.isLoading && (
							<div className="space-y-2">
								<Progress value={state.progress} />
								<p className="text-sm text-gray-500">
									{state.result?.currentPost && state.result?.totalPosts ? (
										<>
											Generando blog {state.result.currentPost} de{" "}
											{state.result.totalPosts}...
										</>
									) : (
										"Generando blogs... Esto puede tomar unos minutos"
									)}
								</p>
							</div>
						)}

						{state.result && (
							<Alert>
								<AlertDescription>
									<div className="space-y-4">
										<div className="space-y-2">
											<p>{state.result.message}</p>
											{state.result.postsProcessed && (
												<p>Posts procesados: {state.result.postsProcessed}</p>
											)}
										</div>

										{state.result.generatedPosts &&
											state.result.generatedPosts.length > 0 && (
												<div className="space-y-2">
													<p className="font-medium">Posts Generados:</p>
													<ul className="list-disc pl-5 space-y-1">
														{state.result.generatedPosts.map((post) => (
															<li key={post.url}>
																<a
																	href={post.url}
																	target="_blank"
																	rel="noopener noreferrer"
																	className="text-primary hover:underline"
																>
																	{post.title}
																</a>
															</li>
														))}
													</ul>
												</div>
											)}
									</div>
								</AlertDescription>
							</Alert>
						)}

						{state.step === "completed" && (
							<Button onClick={handleReset}>Generar Otra Demo</Button>
						)}
					</div>
				);

			default:
				return renderForm();
		}
	};

	return (
		<Card>
			<CardHeader>
				<CardTitle>Demo de Ventas</CardTitle>
			</CardHeader>
			<CardContent>
				<div className="space-y-6">
					<div>
						<h1 className="text-2xl font-bold">Generador de Demo de Ventas</h1>
						<p className="text-sm text-gray-500 mt-1">
							Genera automáticamente blogs a partir de un perfil de LinkedIn
						</p>
					</div>

					{state.error && (
						<Alert variant="destructive">
							<AlertDescription>{state.error}</AlertDescription>
						</Alert>
					)}

					{state.posts?.[0]?.profile && (
						<div className="flex items-center gap-4 p-4 bg-gray-50 rounded-lg">
							<Avatar className="h-16 w-16">
								<AvatarImage
									src={state.posts[0].profile?.imageUrl}
									alt={state.posts[0].profile?.name}
								/>
								<AvatarFallback>
									{state.posts[0].profile?.name
										?.split(" ")
										.map((n) => n[0])
										.join("")}
								</AvatarFallback>
							</Avatar>
							<div>
								<h2 className="text-xl font-semibold">
									{state.posts[0].profile?.name}
								</h2>
								{state.posts[0].profile?.headline && (
									<p className="text-sm text-gray-600">
										{state.posts[0].profile?.headline}
									</p>
								)}
							</div>
						</div>
					)}

					{renderStep()}
				</div>
			</CardContent>
		</Card>
	);
}
