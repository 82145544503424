import { useQueryClient } from "@tanstack/react-query";
import { createAudience } from "api/audience";
import { useAxiosMutation } from "hooks/useAxiosMutation";
import type { CreateAudiencePayload } from "types/audience";

export const useCreateAudience = (
	organization_id: string,
	owner_user_id?: string,
) => {
	const queryClient = useQueryClient();

	const mutation = useAxiosMutation({
		mutationFn: (data: CreateAudiencePayload) =>
			createAudience({
				...data,
				organization_id,
				owner_user_id,
			}),
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ["audiences"],
			});
		},
	});

	return {
		createAudience: mutation.mutateAsync,
		isCreating: mutation.isPending,
		error: mutation.error,
	};
};
