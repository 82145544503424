// Helper functions for filtering and categorization

// Match filter enum
export enum MatchFilter {
	ALL = "all",
	HIGH = "high",
	MEDIUM = "medium",
	LOW = "low",
}

// Engagement filter enum
export enum EngagementFilter {
	ALL = "all",
	HIGH = "high",
	MEDIUM = "medium",
	LOW = "low",
}

// Match threshold values (actualizado para match con el backend)
export const MATCH_THRESHOLDS = {
	HIGH_MIN: 7,
	MEDIUM_MAX: 7,
	MEDIUM_MIN: 4,
	LOW_MAX: 4,
};

// Engagement threshold values (actualizado para match con el backend)
export const ENGAGEMENT_THRESHOLDS = {
	HIGH_MIN: 5,
	MEDIUM_MIN: 2,
	MEDIUM_MAX: 5,
	LOW_MAX: 2,
};

// Transforma un MatchFilter a un rango numérico para el backend
export const getMatchFilterRange = (
	filter: MatchFilter,
): { min?: number; max?: number } | undefined => {
	if (filter === MatchFilter.ALL) return undefined;

	switch (filter) {
		case MatchFilter.HIGH:
			return { min: MATCH_THRESHOLDS.HIGH_MIN };
		case MatchFilter.MEDIUM:
			return {
				min: MATCH_THRESHOLDS.MEDIUM_MIN,
				max: MATCH_THRESHOLDS.MEDIUM_MAX,
			};
		case MatchFilter.LOW:
			return { max: MATCH_THRESHOLDS.LOW_MAX };
		default:
			return undefined;
	}
};

// Transforma un EngagementFilter a un rango numérico para el backend
export const getEngagementFilterRange = (
	filter: EngagementFilter,
): { min?: number; max?: number } | undefined => {
	if (filter === EngagementFilter.ALL) return undefined;

	switch (filter) {
		case EngagementFilter.HIGH:
			return { min: ENGAGEMENT_THRESHOLDS.HIGH_MIN };
		case EngagementFilter.MEDIUM:
			return {
				min: ENGAGEMENT_THRESHOLDS.MEDIUM_MIN,
				max: ENGAGEMENT_THRESHOLDS.MEDIUM_MAX,
			};
		case EngagementFilter.LOW:
			return { max: ENGAGEMENT_THRESHOLDS.LOW_MAX };
		default:
			return undefined;
	}
};

// Funciones antiguas mantenidas para compatibilidad
export const getMatchThreshold = (filter: MatchFilter): number => {
	if (filter === MatchFilter.ALL) return 0;

	switch (filter) {
		case MatchFilter.HIGH:
			return MATCH_THRESHOLDS.HIGH_MIN * 100;
		case MatchFilter.MEDIUM:
			return MATCH_THRESHOLDS.MEDIUM_MIN * 100;
		case MatchFilter.LOW:
			return 0;
		default:
			return 0;
	}
};

export const getEngagementThreshold = (filter: EngagementFilter): number => {
	if (filter === EngagementFilter.ALL) return 0;

	switch (filter) {
		case EngagementFilter.HIGH:
			return ENGAGEMENT_THRESHOLDS.HIGH_MIN;
		case EngagementFilter.MEDIUM:
			return ENGAGEMENT_THRESHOLDS.MEDIUM_MIN;
		case EngagementFilter.LOW:
			return 0;
		default:
			return 0;
	}
};

export const getMatchCategory = (score: number) => {
	if (score >= 8) return "Muy alta";
	if (score >= 6) return "Alta";
	if (score >= 4) return "Media";
	if (score >= 2) return "Baja";
	return "Muy baja";
};

export const getMatchCategoryColor = (category: string) => {
	switch (category) {
		case "Muy alta":
			return "green";
		case "Alta":
			return "green";
		case "Media":
			return "yellow";
		case "Baja":
			return "red";
	}
};

export const getMatchColor = (category: string) => {
	switch (category) {
		case "Muy alta":
			return "success";
		case "Alta":
			return "success";
		case "Media":
			return "warning";
		case "Baja":
			return "default";
		case "Muy baja":
			return "destructive";
		default:
			return "default";
	}
};

export const getEngagementLevel = (score: number) => {
	if (score >= 8) return { text: "Alto", percentage: score };
	if (score >= 6) return { text: "Medio", percentage: score };
	if (score >= 4) return { text: "Bajo", percentage: score };
	return { text: "Muy bajo", percentage: score };
};

// Helper to convert engagement level text to filter value
export const getEngagementFilterFromText = (text: string): EngagementFilter => {
	switch (text.toLowerCase()) {
		case "alto":
			return EngagementFilter.HIGH;
		case "medio":
			return EngagementFilter.MEDIUM;
		case "bajo":
		case "muy bajo":
			return EngagementFilter.LOW;
		default:
			return EngagementFilter.ALL;
	}
};

// Helper to convert match category to filter value
export const getMatchFilterFromCategory = (category: string): MatchFilter => {
	switch (category) {
		case "Muy alta":
		case "Alta":
			return MatchFilter.HIGH;
		case "Media":
			return MatchFilter.MEDIUM;
		case "Baja":
		case "Muy baja":
			return MatchFilter.LOW;
		default:
			return MatchFilter.ALL;
	}
};

const getEngagementHeatBar = (count: number) => {
	const maxEngagement = 10;
	const percentage = Math.min((count / maxEngagement) * 100, 100);
	const getColor = (percent: number) => {
		if (percent >= 80) return "from-red-500 to-red-600";
		if (percent >= 60) return "from-orange-500 to-orange-600";
		if (percent >= 40) return "from-yellow-500 to-yellow-600";
		if (percent >= 20) return "from-blue-500 to-blue-600";
		return "from-gray-400 to-gray-500";
	};

	return (
		<div className="flex items-center gap-2">
			<div className="w-32 h-3 bg-gray-200 rounded-full overflow-hidden">
				<div
					className={`h-full bg-gradient-to-r ${getColor(percentage)} transition-all duration-300`}
					style={{ width: `${percentage}%` }}
				/>
			</div>
			<span className="text-sm text-gray-600">({count})</span>
		</div>
	);
};
