import type { Column, ColumnDef, Row } from "@tanstack/react-table";
import { TooltipCell } from "components/tooltip/table-tooltips";
import { Avatar, AvatarFallback, AvatarImage } from "components/ui/avatar";
import { Badge } from "components/ui/badge";
import { Button } from "components/ui/button";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "components/ui/tooltip";
import {
	ArrowUpDown,
	Building2,
	CheckCircle,
	HelpCircle,
	InfoIcon,
	LinkedinIcon,
	Loader2,
	MapPin,
	MessageSquare,
	MoreVertical,
	Send,
	Trophy,
	Users,
	X,
} from "lucide-react";
import { useState } from "react";
import type { PostInteraction } from "../../types";

interface ColumnConfig {
	syncingProfiles: Set<string>;
	onGenerateIcebreaker: (linkedinId: string) => Promise<void>;
	onSendMessage: (linkedinId: string) => void;
	icpFilter: "all" | "high" | "medium" | "low";
	disableSendMessage?: boolean;
}

const getScoreBadge = (score: number) => {
	if (score >= 9)
		return (
			<div className="flex items-center gap-2">
				<Badge className="bg-green-600 text-white font-semibold">
					Muy alto
				</Badge>
				<span className="text-sm text-gray-600">({score})</span>
			</div>
		);
	if (score >= 7)
		return (
			<div className="flex items-center gap-2">
				<Badge className="bg-green-500 text-white font-semibold">Alto</Badge>
				<span className="text-sm text-gray-600">({score})</span>
			</div>
		);
	if (score >= 5)
		return (
			<div className="flex items-center gap-2">
				<Badge className="bg-yellow-500 text-white font-semibold">Medio</Badge>
				<span className="text-sm text-gray-600">({score})</span>
			</div>
		);
	return (
		<div className="flex items-center gap-2">
			<Badge className="bg-red-500 text-white font-semibold">Bajo</Badge>
			<span className="text-sm text-gray-600">({score})</span>
		</div>
	);
};

const getEngagementHeatBar = (count: number) => {
	const maxEngagement = 10;
	const percentage = Math.min((count / maxEngagement) * 100, 100);
	const getColor = (percent: number) => {
		if (percent >= 80) return "from-red-500 to-red-600";
		if (percent >= 60) return "from-orange-500 to-orange-600";
		if (percent >= 40) return "from-yellow-500 to-yellow-600";
		if (percent >= 20) return "from-blue-500 to-blue-600";
		return "from-gray-400 to-gray-500";
	};

	return (
		<div className="flex items-center gap-2">
			<div className="w-32 h-3 bg-gray-200 rounded-full overflow-hidden">
				<div
					className={`h-full bg-gradient-to-r ${getColor(percentage)} transition-all duration-300`}
					style={{ width: `${percentage}%` }}
				/>
			</div>
			<span className="text-sm text-gray-600">({count})</span>
		</div>
	);
};

export const createColumns = ({
	syncingProfiles,
	onGenerateIcebreaker,
	onSendMessage,
	icpFilter,
	disableSendMessage = false,
}: ColumnConfig): ColumnDef<PostInteraction>[] => {
	const filteredColumns = [
		{
			id: "fullName",
			accessorFn: (row: PostInteraction) =>
				`${row.profile.firstName} ${row.profile.lastName}`,
			header: "NOMBRE",
			cell: ({ row }: { row: Row<PostInteraction> }) => {
				const profile = row.original.profile;
				const fullName = `${profile.firstName} ${profile.lastName}`;
				const initials =
					`${profile.firstName?.[0] || ""}${profile.lastName?.[0] || ""}`.toUpperCase();
				const linkedinUrl = `https://www.linkedin.com/in/${profile.linkedinId}`;

				return (
					<div className="flex items-center gap-3">
						<a
							href={linkedinUrl}
							target="_blank"
							rel="noopener noreferrer"
							className="group relative"
							onClick={(e) => e.stopPropagation()}
						>
							<Avatar className="h-10 w-10 ring-2 ring-blue-100 group-hover:ring-blue-300 transition-all">
								<AvatarImage
									src={profile.profileImageUrl || undefined}
									alt={fullName}
								/>
								<AvatarFallback>{initials}</AvatarFallback>
							</Avatar>
							<LinkedinIcon className="h-4 w-4 text-[#0A66C2] absolute -bottom-1 -right-1 opacity-0 group-hover:opacity-100 transition-opacity" />
						</a>
						<a
							href={linkedinUrl}
							target="_blank"
							rel="noopener noreferrer"
							className="font-medium hover:text-blue-600 transition-colors"
							onClick={(e) => e.stopPropagation()}
						>
							{fullName}
						</a>
					</div>
				);
			},
		},
		{
			id: "title",
			accessorFn: (row: PostInteraction) => row.profile.title,
			header: "CARGO",
			cell: ({ row }: { row: Row<PostInteraction> }) => (
				<div className="flex items-center gap-2 text-gray-700">
					<span>{row.original.profile.title}</span>
				</div>
			),
		},
		{
			id: "location",
			accessorFn: (row: PostInteraction) => row.profile.locationName,
			header: "UBICACIÓN",
			cell: ({ row }: { row: Row<PostInteraction> }) => (
				<div className="flex items-center text-gray-700">
					<span>{row.original.profile.locationName}</span>
				</div>
			),
		},
		{
			id: "company",
			accessorFn: (row: PostInteraction) => row.profile.company,
			header: "EMPRESA",
			cell: ({ row }: { row: Row<PostInteraction> }) => (
				<div className="flex items-center text-gray-700">
					<span className="font-medium">{row.original.profile.company}</span>
				</div>
			),
		},
		{
			id: "coincidenciaClienteIdeal",
			accessorFn: (row: PostInteraction) => row.profile.lead?.icpScore,
			header: ({ column }: { column: Column<PostInteraction> }) => {
				const isSorted = column.getIsSorted();
				return (
					<Tooltip>
						<TooltipTrigger asChild>
							<Button
								variant={isSorted ? "secondary" : "ghost"}
								onClick={() => column.toggleSorting(isSorted === "asc")}
								className="hover:bg-muted flex items-center gap-1 mx-auto"
							>
								COINCIDENCIA CLIENTE IDEAL
								<HelpCircle className="h-4 w-4 text-muted-foreground" />
								<ArrowUpDown
									className={`ml-2 h-4 w-4 ${isSorted ? "text-primary" : "text-muted-foreground"}`}
								/>
							</Button>
						</TooltipTrigger>
						<TooltipContent>
							<p className="max-w-xs">
								Nivel de coincidencia con tu Perfil de Cliente Ideal
							</p>
						</TooltipContent>
					</Tooltip>
				);
			},
			cell: ({ row }: { row: Row<PostInteraction> }) => {
				const score = (row.getValue("coincidenciaClienteIdeal") as number) || 0;
				return (
					<div className="flex items-center justify-center w-full">
						{getScoreBadge(score)}
					</div>
				);
			},
		},
		{
			id: "nivelEngagement",
			accessorFn: (row: PostInteraction) => row.interactions?.count,
			header: ({ column }: { column: Column<PostInteraction> }) => {
				const isSorted = column.getIsSorted();
				return (
					<Tooltip>
						<TooltipTrigger asChild>
							<Button
								variant={isSorted ? "secondary" : "ghost"}
								onClick={() => column.toggleSorting(isSorted === "asc")}
								className="hover:bg-muted flex items-center gap-1 mx-auto"
							>
								NIVEL DE ENGAGEMENT
								<HelpCircle className="h-4 w-4 text-muted-foreground" />
								<ArrowUpDown
									className={`ml-2 h-4 w-4 ${isSorted ? "text-primary" : "text-muted-foreground"}`}
								/>
							</Button>
						</TooltipTrigger>
						<TooltipContent>
							<p className="max-w-xs">
								Nivel de interacción del lead con tu contenido
							</p>
						</TooltipContent>
					</Tooltip>
				);
			},
			cell: ({ row }: { row: Row<PostInteraction> }) => {
				const count = (row.getValue("nivelEngagement") as number) || 0;
				return (
					<div className="flex items-center justify-start px-2">
						{getEngagementHeatBar(count)}
					</div>
				);
			},
		},
	];

	const actionsColumn = {
		id: "actions",
		header: "ACCIONES",
		cell: ({ row }: { row: Row<PostInteraction> }) => {
			const linkedinId = row.original.profile.linkedinId;
			const lead = row.original.profile.lead;
			const hasInsights =
				lead?.engagementFactors ||
				lead?.icpAlignment ||
				lead?.qualificationReason;

			const [isMenuOpen, setIsMenuOpen] = useState(false);

			const toggleMenu = (e: React.MouseEvent) => {
				e.stopPropagation();
				setIsMenuOpen(!isMenuOpen);
			};

			const handleMenuItemClick = (e: React.MouseEvent, action: () => void) => {
				e.stopPropagation();
				action();
				setIsMenuOpen(false);
			};

			const handleClickOutside = () => {
				setIsMenuOpen(false);
			};

			const handleKeyDown = (e: React.KeyboardEvent, action?: () => void) => {
				e.stopPropagation();
				if (e.key === "Enter" || e.key === " ") {
					e.preventDefault();
					if (action) {
						action();
					} else {
						setIsMenuOpen(!isMenuOpen);
					}
				} else if (e.key === "Escape" && isMenuOpen) {
					e.preventDefault();
					setIsMenuOpen(false);
				}
			};

			return (
				<div
					className="flex items-center justify-end actions-cell relative"
					onClick={(e) => e.stopPropagation()}
					onKeyDown={(e) => handleKeyDown(e)}
				>
					<Button
						variant="ghost"
						className="h-8 w-8 p-0 hover:bg-muted"
						onClick={toggleMenu}
						onKeyDown={(e) => handleKeyDown(e)}
					>
						<MoreVertical className="h-4 w-4" />
					</Button>

					{isMenuOpen && (
						<>
							<div
								className="fixed inset-0 z-40"
								onClick={handleClickOutside}
								onKeyDown={(e) => {
									if (e.key === "Escape") {
										handleClickOutside();
									}
								}}
								role="presentation"
							/>

							<div className="absolute right-0 top-full mt-1 z-50 min-w-[8rem] rounded-md border bg-popover p-1 text-popover-foreground shadow-md">
								{!disableSendMessage && (
									<button
										type="button"
										className="relative flex w-full cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none hover:bg-accent hover:text-accent-foreground"
										onClick={(e) =>
											handleMenuItemClick(e, () => onSendMessage(linkedinId))
										}
										onKeyDown={(e) =>
											handleKeyDown(e, () => onSendMessage(linkedinId))
										}
									>
										<Send className="mr-2 h-4 w-4" />
										<span>Enviar mensaje</span>
									</button>
								)}

								{hasInsights && (
									<TooltipProvider>
										<Tooltip>
											<TooltipTrigger asChild>
												<button
													type="button"
													className="relative flex w-full cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none hover:bg-accent hover:text-accent-foreground"
													onClick={(e) => e.stopPropagation()}
													onKeyDown={(e) => handleKeyDown(e)}
												>
													<InfoIcon className="mr-2 h-4 w-4" />
													<span>Ver Insights</span>
												</button>
											</TooltipTrigger>
											<TooltipContent
												side="left"
												align="start"
												className="w-80 bg-white text-foreground border shadow-sm"
											>
												<div className="space-y-4">
													<div className="flex items-center gap-2 pb-2 border-b">
														<Trophy className="h-5 w-5 text-yellow-500" />
														<h3 className="font-semibold text-foreground">
															Why this Lead Score?
														</h3>
													</div>
													{lead?.engagementFactors && (
														<div className="space-y-1">
															<h4 className="text-sm font-semibold flex items-center gap-2 text-foreground">
																<MessageSquare className="h-4 w-4 text-blue-500" />
																Engagement Factors
															</h4>
															<p className="text-sm text-muted-foreground">
																{lead.engagementFactors}
															</p>
														</div>
													)}
													{lead?.icpAlignment && (
														<div className="space-y-1">
															<h4 className="text-sm font-semibold flex items-center gap-2 text-foreground">
																<Users className="h-4 w-4 text-green-500" />
																ICP Alignment
															</h4>
															<p className="text-sm text-muted-foreground">
																{lead.icpAlignment}
															</p>
														</div>
													)}
													{lead?.qualificationReason && (
														<div className="space-y-1">
															<h4 className="text-sm font-semibold flex items-center gap-2 text-foreground">
																<Trophy className="h-4 w-4 text-yellow-500" />
																Qualification
															</h4>
															<p className="text-sm text-muted-foreground">
																{lead.qualificationReason}
															</p>
														</div>
													)}
												</div>
											</TooltipContent>
										</Tooltip>
									</TooltipProvider>
								)}
							</div>
						</>
					)}
				</div>
			);
		},
	};

	const filterLeadsByIcp = (leads: PostInteraction[]) => {
		switch (icpFilter) {
			case "high":
				return leads.filter((lead) => (lead.profile.lead?.icpScore || 0) >= 70);
			case "medium":
				return leads.filter(
					(lead) =>
						(lead.profile.lead?.icpScore || 0) >= 50 &&
						(lead.profile.lead?.icpScore || 0) < 70,
				);
			case "low":
				return leads.filter((lead) => (lead.profile.lead?.icpScore || 0) < 50);
			default:
				return leads;
		}
	};

	return [...filteredColumns, actionsColumn];
};
