import { Badge } from "components/ui/badge";
import type { Audience } from "types/audience";
import type { OrganizationICP } from "types/organization-icp";

interface AudienceCardProps {
	audience: Audience;
	matchingICP?: OrganizationICP;
}

export const AudienceCard = ({ audience, matchingICP }: AudienceCardProps) => {
	const roles = audience.targeting_criteria.currentRole
		? audience.targeting_criteria.currentRole.split(",").map((r) => r.trim())
		: ["No Role"];

	return (
		<div className="cursor-pointer rounded-lg border bg-card p-3 transition-colors hover:bg-accent/50">
			<h4 className="font-medium">{audience.name}</h4>

			{audience.targeting_criteria.description && (
				<p className="mt-2 text-sm text-muted-foreground line-clamp-2">
					{audience.targeting_criteria.description}
				</p>
			)}

			<div className="mt-2">
				<Badge
					variant={matchingICP ? "secondary" : "outline"}
					className="shrink-0"
				>
					{matchingICP ? matchingICP.name : "No ICP"}
				</Badge>
			</div>

			<div className="mt-2 flex flex-wrap gap-1">
				{roles.map((role) => (
					<Badge key={role} variant="outline">
						{role}
					</Badge>
				))}
			</div>
		</div>
	);
};
