import axios from "axios";
import type {
	CreateOrganizationICPPayload,
	OrganizationICP,
	UpdateOrganizationICPPayload,
} from "types/organization-icp";

export const getICPs = async (params: {
	organization_id: string;
	activeOnly?: boolean;
}): Promise<OrganizationICP[]> => {
	const { data } = await axios.get("/api/organization-icp", { params });
	return data;
};

export const getICP = async (id: number): Promise<OrganizationICP> => {
	const { data } = await axios.get(`/api/organization-icp/${id}`);
	return data;
};

export const createICP = async (
	payload: CreateOrganizationICPPayload,
): Promise<OrganizationICP> => {
	const { data } = await axios.post("/api/organization-icp", payload);
	return data;
};

export const updateICP = async (
	id: number,
	payload: UpdateOrganizationICPPayload,
): Promise<OrganizationICP> => {
	const { data } = await axios.put(`/api/organization-icp/${id}`, payload);
	return data;
};

export const deleteICP = async (id: number): Promise<void> => {
	await axios.delete(`/api/organization-icp/${id}`);
};
