import { useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { AuthContext } from "components/lib";
import { useContext } from "react";
import type { AuthContextType } from "types/authContext";
import { appConfig } from "views/content-creation/config";
import type { PaginatedLeadsResponse } from "../types";

interface UseUserLeadsReturn {
	data: PaginatedLeadsResponse | null;
	loading: boolean;
	error: Error | null;
	refetch: () => Promise<void>;
}

const getDbColumnName = (columnId: string): string => {
	const columnMap: Record<string, string> = {
		fullName: "pp.first_name",
		company: "pp.company",
		title: "pp.title",
		location: "pp.location_name",
		icpScore: "l.icp_score",
		engagementScore: "l.engagement_score",
		latestInteraction: "MAX(i.interaction_date)",
		creation_time: "p.creation_time",
		"metrics.likes": "p.likes",
		"metrics.comments": "p.comments",
		"metrics.shares": "p.shares",
		totalInteractions: "total_interactions",
	};

	return columnMap[columnId] || columnId;
};

const fetchUserLeads = async ({
	userId,
	page,
	limit,
	sortBy,
	sortDirection,
	searchTerm = "",
	token,
}: {
	userId: string;
	page: number;
	limit: number;
	sortBy: string;
	sortDirection: "asc" | "desc";
	searchTerm?: string;
	token: string;
}) => {
	const mappedSortBy = getDbColumnName(sortBy);
	const { data } = await axios.get(
		`${appConfig.API_MAIN_URL}/api/lead-lens/leads/${userId}`,
		{
			params: {
				page,
				limit,
				sortBy: mappedSortBy,
				sortDirection,
				searchTerm,
			},
			headers: {
				Authorization: `Bearer ${token}`,
			},
		},
	);
	return data;
};

export const useUserLeads = (
	userId: string | undefined,
	page = 1,
	limit = 10,
	sortBy = "icpScore",
	sortDirection = "desc",
	searchTerm = "",
): UseUserLeadsReturn => {
	const auth = useContext<AuthContextType>(AuthContext);
	const queryClient = useQueryClient();

	const {
		data,
		isLoading,
		error,
		refetch: queryRefetch,
	} = useQuery({
		queryKey: [
			"userLeads",
			userId,
			page,
			limit,
			sortBy,
			sortDirection,
			searchTerm,
		],
		queryFn: async () => {
			if (!userId || !auth.user?.token) return null;
			console.log(`Fetching user leads for page ${page}, limit ${limit}`);
			return fetchUserLeads({
				userId,
				page,
				limit,
				sortBy,
				sortDirection: sortDirection as "asc" | "desc",
				searchTerm,
				token: auth.user.token,
			});
		},
		enabled: !!userId && !!auth.user?.token,
		staleTime: 0,
		refetchOnWindowFocus: false,
	});

	const refetch = async (): Promise<void> => {
		if (!userId) return;
		console.log(
			`Invalidating and refetching user leads for ${userId}, page ${page}`,
		);
		await queryClient.invalidateQueries({
			queryKey: ["userLeads", userId],
			exact: false,
		});
		await queryRefetch();
	};

	return {
		data: data || null,
		loading: isLoading,
		error: error as Error | null,
		refetch,
	};
};
