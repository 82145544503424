import {
	type UseQueryOptions,
	type UseQueryResult,
	useQuery,
} from "@tanstack/react-query";
import type { AxiosError } from "axios";

type AxiosQueryResult<TData> = Omit<UseQueryResult<TData, unknown>, "error"> & {
	error: {
		message: string;
		errorCode: string;
		error: {
			statusCode: number;
			errorCode: string;
		};
	};
};

export function useAxiosQuery<TData>(
	options: UseQueryOptions<TData, unknown, TData>,
): AxiosQueryResult<TData> {
	const result = useQuery<TData, unknown>(options);
	const { error } = result;

	return {
		...result,
		// biome-ignore lint/suspicious/noExplicitAny: <explanation>
		data: (result.data as any)?.data ?? [],
		// biome-ignore lint/suspicious/noExplicitAny: <explanation>
		error: (error as AxiosError)?.response?.data as any,
	};
}
