import { AuthContext, Logo, User } from "components/lib";
import {
	Sidebar,
	SidebarContent,
	SidebarFooter,
	SidebarHeader,
	SidebarMenu,
	SidebarMenuButton,
	SidebarMenuItem,
} from "components/ui/sidebar";
import {
	Activity,
	BarChart2,
	Eye,
	Handshake,
	HelpCircle,
	Megaphone,
	PlusCircle,
	ScanEye,
	SquareUserRound,
	Target,
	Users,
} from "lucide-react";
import type React from "react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import type { AuthContextType } from "types/authContext";
import { domainTools, tools } from "views/content-creation/tools";
import type { Tool } from "views/content-creation/types";
import type { NavItem, NavItemChild } from "./app-sidebar-types";
import { IcpNavigation } from "./nav-icp";
import { MainNavigation } from "./nav-main";
import { OrganizationNavigation } from "./nav-organization";

export function AppSidebar(): React.ReactElement {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { user } = useContext<AuthContextType>(AuthContext);
	const canSeeContentCreation = domainTools.some(
		(tool) => tool.id === user?.organization_id,
	);
	const isOwnerOrAdmin =
		user?.permission === "owner" || user?.permission === "admin";

	const availableTools = tools
		.filter((tool) => tool.released)
		.filter((tool) => {
			if (!tool.internal) return true;
			const domain = domainTools.find((d) => d.id === user?.organization_id);
			return domain?.internal === true;
		});

	// Create tool children
	const toolChildren: NavItemChild[] = availableTools.map((tool: Tool) => ({
		label: t(`nav.${tool.nameKey}`),
		link: tool.href,
	}));

	// Group 1: Main Navigation
	const mainNavItems: NavItem[] = [
		{
			label: t("nav.dashboard"),
			icon: <Activity size={18} />,
			link: "/dashboard",
		},
		{
			label: t("nav.my_statistics"),
			icon: <BarChart2 size={18} />,
			link: "/stats",
		},
		{
			label: t("nav.lead_lens"),
			icon: <Eye size={18} />,
			link: "/lead-lens",
		},
		{
			label: "Lead Lens v2 (BETA)",
			icon: <ScanEye size={18} />,
			link: "/lead-lens/v2",
		},
		canSeeContentCreation && {
			label: "Create Content",
			icon: <PlusCircle size={18} />,
			children: toolChildren,
			isActive: true,
		},
		{
			label: t("nav.automations"),
			icon: <Activity size={18} />,
			link: "/automations",
		},
	].filter(Boolean) as NavItem[];

	// Group 2: Organization Navigation
	const orgNavItems: NavItem[] = [
		isOwnerOrAdmin && {
			label: t("nav.teams"),
			icon: <Handshake size={18} />,
			link: "/teams",
		},
		isOwnerOrAdmin && {
			label: t("nav.organization_goals"),
			icon: <Target size={18} />,
			link: "/organization-goals",
		},
		isOwnerOrAdmin && {
			label: t("nav.organization_users"),
			icon: <Users size={18} />,
			link: "/organization/users",
		},
	].filter(Boolean) as NavItem[];

	// Group 3: ICP Navigation
	const icpNavItems: NavItem[] = [
		{
			label: t("nav.icp", "Ideal Customer Profile"),
			icon: <SquareUserRound size={18} />,
			link: "/icp",
		},
		{
			label: t("nav.audiences", "Target Audiences"),
			icon: <Megaphone size={18} />,
			link: "/icp/audiences",
		},
	];

	return (
		<Sidebar collapsible="icon">
			<SidebarHeader className="space-y-4 p-2">
				<div className="flex items-center justify-center">
					<Logo className="h-8" />
				</div>
				<User />
			</SidebarHeader>
			<SidebarContent>
				<MainNavigation items={mainNavItems} />
				<IcpNavigation items={icpNavItems} />
				{orgNavItems.length > 0 && (
					<OrganizationNavigation
						title={t("nav.organization")}
						items={orgNavItems}
					/>
				)}
			</SidebarContent>
			<SidebarFooter className="p-2">
				<SidebarMenu>
					<SidebarMenuItem>
						<SidebarMenuButton
							tooltip={t("nav.help", "Help")}
							onClick={() => navigate("/help")}
						>
							<HelpCircle size={18} />
							<span>{t("nav.help", "Help")}</span>
						</SidebarMenuButton>
					</SidebarMenuItem>
				</SidebarMenu>
			</SidebarFooter>
		</Sidebar>
	);
}
