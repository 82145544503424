import { ChevronDownIcon, ChevronUpIcon } from "@radix-ui/react-icons";
import {
	flexRender,
	getCoreRowModel,
	getFilteredRowModel,
	getSortedRowModel,
	useReactTable,
} from "@tanstack/react-table";
import { Button } from "components/ui/button";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "components/ui/table";
import { UnipileAuthProvider } from "contexts/unipile-auth.context";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import SyncStatusBadge from "./sync-status-badge";

const SortableHeaderButton = ({ column, label }) => {
	return (
		<Button
			className="px-0"
			variant="ghost"
			onClick={() => column.toggleSorting()}
		>
			{label}
			{column.getIsSorted() === "desc" ? (
				<ChevronDownIcon className="ml-2 w-4 h-4" />
			) : (
				column.getIsSorted() === "asc" && (
					<ChevronUpIcon className="ml-2 w-4 h-4" />
				)
			)}
		</Button>
	);
};

const TotalRow = ({ metrics }) => {
	if (!metrics) return null;

	const totals = {
		posts: metrics.reduce(
			(sum, metric) => sum + metric.linkedin.posts.current,
			0,
		),
		previousPosts: metrics.reduce(
			(sum, metric) => sum + metric.linkedin.posts.previous,
			0,
		),
		impressions: metrics.reduce(
			(sum, metric) => sum + metric.linkedin.impressions.current,
			0,
		),
		previousImpressions: metrics.reduce(
			(sum, metric) => sum + metric.linkedin.impressions.previous,
			0,
		),
		interactions: metrics.reduce(
			(sum, metric) => sum + metric.linkedin.interactions.current,
			0,
		),
		previousInteractions: metrics.reduce(
			(sum, metric) => sum + metric.linkedin.interactions.previous,
			0,
		),
		comments: metrics.reduce(
			(sum, metric) => sum + metric.linkedin.comments.current,
			0,
		),
		previousComments: metrics.reduce(
			(sum, metric) => sum + metric.linkedin.comments.previous,
			0,
		),
		followers: metrics.reduce(
			(sum, metric) => sum + metric.linkedin.followers.current,
			0,
		),
		previousFollowers: metrics.reduce(
			(sum, metric) => sum + metric.linkedin.followers.previous,
			0,
		),
		profile_views: metrics.reduce(
			(sum, metric) => sum + metric.linkedin.profile_views.current,
			0,
		),
		previousProfileViews: metrics.reduce(
			(sum, metric) => sum + metric.linkedin.profile_views.previous,
			0,
		),
		leads: metrics.reduce(
			(sum, metric) => sum + metric.linkedin.leads.current,
			0,
		),
		previousLeads: metrics.reduce(
			(sum, metric) => sum + metric.linkedin.leads.previous,
			0,
		),
	};

	totals.postsChange = totals.previousPosts
		? ((totals.posts - totals.previousPosts) / totals.previousPosts) * 100
		: 0;
	totals.impressionsChange = totals.previousImpressions
		? ((totals.impressions - totals.previousImpressions) /
				totals.previousImpressions) *
			100
		: 0;
	totals.commentsChange = totals.previousComments
		? ((totals.comments - totals.previousComments) / totals.previousComments) *
			100
		: 0;
	totals.interactionsChange = totals.previousInteractions
		? ((totals.interactions - totals.previousInteractions) /
				totals.previousInteractions) *
			100
		: 0;

	totals.followersChange = totals.followers - totals.previousFollowers;

	totals.profileViewsChange = totals.previousProfileViews
		? ((totals.profile_views - totals.previousProfileViews) /
				totals.previousProfileViews) *
			100
		: 0;

	totals.leadsChange = totals.previousLeads
		? ((totals.leads - totals.previousLeads) / totals.previousLeads) * 100
		: 0;

	const currentEngagement = totals.impressions
		? (totals.interactions / totals.impressions) * 100
		: 0;
	const previousEngagement = totals.previousImpressions
		? (totals.previousInteractions / totals.previousImpressions) * 100
		: 0;
	const engagementChange = previousEngagement
		? ((currentEngagement - previousEngagement) / previousEngagement) * 100
		: 0;

	return (
		<TableRow>
			<TableCell className="font-bold text-base">Total</TableCell>
			<TableCell className="text-center border-l text-base">
				{totals.posts}
			</TableCell>
			<TableCell className="text-center border-l text-base">
				<span
					className={`font-bold ${
						totals.postsChange > 0
							? "text-green-500"
							: totals.postsChange < 0
								? "text-red-500"
								: "text-black"
					}`}
				>
					{totals.postsChange > 0 ? "+" : ""}
					{Math.round(totals.postsChange)}%
				</span>
			</TableCell>
			<TableCell className="text-center border-l text-base">
				{totals.impressions}
			</TableCell>
			<TableCell className="text-center border-l text-base">
				<span
					className={`font-bold ${
						totals.impressionsChange > 0
							? "text-green-500"
							: totals.impressionsChange < 0
								? "text-red-500"
								: "text-black"
					}`}
				>
					{totals.impressionsChange > 0 ? "+" : ""}
					{Math.round(totals.impressionsChange)}%
				</span>
			</TableCell>
			<TableCell className="text-center border-l text-base">
				{totals.interactions}
			</TableCell>
			<TableCell className="text-center border-l text-base">
				<span
					className={`font-bold ${
						totals.interactionsChange > 0
							? "text-green-500"
							: totals.interactionsChange < 0
								? "text-red-500"
								: "text-black"
					}`}
				>
					{totals.interactionsChange > 0 ? "+" : ""}
					{Math.round(totals.interactionsChange)}%
				</span>
			</TableCell>
			<TableCell className="text-center border-l text-base">
				{totals.comments}
			</TableCell>
			<TableCell className="text-center border-l text-base">
				<span
					className={`font-bold ${
						totals.commentsChange > 0
							? "text-green-500"
							: totals.commentsChange < 0
								? "text-red-500"
								: "text-black"
					}`}
				>
					{totals.commentsChange > 0 ? "+" : ""}
					{Math.round(totals.commentsChange)}%
				</span>
			</TableCell>
			<TableCell className="text-center border-l text-base">
				{totals.followers}
			</TableCell>
			<TableCell className="text-center border-l text-base">
				<span
					className={`font-bold ${
						totals.followersChange > 0
							? "text-green-500"
							: totals.followersChange < 0
								? "text-red-500"
								: "text-black"
					}`}
				>
					{totals.followersChange > 0 ? "+" : ""}
					{totals.followersChange}
				</span>
			</TableCell>
			<TableCell className="text-center border-l text-base">
				{totals.profile_views}
			</TableCell>
			<TableCell className="text-center border-l text-base">
				<span
					className={`font-bold ${
						totals.profileViewsChange > 0
							? "text-green-500"
							: totals.profileViewsChange < 0
								? "text-red-500"
								: "text-black"
					}`}
				>
					{totals.profileViewsChange > 0 ? "+" : ""}
					{Math.round(totals.profileViewsChange)}%
				</span>
			</TableCell>
			<TableCell className="text-center border-l text-base">
				{currentEngagement.toFixed(2)}%
			</TableCell>
			<TableCell className="text-center border-l text-base">
				<span
					className={`font-bold ${
						engagementChange > 0
							? "text-green-500"
							: engagementChange < 0
								? "text-red-500"
								: "text-black"
					}`}
				>
					{engagementChange > 0 ? "+" : ""}
					{Math.round(engagementChange)}%
				</span>
			</TableCell>
			<TableCell className="text-center border-l text-base">
				{totals.leads}
			</TableCell>
			<TableCell className="text-center border-l text-base">
				<span
					className={`font-bold ${
						totals.leadsChange > 0
							? "text-green-500"
							: totals.leadsChange < 0
								? "text-red-500"
								: "text-black"
					}`}
				>
					{totals.leadsChange > 0 ? "+" : ""}
					{Math.round(totals.leadsChange)}%
				</span>
			</TableCell>
			<TableCell className="text-center border-l" />
		</TableRow>
	);
};

const getColumns = (t, usersSyncStatus) => [
	{
		id: "user_name",
		accessorKey: "user_name",
		title: t("dashboard.collaborator_metrics.user_name"),
		header: ({ column }) => (
			<SortableHeaderButton
				column={column}
				label={t("dashboard.collaborator_metrics.user_name")}
			/>
		),
		cell: (info) => {
			return (
				<div className="flex items-center gap-2">
					{info.row.original.profile_picture_url && (
						<img
							src={info.row.original.profile_picture_url}
							alt={info.getValue()}
							className="w-8 h-8 rounded-full object-cover"
						/>
					)}
					<span className="text-base">
						{info.row.original.linkedin_profile_name || info.getValue()}
					</span>
				</div>
			);
		},
	},
	{
		header: t("dashboard.collaborator_metrics.posts"),
		columns: [
			{
				id: "linkedin.posts.current",
				accessorKey: "linkedin.posts.current",
				title: t("dashboard.collaborator_metrics.posts"),
				header: ({ column }) => (
					<SortableHeaderButton
						column={column}
						label={t("dashboard.collaborator_metrics.actual")}
					/>
				),
				cell: (info) => {
					return <span className="text-base">{info.getValue()}</span>;
				},
			},
			{
				id: "linkedin.posts.change",
				accessorKey: "linkedin.posts.change",
				title: t("dashboard.collaborator_metrics.postsChange"),
				header: ({ column }) => (
					<SortableHeaderButton
						column={column}
						label={t("dashboard.collaborator_metrics.change")}
					/>
				),
				cell: (info) => {
					const change = info.getValue();
					const colorClass =
						change > 0
							? "text-green-500"
							: change < 0
								? "text-red-500"
								: "text-black";
					return (
						<span className={`font-bold text-base ${colorClass}`}>
							{change > 0 ? "+" : ""}
							{Math.round(change)}%
						</span>
					);
				},
			},
		],
	},
	{
		header: t("dashboard.collaborator_metrics.impressions"),
		columns: [
			{
				id: "linkedin.impressions.current",
				accessorKey: "linkedin.impressions.current",
				title: t("dashboard.collaborator_metrics.impressions"),
				header: ({ column }) => (
					<SortableHeaderButton
						column={column}
						label={t("dashboard.collaborator_metrics.actual")}
					/>
				),
				cell: (info) => {
					return <span className="text-base">{info.getValue()}</span>;
				},
			},
			{
				id: "linkedin.impressions.change",
				accessorKey: "linkedin.impressions.change",
				title: t("dashboard.collaborator_metrics.impressionsChange"),
				header: ({ column }) => (
					<SortableHeaderButton
						column={column}
						label={t("dashboard.collaborator_metrics.change")}
					/>
				),
				cell: (info) => {
					const change = info.getValue();
					const colorClass =
						change > 0
							? "text-green-500"
							: change < 0
								? "text-red-500"
								: "text-black";
					return (
						<span className={`font-bold text-base ${colorClass}`}>
							{change > 0 ? "+" : ""}
							{Math.round(change)}%
						</span>
					);
				},
			},
		],
	},
	{
		header: t("dashboard.collaborator_metrics.interactions"),
		columns: [
			{
				id: "linkedin.interactions.current",
				accessorKey: "linkedin.interactions.current",
				title: t("dashboard.collaborator_metrics.interactions"),
				header: ({ column }) => (
					<SortableHeaderButton
						column={column}
						label={t("dashboard.collaborator_metrics.actual")}
					/>
				),
				cell: (info) => {
					return <span className="text-base">{info.getValue()}</span>;
				},
			},
			{
				id: "linkedin.interactions.change",
				accessorKey: "linkedin.interactions.change",
				title: t("dashboard.collaborator_metrics.interactionsChange"),
				header: ({ column }) => (
					<SortableHeaderButton
						column={column}
						label={t("dashboard.collaborator_metrics.change")}
					/>
				),
				cell: (info) => {
					const change = info.getValue();
					const colorClass =
						change > 0
							? "text-green-500"
							: change < 0
								? "text-red-500"
								: "text-black";
					return (
						<span className={`font-bold text-base ${colorClass}`}>
							{change > 0 ? "+" : ""}
							{Math.round(change)}%
						</span>
					);
				},
			},
		],
	},
	{
		header: t("dashboard.collaborator_metrics.comments"),
		columns: [
			{
				id: "linkedin.comments.current",
				accessorKey: "linkedin.comments.current",
				title: t("dashboard.collaborator_metrics.comments"),
				header: ({ column }) => (
					<SortableHeaderButton
						column={column}
						label={t("dashboard.collaborator_metrics.actual")}
					/>
				),
				cell: (info) => {
					return <span className="text-base">{info.getValue()}</span>;
				},
			},
			{
				id: "linkedin.comments.change",
				accessorKey: "linkedin.comments.change",
				title: t("dashboard.collaborator_metrics.commentsChange"),
				header: ({ column }) => (
					<SortableHeaderButton
						column={column}
						label={t("dashboard.collaborator_metrics.change")}
					/>
				),
				cell: (info) => {
					const change = info.getValue();
					const colorClass =
						change > 0
							? "text-green-500"
							: change < 0
								? "text-red-500"
								: "text-black";
					return (
						<span className={`font-bold text-base ${colorClass}`}>
							{change > 0 ? "+" : ""}
							{Math.round(change)}%
						</span>
					);
				},
			},
		],
	},
	{
		header: t("dashboard.collaborator_metrics.followers"),
		columns: [
			{
				id: "linkedin.followers.current",
				accessorKey: "linkedin.followers.current",
				title: t("dashboard.collaborator_metrics.followers"),
				header: ({ column }) => (
					<SortableHeaderButton
						column={column}
						label={t("dashboard.collaborator_metrics.actual")}
					/>
				),
				cell: (info) => {
					return <span className="text-base">{info.getValue()}</span>;
				},
			},
			{
				id: "linkedin.followers.change",
				accessorKey: "linkedin.followers.change",
				title: t("dashboard.collaborator_metrics.followersChange"),
				header: ({ column }) => (
					<SortableHeaderButton
						column={column}
						label={t("dashboard.collaborator_metrics.change")}
					/>
				),
				cell: (info) => {
					const change = info.getValue();
					const colorClass =
						change > 0
							? "text-green-500"
							: change < 0
								? "text-red-500"
								: "text-black";
					return (
						<span className={`font-bold text-base ${colorClass}`}>
							{change > 0 ? "+" : ""}
							{change}
						</span>
					);
				},
			},
		],
	},
	{
		header: t("dashboard.collaborator_metrics.profile_views"),
		columns: [
			{
				id: "linkedin.profile_views.current",
				accessorKey: "linkedin.profile_views.current",
				title: t("dashboard.collaborator_metrics.profile_views"),
				header: ({ column }) => (
					<SortableHeaderButton
						column={column}
						label={t("dashboard.collaborator_metrics.actual")}
					/>
				),
				cell: (info) => {
					return <span className="text-base">{info.getValue()}</span>;
				},
			},
			{
				id: "linkedin.profile_views.change",
				accessorKey: "linkedin.profile_views.change",
				title: t("dashboard.collaborator_metrics.profile_viewsChange"),
				header: ({ column }) => (
					<SortableHeaderButton
						column={column}
						label={t("dashboard.collaborator_metrics.change")}
					/>
				),
				cell: (info) => {
					const change = info.getValue();
					const colorClass =
						change > 0
							? "text-green-500"
							: change < 0
								? "text-red-500"
								: "text-black";
					return (
						<span className={`font-bold text-base ${colorClass}`}>
							{change > 0 ? "+" : ""}
							{Math.round(change)}%
						</span>
					);
				},
			},
		],
	},
	{
		header: t("dashboard.collaborator_metrics.engagement"),
		columns: [
			{
				id: "linkedin.engagement.current",
				accessorKey: "linkedin.engagement.current",
				title: t("dashboard.collaborator_metrics.engagement"),
				header: ({ column }) => (
					<SortableHeaderButton
						column={column}
						label={t("dashboard.collaborator_metrics.actual")}
					/>
				),
				cell: (info) => {
					const engagement = info.getValue();
					return <span className="text-base">{engagement.toFixed(2)}%</span>;
				},
				sortingFn: (rowA, rowB) => {
					const engagementA = rowA.original.engagement || 0;
					const engagementB = rowB.original.engagement || 0;
					return engagementA - engagementB;
				},
			},
			{
				id: "linkedin.engagement.change",
				accessorKey: "linkedin.engagement.change",
				title: t("dashboard.collaborator_metrics.engagementChange"),
				header: ({ column }) => (
					<SortableHeaderButton
						column={column}
						label={t("dashboard.collaborator_metrics.change")}
					/>
				),
				cell: (info) => {
					const change = info.getValue();
					const colorClass =
						change > 0
							? "text-green-500"
							: change < 0
								? "text-red-500"
								: "text-black";
					return (
						<span className={`font-bold text-base ${colorClass}`}>
							{change > 0 ? "+" : ""}
							{Math.round(change)}%
						</span>
					);
				},
			},
		],
	},
	{
		header: t("dashboard.collaborator_metrics.leads"),
		columns: [
			{
				id: "linkedin.leads.current",
				accessorKey: "linkedin.leads.current",
				title: t("dashboard.collaborator_metrics.actual"),
				header: ({ column }) => (
					<SortableHeaderButton
						column={column}
						label={t("dashboard.collaborator_metrics.actual")}
					/>
				),
				cell: (info) => {
					const leads = info.getValue();
					return <span className="text-base">{leads}</span>;
				},
				sortingFn: (rowA, rowB) => {
					const leadsA = rowA.original.linkedin.leads.current || 0;
					const leadsB = rowB.original.linkedin.leads.current || 0;
					return leadsA - leadsB;
				},
			},
			{
				id: "linkedin.leads.change",
				accessorKey: "linkedin.leads.change",
				title: t("dashboard.collaborator_metrics.change"),
				header: ({ column }) => (
					<SortableHeaderButton
						column={column}
						label={t("dashboard.collaborator_metrics.change")}
					/>
				),
				cell: (info) => {
					const change = info.getValue();
					const colorClass =
						change > 0
							? "text-green-500"
							: change < 0
								? "text-red-500"
								: "text-black";
					return (
						<span className={`font-bold text-base ${colorClass}`}>
							{change > 0 ? "+" : ""}
							{Math.round(change)}%
						</span>
					);
				},
			},
		],
	},
	{
		id: "user_id_status",
		accessorKey: "user_id",
		title: t("dashboard.collaborator_metrics.status"),
		header: () => <span>{t("dashboard.collaborator_metrics.status")}</span>,
		cell: (info) => {
			const user = usersSyncStatus.find((u) => u.user_id === info.getValue());
			const userId = info.getValue();
			const userName = info.row.original.user_name;
			// Get avatar from row.original which is more reliable
			const userAvatar =
				info.row.original.profile_picture_url ||
				user?.profile_picture_url ||
				"";

			return user ? (
				<UnipileAuthProvider>
					<SyncStatusBadge
						syncStatus={user.sync_status}
						lastSyncDate={user.last_sync_date}
						lastSuccessDate={user.last_success_date}
						userId={userId}
						userName={userName}
						userAvatar={userAvatar}
					/>
				</UnipileAuthProvider>
			) : null;
		},
		enableSorting: false,
	},
];

// Add this special TableCell component for status badge to prevent navigation
const StatusTableCell = ({ children }) => {
	const handleCellClick = (e) => {
		// Stop propagation to prevent navigation
		e.stopPropagation();
	};

	return (
		<TableCell className="text-center border-l" onClick={handleCellClick}>
			{children}
		</TableCell>
	);
};

const LinkedInMetricsTable = ({ metrics, t, usersSyncStatus }) => {
	const navigate = useNavigate();
	const [sorting, setSorting] = useState([
		{
			id: "linkedin.posts.current",
			desc: true,
		},
	]);
	const [columnFilters, setColumnFilters] = useState([]);
	const [columnVisibility, setColumnVisibility] = useState({});
	const table = useReactTable({
		data: metrics,
		columns: getColumns(t, usersSyncStatus),
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getSortedRowModel: getSortedRowModel(),
		onSortingChange: setSorting,
		onColumnFiltersChange: setColumnFilters,
		onColumnVisibilityChange: setColumnVisibility,
		state: {
			columnFilters,
			columnVisibility,
			sorting,
		},
	});

	const handleRowClick = (userId) => {
		navigate(`/stats/${userId}`);
	};

	return (
		<Table>
			<TableHeader>
				{table.getHeaderGroups().map((headerGroup) => (
					<TableRow key={headerGroup.id}>
						{headerGroup.headers.map((header, headerIndex) => {
							const isFirstHeader = headerIndex === 0;
							const isLastHeader =
								headerIndex === headerGroup.headers.length - 1;
							return (
								<TableHead
									key={header.id}
									colSpan={header.colSpan}
									className={`text-center ${!isFirstHeader ? "border-l" : ""} ${!isLastHeader ? "border-r" : ""}`}
								>
									{header.isPlaceholder
										? null
										: flexRender(
												header.column.columnDef.header,
												header.getContext(),
											)}
								</TableHead>
							);
						})}
					</TableRow>
				))}
			</TableHeader>
			<TableBody>
				<TotalRow metrics={metrics} />
				{table.getRowModel().rows.map((row, rowIndex) => (
					<TableRow
						key={row.id}
						className={`hover:cursor-pointer hover:bg-muted-foreground/10 ${rowIndex % 2 === 0 ? "bg-gray-100" : "bg-white"}`}
						onClick={() => handleRowClick(row.original.user_id)}
					>
						{row.getVisibleCells().map((cell, cellIndex) => {
							const isFirstCell = cellIndex === 0;
							const isLastCell = cellIndex === row.getVisibleCells().length - 1;

							// Special handling for status column to prevent navigation
							if (cell.column.id === "user_id_status") {
								return (
									<StatusTableCell key={cell.id}>
										{flexRender(cell.column.columnDef.cell, cell.getContext())}
									</StatusTableCell>
								);
							}

							return (
								<TableCell
									key={cell.id}
									className={`${cell.column.id !== "user_name" ? "text-center" : ""} ${!isFirstCell ? "border-l" : ""} ${!isLastCell ? "border-r" : ""}`}
								>
									{flexRender(cell.column.columnDef.cell, cell.getContext())}
								</TableCell>
							);
						})}
					</TableRow>
				))}
			</TableBody>
		</Table>
	);
};

export default LinkedInMetricsTable;
