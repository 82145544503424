"use client";

import { useContext } from "react";

import { AudienceSelector } from "components/audience/AudienceSelector";
import { useToast } from "components/hooks/use-toast";
import { AuthContext } from "components/lib";
import type { AuthContextType } from "types/authContext";

interface AudiencesPageProps {
	t: (key: string) => string;
}

export const AudiencesPage = ({ t }: AudiencesPageProps) => {
	const { user } = useContext<AuthContextType>(AuthContext);
	const organizationId = user?.organization_id || "";
	const { toast } = useToast();

	return (
		<div className="flex flex-1 flex-col bg-background">
			<div className="container space-y-6 p-4 md:p-6 lg:p-8">
				<AudienceSelector t={t} showTitle={true} />
			</div>
		</div>
	);
};
