import type { FC } from "react";

export type Tool = {
	nameKey: string;
	shortDescKey: string;
	longDescKey: string;
	href: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	component: FC<any>;
	released: boolean;
	internal?: boolean;
};

export type GetMorePostsFetchType = {
	posts: LinkedinPostType[];
	hasMore: boolean;
};

export interface LinkedinPostType {
	linkedin_id: string;
	content: string;
	status: "published" | "draft";
	created_at: string;
	comments: number;
	likes: number;
	shares: number;
	impressions: number;
	image_id?: string;
	creation_time: string;
	user_id: string;
	organization_id: string;
	last_updated: string;
}

export type PostAnalysis = {
	"analisis del post": string;
	temas: string;
	keywords: string;
	objetivo: string;
	audiencia: string;
	perspectiva: string;
	tono: string;
	estructura: string;
	"plan de redacción": string;
};

export type WordpressBlogResponse = {
	id: number;
};

export type WordPressBlogPost = {
	content: string;
	title: string;
	excerpt: string;
	slug: string;
	imageUrl?: string;
};

type BlogPostParameter = {
	label: string;
	value: string;
};

export type BlogPostParameters = {
	[key: string]: BlogPostParameter;
};

export interface TransformToBlogResponse {
	success: boolean;
	message: string;
}

export interface LinkedPostMapping {
	linkedin_post_id: string;
	blog_post_id: string;
	status?: string;
}

export enum PostFilter {
	ALL = "all",
	PUBLISHED = "publish",
	DRAFT = "draft",
	UNPUBLISHED = "unpublished",
	PENDING = "pending",
	FAILED = "failed",
}

export interface WordpressDomain {
	id: string;
	domain: string;
	organizationId: string;
}
