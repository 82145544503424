import AmplificationBlog from "./_components/tools/AmplificationBlog/page";
import BlogFromMultimedia from "./_components/tools/BlogFromMultimedia/page";
import BlogFromPostsBulk from "./_components/tools/BlogFromPostsBulk/page";
import { CompanyResearch } from "./_components/tools/CompanyResearch/page";
import CreateChatbotDemo from "./_components/tools/CreateChatbotDemo/page";
import CustomVoiceManagement from "./_components/tools/CustomVoiceManagement/page";
import ProfilePersonaCreation from "./_components/tools/ProfilePersonaCreation/page";
import PublishToLinkedin from "./_components/tools/PublishToLinkedin/page";
import SalesDemo from "./_components/tools/SalesDemo/page";
import { WordpressBlogFromLinkedinPost } from "./_components/tools/WordpressBlogFromLinkedinPost/page";
import type { Tool } from "./types";

export const tools: Tool[] = [
	{
		nameKey: "blog_from_linkedin_post",
		shortDescKey: "short_desc.blog_from_linkedin_post",
		longDescKey: "long_desc.blog_from_linkedin_post",
		href: "/content-creation/blog-from-linkedin-post",
		component: WordpressBlogFromLinkedinPost,
		released: false,
		internal: false,
	},
	{
		nameKey: "blog_from_multimedia",
		shortDescKey: "short_desc.blog_from_multimedia",
		longDescKey: "long_desc.blog_from_multimedia",
		href: "/content-creation/blog-from-multimedia",
		component: BlogFromMultimedia,
		released: true,
		internal: true,
	},
	{
		nameKey: "blog_from_posts_bulk",
		shortDescKey: "short_desc.blog_from_posts_bulk",
		longDescKey: "long_desc.blog_from_posts_bulk",
		href: "/content-creation/blog-from-posts-bulk",
		component: BlogFromPostsBulk,
		released: true,
		internal: true,
	},
	{
		nameKey: "amplification_blog",
		shortDescKey: "short_desc.amplification_blog",
		longDescKey: "long_desc.amplification_blog",
		href: "/content-creation/amplification-blog",
		component: AmplificationBlog,
		released: true,
		internal: false,
	},
	{
		nameKey: "publish_to_linkedin",
		shortDescKey: "short_desc.publish_to_linkedin",
		longDescKey: "long_desc.publish_to_linkedin",
		href: "/content-creation/publish-to-linkedin",
		component: PublishToLinkedin,
		released: true,
		internal: true,
	},
	{
		nameKey: "chatbot_user",
		shortDescKey: "short_desc.chatbot_user",
		longDescKey: "long_desc.chatbot_user",
		href: "/content-creation/profile-persona/creation",
		component: ProfilePersonaCreation,
		released: true,
		internal: true,
	},
	{
		nameKey: "custom_voice_management",
		shortDescKey: "short_desc.custom_voice_management",
		longDescKey: "long_desc.custom_voice_management",
		href: "/content-creation/custom-voice-management",
		component: CustomVoiceManagement,
		released: true,
		internal: true,
	},
	{
		nameKey: "company_research",
		shortDescKey: "short_desc.company_research",
		longDescKey: "long_desc.company_research",
		href: "/content-creation/company-research",
		component: CompanyResearch,
		released: true,
		internal: true,
	},
	{
		nameKey: "sales_demo",
		shortDescKey: "short_desc.sales_demo",
		longDescKey: "long_desc.sales_demo",
		href: "/content-creation/sales-demo",
		component: SalesDemo,
		released: true,
		internal: true,
	},
];

export const domainTools = [
	{
		domain: "Squads Ventures",
		id: "3830378c-54a8-49b7-a161-e0c7b0b9a6f7",
		internal: true,
	},
	{
		domain: "Emblue",
		id: "be332384-de90-4ae9-a588-1d5ed8f2cfee",
		internal: true,
	},
	{
		domain: "Mental Test Lab",
		id: "fe382e7f-3ec4-4f17-9418-b4cf09b5335d",
		internal: true,
	},
	{
		domain: "Boris",
		id: "98c8b7aa-1d0e-4ba0-a498-ecf821491dd3",
		internal: true,
	},
	{
		domain: "Worth It VC",
		id: "b3c904be-645d-46ef-ab75-20223bf7fe21",
		internal: false,
	},
];
