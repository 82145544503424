import { Card } from "components/ui/card";
import { DatePickerWithRange } from "components/ui/date-range-picker";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "components/ui/select";
import { useEffect, useState } from "react";
import MetricsChart from "views/dashboard/_components/metrics-chart.jsx";
import MetricsChartVsGoal from "./metric-chart-vs-goal";

const linkedinMetrics = [
	{
		name: "posts",
		bgColor: "rgba(190, 220, 255, 0.8)",
		borderColor: "rgba(0, 64, 133, 0.8)",
	},
	{
		name: "impressions",
		bgColor: "rgba(170, 200, 255, 0.8)",
		borderColor: "rgba(0, 64, 133, 0.8)",
	},
	{
		name: "likes",
		bgColor: "rgba(150, 180, 255, 0.8)",
		borderColor: "rgba(0, 64, 133, 0.8)",
	},
	{
		name: "comments",
		bgColor: "rgba(130, 160, 255, 0.8)",
		borderColor: "rgba(0, 64, 133, 0.8)",
	},
	{
		name: "leads",
		bgColor: "rgba(110, 140, 255, 0.8)",
		borderColor: "rgba(0, 64, 133, 0.8)",
	},
];

const wordpressMetrics = [
	{
		name: "blogs",
		bgColor: "rgba(130, 200, 130, 0.8)",
		borderColor: "rgba(0, 100, 0, 0.8)",
	},
	{
		name: "impressions",
		bgColor: "rgba(110, 180, 110, 0.8)",
		borderColor: "rgba(0, 100, 0, 0.8)",
	},
	{
		name: "clicks",
		bgColor: "rgba(90, 160, 90, 0.8)",
		borderColor: "rgba(0, 100, 0, 0.8)",
	},
	{
		name: "position",
		bgColor: "rgba(70, 140, 70, 0.8)",
		borderColor: "rgba(0, 100, 0, 0.8)",
	},
	{
		name: "ctr",
		bgColor: "rgba(50, 120, 50, 0.8)",
		borderColor: "rgba(0, 100, 0, 0.8)",
	},
];

const CollaboratorsMetricsCharts = ({
	organizationId,
	t,
	goalMetrics,
	weeklyProgressData,
	goal,
	userInitialGoalMetrics,
	selectedTeamId,
	platform = "linkedin",
	vsGoal,
}) => {
	const [dateRange, setDateRange] = useState(() => {
		const today = new Date();
		const firstDayOfYear = new Date(today.getFullYear(), 0, 1);
		return { from: firstDayOfYear, to: today };
	});

	const [timeInterval, setTimeInterval] = useState("weekly");
	const metrics = vsGoal
		? linkedinMetrics
		: platform === "linkedin"
			? linkedinMetrics
			: wordpressMetrics;
	const [selectedMetric, setSelectedMetric] = useState(metrics[0]);

	useEffect(() => {
		setSelectedMetric(metrics[0]);
	}, [metrics]);

	return (
		<section className="space-y-6 w-full">
			<div className="flex gap-4 flex-wrap">
				<Select
					value={selectedMetric.name}
					onValueChange={(value) =>
						setSelectedMetric(metrics.find((m) => m.name === value))
					}
				>
					<SelectTrigger className="w-[180px] bg-white">
						<SelectValue>
							{t(`dashboard.collaborator_metrics.${selectedMetric.name}`)}
						</SelectValue>
					</SelectTrigger>
					<SelectContent>
						{metrics.map((metric) => (
							<SelectItem key={metric.name} value={metric.name}>
								{t(`dashboard.collaborator_metrics.${metric.name}`)}
							</SelectItem>
						))}
					</SelectContent>
				</Select>

				<DatePickerWithRange
					from={dateRange.from}
					to={dateRange.to}
					onDateChange={({ from, to }) => setDateRange({ from, to })}
					disabled={vsGoal}
				/>

				<Select
					value={timeInterval}
					onValueChange={setTimeInterval}
					disabled={vsGoal}
				>
					<SelectTrigger className="w-[130px] bg-white">
						<SelectValue>
							{t(`dashboard.collaborator_metrics.${timeInterval}`)}
						</SelectValue>
					</SelectTrigger>
					<SelectContent>
						<SelectItem value="daily">
							{t("dashboard.collaborator_metrics.daily")}
						</SelectItem>
						<SelectItem value="weekly">
							{t("dashboard.collaborator_metrics.weekly")}
						</SelectItem>
						<SelectItem value="monthly">
							{t("dashboard.collaborator_metrics.monthly")}
						</SelectItem>
						<SelectItem value="yearly">
							{t("dashboard.collaborator_metrics.yearly")}
						</SelectItem>
					</SelectContent>
				</Select>
			</div>

			<div className="w-full max-h-[700px]">
				<Card className="h-full w-full bg-white p-6">
					{vsGoal ? (
						<MetricsChartVsGoal
							organizationId={organizationId}
							t={t}
							metric={selectedMetric.name}
							backgroundColor={selectedMetric.bgColor}
							borderColor={selectedMetric.borderColor}
							weeklyProgressData={weeklyProgressData}
							goalMetrics={goalMetrics}
							goal={goal}
							userInitialGoalMetrics={userInitialGoalMetrics}
						/>
					) : (
						<MetricsChart
							organizationId={organizationId}
							t={t}
							dates={dateRange}
							metric={selectedMetric.name}
							timeInterval={timeInterval}
							showInputs={false}
							backgroundColor={selectedMetric.bgColor}
							borderColor={selectedMetric.borderColor}
							platform={platform}
						/>
					)}
				</Card>
			</div>
		</section>
	);
};

export default CollaboratorsMetricsCharts;
