import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
	deleteAutomation,
	getAutomationMessages,
	getOrganizationAutomations,
} from "api/automation.ts";
import { useToast } from "components/hooks/use-toast.ts";
import { AuthContext } from "components/lib";
import { useContext, useState } from "react";
import type { AuthContextType } from "types/authContext";
import type { AutomationWithMessages } from "types/automations";

interface UseAutomationsResult {
	automations?: AutomationWithMessages[];
	fetchLoading: boolean;
	deleteAutomation: (automationId: number) => Promise<void>;
	deleteLoading: boolean;
	selectedAutomation: AutomationWithMessages | null;
	setSelectedAutomation: (
		selectedAutomation: AutomationWithMessages | null,
	) => void;
}

export const useAutomations = (
	organizationId: string,
): UseAutomationsResult => {
	const [selectedAutomation, setSelectedAutomation] =
		useState<AutomationWithMessages | null>(null);
	const { toast } = useToast();
	const auth = useContext<AuthContextType>(AuthContext);
	const queryClient = useQueryClient();

	const {
		data: automations,
		isLoading: fetchLoading,
		error,
	} = useQuery({
		queryKey: ["automations", organizationId],
		queryFn: async () => {
			if (!auth.user?.organization_id) {
				throw new Error("Failed to load automations.");
			}

			const apiResponse = await getOrganizationAutomations(
				auth.user.organization_id,
			);
			if (apiResponse.error) {
				throw new Error("Failed to load automations.");
			}

			return await Promise.all(
				apiResponse.data.map(
					async (automation): Promise<AutomationWithMessages> => {
						const automationMessagesResponse = await getAutomationMessages(
							automation.id,
						);
						if (automationMessagesResponse.error) {
							throw new Error("Failed to load automation messages.");
						}
						return { ...automation, messages: automationMessagesResponse.data };
					},
				),
			);
		},
	});

	if (error) {
		toast({
			title: "Error",
			description: error.message,
			variant: "destructive",
		});
	}

	const { mutateAsync: deleteAutomationMutation, isPending: deleteLoading } =
		useMutation({
			mutationFn: async (automationId: number) => {
				const apiResponse = await deleteAutomation(automationId);
				if (apiResponse.error) {
					throw new Error("Failed to delete automation");
				}
			},
			onSuccess: () => {
				queryClient.invalidateQueries({
					queryKey: ["automations", organizationId],
				});
			},
			onError: () => {
				toast({
					title: "Error",
					description: "Failed to delete automation.",
					variant: "destructive",
				});
			},
		});

	return {
		automations,
		fetchLoading,
		deleteAutomation: deleteAutomationMutation,
		deleteLoading,
		selectedAutomation,
		setSelectedAutomation,
	};
};
