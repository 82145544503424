import { useParams } from "react-router-dom";
import { AutomationConfiguration } from "views/automations/automation/automation-configuration.tsx";
import { AutomationLoading } from "views/automations/automation/automation-loading.tsx";
import { useAutomation } from "views/automations/automation/hooks/use-automation.ts";
import { AutomationMessages } from "views/automations/automation/messages/automation-messages.tsx";
import { useRunAutomation } from "../hooks/use-run-automation";
import { useCancelAutomationMessage } from "./hooks/use-cancel-automation-message";

export interface AutomationPageProps {
	t: (key: string) => string;
}

export function AutomationPage(props: AutomationPageProps) {
	const params = useParams();
	const automationId = +(params.id as string);
	const { automation, loadingAutomation } = useAutomation(automationId);
	const { cancelMessage, loading } = useCancelAutomationMessage(automationId);
	const { onRunAutomation, isRunAutomationLoading } = useRunAutomation();

	if (loadingAutomation || !automation) {
		return <AutomationLoading />;
	}

	return (
		<>
			<AutomationConfiguration config={automation.config} t={props.t} />
			<AutomationMessages
				messages={automation.messages}
				invites={automation.invites}
				t={props.t}
				onCancelMessage={cancelMessage}
				onRunAutomation={() => onRunAutomation(automationId)}
				isRunAutomationLoading={isRunAutomationLoading !== undefined}
				cancelMessageLoading={loading}
			/>
		</>
	);
}
