import { Plus } from "lucide-react";
import { useState } from "react";

import { Button } from "components/ui/button";
import { Dialog, DialogContent, DialogTrigger } from "components/ui/dialog";

import { AudienceForm } from "components/forms/AudienceForm";
import type { OrganizationICP } from "types/organization-icp";

interface CreateAudienceDialogProps {
	t: (key: string) => string;
	organizationId: string;
	owner_user_id: string;
	icps: OrganizationICP[];
}

export function CreateAudienceDialog({
	t,
	organizationId,
	owner_user_id,
	icps,
}: CreateAudienceDialogProps) {
	const [open, setOpen] = useState(false);

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogTrigger asChild>
				<Button>
					<Plus className="mr-2 h-4 w-4" />
					{t("organization.audiences.create_button")}
				</Button>
			</DialogTrigger>
			<DialogContent className="max-h-[90vh] overflow-auto sm:max-w-[800px]">
				<AudienceForm
					onSuccess={() => setOpen(false)}
					onCancel={() => setOpen(false)}
					icps={icps}
					organizationId={organizationId}
					owner_user_id={owner_user_id}
					t={t}
				/>
			</DialogContent>
		</Dialog>
	);
}
