"use client";

import {
	Card,
	CardContent,
	CardDescription,
	CardHeader,
	CardTitle,
} from "components/ui/card";
import {
	ChartContainer,
	ChartTooltip,
	ChartTooltipContent,
} from "components/ui/chart";
import { MoveRight, TrendingDown, TrendingUp } from "lucide-react";
import * as React from "react";
import { Label, Pie, PieChart } from "recharts";

import { calculateTrend, formatNumber, generateBlueHues } from "../../utils";

interface MetricPieChartProps {
	metrics: Array<{
		user_id: string;
		user_name: string;
		linkedin: {
			[key: string]: {
				current: number;
				previous: number;
				change: number;
			};
		};
		wordpress: {
			[key: string]: {
				current: number;
				previous: number;
				change: number;
			};
		};
	}>;
	platform: "linkedin" | "wordpress";
	metricKey: string;
	title: string;
	description: string;
}

export const emptyChartData = [
	{
		name: "No data",
		value: 100, // Full circle
		fill: "hsl(215, 25%, 85%)", // Light grey color
	},
];

const getMetricData = (
	metrics: MetricPieChartProps["metrics"],
	platform: "linkedin" | "wordpress",
	metricKey: string,
) => {
	const TOP_N = 5;
	const hues = generateBlueHues(TOP_N);
	const isCTR = metricKey.toLowerCase() === "ctr";

	const sortedMetrics = [...metrics].sort(
		(a, b) => b[platform][metricKey].current - a[platform][metricKey].current,
	);

	const topEntries = sortedMetrics.slice(0, TOP_N).map((metric, index) => ({
		name: metric.user_name,
		value: isCTR
			? metric[platform][metricKey].current * 100
			: metric[platform][metricKey].current,
		fill: `hsl(${hues[index]}, 70%, 50%)`,
	}));

	if (sortedMetrics.length > TOP_N) {
		const othersMetrics = sortedMetrics.slice(TOP_N);
		const othersValue = isCTR
			? (() => {
					const totalClicks = othersMetrics.reduce(
						(sum, metric) => sum + metric[platform].clicks.current,
						0,
					);
					const totalImpressions = othersMetrics.reduce(
						(sum, metric) => sum + metric[platform].impressions.current,
						0,
					);
					return totalImpressions > 0
						? (totalClicks / totalImpressions) * 100
						: 0;
				})()
			: othersMetrics.reduce(
					(sum, metric) => sum + metric[platform][metricKey].current,
					0,
				);

		const othersEntry = {
			name: "Others",
			value: othersValue,
			fill: "hsl(215, 25%, 35%)",
		};

		return [...topEntries, othersEntry];
	}

	return topEntries;
};

export const generateChartConfig = (title: string) => ({
	[title.toLowerCase()]: {
		label: title,
	},
});

export const MetricPieChart = ({
	metrics,
	platform,
	metricKey,
	title,
	description,
}: MetricPieChartProps) => {
	const chartData = React.useMemo(
		() => getMetricData(metrics, platform, metricKey),
		[metrics, platform, metricKey],
	);

	const isCTR = metricKey.toLowerCase() === "ctr";

	const total = React.useMemo(() => {
		if (isCTR) {
			const totalClicks = metrics.reduce(
				(acc, metric) => acc + metric[platform].clicks.current,
				0,
			);
			const totalImpressions = metrics.reduce(
				(acc, metric) => acc + metric[platform].impressions.current,
				0,
			);
			return totalImpressions > 0 ? (totalClicks / totalImpressions) * 100 : 0;
		}
		return metrics.reduce(
			(acc, metric) => acc + metric[platform][metricKey].current,
			0,
		);
	}, [metrics, platform, metricKey, isCTR]);

	const previousTotal = React.useMemo(() => {
		if (isCTR) {
			const previousClicks = metrics.reduce(
				(acc, metric) => acc + metric[platform].clicks.previous,
				0,
			);
			const previousImpressions = metrics.reduce(
				(acc, metric) => acc + metric[platform].impressions.previous,
				0,
			);
			return previousImpressions > 0
				? (previousClicks / previousImpressions) * 100
				: 0;
		}
		return metrics.reduce(
			(acc, metric) => acc + metric[platform][metricKey].previous,
			0,
		);
	}, [metrics, platform, metricKey, isCTR]);

	const trend = Math.round(calculateTrend(total, previousTotal));
	const trendColor = trend > 0 ? "green" : trend < 0 ? "red" : "gray";
	const trendIcon =
		trend > 0 ? (
			<TrendingUp className="h-4 w-4 text-green-500" />
		) : trend < 0 ? (
			<TrendingDown className="h-4 w-4 text-red-500" />
		) : (
			<MoveRight className="h-4 w-4" />
		);

	const chartConfig = React.useMemo(() => generateChartConfig(title), [title]);

	// Create empty data for when total is 0

	return (
		<Card className="flex flex-col bg-white w-[220px]">
			<CardHeader className="items-center pb-0">
				<CardTitle className="text-lg">{title}</CardTitle>
				<CardDescription>
					<div className="flex items-center gap-2 font-medium leading-none whitespace-nowrap text-sm">
						{trend !== 0 ? (
							<>
								<span>{trendIcon}</span>
								<span
									className={`font-bold ${trend > 0 ? "text-green-500" : "text-red-500"}`}
								>
									{trend}%
								</span>
								<span>this period</span>
							</>
						) : (
							<>No significant change this period</>
						)}
					</div>
				</CardDescription>
			</CardHeader>
			<CardContent className="flex-1 py-2">
				<ChartContainer config={chartConfig} className="mx-auto">
					<PieChart>
						{total && (
							<ChartTooltip
								cursor={false}
								content={
									<ChartTooltipContent
										hideLabel
										formatter={(value, name, item, index) => (
											<>
												<div
													className="h-3.0 w-3.0 shrink-0 rounded-[2px] bg-[--color-bg]"
													style={
														{
															"--color-bg": `${item.payload.fill}`,
														} as React.CSSProperties
													}
												/>
												{chartConfig[name as keyof typeof chartConfig]?.label ||
													name}
												<div className="ml-auto flex items-baseline gap-0.5 font-mono font-medium tabular-nums text-foreground">
													{formatNumber(Number(value))}
													{isCTR && "%"}
												</div>
											</>
										)}
									/>
								}
							/>
						)}
						<Pie
							data={total === 0 ? emptyChartData : chartData}
							dataKey="value"
							nameKey="name"
							innerRadius={45}
							outerRadius={60}
							strokeWidth={4}
							startAngle={180}
							endAngle={0}
							paddingAngle={2}
							animationBegin={250}
							cy={80}
						>
							<Label
								content={({ viewBox }) => {
									if (viewBox && "cx" in viewBox && "cy" in viewBox) {
										return (
											<text
												x={viewBox.cx}
												y={viewBox.cy}
												textAnchor="middle"
												dominantBaseline="middle"
											>
												<tspan
													x={viewBox.cx}
													y={(viewBox.cy || 0) - 15}
													className="fill-foreground text-2xl font-bold"
												>
													{formatNumber(total)}
													{isCTR && "%"}
												</tspan>
												<tspan
													x={viewBox.cx}
													y={viewBox.cy || 0}
													className="fill-muted-foreground text-xs"
												>
													{title}
												</tspan>
											</text>
										);
									}
								}}
							/>
						</Pie>
					</PieChart>
				</ChartContainer>
			</CardContent>
		</Card>
	);
};
