import { useQueryClient } from "@tanstack/react-query";
import { deleteICP } from "api/organization-icp";
import { useAxiosMutation } from "hooks/useAxiosMutation";

export const useDeleteICP = () => {
	const queryClient = useQueryClient();

	const mutation = useAxiosMutation({
		mutationFn: (id: number) => deleteICP(id),
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ["icps"],
			});
		},
	});

	return {
		deleteICP: mutation.mutateAsync,
		isDeleting: mutation.isPending,
		error: mutation.error,
	};
};
