import axios from "axios";
import { Button } from "components/ui/button";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import { Download, Plus, RefreshCw } from "lucide-react";
import { appConfig } from "views/content-creation/config";
import { useSyncLeads } from "../../_hooks/use-sync-leads";

interface ActionButtonsV2Props {
	selectedUserId?: string;
	userName: string;
	onRefresh: () => void;
	onExportCsv: () => void;
	onOpenDialog: () => void;
	authToken?: string;
	postIds: string[];
}

const downloadLeadsCSV = async (
	userId: string,
	userName: string,
	authToken: string,
) => {
	try {
		if (!authToken) {
			console.error("No auth token found");
			return;
		}

		const response = await axios.get(`/api/lead-lens/leads/${userId}/export`, {
			responseType: "blob",
		});

		const blob = new Blob([response.data], { type: "text/csv" });
		const url = window.URL.createObjectURL(blob);
		const link = document.createElement("a");
		link.href = url;
		link.setAttribute("download", `${userName.replace(/\s+/g, "_")}_leads.csv`);
		document.body.appendChild(link);
		link.click();
		window.URL.revokeObjectURL(url);
		document.body.removeChild(link);
	} catch (error) {
		console.error("Error downloading leads CSV:", error);
		alert("Failed to download leads CSV. Please try again later.");
	}
};

export const ActionButtonsV2 = ({
	selectedUserId,
	userName,
	onRefresh,
	onExportCsv,
	onOpenDialog,
	authToken,
	postIds,
}: ActionButtonsV2Props) => {
	const { syncUserLeads, isUserSyncing } = useSyncLeads({
		userId: selectedUserId,
		postIds: postIds,
	});

	const handleSync = async () => {
		syncUserLeads();
		onRefresh();
	};

	const handleExport = () => {
		if (!authToken) {
			alert("You need to be logged in to download leads");
			return;
		}
		if (!selectedUserId) return;
		downloadLeadsCSV(selectedUserId, userName, authToken);
	};

	return (
		<div className="flex gap-2">
			<Button
				variant="outline"
				className="font-medium"
				onClick={handleSync}
				disabled={isUserSyncing}
			>
				<RefreshCw
					className={`h-5 w-5 mr-2 ${isUserSyncing ? "animate-spin" : ""}`}
				/>
				{isUserSyncing ? "Sincronizando..." : "Sincronizar Leads"}
			</Button>
		</div>
	);
};
