import {
	Pagination,
	PaginationContent,
	PaginationItem,
	PaginationLink,
	PaginationNext,
	PaginationPrevious,
} from "components/ui/pagination";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "components/ui/select";

interface TablePaginationProps {
	currentPage: number;
	totalPages: number;
	onPageChange: (page: number) => void;
	pageSize: number;
	onPageSizeChange: (size: number) => void;
	totalItems?: number;
}

export const TablePagination = ({
	currentPage,
	totalPages,
	onPageChange,
	pageSize = 100,
	onPageSizeChange,
	totalItems,
}: TablePaginationProps) => {
	return (
		<div className="flex items-center justify-between px-4">
			{totalItems !== undefined && (
				<span className="text-sm text-muted-foreground">
					{totalItems} total items
				</span>
			)}

			<div className="flex-1 flex justify-center">
				<Pagination>
					<PaginationContent className="list-none">
						<PaginationItem>
							<PaginationPrevious
								onClick={() => currentPage > 1 && onPageChange(currentPage - 1)}
								aria-disabled={currentPage === 1}
								className={
									currentPage === 1 ? "pointer-events-none opacity-50" : ""
								}
							/>
						</PaginationItem>
						{Array.from({ length: totalPages }, (_, i) => i + 1)
							.filter((page) => {
								if (totalPages <= 5) return true;

								if (currentPage === 1) return page <= 5;
								if (currentPage === 2) return page >= 1 && page <= 5;
								if (currentPage === totalPages - 1)
									return page >= totalPages - 4 && page <= totalPages;
								if (currentPage === totalPages) return page > totalPages - 5;

								return Math.abs(currentPage - page) <= 2;
							})
							.sort((a, b) => a - b)
							.map((page) => (
								<PaginationItem key={page}>
									<PaginationLink
										onClick={() => onPageChange(page)}
										isActive={currentPage === page}
									>
										{page}
									</PaginationLink>
								</PaginationItem>
							))}
						<PaginationItem>
							<PaginationNext
								onClick={() =>
									currentPage < totalPages && onPageChange(currentPage + 1)
								}
								aria-disabled={currentPage === totalPages}
								className={
									currentPage === totalPages
										? "pointer-events-none opacity-50"
										: ""
								}
							/>
						</PaginationItem>
					</PaginationContent>
				</Pagination>
			</div>

			<div className="flex items-center gap-2">
				<span className="text-sm text-muted-foreground">Rows per page</span>
				<Select
					value={pageSize.toString()}
					onValueChange={(value) => onPageSizeChange(Number(value))}
				>
					<SelectTrigger className="w-[100px]">
						<SelectValue />
					</SelectTrigger>
					<SelectContent>
						{[50, 100, 200, 500].map((size) => (
							<SelectItem key={size} value={size.toString()}>
								{size}
							</SelectItem>
						))}
					</SelectContent>
				</Select>
			</div>
		</div>
	);
};
