import { Button } from "components/ui/button";
import {
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "components/ui/form";
import { Textarea } from "components/ui/textarea";
import { Loader2 } from "lucide-react";
import type { UseFormReturn } from "react-hook-form";
import type { AutomationFormValues } from "../schemas";

export interface TemplateStepProps {
	form: UseFormReturn<AutomationFormValues>;
	onPrevious: () => void;
	onSubmit: () => void;
	isSubmitting?: boolean;
	t: (key: string) => string;
}

export function TemplateStep({
	form,
	onPrevious,
	onSubmit,
	isSubmitting = false,
	t,
}: TemplateStepProps) {
	return (
		<div className="space-y-6">
			<FormField
				control={form.control}
				name="templateMessage"
				render={({ field, fieldState }) => (
					<FormItem>
						<FormLabel>{t("automations.template.label")}</FormLabel>
						<FormControl>
							<Textarea
								placeholder={t("automations.template.placeholder")}
								className="min-h-[200px]"
								{...field}
							/>
						</FormControl>
						{fieldState.error && (
							<FormMessage>
								{fieldState.error.type === "too_small"
									? t("automations.validation.template.minLength")
									: t("automations.validation.template.required")}
							</FormMessage>
						)}
					</FormItem>
				)}
			/>

			<div className="flex justify-between">
				<Button
					type="button"
					variant="outline"
					onClick={onPrevious}
					disabled={isSubmitting}
				>
					{t("automations.create.buttons.previous")}
				</Button>
				<Button type="submit" onClick={onSubmit} disabled={isSubmitting}>
					{isSubmitting ? (
						<>
							<Loader2 className="mr-2 h-4 w-4 animate-spin" />
							{t("automations.create.buttons.creating")}
						</>
					) : (
						t("automations.create.buttons.create")
					)}
				</Button>
			</div>
		</div>
	);
}
