import { AudiencesPage } from "views/organization/audiences/page";
import { CreateICP } from "views/organization/icp/page";

const routes = [
	{
		path: "/icp",
		view: CreateICP,
		layout: "app",
		permission: "user",
		title: "organization.icp.title",
	},
	{
		path: "/icp/audiences",
		view: AudiencesPage,
		layout: "app",
		permission: "user",
		title: "organization.audiences.title",
	},
];

export default routes;
