import CreateAutomationPage from "views/automations/create/page";
import { AutomationsPage } from "views/automations/page";
import {AutomationPage} from "views/automations/automation/page.tsx";

const routes = [
	{
		path: "/automations",
		view: AutomationsPage,
		layout: "app",
		permission: "user",
		title: "Automations",
	},
	{
		path: "/automations/create",
		view: CreateAutomationPage,
		layout: "app",
		permission: "user",
		title: "Create Automation",
	},
    {
        path: "/automations/:id",
        view: AutomationPage,
        layout: "app",
        permission: "user",
        title: "View Automation",
    }
];

export default routes;
