import { formatDistance } from "date-fns";
import { CalendarIcon } from "lucide-react";
import { useState } from "react";

import { AudienceForm } from "components/forms/AudienceForm";
import { Badge } from "components/ui/badge";
import { Button } from "components/ui/button";
import {
	Card,
	CardContent,
	CardDescription,
	CardFooter,
	CardHeader,
	CardTitle,
} from "components/ui/card";
import { Checkbox } from "components/ui/checkbox";
import { Dialog, DialogContent, DialogTrigger } from "components/ui/dialog";
import { Edit, Info } from "lucide-react";
import type { Audience } from "types/audience";
import type { OrganizationICP } from "types/organization-icp";
import { capitalizeWords, splitCommaSeparated } from "utils/string-utils";
import { AudienceDetailsDialog } from "./AudienceDetailsDialog";

interface AudienceCardProps {
	audience: Audience;
	icp?: OrganizationICP;
	isSelected: boolean;
	isToggling: boolean;
	onToggle: (audience: Audience) => void;
	onAudienceUpdated?: () => void;
	icps: OrganizationICP[];
	canEdit?: boolean;
	t: (key: string) => string;
}

export function AudienceCard({
	audience,
	icp,
	isSelected,
	isToggling,
	onToggle,
	onAudienceUpdated,
	icps,
	canEdit = false,
	t,
}: AudienceCardProps) {
	const [detailsOpen, setDetailsOpen] = useState(false);
	const [editOpen, setEditOpen] = useState(false);

	const handleCardClick = () => {
		onToggle(audience);
	};

	const handleEditSuccess = () => {
		setEditOpen(false);
		if (onAudienceUpdated) {
			onAudienceUpdated();
		}
	};

	const handleButtonClick = (e: React.MouseEvent) => {
		e.stopPropagation();
	};

	return (
		<>
			<Card
				className="flex flex-col h-full relative border bg-card cursor-pointer hover:border-primary transition-colors"
				onClick={handleCardClick}
			>
				<CardHeader>
					<div className="flex items-start justify-between">
						<div className="space-y-1">
							<CardTitle>{audience.name}</CardTitle>
							{icp && (
								<div className="mt-1">
									<Badge variant="outline">{icp.name}</Badge>
								</div>
							)}
							{audience.targeting_criteria.description && (
								<CardDescription className="line-clamp-2 mt-1">
									{audience.targeting_criteria.description}
								</CardDescription>
							)}
						</div>
						<Checkbox
							checked={isSelected}
							onCheckedChange={() => onToggle(audience)}
							onClick={(e) => e.stopPropagation()}
							disabled={isToggling}
						/>
					</div>
				</CardHeader>
				<CardContent className="flex-grow">
					<div className="text-sm font-medium mb-2">
						{t("organization.audiences.criteria_label")}
					</div>
					<div className="space-y-2">
						<div className="flex flex-wrap gap-2">
							{splitCommaSeparated(audience.targeting_criteria.currentRole).map(
								(role) => (
									<Badge variant="secondary" key={`role-${role}`}>
										{role}
									</Badge>
								),
							)}

							{splitCommaSeparated(audience.targeting_criteria.location).map(
								(location) => (
									<Badge variant="secondary" key={`location-${location}`}>
										{location}
									</Badge>
								),
							)}

							{audience.targeting_criteria.education && (
								<Badge variant="secondary" key="education">
									{capitalizeWords(audience.targeting_criteria.education)}
								</Badge>
							)}

							{splitCommaSeparated(audience.targeting_criteria.languages).map(
								(language) => (
									<Badge variant="secondary" key={`language-${language}`}>
										{language}
									</Badge>
								),
							)}

							{audience.targeting_criteria.minFollowerCount && (
								<Badge variant="secondary">
									{audience.targeting_criteria.minFollowerCount}+ followers
								</Badge>
							)}
						</div>
					</div>
				</CardContent>
				<CardFooter
					className="pt-0 mt-auto flex gap-2"
					onClick={handleButtonClick}
				>
					<Dialog open={detailsOpen} onOpenChange={setDetailsOpen}>
						<DialogTrigger asChild>
							<Button
								size="sm"
								variant="outline"
								className={canEdit ? "w-3/4" : "w-full"}
							>
								<Info className="h-4 w-4 mr-2" />
								{t("organization.audiences.view_details_button")}
							</Button>
						</DialogTrigger>
						<DialogContent className="max-h-[90vh] overflow-auto sm:max-w-[800px]">
							<AudienceDetailsDialog audience={audience} icp={icp} t={t} />
						</DialogContent>
					</Dialog>

					{canEdit && (
						<Dialog open={editOpen} onOpenChange={setEditOpen}>
							<DialogTrigger asChild>
								<Button size="sm" variant="outline" className="w-1/4">
									<Edit className="h-4 w-4" />
								</Button>
							</DialogTrigger>
							<DialogContent className="max-h-[90vh] overflow-auto sm:max-w-[800px]">
								<AudienceForm
									onSuccess={handleEditSuccess}
									onCancel={() => setEditOpen(false)}
									icps={icps}
									audienceToEdit={audience}
									t={t}
								/>
							</DialogContent>
						</Dialog>
					)}
				</CardFooter>
			</Card>
		</>
	);
}
