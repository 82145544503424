import { useQueryClient } from "@tanstack/react-query";
import { createICP } from "api/organization-icp";
import { useAxiosMutation } from "hooks/useAxiosMutation";
import type { CreateOrganizationICPPayload } from "types/organization-icp";

export const useCreateICP = (organization_id: string) => {
	const queryClient = useQueryClient();

	const mutation = useAxiosMutation({
		mutationFn: (data: CreateOrganizationICPPayload) => createICP(data),
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ["icps"],
			});
		},
	});

	return {
		createICP: mutation.mutateAsync,
		isCreating: mutation.isPending,
		error: mutation.error,
	};
};
