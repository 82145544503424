import { getUserAudiences } from "api/audience";
import { useAxiosQuery } from "hooks/useAxiosQuery";

export const useUserAudiences = (userId?: string) => {
	const {
		data: userAudiences,
		isFetching,
		error,
	} = useAxiosQuery({
		queryKey: ["userAudiences", userId],
		queryFn: async () => {
			if (!userId) return;
			return getUserAudiences(userId);
		},
		enabled: !!userId,
	});

	return {
		userAudiences: userAudiences ?? [],
		isFetching,
		error,
	};
};
