import type { AudienceFormValues } from "schemas/audience-schemas";
import type { Audience } from "types/audience";
import type { OrganizationICP } from "types/organization-icp";
import { convertToNumber } from "utils/form-helpers";
import type { ICPFormValues } from "../schemas";

export const convertICPFormToPayload = (
	data: ICPFormValues,
	organizationId: string,
) => {
	return {
		name: data.name,
		organization_id: organizationId,
		targeting_criteria: {
			...data.targeting_criteria,
			minFollowerCount: convertToNumber(
				data.targeting_criteria.minFollowerCount,
			),
			maxFollowerCount: convertToNumber(
				data.targeting_criteria.maxFollowerCount,
			),
			minStaffCount: convertToNumber(data.targeting_criteria.minStaffCount),
			maxStaffCount: convertToNumber(data.targeting_criteria.maxStaffCount),
			minFoundingYear: convertToNumber(data.targeting_criteria.minFoundingYear),
			maxFoundingYear: convertToNumber(data.targeting_criteria.maxFoundingYear),
			minAverageInteractionPerPostCount: convertToNumber(
				data.targeting_criteria.minAverageInteractionPerPostCount,
			),
			maxAverageInteractionPerPostCount: convertToNumber(
				data.targeting_criteria.maxAverageInteractionPerPostCount,
			),
			fundingData: data.targeting_criteria.fundingData
				? {
						minLastMoneyRaised: convertToNumber(
							data.targeting_criteria.fundingData.minLastMoneyRaised,
						),
						maxLastMoneyRaised: convertToNumber(
							data.targeting_criteria.fundingData.maxLastMoneyRaised,
						),
						minLastFundingYear: convertToNumber(
							data.targeting_criteria.fundingData.minLastFundingYear,
						),
						maxLastFundingYear: convertToNumber(
							data.targeting_criteria.fundingData.maxLastFundingYear,
						),
						minLastFundingType:
							data.targeting_criteria.fundingData.minLastFundingType,
					}
				: undefined,
		},
	};
};

export const getICPFormDefaultValues = (icpToEdit?: OrganizationICP | null) => {
	if (!icpToEdit) {
		return {
			name: "",
			targeting_criteria: {
				locations: "",
				description: "",
				type: "",
				industries: "",
				specialities: "",
				minFollowerCount: "",
				maxFollowerCount: "",
				minStaffCount: "",
				maxStaffCount: "",
				minFoundingYear: "",
				maxFoundingYear: "",
				fundingData: {
					minLastMoneyRaised: "",
					maxLastMoneyRaised: "",
					minLastFundingYear: "",
					maxLastFundingYear: "",
					minLastFundingType: "",
				},
				postsTopics: "",
				minAverageInteractionPerPostCount: "",
				maxAverageInteractionPerPostCount: "",
			},
		};
	}

	const { targeting_criteria: tc } = icpToEdit;
	return {
		name: icpToEdit.name,
		targeting_criteria: {
			locations: tc.locations ?? "",
			description: tc.description ?? "",
			type: tc.type ?? "",
			industries: tc.industries ?? "",
			specialities: tc.specialities ?? "",
			minFollowerCount: tc.minFollowerCount?.toString() ?? "",
			maxFollowerCount: tc.maxFollowerCount?.toString() ?? "",
			minStaffCount: tc.minStaffCount?.toString() ?? "",
			maxStaffCount: tc.maxStaffCount?.toString() ?? "",
			minFoundingYear: tc.minFoundingYear?.toString() ?? "",
			maxFoundingYear: tc.maxFoundingYear?.toString() ?? "",
			fundingData: {
				minLastMoneyRaised:
					tc.fundingData?.minLastMoneyRaised?.toString() ?? "",
				maxLastMoneyRaised:
					tc.fundingData?.maxLastMoneyRaised?.toString() ?? "",
				minLastFundingYear:
					tc.fundingData?.minLastFundingYear?.toString() ?? "",
				maxLastFundingYear:
					tc.fundingData?.maxLastFundingYear?.toString() ?? "",
				minLastFundingType: tc.fundingData?.minLastFundingType ?? "",
			},
			postsTopics: tc.postsTopics ?? "",
			minAverageInteractionPerPostCount:
				tc.minAverageInteractionPerPostCount?.toString() ?? "",
			maxAverageInteractionPerPostCount:
				tc.maxAverageInteractionPerPostCount?.toString() ?? "",
		},
	};
};

export const getAudienceFormDefaultValues = (
	audienceToEdit?: Audience | null,
) => {
	if (!audienceToEdit) {
		return {
			name: "",
			organization_icp_id: "",
			targeting_criteria: {
				description: "",
				location: "",
				currentRole: "",
				education: "",
				languages: "",
				minFollowerCount: "",
				maxFollowerCount: "",
				skills: "",
				postsTopics: "",
				minAverageInteractionPerPostCount: "",
				maxAverageInteractionPerPostCount: "",
			},
		};
	}

	const { targeting_criteria: tc } = audienceToEdit;
	return {
		name: audienceToEdit.name,
		organization_icp_id: audienceToEdit.organization_icp_id?.toString() ?? "",
		targeting_criteria: {
			description: tc.description ?? "",
			location: tc.location ?? "",
			currentRole: tc.currentRole ?? "",
			education: tc.education ?? "",
			languages: tc.languages ?? "",
			minFollowerCount: tc.minFollowerCount?.toString() ?? "",
			maxFollowerCount: tc.maxFollowerCount?.toString() ?? "",
			skills: tc.skills ?? "",
			postsTopics: tc.postsTopics ?? "",
			minAverageInteractionPerPostCount:
				tc.minAverageInteractionPerPostCount?.toString() ?? "",
			maxAverageInteractionPerPostCount:
				tc.maxAverageInteractionPerPostCount?.toString() ?? "",
		},
	};
};

export const hasAdvancedICPFields = (icp: OrganizationICP): boolean => {
	const tc = icp.targeting_criteria;
	return !!(
		tc.minFollowerCount ||
		tc.maxFollowerCount ||
		tc.minStaffCount ||
		tc.maxStaffCount ||
		tc.minFoundingYear ||
		tc.maxFoundingYear ||
		tc.fundingData?.minLastMoneyRaised ||
		tc.fundingData?.maxLastMoneyRaised ||
		tc.fundingData?.minLastFundingYear ||
		tc.fundingData?.maxLastFundingYear ||
		tc.fundingData?.minLastFundingType ||
		tc.minAverageInteractionPerPostCount ||
		tc.maxAverageInteractionPerPostCount
	);
};

export const hasAdvancedAudienceFields = (audience: Audience): boolean => {
	const tc = audience.targeting_criteria;
	return !!(
		tc.minFollowerCount ||
		tc.maxFollowerCount ||
		tc.skills ||
		tc.minAverageInteractionPerPostCount ||
		tc.maxAverageInteractionPerPostCount
	);
};
