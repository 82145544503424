import { getICPs } from "api/organization-icp";
import { useAxiosQuery } from "hooks/useAxiosQuery";

export const useOrganizationICPs = (organization_id: string) => {
	const {
		data: icps,
		isFetching,
		error,
	} = useAxiosQuery({
		queryKey: ["icps"],
		queryFn: () => getICPs({ organization_id }),
		enabled: !!organization_id,
	});

	return {
		icps: icps ?? [],
		isFetching,
		error,
	};
};
