import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AuthContext } from "components/lib";
import { useContext, useEffect } from "react";
import type { AuthContextType } from "types/authContext";
import { leadLensApi } from "../services/lead-lens-api";

interface SyncLeadsOptions {
	userId?: string;
	organizationId?: string;
	postIds: string[];
}

export const useSyncLeads = ({
	userId,
	organizationId,
	postIds,
}: SyncLeadsOptions) => {
	const auth = useContext<AuthContextType>(AuthContext);
	const queryClient = useQueryClient();

	const {
		data: syncStatus,
		refetch: refetchSyncStatus,
		isFetching: isSyncStatusFetching,
	} = useQuery({
		queryKey: ["userSyncStatus", userId],
		queryFn: async () => {
			if (!userId) throw new Error("Missing userId");
			return leadLensApi.getUserPostsSyncStatus(postIds);
		},
		enabled: false,
		refetchInterval: 2000,
	});

	const userSyncMutation = useMutation({
		mutationFn: async () => {
			if (!userId) throw new Error("Missing userId");
			return leadLensApi.syncUserLeads(userId);
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ["userLeadsV2", userId] });
			refetchSyncStatus();
		},
	});

	const organizationSyncMutation = useMutation({
		mutationFn: async () => {
			if (!organizationId) throw new Error("Missing organizationId");
			return leadLensApi.syncOrganizationLeads(organizationId);
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ["userLeadsV2"] });
		},
	});

	// Refetch sync status when component mounts
	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		refetchSyncStatus();
	}, [refetchSyncStatus, postIds]);

	// eslint-disable-next-line react-hooks/exhaustive-deps

	// Refetch sync status when mutation starts
	useEffect(() => {
		if (userSyncMutation.isPending) {
			refetchSyncStatus();
		}
	}, [userSyncMutation.isPending, refetchSyncStatus]);

	return {
		syncUserLeads: userSyncMutation.mutate,
		syncOrganizationLeads: organizationSyncMutation.mutate,
		isUserSyncing:
			userSyncMutation.isPending ||
			isSyncStatusFetching ||
			(syncStatus?.data?.pending ?? 0) > 0 ||
			false,
		isOrganizationSyncing: organizationSyncMutation.isPending,
		userSyncError: userSyncMutation.error,
		organizationSyncError: organizationSyncMutation.error,
	};
};
