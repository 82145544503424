import {
	type UseMutationOptions,
	type UseMutationResult,
	useMutation,
} from "@tanstack/react-query";
import type { AxiosError } from "axios";

type AxiosMutationResult<TData, TVariables> = Omit<
	UseMutationResult<TData, unknown, TVariables, unknown>,
	"error"
> & {
	error: {
		message: string;
		errorCode: string;
		error: {
			statusCode: number;
			errorCode: string;
		};
	};
};

export function useAxiosMutation<TData, TVariables>(
	options: UseMutationOptions<TData, unknown, TVariables, unknown>,
): AxiosMutationResult<TData, TVariables> {
	const result = useMutation<TData, unknown, TVariables, unknown>(options);
	const { error } = result;

	return {
		...result,
		// biome-ignore lint/suspicious/noExplicitAny: <explanation>
		data: (result.data as any)?.data ?? [],

		// biome-ignore lint/suspicious/noExplicitAny: <explanation>
		error: (error as AxiosError)?.response?.data as any,
	};
}
