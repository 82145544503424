import {
	SidebarGroup,
	SidebarGroupContent,
	SidebarGroupLabel,
	SidebarMenu,
	SidebarMenuButton,
	SidebarMenuItem,
} from "components/ui/sidebar";
import { Target, Users } from "lucide-react";
import { useNavigate } from "react-router-dom";
import type { NavItem } from "./app-sidebar-types";

interface OrganizationNavigationProps {
	title: string;
	items: NavItem[];
}

export function OrganizationNavigation({
	title,
	items,
}: OrganizationNavigationProps) {
	const navigate = useNavigate();

	return (
		<SidebarGroup>
			<SidebarGroupLabel>{title}</SidebarGroupLabel>
			<SidebarGroupContent>
				<SidebarMenu>
					{items.map((item) => (
						<SidebarMenuItem key={item.label}>
							<SidebarMenuButton
								tooltip={item.label}
								onClick={() => item.link && navigate(item.link)}
							>
								{item.icon}
								<span>{item.label}</span>
							</SidebarMenuButton>
						</SidebarMenuItem>
					))}
				</SidebarMenu>
			</SidebarGroupContent>
		</SidebarGroup>
	);
}
