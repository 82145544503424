import { Badge } from "components/ui/badge";
import { Button } from "components/ui/button";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectLabel,
	SelectTrigger,
	SelectValue,
} from "components/ui/select";
import { SkeletonSelect } from "views/dashboard/_components/skeleton-select";

interface User {
	user_id: string;
	user_name: string;
}

interface UserSelectionDropdownProps {
	selectedUserId: string | undefined;
	currentUserId: string | undefined;
	users: User[];
	loading: boolean;
	onUserChange: (userId: string) => void;
}

export const UserSelectionDropdown = ({
	selectedUserId,
	currentUserId,
	users,
	loading,
	onUserChange,
}: UserSelectionDropdownProps) => {
	return (
		<div className="flex justify-between items-center">
			{selectedUserId && selectedUserId !== currentUserId && (
				<Badge variant="outline" className="text-amber-600 border-amber-600">
					Viewing as{" "}
					{users.find((user) => user.user_id === selectedUserId)?.user_name ||
						"another user"}
				</Badge>
			)}
			<div
				className={`flex-shrink-0 ${
					selectedUserId && selectedUserId !== currentUserId ? "" : "ml-auto"
				}`}
			>
				{loading ? (
					<SkeletonSelect />
				) : (
					<div className="flex-shrink-0">
						<Select
							value={selectedUserId}
							onValueChange={onUserChange}
							disabled={users.length === 0}
						>
							<SelectTrigger className="max-w-60 bg-white">
								<SelectValue>
									{users.find((user) => user.user_id === selectedUserId)
										?.user_name || "Select User"}
								</SelectValue>
							</SelectTrigger>
							<SelectContent>
								<SelectGroup>
									<SelectLabel>Users</SelectLabel>
									{users.map((user) => (
										<SelectItem key={user.user_id} value={user.user_id}>
											{user.user_name}
										</SelectItem>
									))}
								</SelectGroup>
							</SelectContent>
						</Select>
					</div>
				)}
			</div>
		</div>
	);
};
