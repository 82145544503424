export const splitCommaSeparated = (value?: string): string[] => {
	if (!value) return [];
	return value
		.split(",")
		.map((item) => item.trim())
		.filter(Boolean);
};

export const capitalizeWords = (value?: string): string => {
	if (!value) return "";
	return value
		.split(" ")
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
		.join(" ");
};
