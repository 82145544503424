import { Badge } from "components/ui/badge";

/**
 * Returns a score badge with appropriate styling based on score value
 */
export const getScoreBadge = (score: number) => {
	if (score >= 9)
		return (
			<div className="flex items-center gap-2">
				<Badge className="bg-green-600 text-white font-semibold">
					Muy alto
				</Badge>
				<span className="text-sm text-gray-600">({score})</span>
			</div>
		);
	if (score >= 7)
		return (
			<div className="flex items-center gap-2">
				<Badge className="bg-green-500 text-white font-semibold">Alto</Badge>
				<span className="text-sm text-gray-600">({score})</span>
			</div>
		);
	if (score >= 5)
		return (
			<div className="flex items-center gap-2">
				<Badge className="bg-yellow-500 text-white font-semibold">Medio</Badge>
				<span className="text-sm text-gray-600">({score})</span>
			</div>
		);
	return (
		<div className="flex items-center gap-2">
			<Badge className="bg-red-500 text-white font-semibold">Bajo</Badge>
			<span className="text-sm text-gray-600">({score})</span>
		</div>
	);
};

/**
 * Returns a heat bar visualization for engagement levels
 */
export const getEngagementHeatBar = (count: number) => {
	const maxEngagement = 10;
	const percentage = Math.min((count / maxEngagement) * 100, 100);
	const getColor = (percent: number) => {
		if (percent >= 80) return "from-red-500 to-red-600";
		if (percent >= 60) return "from-orange-500 to-orange-600";
		if (percent >= 40) return "from-yellow-500 to-yellow-600";
		if (percent >= 20) return "from-blue-500 to-blue-600";
		return "from-gray-400 to-gray-500";
	};

	return (
		<div className="flex items-center gap-2">
			<div className="w-16 h-3 bg-gray-200 rounded-full overflow-hidden">
				<div
					className={`h-full bg-gradient-to-r ${getColor(percentage)} transition-all duration-300`}
					style={{ width: `${percentage}%` }}
				/>
			</div>
			<span className="text-sm text-gray-600">({count})</span>
		</div>
	);
};
