import { ImageIcon } from "@radix-ui/react-icons";
import { Card, CardDescription, CardTitle } from "components/ui/card";
import { cn } from "lib/utils";

interface EmptyCardProps extends React.ComponentPropsWithoutRef<typeof Card> {
	title: string;
	description?: string;
	action?: React.ReactNode;
	icon?: React.ComponentType<{ className?: string }>;
}

export function EmptyCard({
	title,
	description,
	icon: Icon = ImageIcon,
	action,
	className,
	...props
}: EmptyCardProps) {
	return (
		<Card
			className={cn(
				"flex flex-col justify-center items-center p-16 space-y-6 w-full bg-transparent",
				className,
			)}
			{...props}
		>
			<div className="p-4 mr-4 rounded-full border border-dashed shrink-0">
				<Icon className="size-8 text-muted-foreground" aria-hidden="true" />
			</div>
			<div className="flex flex-col items-center gap-1.5 text-center">
				<CardTitle>{title}</CardTitle>
				{description ? <CardDescription>{description}</CardDescription> : null}
			</div>
			{action ? action : null}
		</Card>
	);
}
