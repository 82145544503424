import {
	Animate,
	AuthContext,
	Card,
	Grid,
	Link,
	Loader,
	useAPI,
} from "components/lib";
import {
	Bell,
	CreditCard,
	Lock,
	Megaphone,
	Settings,
	Shield,
	Sliders,
	SquareUserRound,
	User,
	Users,
} from "lucide-react";
import { useContext } from "react";
import type { AuthContextType } from "types/authContext";

interface OrganizationProps {
	t: (key: string, defaultValue?: string) => string;
}

export function Organization(props: OrganizationProps) {
	const context = useContext<AuthContextType>(AuthContext);

	const user = useAPI("/api/user");
	const iconSize = 20;

	if (user.loading) return <Loader />;

	return (
		<Animate>
			<Grid cols="4">
				<Card>
					<User size={iconSize} />
					<h2>{props.t("organization.profile.title")}</h2>
					<div>{props.t("organization.profile.description")}</div>
					<Link url="/organization/profile">
						{props.t("organization.profile.button")}
					</Link>
				</Card>

				<Card>
					<Lock size={iconSize} />
					<h2>{props.t("organization.password.title")}</h2>
					<div>
						{user?.data?.has_password
							? props.t("organization.password.description.change")
							: props.t("organization.password.description.create")}
					</div>
					<Link url="/organization/password">
						{user?.data?.has_password
							? props.t("organization.password.button.change")
							: props.t("organization.password.button.create")}
					</Link>
				</Card>

				<Card>
					<Shield size={iconSize} />
					<h2>{props.t("organization.2fa.title")}</h2>
					<div>{props.t("organization.2fa.description")}</div>
					<Link url="/organization/2fa">
						{user?.data?.["2fa_enabled"]
							? props.t("organization.2fa.button.manage")
							: props.t("organization.2fa.button.enable")}
					</Link>
				</Card>

				{context.permission?.owner && (
					<Card>
						<CreditCard size={iconSize} />
						<h2>{props.t("organization.billing.title")}</h2>
						<div>{props.t("organization.billing.description")}</div>
						<Link url="/organization/billing">
							{props.t("organization.billing.button")}
						</Link>
					</Card>
				)}

				<Card>
					<Bell size={iconSize} />
					<h2>{props.t("organization.notifications.title")}</h2>
					<div>{props.t("organization.notifications.description")}</div>
					<Link url="/organization/notifications">
						{props.t("organization.notifications.button")}
					</Link>
				</Card>

				{context.permission?.developer && (
					<Card>
						<Settings size={iconSize} />
						<h2>{props.t("organization.api_keys.title")}</h2>
						<div>{props.t("organization.api_keys.description")}</div>
						<Link url="/organization/apikeys">
							{props.t("organization.api_keys.button")}
						</Link>
					</Card>
				)}

				{context.permission?.admin && (
					<Card>
						<Users size={iconSize} />
						<h2>{props.t("organization.users.title")}</h2>
						<div>{props.t("organization.users.description")}</div>
						<Link url="/organization/users">
							{props.t("organization.users.button")}
						</Link>
					</Card>
				)}

				<Card>
					<Sliders size={iconSize} />
					<h2>{props.t("organization.user_settings.title")}</h2>
					<div>{props.t("organization.user_settings.description")}</div>
					<Link url="/organization/user-settings">
						{props.t("organization.user_settings.button")}
					</Link>
				</Card>

				{context.permission?.admin && (
					<Card>
						<SquareUserRound size={iconSize} />
						<h2>{props.t("organization.icp.title")}</h2>
						<div>{props.t("organization.icp.description")}</div>
						<Link url="/organization/icp">
							{props.t("organization.icp.button")}
						</Link>
					</Card>
				)}

				<Card>
					<Megaphone size={iconSize} />
					<h2>{props.t("organization.audiences.title", "Target Audiences")}</h2>
					<div>
						{props.t(
							"organization.audiences.description",
							"Manage the audiences you want to target with your content",
						)}
					</div>
					<Link url="/organization/audiences">
						{props.t("organization.audiences.button", "Manage Audiences")}
					</Link>
				</Card>
			</Grid>
		</Animate>
	);
}
