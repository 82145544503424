import axios from "axios";
import type { PaginatedAudienceMatchesResponse } from "types/lead";
import { appConfig } from "views/content-creation/config";
import {
	type EngagementFilter,
	type MatchFilter,
	getEngagementFilterRange,
	getMatchFilterRange,
} from "../_components/v2/lead-table-helpers";
import type { IcpFormData } from "../schemas";
import type { PostReactionsResponse } from "../types";

const API_URL = appConfig.API_MAIN_URL;

interface ApiResponse<T> {
	data: T;
	error?: string;
}

interface ProfileIdResponse {
	urn: string;
}

interface SyncLeadsResponse {
	success: boolean;
	message?: string;
	error?: string;
}

interface SyncStatusResponse {
	success: boolean;
	data: {
		pending: number;
		failed: number;
		notSynced: number;
		success: number;
	};
}

export interface UseUserLeadsV2Options {
	page: number;
	limit: number;
	sortBy?: string;
	sortDirection?: "asc" | "desc";
	searchTerm?: string;
	matchFilter?: "all" | "high" | "medium" | "low";
	audienceId?: number;
	engagementFilter?: "all" | "high" | "medium" | "low";
}
export const leadLensApi = {
	generateIcebreaker: async (params: {
		linkedinId: string;
		userId: string;
		token: string;
	}): Promise<ApiResponse<string>> => {
		const { data } = await axios.post(
			`${API_URL}/api/lead-lens/generate-icebreaker`,
			{
				linkedinId: params.linkedinId,
				linkedinProfileId: params.userId,
				userId: params.userId,
			},
			{
				headers: { Authorization: `Bearer ${params.token}` },
			},
		);
		return data;
	},

	syncPostInteractions: async (params: {
		postId: string;
		userId: string;
		token: string;
	}): Promise<ApiResponse<PostReactionsResponse>> => {
		const { data } = await axios.post(
			`${API_URL}/api/lead-lens/sync-post-interactions`,
			{
				postUrn: params.postId,
				linkedinProfileId: params.userId,
				userId: params.userId,
			},
			{
				headers: { Authorization: `Bearer ${params.token}` },
			},
		);
		return data;
	},

	updateIcpSettings: async (params: {
		userId: string;
		token: string;
		data: IcpFormData;
	}): Promise<ApiResponse<unknown>> => {
		const { data } = await axios.put(
			`${API_URL}/api/user-settings`,
			{
				user_id: params.userId,
				...params.data,
			},
			{
				headers: { Authorization: `Bearer ${params.token}` },
			},
		);
		return data;
	},

	getProfileIdByUserId: async (params: { userId: string }): Promise<
		ApiResponse<ProfileIdResponse>
	> => {
		try {
			const { data } = await axios.get(
				`${API_URL}/api/lead-lens/get-profile-id-by-user-id`,
				{ params: { userId: params.userId } },
			);

			return data;
		} catch (error) {
			console.error(error, "[LeadLensApi] Error getting profile id by user id");
			throw error;
		}
	},

	getAudiencesByUserId: async (params: { userId: string }): Promise<
		ApiResponse<ProfileIdResponse>
	> => {
		try {
			const { data } = await axios.get("/api/audience/users", {
				params: { userId: params.userId },
			});

			return data;
		} catch (error) {
			console.error(error, "[LeadLensApi] Error getting audiences by user id");
			throw error;
		}
	},

	syncUserLeads: async (userId: string): Promise<SyncLeadsResponse> => {
		const { data } = await axios.post("/api/leads/sync", {
			userId,
			daysToSync: 30,
		});
		return data;
	},

	syncOrganizationLeads: async (
		organizationId: string,
	): Promise<SyncLeadsResponse> => {
		const { data } = await axios.post("/api/leads/sync/organization", {
			organizationId,
		});
		return data;
	},

	getUserPostsSyncStatus: async (
		postIds: string[],
	): Promise<SyncStatusResponse> => {
		const { data } = await axios.post("/api/leads/sync/posts/progress", {
			postIds,
		});
		return data;
	},

	fetchUserLeadsV2: async ({
		userId,
		page,
		limit,
		sortBy = "score",
		sortDirection = "desc",
		searchTerm = "",
		matchFilter = "all",
		audienceId,
		engagementFilter,
		token,
	}: UseUserLeadsV2Options & {
		userId: string;
		token: string;
	}): Promise<PaginatedAudienceMatchesResponse> => {
		// Convertir filtros categóricos a rangos numéricos usando funciones de los helpers
		const matchFilterRange = getMatchFilterRange(matchFilter as MatchFilter);
		const engagementFilterRange = getEngagementFilterRange(
			engagementFilter as EngagementFilter,
		);

		// Preparar el objeto de filtros - solo incluir audienceId si es válido (no es 0 ni undefined)
		const filters: {
			matchFilterRange?: { min?: number; max?: number };
			engagementFilterRange?: { min?: number; max?: number };
			audienceId?: number;
		} = {
			matchFilterRange,
			engagementFilterRange,
		};

		// Solo incluir audienceId en los filtros si es un valor válido (diferente de 0)
		if (audienceId && audienceId !== 0) {
			filters.audienceId = audienceId;
		}

		// Check if we're using default sort (createdAt desc, score desc, interactions count desc)
		const isDefaultSort = sortBy === "createdAt" && sortDirection === "desc";

		// Add information about the sort to help with debugging
		const requestParams = {
			page,
			limit,
			sortBy,
			sortDirection,
			searchTerm,
			filters,
			isDefaultSort, // Add this flag to indicate default multi-column sort
		};

		console.log("API request params:", requestParams);

		try {
			const { data } = await axios.get(`/api/v2/lead-lens/leads/${userId}`, {
				params: requestParams,
				headers: {
					Authorization: `Bearer ${token}`,
				},
				paramsSerializer: (params) => {
					// Custom serializer to properly handle nested objects in the URL
					return Object.entries(params)
						.map(([key, value]) => {
							if (typeof value === "object" && value !== null) {
								return `${key}=${encodeURIComponent(JSON.stringify(value))}`;
							}
							return `${key}=${encodeURIComponent(String(value))}`;
						})
						.join("&");
				},
			});

			// Check if data is an object with leads property or just an array of leads
			const leads = Array.isArray(data) ? data : data.leads || [];
			const totalItems = Array.isArray(data)
				? data.length
				: data.totalItems || data.total || leads.length;
			const totalCount = Array.isArray(data)
				? data.length
				: data.totalCount || totalItems;

			return {
				leads: leads,
				page,
				limit,
				totalItems,
				totalPages: Math.ceil(totalItems / limit),
				totalCount,
			};
		} catch (error) {
			console.error("Error fetching audience matches:", error);
			throw error;
		}
	},
};

function getDbColumnName(columnId: string): string {
	const columnMap: Record<string, string> = {
		// Basic profile fields - now with better naming based on database schema
		"profile.firstName": "pp.first_name",
		"profile.lastName": "pp.last_name",
		"profile.title": "pp.headline",
		"profile.company": "c.name",
		"profile.locationName": "pp.location_name",

		// Score and engagement fields
		score: "l.score",
		"interactions.count": "i.count",
		"audience.name": "a.name",

		// Legacy mappings for backward compatibility
		fullName: "pp.first_name",
		company: "c.name",
		title: "pp.headline",
		location: "pp.location_name",
		engagementScore: "i.count",
		latestInteraction: "i.interaction_date",
		audience: "a.id",
	};

	// Return the mapped column name, or the original if no mapping exists
	console.log(
		`Column mapping: ${columnId} -> ${columnMap[columnId] || columnId}`,
	);
	return columnMap[columnId] || columnId;
}
