import { EnvelopeClosedIcon } from "@radix-ui/react-icons";
import {
	type ColumnDef,
	type Row,
	flexRender,
	getCoreRowModel,
	getSortedRowModel,
	useReactTable,
} from "@tanstack/react-table";
import { useToast } from "components/hooks/use-toast";
import { Avatar, AvatarFallback, AvatarImage } from "components/ui/avatar.tsx";
import { Badge } from "components/ui/badge";
import { Button } from "components/ui/button.tsx";
import {
	Card,
	CardContent,
	CardHeader,
	CardTitle,
} from "components/ui/card.tsx";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "components/ui/table.tsx";
import { CircleX, LinkedinIcon, Loader2, MoreVertical } from "lucide-react";
import type React from "react";
import { useState } from "react";
import type { AutomationMessage } from "types/automations.ts";
import type { LinkedinInvite } from "types/linkedin-invite";
import { EmptyAutomation } from "views/automations/automation/empty-automation.tsx";

export interface AutomationModalContentProps {
	t: (key: string) => string;
	messages: AutomationMessage[];
	invites?: LinkedinInvite[];
	onCancelMessage: (messageId: number) => Promise<void>;
	onRunAutomation: () => void;
	isRunAutomationLoading: boolean;
	cancelMessageLoading: boolean;
}

function formatDateLocal(isoString: string) {
	const date = new Date(isoString);

	const day = String(date.getDate()).padStart(2, "0");
	const month = String(date.getMonth() + 1).padStart(2, "0");
	const year = date.getFullYear();
	const hours = String(date.getHours()).padStart(2, "0");
	const minutes = String(date.getMinutes()).padStart(2, "0");

	return `${day}/${month}/${year} - ${hours}:${minutes}`;
}

export function AutomationMessages(props: AutomationModalContentProps) {
	const { messages, t, invites = [] } = props;

	const [openMenuRow, setOpenMenuRow] = useState<number | null>(null);
	const { toast } = useToast();

	const toggleMenu = (e: React.MouseEvent, rowId: number) => {
		e.stopPropagation();
		setOpenMenuRow(openMenuRow === rowId ? null : rowId);
	};

	const invitesByReceiverId = (invites || []).reduce(
		(acc, invite) => {
			acc[invite.receiver_id] = invite;
			return acc;
		},
		{} as Record<number, LinkedinInvite>,
	);

	const columns: ColumnDef<AutomationMessage>[] = [
		{
			id: "receiver",
			header: t("automations.view.modal.messages.table.columns.receiver"),
			cell: ({ row }: { row: Row<AutomationMessage> }) => {
				const initials =
					`${row.original.receiverFirstName?.[0] || ""}${row.original.receiverLastName?.[0] || ""}`.toUpperCase();
				const firstName = row.original.receiverFirstName;
				const lastName = row.original.receiverLastName;
				console.log("row message", row.original);
				return (
					<div className="flex items-center gap-3 justify-left">
						<a
							href={row.original.profilePictureUrl}
							target="_blank"
							rel="noopener noreferrer"
							className="group relative"
							onClick={(e) => e.stopPropagation()}
						>
							<Avatar className="h-10 w-10 ring-2 ring-blue-100 group-hover:ring-blue-300 transition-all">
								<AvatarImage
									src={row.original.profilePictureUrl || undefined}
									alt={`${firstName} ${lastName}`}
								/>
								<AvatarFallback>{initials}</AvatarFallback>
							</Avatar>
							<LinkedinIcon className="h-4 w-4 text-[#0A66C2] absolute -bottom-1 -right-1 opacity-0 group-hover:opacity-100 transition-opacity" />
						</a>
						<span>{`${firstName} ${lastName}`}</span>
					</div>
				);
			},
		},
		{
			id: "status",
			header: t("automations.view.modal.messages.table.columns.status"),
			cell: ({ row }: { row: Row<AutomationMessage> }) => {
				const matchingInvite = invitesByReceiverId[row.original.receiverId];

				if (row.original.status === "failed" && matchingInvite) {
					let inviteStatusDisplay: string;
					let badgeColorClass = "";

					switch (matchingInvite.status) {
						case "pending":
							inviteStatusDisplay = t(
								"automations.view.modal.messages.table.inviteStatus.pending",
							);
							badgeColorClass = "bg-blue-100 text-blue-800";
							break;
						case "accepted":
							inviteStatusDisplay = t(
								"automations.view.modal.messages.table.inviteStatus.accepted",
							);
							badgeColorClass = "bg-green-100 text-green-800";
							break;
						case "rejected":
							inviteStatusDisplay = t(
								"automations.view.modal.messages.table.inviteStatus.rejected",
							);
							badgeColorClass = "bg-red-100 text-red-800";
							break;
						case "canceled":
							inviteStatusDisplay = t(
								"automations.view.modal.messages.table.inviteStatus.canceled",
							);
							badgeColorClass = "bg-gray-100 text-gray-800";
							break;
					}

					return (
						<Badge className={`font-medium ${badgeColorClass}`}>
							{inviteStatusDisplay}
						</Badge>
					);
				}

				let statusDisplay: string;
				let statusColorClass = "";

				switch (row.original.status) {
					case "sent":
						statusDisplay = t(
							"automations.view.modal.messages.table.status.sent",
						);
						statusColorClass = "bg-green-100 text-green-800";
						break;
					case "scheduled":
						statusDisplay = t(
							"automations.view.modal.messages.table.status.scheduled",
						);
						statusColorClass = "bg-yellow-100 text-yellow-800";
						break;
					case "failed":
						statusDisplay = "NOT CONTACT";
						statusColorClass = "bg-red-100 text-red-800";
						break;
					case "canceled":
						statusDisplay = "CANCELED";
						statusColorClass = "bg-gray-100 text-gray-800";
						break;
				}

				return <Badge className={`${statusColorClass}`}>{statusDisplay}</Badge>;
			},
		},
		{
			id: "scheduledFor",
			header: t("automations.view.modal.messages.table.columns.scheduledFor"),
			cell: ({ row }: { row: Row<AutomationMessage> }) => {
				return <>{formatDateLocal(row.original.scheduledFor)}</>;
			},
		},
		{
			id: "sentAt",
			header: t("automations.view.modal.messages.table.columns.sentAt"),
			cell: ({ row }: { row: Row<AutomationMessage> }) => {
				return (
					<>
						{row.original.sentAt !== undefined
							? formatDateLocal(row.original.sentAt)
							: "-"}
					</>
				);
			},
		},
		{
			id: "messageText",
			header: t("automations.view.modal.messages.table.columns.text"),
			cell: ({ row }: { row: Row<AutomationMessage> }) => {
				return (
					<div>
						<span>{row.original.text}</span>
					</div>
				);
			},
		},
		{
			id: "actions",
			header: t("automations.view.automationsTable.columns.actions"),
			cell: ({ row }: { row: Row<AutomationMessage> }) => {
				return (
					// biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
					<div
						className="flex items-center justify-end actions-cell relative"
						onClick={(e) => e.stopPropagation()}
					>
						<DropdownMenu>
							<DropdownMenuTrigger>
								<Button
									variant="ghost"
									className="h-8 w-8 p-0 hover:bg-muted"
									onClick={(e) => toggleMenu(e, row.original.id)}
								>
									<MoreVertical className="h-4 w-4" />
								</Button>
							</DropdownMenuTrigger>
							<DropdownMenuContent>
								{row.original.status === "scheduled" && (
									<DropdownMenuItem
										onClick={async (e) => {
											e.stopPropagation();
											props.onCancelMessage(row.original.id);

											toast({
												title: "Canceling Message",
												variant: "default",
											});
										}}
									>
										{props.cancelMessageLoading ? (
											<Loader2 className="mr-2 h-5 w-5 text-red-600 animate-spin" />
										) : (
											<CircleX className="mr-2 h-5 w-5 text-red-600" />
										)}
										<span className="text-red-600">
											{t(
												"automations.view.modal.messages.table.actions.cancel",
											)}
										</span>
									</DropdownMenuItem>
								)}
								{row.original.status !== "scheduled" && (
									<DropdownMenuItem>
										<span>No actions available</span>
									</DropdownMenuItem>
								)}
							</DropdownMenuContent>
						</DropdownMenu>
					</div>
				);
			},
		},
	];

	const table = useReactTable({
		data: messages,
		columns,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
	});

	const automationHasMessages = messages.length > 0;

	if (!automationHasMessages) {
		return (
			<EmptyAutomation
				t={t}
				onRunAutomation={props.onRunAutomation}
				isRunAutomationLoading={props.isRunAutomationLoading}
			/>
		);
	}

	return (
		<>
			<Card>
				<CardHeader>
					<CardTitle className="text-2xl font-medium flex items-center gap-2">
						<EnvelopeClosedIcon className="w-6 h-6" />
						{t("automations.view.modal.messages.table.title")}
					</CardTitle>
				</CardHeader>
				<CardContent>
					<Table className={"overflow-visible"}>
						<TableHeader>
							{table.getHeaderGroups().map((headerGroup) => (
								<TableRow key={headerGroup.id}>
									{headerGroup.headers.map((header) => (
										<TableHead
											key={header.id}
											className={
												header.id === "receiver"
													? "text-left"
													: header.id === "actions"
														? "text-right"
														: "text-center"
											}
										>
											{header.isPlaceholder
												? null
												: flexRender(
														header.column.columnDef.header,
														header.getContext(),
													)}
										</TableHead>
									))}
								</TableRow>
							))}
						</TableHeader>
						<TableBody className={"overflow-visible"}>
							{table.getRowModel().rows.map((row) => (
								<TableRow
									key={row.id}
									className="hover:bg-muted/50 cursor-pointer overflow-visible"
								>
									{row.getVisibleCells().map((cell) => (
										<TableCell
											key={cell.id}
											className="text-center overflow-visible"
										>
											{flexRender(
												cell.column.columnDef.cell,
												cell.getContext(),
											)}
										</TableCell>
									))}
								</TableRow>
							))}
						</TableBody>
					</Table>
				</CardContent>
			</Card>
		</>
	);
}
