import { getOldLinkedinProfile } from "api/linkedin-profile";
import { useAxiosQuery } from "hooks/useAxiosQuery";

export const useOldLinkedinProfile = (id?: string) => {
	const {
		data: profile,
		isFetching,
		error,
	} = useAxiosQuery({
		queryKey: ["oldLinkedinProfile", id],
		queryFn: async () => {
			if (!id) return;
			return getOldLinkedinProfile(id);
		},
		enabled: !!id,
	});

	return {
		profile,
		isFetching,
		error,
	};
};
