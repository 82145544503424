import { Button } from "components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "components/ui/dialog";
import { Separator } from "components/ui/separator";
import type { Audience } from "types/audience";
import type { OrganizationICP } from "types/organization-icp";

interface AudienceDetailsDialogProps {
	audience: Audience;
	icp?: OrganizationICP;
	t: (key: string) => string;
}

export function AudienceDetailsDialog({
	audience,
	icp,
	t,
}: AudienceDetailsDialogProps) {
	return (
		<>
			<DialogHeader>
				<DialogTitle>{audience.name}</DialogTitle>
				<DialogDescription>
					{t("organization.audiences.details_dialog.description")}
				</DialogDescription>
			</DialogHeader>

			<div className="grid gap-6 py-4">
				<div>
					<h3 className="text-xl font-bold mb-3">
						{t("organization.audiences.details_dialog.audience_info_title")}
					</h3>
					<Separator className="mb-4" />

					<div className="grid grid-cols-1 md:grid-cols-2 gap-4">
						<div className="space-y-2">
							<div>
								<h4 className="text-sm font-semibold text-primary">
									{t("organization.audiences.details_dialog.description_label")}
								</h4>
								<p>{audience?.targeting_criteria.description || "—"}</p>
							</div>

							<div>
								<h4 className="text-sm font-semibold text-primary">
									{t("organization.audiences.details_dialog.role_label")}
								</h4>
								<p>{audience?.targeting_criteria.currentRole || "—"}</p>
							</div>

							<div>
								<h4 className="text-sm font-semibold text-primary">
									{t("organization.audiences.details_dialog.location_label")}
								</h4>
								<p>{audience?.targeting_criteria.location || "—"}</p>
							</div>
						</div>

						<div className="space-y-2">
							<div>
								<h4 className="text-sm font-semibold text-primary">
									{t("organization.audiences.details_dialog.education_label")}
								</h4>
								<p>{audience?.targeting_criteria.education || "—"}</p>
							</div>

							<div>
								<h4 className="text-sm font-semibold text-primary">
									{t("organization.audiences.details_dialog.languages_label")}
								</h4>
								<p>{audience?.targeting_criteria.languages || "—"}</p>
							</div>

							<div>
								<h4 className="text-sm font-semibold text-primary">
									{t("organization.audiences.details_dialog.skills_label")}
								</h4>
								<p>{audience?.targeting_criteria.skills || "—"}</p>
							</div>

							<div>
								<h4 className="text-sm font-semibold text-primary">
									{t("organization.audiences.details_dialog.posts_about_label")}
								</h4>
								<p>{audience?.targeting_criteria.postsTopics || "—"}</p>
							</div>
						</div>
					</div>

					<div className="mt-4 space-y-2">
						<h4 className="text-base font-bold text-foreground">
							{t(
								"organization.audiences.details_dialog.engagement_metrics_title",
							)}
						</h4>
						<div className="grid grid-cols-2 gap-4">
							<div>
								<p className="text-xs text-muted-foreground">
									{t(
										"organization.audiences.details_dialog.followers_range_label",
									)}
								</p>
								<p>
									{audience?.targeting_criteria.minFollowerCount || "0"}{" "}
									{t("organization.audiences.details_dialog.range_to")}{" "}
									{audience?.targeting_criteria.maxFollowerCount || "∞"}
								</p>
							</div>
							<div>
								<p className="text-xs text-muted-foreground">
									{t(
										"organization.audiences.details_dialog.interactions_range_label",
									)}
								</p>
								<p>
									{audience?.targeting_criteria
										.minAverageInteractionPerPostCount || "0"}{" "}
									{t("organization.audiences.details_dialog.range_to")}{" "}
									{audience?.targeting_criteria
										.maxAverageInteractionPerPostCount || "∞"}
								</p>
							</div>
						</div>
					</div>
				</div>

				{icp && (
					<div>
						<h3 className="text-xl font-bold mb-3">
							{`${t("organization.audiences.details_dialog.associated_icp_title")}: ${icp.name}`}
						</h3>
						<Separator className="mb-4" />

						<div className="grid grid-cols-1 md:grid-cols-2 gap-4">
							<div className="space-y-2">
								<div>
									<h4 className="text-sm font-semibold text-primary">
										{t(
											"organization.audiences.details_dialog.icp_description_label",
										)}
									</h4>
									<p>{icp.targeting_criteria.description || "—"}</p>
								</div>

								<div>
									<h4 className="text-sm font-semibold text-primary">
										{t(
											"organization.audiences.details_dialog.icp_industries_label",
										)}
									</h4>
									<p>{icp.targeting_criteria.industries || "—"}</p>
								</div>

								<div>
									<h4 className="text-sm font-semibold text-primary">
										{t(
											"organization.audiences.details_dialog.icp_locations_label",
										)}
									</h4>
									<p>{icp.targeting_criteria.locations || "—"}</p>
								</div>
							</div>

							<div className="space-y-2">
								<div>
									<h4 className="text-sm font-semibold text-primary">
										{t(
											"organization.audiences.details_dialog.icp_company_type_label",
										)}
									</h4>
									<p>{icp.targeting_criteria.type || "—"}</p>
								</div>

								<div>
									<h4 className="text-sm font-semibold text-primary">
										{t(
											"organization.audiences.details_dialog.icp_specialities_label",
										)}
									</h4>
									<p>{icp.targeting_criteria.specialities || "—"}</p>
								</div>

								{icp.targeting_criteria.minFoundingYear && (
									<div>
										<h4 className="text-sm font-semibold text-primary">
											{t(
												"organization.audiences.details_dialog.icp_founding_year_label",
											)}
										</h4>
										<p>
											{icp.targeting_criteria.minFoundingYear}{" "}
											{t("organization.audiences.details_dialog.range_to")}{" "}
											{icp.targeting_criteria.maxFoundingYear ||
												t("organization.audiences.details_dialog.icp_present")}
										</p>
									</div>
								)}
							</div>
						</div>
					</div>
				)}
			</div>
		</>
	);
}
