import { useQueryClient } from "@tanstack/react-query";
import { assignAudienceToUser, removeAudienceFromUser } from "api/audience";
import { useAxiosMutation } from "hooks/useAxiosMutation";

export const useToggleAudience = (userId?: string) => {
	const queryClient = useQueryClient();

	const assignMutation = useAxiosMutation({
		mutationFn: async ({ audienceId }: { audienceId: number }) => {
			if (!userId) throw new Error("User ID is required");
			return assignAudienceToUser(audienceId, userId);
		},
	});

	const removeMutation = useAxiosMutation({
		mutationFn: async ({ audienceId }: { audienceId: number }) => {
			if (!userId) throw new Error("User ID is required");
			return removeAudienceFromUser(audienceId, userId);
		},
	});

	return {
		assignAudience: assignMutation.mutateAsync,
		removeAudience: removeMutation.mutateAsync,
		isAssigning: assignMutation.isPending,
		isRemoving: removeMutation.isPending,
		assignError: assignMutation.error,
		removeError: removeMutation.error,
	};
};
