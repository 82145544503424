import { Card, CardContent } from "components/ui/card";
import { motion } from "framer-motion";
import { cn } from "lib/utils";
import { CheckIcon } from "lucide-react";

interface Step {
	title: string;
	id: string;
}

interface StepsProps {
	steps: Step[];
	currentStep: number;
}

export function Steps({ steps, currentStep }: StepsProps) {
	const progressPercentage =
		steps.length <= 1 ? 100 : (currentStep / (steps.length - 1)) * 100;

	const totalColumns = steps.length * 2;

	// Create custom grid templates with smaller first and last columns
	const mainGridTemplate =
		steps.length <= 2
			? "1fr 1fr 1fr 1fr"
			: `0.33fr 0.33fr ${Array(totalColumns - 4)
					.fill("1fr")
					.join(" ")} 0.33fr 0.33fr`;

	const innerGridTemplate =
		steps.length <= 2
			? "1fr 1fr"
			: `0.33fr ${Array(steps.length - 2)
					.fill("1fr")
					.join(" ")} 0.33fr`;

	return (
		<Card className="mx-auto w-full">
			<CardContent className="p-6">
				<div
					className="grid relative"
					style={{
						gridTemplateColumns: mainGridTemplate,
						gridTemplateRows: "auto",
					}}
				>
					<div
						className="grid"
						style={{
							gridRow: 1,
							gridColumn: "1 / -1",
							gridTemplateColumns: innerGridTemplate,
						}}
					>
						{steps.map((step, index) => (
							<div
								key={`step-${step.id}`}
								className="flex flex-col items-center justify-center overflow-hidden"
								style={{ minWidth: 0 }}
							>
								<div className="py-1">
									<motion.div
										className={cn(
											"w-10 h-10 rounded-full flex items-center justify-center text-sm font-semibold relative z-10",
											index <= currentStep
												? "bg-primary text-primary-foreground"
												: "bg-muted text-muted-foreground",
										)}
										initial={false}
										animate={{
											scale: index === currentStep ? 1.05 : 1,
											transition: {
												type: "spring",
												stiffness: 300,
												damping: 20,
											},
										}}
									>
										{index < currentStep ? (
											<CheckIcon className="w-5 h-5" />
										) : (
											index + 1
										)}
									</motion.div>
								</div>
								<div
									className="text-sm font-medium text-muted-foreground w-full px-1"
									style={{
										whiteSpace: "nowrap",
										overflow: "hidden",
										textOverflow: "ellipsis",
										textAlign: "center",
									}}
								>
									{step.title}
								</div>
							</div>
						))}
					</div>

					<div
						className="bg-muted"
						style={{
							height: "0.25rem",
							gridRow: 1,
							gridColumn: `2 / ${totalColumns}`,
							marginTop: "1.375rem",
							zIndex: 0,
						}}
					>
						<motion.div
							className="h-full bg-primary origin-left"
							initial={{ width: "0%" }}
							animate={{ width: `${progressPercentage}%` }}
							transition={{ type: "spring", stiffness: 100, damping: 20 }}
						/>
					</div>
				</div>
			</CardContent>
		</Card>
	);
}
