import EditBlogPage from "views/content-creation/EditBlog/[id]/page";
import { ContentGeneration } from "views/content-creation/content-creation";
import { tools } from "views/content-creation/tools";

const routes = [
	{
		path: "/content-creation",
		view: ContentGeneration,
		layout: "app",
		permission: "user",
		title: "dashboard.title",
	},
	{
		path: "/content-creation/edit-blog/:id",
		view: EditBlogPage,
		layout: "app",
		permission: "user",
		title: "Edit Blog",
	},
	...tools.map((tool) => ({
		path: tool.href,
		view: tool.component,
		layout: "app",
		permission: "user",
		title: "dashboard.title",
	})),
];

export default routes;
