import { Button } from "components/ui/button";
import { Card } from "components/ui/card";
import { AlertCircle, RefreshCw } from "lucide-react";

interface EmptyErrorStateProps {
	onRetry: () => void;
}

export const EmptyErrorState = ({ onRetry }: EmptyErrorStateProps) => {
	return (
		<Card className="p-8 bg-white flex flex-col items-center justify-center text-center">
			<AlertCircle className="h-12 w-12 text-red-500 mb-4" />
			<h3 className="text-lg font-medium">Failed to load data</h3>
			<p className="text-gray-500 mt-2 mb-4 max-w-md">
				We couldn't load your lead data. This could be due to a network issue or
				server problem.
			</p>
			<Button
				variant="outline"
				onClick={onRetry}
				className="flex items-center gap-2"
			>
				<RefreshCw className="h-4 w-4" />
				Try Again
			</Button>
		</Card>
	);
};
