import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { AuthContext } from "components/lib";
import { useContext } from "react";
import type { AuthContextType } from "types/authContext";
import { appConfig } from "views/content-creation/config";
import type { PostReactionsResponse } from "../types";

const getDbColumnName = (columnId: string): string => {
	const columnMap: Record<string, string> = {
		fullName: "pp.first_name",
		company: "pp.company",
		title: "pp.title",
		location: "pp.location_name",
		engagementCount: "interaction_count",
		icpScore: "l.icp_score",
		engagementScore: "l.engagement_score",
		latestInteraction: "i.latest_interaction_date",
		icebreaker: "l.icebreaker",
		interactions: "interaction_count",
	};

	return columnMap[columnId] || columnId;
};

const fetchPostReactions = async ({
	postId,
	userId,
	page = 1,
	limit = 10,
	sortBy = "icpScore",
	sortDirection = "desc",
	token,
}: {
	postId: string;
	userId: string;
	page?: number;
	limit?: number;
	sortBy?: string;
	sortDirection?: "asc" | "desc";
	token: string;
}): Promise<PostReactionsResponse> => {
	const mappedSortBy = getDbColumnName(sortBy);

	const { data } = await axios.get(
		`${appConfig.API_MAIN_URL}/api/post/${postId}/reactions`,
		{
			params: {
				userId,
				page,
				limit,
				sortBy: mappedSortBy,
				sortDirection,
			},
			headers: {
				Authorization: `Bearer ${token}`,
			},
		},
	);
	return data;
};

export const usePostReactions = (
	postId: string | null,
	page = 1,
	limit = 10,
	sortBy = "icpScore",
	sortDirection: "asc" | "desc" = "desc",
	selectedUserId?: string,
) => {
	const auth = useContext<AuthContextType>(AuthContext);

	return useQuery({
		queryKey: [
			"postReactions",
			postId,
			page,
			limit,
			sortBy,
			sortDirection,
			selectedUserId,
		],
		queryFn: async () => {
			if (!postId || !auth.user?.token || !auth.user?.id) return null;
			return fetchPostReactions({
				postId,
				userId: selectedUserId || auth.user.id,
				page,
				limit,
				sortBy,
				sortDirection,
				token: auth.user.token,
			});
		},
		enabled: !!postId && !!auth.user?.token && !!auth.user?.id,
		staleTime: 0,
	});
};
