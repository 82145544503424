import { SkeletonPieChart } from "../skeleton-pie-chart";
import { LinkedinMetricsPieCharts } from "./linkedin-metrics-pie-charts";
import { MetricPieChart } from "./metric-pie-chart";
import type { MetricPieChartsProps } from "./types";

export const MetricsPieCharts = ({
	metrics,
	loading,
	platform,
}: MetricPieChartsProps) => {
	if (loading || (!loading && !metrics)) {
		return (
			<div className="flex items-center justify-between w-full">
				<SkeletonPieChart />
				<SkeletonPieChart />
				<SkeletonPieChart />
				<SkeletonPieChart />
				{platform === "linkedin" && <SkeletonPieChart />}
			</div>
		);
	}

	console.log("metrics prop is", metrics);

	if (platform === "linkedin") {
		return <LinkedinMetricsPieCharts metrics={metrics} />;
	}

	return (
		<div className="flex items-center justify-between w-full">
			<MetricPieChart
				metrics={metrics.collaboratorMetrics}
				platform="wordpress"
				metricKey="blogs"
				title="Blogs"
				description="Distribution of blogs among contributors"
				key="pie-blogs"
			/>
			<MetricPieChart
				metrics={metrics.collaboratorMetrics}
				platform="wordpress"
				metricKey="impressions"
				title="Impressions"
				description="Distribution of impressions among contributors"
				key="pie-impressions-blog"
			/>
			<MetricPieChart
				metrics={metrics.collaboratorMetrics}
				platform="wordpress"
				metricKey="clicks"
				title="Clicks"
				description="Distribution of clicks among contributors"
				key="pie-clicks"
			/>
			<MetricPieChart
				metrics={metrics.collaboratorMetrics}
				platform="wordpress"
				metricKey="ctr"
				title="CTR"
				description="Distribution of CTR among contributors"
				key="pie-ctr"
			/>
		</div>
	);
};
