import { useQueryClient } from "@tanstack/react-query";
import { updateICP } from "api/organization-icp";
import { useAxiosMutation } from "hooks/useAxiosMutation";
import type { UpdateOrganizationICPPayload } from "types/organization-icp";

export const useUpdateICP = () => {
	const queryClient = useQueryClient();

	const mutation = useAxiosMutation({
		mutationFn: (params: { id: number; data: UpdateOrganizationICPPayload }) =>
			updateICP(params.id, params.data),
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ["icps"],
			});
		},
	});

	return {
		updateICP: (id: number, data: UpdateOrganizationICPPayload) =>
			mutation.mutateAsync({ id, data }),
		isUpdating: mutation.isPending,
		error: mutation.error,
	};
};
