import { Button } from "components/ui/button";
import { FormLabel } from "components/ui/form";
import { Input } from "components/ui/input";
import { PlusIcon, Trash2 } from "lucide-react";
import type { UseFormReturn } from "react-hook-form";
import type { AutomationFormValues } from "../schemas";

interface ExclusionsStepProps {
	form: UseFormReturn<AutomationFormValues>;
	onNext: () => void;
	onPrevious: () => void;
	t: (key: string) => string;
}

export function ExclusionsStep({
	form,
	onNext,
	onPrevious,
	t,
}: ExclusionsStepProps) {
	const exclusions = form.watch("exclusions");

	const addExclusion = () => {
		form.setValue("exclusions", [...exclusions, ""]);
	};

	const updateExclusion = (index: number, newName: string) => {
		const updatedExclusions = [...exclusions];
		updatedExclusions[index] = newName;
		form.setValue("exclusions", updatedExclusions);
	};

	const removeExclusion = (index: number) => {
		if (exclusions.length === 1 && exclusions[0] === "") {
			return;
		}

		const updatedExclusions = exclusions.filter((_, i) => i !== index);

		if (updatedExclusions.length === 0) {
			updatedExclusions.push("");
		}

		form.setValue("exclusions", updatedExclusions);
	};

	return (
		<div className="space-y-6">
			<div>
				<FormLabel>{t("automations.exclusions.label")}</FormLabel>
				<p className="text-sm text-muted-foreground mb-4">
					{t("automations.exclusions.description")}
				</p>

				<div className="flex flex-col gap-2 w-full">
					{exclusions.map((exclusion, index) => (
						<div
							key={`exclusion-${index + 1}`}
							className="flex items-center w-full gap-2 mb-2"
						>
							<div className="flex-1 min-w-0">
								<Input
									value={exclusion}
									onChange={(e) => updateExclusion(index, e.target.value)}
									placeholder={t("automations.exclusions.placeholder")}
									className="w-full"
								/>
							</div>
							<Button
								variant="outline"
								size="icon"
								type="button"
								onClick={() => removeExclusion(index)}
								disabled={exclusions.length === 1 && exclusion === ""}
								className="flex-shrink-0 text-red-500"
							>
								<Trash2 className="h-4 w-4" />
							</Button>
						</div>
					))}

					<Button
						variant="outline"
						type="button"
						className="w-full mt-2"
						onClick={addExclusion}
					>
						<PlusIcon className="h-4 w-4 mr-2" />
						{t("automations.exclusions.add")}
					</Button>
				</div>
			</div>

			<div className="flex justify-between">
				<Button type="button" variant="outline" onClick={onPrevious}>
					{t("automations.create.buttons.previous")}
				</Button>
				<Button type="button" onClick={onNext}>
					{t("automations.create.buttons.next")}
				</Button>
			</div>
		</div>
	);
}
