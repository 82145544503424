import {
	Collapsible,
	CollapsibleContent,
	CollapsibleTrigger,
} from "components/ui/collapsible";
import {
	SidebarGroup,
	SidebarGroupContent,
	SidebarGroupLabel,
	SidebarMenu,
	SidebarMenuButton,
	SidebarMenuItem,
	SidebarMenuSub,
	SidebarMenuSubButton,
	SidebarMenuSubItem,
} from "components/ui/sidebar";
import { ChevronRight } from "lucide-react";
import { useNavigate } from "react-router-dom";
import type { NavItem } from "./app-sidebar-types";

interface IcpNavigationProps {
	items: NavItem[];
}

export function IcpNavigation({ items }: IcpNavigationProps) {
	const navigate = useNavigate();

	return (
		<SidebarGroup>
			<SidebarGroupLabel>ICP</SidebarGroupLabel>
			<SidebarGroupContent>
				<SidebarMenu>
					{items.map((item) =>
						item.children ? (
							<Collapsible
								key={item.label}
								asChild
								className="group/collapsible"
							>
								<SidebarMenuItem>
									<CollapsibleTrigger asChild>
										<SidebarMenuButton tooltip={item.label}>
											{item.icon}
											<span>{item.label}</span>
											<ChevronRight className="ml-auto size-4 transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90" />
										</SidebarMenuButton>
									</CollapsibleTrigger>
									<CollapsibleContent>
										<SidebarMenuSub>
											{item.children.map((child) => (
												<SidebarMenuSubItem key={child.label}>
													<SidebarMenuSubButton
														className="cursor-pointer"
														onClick={() => navigate(child.link)}
													>
														<span>{child.label}</span>
													</SidebarMenuSubButton>
												</SidebarMenuSubItem>
											))}
										</SidebarMenuSub>
									</CollapsibleContent>
								</SidebarMenuItem>
							</Collapsible>
						) : (
							<SidebarMenuItem key={item.label}>
								<SidebarMenuButton
									tooltip={item.label}
									onClick={() => item.link && navigate(item.link)}
								>
									{item.icon}
									<span>{item.label}</span>
								</SidebarMenuButton>
							</SidebarMenuItem>
						),
					)}
				</SidebarMenu>
			</SidebarGroupContent>
		</SidebarGroup>
	);
}
