import { useToast } from "components/hooks/use-toast";
import { TablePagination } from "components/table-pagination";
import { Avatar, AvatarFallback, AvatarImage } from "components/ui/avatar";
import { Badge } from "components/ui/badge";
import { Button } from "components/ui/button";
import { Checkbox } from "components/ui/checkbox";
import {
	Dialog,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "components/ui/dialog";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import { Progress } from "components/ui/progress";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "components/ui/select";
import { Skeleton } from "components/ui/skeleton";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "components/ui/table";
import { Textarea } from "components/ui/textarea";
import { useUnipileAuth } from "contexts/unipile-auth.context";
import {
	Info,
	Loader2,
	MapPin,
	MoreHorizontal,
	Send,
	User,
} from "lucide-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import type { AudienceMatch } from "types/lead";
import { useLeadLensEvents } from "views/lead-lens/events";
import { LinkedInConnectDialog } from "../linkedin-connect-dialog";
import { LeadTableEmptyState } from "./lead-table-empty-state";
import { LeadTableFilters } from "./lead-table-filters";
import {
	type EngagementFilter,
	type MatchFilter,
	getEngagementLevel,
	getMatchCategory,
	getMatchCategoryColor,
	getMatchColor,
} from "./lead-table-helpers";
import { getEngagementHeatBar } from "./lead-visualization-components";

interface SortConfig {
	column: string;
	direction: "asc" | "desc";
}

interface LeadsTableV2Props {
	leads: AudienceMatch[];
	onLeadClick: (lead: AudienceMatch) => void;
	onGenerateIcebreaker: (linkedinId: string) => Promise<void>;
	onSendMessage: (linkedinId: string, message: string) => Promise<void>;
	syncingProfiles: Set<string>;
	currentPage: number;
	totalPages: number;
	onPageChange: (page: number) => void;
	pageSize: number;
	onPageSizeChange: (size: number) => void;
	totalItems: number;
	sortBy: string;
	sortDirection: "asc" | "desc";
	onSortChange: (column: string, direction: "asc" | "desc") => void;
	audienceId: number | undefined;
	engagementFilter: EngagementFilter;
	matchFilter: MatchFilter;
	onFilterChange: (
		filterType: string,
		value: EngagementFilter | MatchFilter | "all" | number,
	) => void;
	disableSendMessage?: boolean;
	isLoading?: boolean;
	error?: Error | null;
	onRetry?: () => void;
}

// Define default sort configuration

export const LeadTableV2 = React.memo(function LeadTableV2({
	leads,
	onLeadClick,
	onGenerateIcebreaker,
	onSendMessage,
	syncingProfiles,
	currentPage,
	totalPages,
	onPageChange,
	pageSize,
	onPageSizeChange,
	totalItems,
	sortBy,
	sortDirection,
	onSortChange,
	audienceId,
	engagementFilter,
	matchFilter,
	onFilterChange,
	disableSendMessage,
	isLoading = false,
	error = null,
	onRetry,
}: LeadsTableV2Props) {
	const events = useLeadLensEvents();
	const unipileAuth = useUnipileAuth();
	const { toast } = useToast();

	// State management
	const [selectedLeads, setSelectedLeads] = useState<string[]>([]);
	const [selectedLead, setSelectedLead] = useState<AudienceMatch | null>(null);
	const [messageModalOpen, setMessageModalOpen] = useState(false);
	const [message, setMessage] = useState("");
	const [sending, setSending] = useState(false);
	const [showLinkedInConnect, setShowLinkedInConnect] = useState(false);
	const [filteredLeads, setFilteredLeads] = useState<AudienceMatch[]>(
		Array.isArray(leads) ? leads : [],
	);

	const [leadProfileModalOpen, setLeadProfileModalOpen] = useState(false);
	const navigate = useNavigate();

	// Keep filteredLeads in sync with leads prop
	useEffect(() => {
		if (Array.isArray(leads)) {
			setFilteredLeads(leads);
		}
	}, [leads]);

	// Handle column header click for sorting
	const handleSortChange = useCallback(
		(column: string, direction: "asc" | "desc") => {
			onSortChange(column, direction);
		},
		[onSortChange],
	);

	// Selection functions
	const onSelectAll = useCallback(
		(checked: boolean) => {
			if (checked && Array.isArray(filteredLeads)) {
				setSelectedLeads(filteredLeads.map((lead) => lead.id.toString()));
			} else {
				setSelectedLeads([]);
			}
		},
		[filteredLeads],
	);

	const onSelectLead = useCallback((id: string, checked: boolean) => {
		if (checked) {
			setSelectedLeads((prev) => [...prev, id]);
		} else {
			setSelectedLeads((prev) => prev.filter((leadId) => leadId !== id));
		}
	}, []);

	// Message handling functions
	const handleSendMessageClick = useCallback(
		(linkedinId: string) => {
			if (!Array.isArray(filteredLeads)) return;

			const lead = filteredLeads.find(
				(lead) => lead.profile.linkedinId === linkedinId,
			);
			if (!lead) return;

			setSelectedLead(lead);
			setMessageModalOpen(true);

			events.trackMessageModalOpen({
				linkedin_id: linkedinId,
				first_name: lead.profile.firstName,
				last_name: lead.profile.lastName,
				company: lead.profile.company || "",
				title: lead.profile.title || "",
				message_length: 0,
				has_icebreaker: false,
			});
		},
		[filteredLeads, events],
	);

	const handleSend = useCallback(async () => {
		if (!selectedLead || !message.trim()) {
			return;
		}

		setSending(true);
		events.trackMessageAttempt({
			linkedin_id: selectedLead.profile.linkedinId,
			first_name: selectedLead.profile.firstName,
			last_name: selectedLead.profile.lastName,
			company: selectedLead.profile.company || "",
			title: selectedLead.profile.title || "",
			message_length: message.length,
			has_icebreaker: false,
		});

		try {
			await onSendMessage(selectedLead.profile.linkedinId, message);

			events.trackMessageSent({
				linkedin_id: selectedLead.profile.linkedinId,
				first_name: selectedLead.profile.firstName,
				last_name: selectedLead.profile.lastName,
				company: selectedLead.profile.company || "",
				title: selectedLead.profile.title || "",
				message_length: message.length,
				has_icebreaker: false,
			});

			toast({
				title: "Mensaje enviado",
				description: `Mensaje enviado exitosamente a ${selectedLead.profile.firstName} ${selectedLead.profile.lastName}`,
			});

			handleModalClose(true);
		} catch (error) {
			events.trackMessageFailed({
				linkedin_id: selectedLead.profile.linkedinId,
				first_name: selectedLead.profile.firstName,
				last_name: selectedLead.profile.lastName,
				company: selectedLead.profile.company || "",
				title: selectedLead.profile.title || "",
				message_length: message.length,
				has_icebreaker: false,
				error: error instanceof Error ? error.message : "Error desconocido",
			});
		} finally {
			setSending(false);
		}
	}, [selectedLead, message, events, onSendMessage, toast]);

	const handleModalClose = useCallback(
		(open: boolean) => {
			if (!open) {
				if (selectedLead) {
					events.trackMessageModalClose({
						linkedin_id: selectedLead.profile.linkedinId,
						first_name: selectedLead.profile.firstName,
						last_name: selectedLead.profile.lastName,
						company: selectedLead.profile.company || "",
						title: selectedLead.profile.title || "",
						message_length: message.length,
						has_icebreaker: false,
					});
				}
				setMessageModalOpen(false);
				setMessage("");
				setSelectedLead(null);
			}
		},
		[selectedLead, message, events],
	);

	const closeModal = useCallback(() => {
		handleModalClose(false);
	}, [handleModalClose]);

	// Handle the click on a lead row
	const handleLeadClick = useCallback(
		(match: AudienceMatch) => {
			setSelectedLead(match);
			setLeadProfileModalOpen(true);
			onLeadClick(match);
		},
		[onLeadClick],
	);

	// Callback from filter component
	const handleLeadsFiltered = useCallback((filtered: AudienceMatch[]) => {
		setFilteredLeads(Array.isArray(filtered) ? filtered : []);
	}, []);

	return (
		<div className="space-y-4">
			{/* Filters */}
			<LeadTableFilters
				leads={Array.isArray(leads) ? leads : []}
				audienceId={audienceId}
				engagementFilter={engagementFilter}
				matchFilter={matchFilter}
				onFilterChange={onFilterChange}
				onLeadsFiltered={handleLeadsFiltered}
			/>

			{/* Table */}
			<Table>
				<TableHeader>
					<TableRow>
						<TableHead className="w-[50px]">
							<Checkbox
								checked={
									Array.isArray(filteredLeads) &&
									selectedLeads.length === filteredLeads.length &&
									filteredLeads.length > 0
								}
								onCheckedChange={(checked) => onSelectAll(checked as boolean)}
							/>
						</TableHead>
						<TableHead
							className="font-semibold cursor-pointer"
							onClick={() =>
								handleSortChange(
									"createdAt",
									sortDirection === "asc" ? "desc" : "asc",
								)
							}
						>
							<div className="flex items-center justify-between ml-2">
								<span>FECHA</span>
								<span className="w-4 text-right">
									{sortBy === "createdAt"
										? sortDirection === "asc"
											? "↑"
											: "↓"
										: ""}
								</span>
							</div>
						</TableHead>
						<TableHead
							className="font-semibold cursor-pointer"
							onClick={() =>
								handleSortChange(
									"profile.firstName",
									sortDirection === "asc" ? "desc" : "asc",
								)
							}
						>
							<div className="flex items-center justify-between">
								<span>NOMBRE</span>
								<span className="w-4 text-right">
									{sortBy === "profile.firstName"
										? sortDirection === "asc"
											? "↑"
											: "↓"
										: ""}
								</span>
							</div>
						</TableHead>
						<TableHead
							className="font-semibold cursor-pointer"
							onClick={() =>
								handleSortChange(
									"profile.title",
									sortDirection === "asc" ? "desc" : "asc",
								)
							}
						>
							<div className="flex items-center justify-between">
								<span>CARGO</span>
								<span className="w-4 text-right">
									{sortBy === "profile.title"
										? sortDirection === "asc"
											? "↑"
											: "↓"
										: ""}
								</span>
							</div>
						</TableHead>
						<TableHead
							className="font-semibold cursor-pointer"
							onClick={() =>
								handleSortChange(
									"profile.company",
									sortDirection === "asc" ? "desc" : "asc",
								)
							}
						>
							<div className="flex items-center justify-between">
								<span>EMPRESA</span>
								<span className="w-4 text-right">
									{sortBy === "profile.company"
										? sortDirection === "asc"
											? "↑"
											: "↓"
										: ""}
								</span>
							</div>
						</TableHead>
						<TableHead
							className="font-semibold cursor-pointer"
							onClick={() =>
								handleSortChange(
									"profile.locationName",
									sortDirection === "asc" ? "desc" : "asc",
								)
							}
						>
							<div className="flex items-center justify-between">
								<span>UBICACIÓN</span>
								<span className="w-4 text-right">
									{sortBy === "profile.locationName"
										? sortDirection === "asc"
											? "↑"
											: "↓"
										: ""}
								</span>
							</div>
						</TableHead>
						<TableHead
							className="font-semibold cursor-pointer"
							onClick={() =>
								handleSortChange(
									"audience.name",
									sortDirection === "asc" ? "desc" : "asc",
								)
							}
						>
							<div className="flex items-center justify-between">
								<span>AUDIENCIA</span>
								<span className="w-4 text-right">
									{sortBy === "audience.name"
										? sortDirection === "asc"
											? "↑"
											: "↓"
										: ""}
								</span>
							</div>
						</TableHead>
						<TableHead
							className="font-semibold cursor-pointer"
							onClick={() =>
								handleSortChange(
									"score",
									sortDirection === "asc" ? "desc" : "asc",
								)
							}
						>
							<div className="flex items-center justify-between">
								<span>COINCIDENCIA AUDIENCIA IDEAL</span>
								<span className="w-4 text-right">
									{sortBy === "score"
										? sortDirection === "asc"
											? "↑"
											: "↓"
										: ""}
								</span>
							</div>
						</TableHead>
						<TableHead
							className="font-semibold cursor-pointer min-w-[120px]"
							onClick={() =>
								handleSortChange(
									"interactions.count",
									sortDirection === "asc" ? "desc" : "asc",
								)
							}
						>
							<div className="flex items-center justify-between">
								<span>ENGAGEMENT</span>
								<span className="w-4 text-right">
									{sortBy === "interactions.count"
										? sortDirection === "asc"
											? "↑"
											: "↓"
										: ""}
								</span>
							</div>
						</TableHead>
						<TableHead className="font-semibold">ACCIONES</TableHead>
					</TableRow>
				</TableHeader>
				<TableBody>
					{isLoading ? (
						// Loading state skeletons
						<>
							{Array(5)
								.fill(null)
								.map((_, i) => {
									const skeletonId = `skeleton-${Date.now()}-${i}`;
									return (
										<TableRow key={skeletonId} className="h-16">
											<TableCell className="w-[50px]">
												<Skeleton className="h-4 w-4" />
											</TableCell>
											<TableCell className="min-w-[200px]">
												<div className="flex items-center gap-2">
													<Skeleton className="h-10 w-10 rounded-full flex-shrink-0" />
													<Skeleton className="h-4 w-32" />
												</div>
											</TableCell>
											<TableCell className="min-w-[180px]">
												<Skeleton className="h-4 w-40" />
											</TableCell>
											<TableCell className="min-w-[180px]">
												<Skeleton className="h-4 w-40" />
											</TableCell>
											<TableCell className="min-w-[150px]">
												<Skeleton className="h-4 w-28" />
											</TableCell>
											<TableCell className="min-w-[150px]">
												<Skeleton className="h-4 w-28" />
											</TableCell>
											<TableCell className="min-w-[130px]">
												<Skeleton className="h-4 w-[85px]" />
											</TableCell>
											<TableCell className="min-w-[120px]">
												<Skeleton className="h-6 w-24 rounded-full" />
											</TableCell>
											<TableCell className="min-w-[120px]">
												<Skeleton className="h-4 w-28" />
											</TableCell>
											<TableCell className="w-[70px]">
												<Skeleton className="h-8 w-8 rounded-full" />
											</TableCell>
										</TableRow>
									);
								})}
						</>
					) : error ? (
						// Error state
						<TableRow>
							<TableCell colSpan={9} className="h-24 text-center">
								<div className="flex flex-col items-center justify-center gap-2">
									<p className="text-sm text-muted-foreground">
										Error al cargar los leads
									</p>
									{onRetry && (
										<Button variant="outline" size="sm" onClick={onRetry}>
											Reintentar
										</Button>
									)}
								</div>
							</TableCell>
						</TableRow>
					) : filteredLeads.length === 0 ? (
						// Empty state
						<TableRow>
							<TableCell colSpan={9} className="h-[400px]">
								<div className="h-full w-full flex items-center justify-center">
									<LeadTableEmptyState
										error={error}
										isEmpty={true}
										errorMessage="Ha ocurrido un error al cargar los leads:"
										emptyMessage="No se encontraron leads que coincidan con los criterios de búsqueda actuales."
										onRetry={onRetry}
									/>
								</div>
							</TableCell>
						</TableRow>
					) : (
						// Normal state
						filteredLeads.map((lead) => (
							<TableRow
								key={lead.id}
								className="hover:bg-muted/50 cursor-pointer h-16"
								onClick={() => handleLeadClick(lead)}
							>
								<TableCell
									className="w-[50px]"
									onClick={(e) => e.stopPropagation()}
								>
									<Checkbox
										checked={selectedLeads.includes(lead.id.toString())}
										onCheckedChange={(checked) =>
											onSelectLead(lead.id.toString(), checked as boolean)
										}
									/>
								</TableCell>
								<TableCell className="font-medium min-w-[100px]">
									<span className="ml-2">
										{new Date(lead.createdAt).toLocaleDateString()}
									</span>
								</TableCell>
								<TableCell className="font-medium min-w-[200px]">
									<div className="flex items-center gap-2">
										<Avatar className="h-10 w-10 flex-shrink-0">
											<AvatarImage
												src={lead.profile.profileImageUrl || ""}
												alt={`${lead.profile.firstName} ${lead.profile.lastName}`}
											/>
											<AvatarFallback>
												{lead.profile.firstName
													? lead.profile.firstName.charAt(0)
													: "?"}
											</AvatarFallback>
										</Avatar>
										<a
											href={`https://www.linkedin.com/in/${lead.profile.linkedinId}`}
											target="_blank"
											rel="noopener noreferrer"
											className="hover:underline truncate"
											onClick={(e) => e.stopPropagation()}
										>
											{`${lead.profile.firstName} ${lead.profile.lastName}`}
										</a>
									</div>
								</TableCell>
								<TableCell className="min-w-[180px] max-w-[220px]">
									<div className="truncate">{lead.profile.title || ""}</div>
								</TableCell>
								<TableCell className="min-w-[150px] max-w-[180px]">
									<div className="truncate">{lead.profile.company || ""}</div>
								</TableCell>
								<TableCell className="min-w-[150px] max-w-[180px]">
									<div className="truncate">
										{lead.profile.locationName || ""}
									</div>
								</TableCell>
								<TableCell className="min-w-[120px] max-w-[150px]">
									<div className="truncate text-sm text-muted-foreground">
										{lead.audience?.name || "Sin audiencia"}
									</div>
								</TableCell>

								<TableCell className="min-w-[130px]">
									<div
										className={`inline-flex items-center gap-1 px-3 py-1 rounded-full text-sm font-medium border-2 border-${getMatchCategory(lead.score || 0) === "Muy alta" ? "green" : getMatchCategory(lead.score || 0) === "Alta" ? "green" : getMatchCategory(lead.score || 0) === "Media" ? "yellow" : getMatchCategory(lead.score || 0) === "Baja" ? "red" : "red"}-600
                      ${
												getMatchCategory(lead.score || 0) === "Muy alta"
													? "bg-green-100 text-green-600"
													: ""
											}
                      ${
												getMatchCategory(lead.score || 0) === "Alta"
													? "bg-green-50 text-green-600"
													: ""
											}
                      ${
												getMatchCategory(lead.score || 0) === "Media"
													? "bg-yellow-100 text-yellow-600"
													: ""
											}
                      ${
												getMatchCategory(lead.score || 0) === "Baja"
													? "bg-red-100 text-red-600"
													: ""
											}
                      ${
												getMatchCategory(lead.score || 0) === "Muy baja"
													? "bg-red-50 text-red-600"
													: ""
											}
                    `}
									>
										{getMatchCategory(lead.score || 0)} ({lead.score})
									</div>
								</TableCell>
								<TableCell className="min-w-[120px]">
									<div className="flex items-center space-x-2">
										<span className="text-sm font-medium">
											{getEngagementHeatBar(lead.interactions.count || 0)}
										</span>
									</div>
								</TableCell>
								<TableCell
									className="actions-cell w-[70px]"
									onClick={(e) => e.stopPropagation()}
								>
									<DropdownMenu modal={false}>
										<DropdownMenuTrigger asChild>
											<Button variant="ghost" className="h-8 w-8 p-0">
												<span className="sr-only">Abrir menú</span>
												<MoreHorizontal className="h-4 w-4" />
											</Button>
										</DropdownMenuTrigger>
										<DropdownMenuContent align="end">
											<DropdownMenuLabel>Acciones</DropdownMenuLabel>
											<DropdownMenuItem
												onClick={() =>
													handleSendMessageClick(lead.profile.linkedinId)
												}
											>
												<Send className="mr-2 h-4 w-4" />
												<span>Enviar mensaje</span>
											</DropdownMenuItem>
											<DropdownMenuSeparator />
											<DropdownMenuItem onClick={() => handleLeadClick(lead)}>
												Ver perfil
											</DropdownMenuItem>
										</DropdownMenuContent>
									</DropdownMenu>
								</TableCell>
							</TableRow>
						))
					)}
				</TableBody>
			</Table>

			{/* Pagination */}
			<TablePagination
				currentPage={currentPage}
				totalPages={totalPages}
				onPageChange={onPageChange}
				pageSize={pageSize}
				onPageSizeChange={(size) => {
					onPageSizeChange(size);
					onPageChange(1); // Reset to first page when changing page size
				}}
				totalItems={totalItems}
			/>

			{/* SEND MESSAGE MODAL */}
			<Dialog open={messageModalOpen} onOpenChange={handleModalClose}>
				<DialogContent>
					<DialogHeader>
						<DialogTitle>
							{!unipileAuth.isAuthenticated
								? "Conexión de LinkedIn Requerida"
								: `Enviar Mensaje a ${selectedLead?.profile.firstName} ${selectedLead?.profile.lastName}`}
						</DialogTitle>
					</DialogHeader>
					{!unipileAuth.isAuthenticated ? (
						<div className="flex flex-col items-center justify-center py-6 text-center">
							<p className="text-muted-foreground mb-6">
								Para enviar mensajes a tus leads, necesitas conectar tu cuenta
								de LinkedIn primero.
							</p>
							{unipileAuth.error && (
								<p className="text-red-500 mb-4">{unipileAuth.error}</p>
							)}
							<Button
								onClick={() => setShowLinkedInConnect(true)}
								disabled={unipileAuth.isLoading}
							>
								{unipileAuth.isLoading ? "Conectando..." : "Conectar LinkedIn"}
							</Button>
						</div>
					) : (
						<>
							<div className="space-y-4 py-4">
								<Textarea
									value={message}
									onChange={(e) => setMessage(e.target.value)}
									placeholder={`Hola ${selectedLead?.profile.firstName}! Gracias por tu interacción en el último post. ¿Qué tal todo en ${selectedLead?.profile.company}?`}
									className="min-h-[200px]"
								/>
							</div>
							<DialogFooter>
								<Button variant="outline" onClick={closeModal}>
									Cancelar
								</Button>
								<Button
									onClick={handleSend}
									disabled={sending || !message.trim() || disableSendMessage}
								>
									{sending ? "Enviando..." : "Enviar"}
								</Button>
							</DialogFooter>
						</>
					)}
				</DialogContent>
			</Dialog>

			{/* LEAD PROFILE MODAL */}
			<Dialog
				open={leadProfileModalOpen}
				onOpenChange={setLeadProfileModalOpen}
			>
				{/**
				 * Make the dialog content large, with padding and scroll:
				 * max-width ~ 90vw (up to ~1200px), max-height 90vh, overflow-y-auto
				 */}
				<DialogContent className="max-w-[90vw] sm:max-w-[1200px] max-h-[90vh] overflow-y-auto p-6">
					<DialogHeader>
						<DialogTitle>Perfil del Lead</DialogTitle>
					</DialogHeader>

					{selectedLead && (
						<div className="space-y-6">
							{/* Header: Avatar + Basic Info + Scores */}
							<div className="flex flex-col md:flex-row items-start md:items-center justify-between gap-4 pb-3 border-b">
								{/* Left side: avatar, name, title, company */}
								<div className="flex items-center gap-4">
									<Avatar className="h-16 w-16">
										<AvatarImage
											src={selectedLead.profile.profileImageUrl || ""}
											alt={`${selectedLead.profile.firstName} ${selectedLead.profile.lastName}`}
										/>
										<AvatarFallback className="text-lg">
											{selectedLead.profile.firstName?.charAt(0) || <User />}
										</AvatarFallback>
									</Avatar>
									<div>
										<h3 className="text-lg font-bold">
											{selectedLead.profile.firstName}{" "}
											{selectedLead.profile.lastName}
										</h3>
										<p className="text-sm text-muted-foreground">
											{selectedLead.profile.title}
										</p>
										<p className="text-sm text-muted-foreground">
											{selectedLead.profile.company}
										</p>
									</div>
								</div>

								{/* Right side: Person & Company match badges, Audience */}
								<div className="flex flex-col items-start gap-2">
									<div className="flex flex-wrap gap-2">
										{/* Person match */}
										<div
											className={`inline-flex items-center gap-1 px-3 py-1 rounded-full text-sm font-medium border-2 border-${getMatchCategoryColor(getMatchCategory(selectedLead.score || 0))}-600 bg-${getMatchCategoryColor(getMatchCategory(selectedLead.score || 0))}-100 text-${getMatchCategoryColor(getMatchCategory(selectedLead.score || 0))}-600`}
										>
											Coincidencia persona:{" "}
											{getMatchCategory(selectedLead.score || 0)} (
											{selectedLead.score ?? 0})
										</div>

										{/* Company match (ICP score) */}
										{typeof selectedLead.icpScore === "number" && (
											<div
												className={`inline-flex items-center gap-1 px-3 py-1 rounded-full text-sm font-medium border-2 border-${getMatchCategoryColor(getMatchCategory(selectedLead.icpScore || 0))}-600 bg-${getMatchCategoryColor(getMatchCategory(selectedLead.icpScore || 0))}-100 text-${getMatchCategoryColor(getMatchCategory(selectedLead.icpScore || 0))}-600`}
											>
												Coincidencia empresa:{" "}
												{getMatchCategory(selectedLead.icpScore || 0)} (
												{selectedLead.icpScore ?? 0})
											</div>
										)}
									</div>

									{/* Audience label */}
									{selectedLead.audience?.name && (
										<span className="text-sm text-muted-foreground px-3 py-1 rounded-full text-sm font-medium bg-blue-100 text-blue-800 border-2 border-blue-300">
											Audiencia: {selectedLead.audience.name}
										</span>
									)}
								</div>
							</div>

							{/* Location & LinkedIn link */}
							<div className="flex flex-col sm:flex-row items-start sm:items-center justify-between text-sm pb-3 border-b">
								<p className="flex items-center text-muted-foreground mb-2 sm:mb-0">
									<MapPin className="w-4 h-4 mr-2" />
									{selectedLead.profile.locationName || "Ubicación desconocida"}
								</p>
								<div className="flex items-center gap-8">
									<a
										href={`https://www.linkedin.com/in/${selectedLead.profile.linkedinId}`}
										target="_blank"
										rel="noopener noreferrer"
										className="text-blue-600 hover:underline"
									>
										Ver en LinkedIn
									</a>
									<Button
										size="sm"
										onClick={() => {
											setLeadProfileModalOpen(false);
											handleSendMessageClick(selectedLead.profile.linkedinId);
										}}
									>
										Enviar mensaje
									</Button>
								</div>
							</div>

							{/* 2-column section for person & company scoring */}
							<div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
								{/* Detalles de Coincidencia (persona) */}
								<div className="bg-slate-50 rounded-lg p-3">
									<h4 className="font-semibold text-sm mb-2 text-slate-700">
										Detalles de Coincidencia (Persona)
									</h4>
									<div className="relative">
										<div className="space-y-2 max-h-[400px] overflow-y-auto pr-1 pb-2">
											{selectedLead.scoringEvaluationResults?.length > 0 ? (
												selectedLead.scoringEvaluationResults.map(
													(result, idx) => (
														<div
															key={`detail-${result.property}-${idx}`}
															className="bg-white p-2 rounded border text-sm"
														>
															<div className="flex justify-between font-medium flex-wrap">
																<span className="mr-2">{result.property}</span>
																<span>{result.score}/10</span>
															</div>
															<p className="text-xs text-muted-foreground mt-1 whitespace-normal">
																{result.reason}
															</p>
														</div>
													),
												)
											) : (
												<p className="text-sm text-muted-foreground">
													No hay datos de coincidencia personal.
												</p>
											)}
										</div>
										<div className="h-8 bg-gradient-to-t from-slate-50 to-transparent absolute bottom-0 left-0 right-0 pointer-events-none" />
									</div>
								</div>

								{/* Detalles de Coincidencia (empresa) */}
								<div className="bg-slate-50 rounded-lg p-3">
									<h4 className="font-semibold text-sm mb-2 text-slate-700">
										Detalles de Coincidencia (Empresa)
									</h4>
									<div className="relative">
										<div className="space-y-2 max-h-[400px] overflow-y-auto pr-1 pb-2">
											{selectedLead.icpScoringEvaluationResults?.length > 0 ? (
												selectedLead.icpScoringEvaluationResults.map(
													(result, idx) => (
														<div
															key={`icp-detail-${result.property}-${idx}`}
															className="bg-white p-2 rounded border text-sm"
														>
															<div className="flex justify-between font-medium flex-wrap">
																<span className="mr-2">{result.property}</span>
																<span>{result.score}/10</span>
															</div>
															<p className="text-xs text-muted-foreground mt-1 whitespace-normal">
																{result.reason}
															</p>
														</div>
													),
												)
											) : (
												<div className="flex flex-col items-center justify-center">
													<p className="text-sm text-muted-foreground">
														La empresa ({selectedLead.profile.company})
														mencionada en el perfil del usuario no está
														registrada en Linkedin o La audiencia a la que
														pertenece este lead no tiene un ICP asociado. Puedes
														configurarle uno dentro de la sección de ICP
													</p>
													<Button
														className="mt-2"
														onClick={() => {
															navigate("/icp/audiences");
														}}
													>
														Configuración de Audiencia
													</Button>
												</div>
											)}
										</div>
										<div className="h-8 bg-gradient-to-t from-slate-50 to-transparent absolute bottom-0 left-0 right-0 pointer-events-none" />
									</div>
								</div>
							</div>

							{/* Engagement / Interactions section */}
							<div className="bg-slate-50 rounded-lg p-3">
								<h4 className="font-semibold text-sm mb-2 text-slate-700">
									Nivel de Engagement
								</h4>
								<div className="bg-white p-2 rounded border mb-4">
									<div className="flex items-center justify-between mb-1">
										<span className="text-sm font-medium">
											Engagement total
										</span>
										<span className="text-sm">
											{selectedLead.interactions.count || 0}
										</span>
									</div>
									<div className="h-2 w-full bg-slate-100 rounded overflow-hidden">
										<div
											className="h-full bg-blue-400"
											style={{
												width: `${Math.min(
													100,
													((selectedLead.interactions.count || 0) / 10) * 100,
												)}%`,
												background:
													"linear-gradient(90deg, rgba(59,130,246,1) 0%, rgba(59,130,246,0.6) 100%)",
											}}
										/>
									</div>
								</div>

								<div className="bg-white p-2 rounded border">
									<p className="text-xs font-medium mb-1">Interacciones:</p>
									{selectedLead.interactions.hasLiked ||
									selectedLead.interactions.hasCommented ||
									selectedLead.interactions.hasShared ? (
										<div className="space-y-2 max-h-[350px] overflow-y-auto">
											{selectedLead.interactions.posts &&
											selectedLead.interactions.posts.length > 0 ? (
												selectedLead.interactions.posts.map((post, idx) => (
													<div
														key={`interaction-${selectedLead.id}-${post.id || idx}`}
														className="text-xs border-l-2 border-blue-400 pl-2 py-1"
													>
														<div className="font-medium">
															{post.type === "like" &&
																"Le dio 'Me gusta' a tu post"}
															{post.type === "comment" && "Comentó tu post"}
															{post.type === "share" && "Compartió tu post"}
														</div>
														<div className="text-muted-foreground mt-1">
															<span className="block line-clamp-2">
																{post.content || "Publicación sin contenido"}
															</span>
															<span className="text-[10px] text-slate-400 mt-1 block">
																{post.interactionDate &&
																	new Date(
																		post.interactionDate,
																	).toLocaleDateString()}
															</span>
														</div>
													</div>
												))
											) : (
												<>
													{/* Fallback if posts array isn't detailed, but hasLiked, etc. */}
													{selectedLead.interactions.hasLiked && (
														<div className="text-xs border-l-2 border-blue-400 pl-2 py-1 mb-2">
															<div className="font-medium">
																Me gusta en tus publicaciones
															</div>
															<div className="text-muted-foreground mt-1">
																<span className="block">
																	{selectedLead.profile.firstName} ha
																	interactuado con tus posts
																</span>
															</div>
														</div>
													)}
													{selectedLead.interactions.hasCommented && (
														<div className="text-xs border-l-2 border-green-400 pl-2 py-1 mb-2">
															<div className="font-medium">
																Comentarios en publicaciones
															</div>
															<div className="text-muted-foreground mt-1">
																<span className="block">
																	{selectedLead.profile.firstName} ha comentado
																	en tus publicaciones
																</span>
															</div>
														</div>
													)}
													{selectedLead.interactions.hasShared && (
														<div className="text-xs border-l-2 border-orange-400 pl-2 py-1">
															<div className="font-medium">
																Publicaciones compartidas
															</div>
															<div className="text-muted-foreground mt-1">
																<span className="block">
																	{selectedLead.profile.firstName} ha compartido
																	tus publicaciones
																</span>
															</div>
														</div>
													)}
												</>
											)}
										</div>
									) : (
										<p className="text-xs text-muted-foreground">
											No hay interacciones registradas para este lead.
										</p>
									)}
								</div>
							</div>

							{/* Footer buttons */}
							<DialogFooter className="gap-2 pt-2 pr-2">
								<Button
									variant="outline"
									size="sm"
									onClick={() => setLeadProfileModalOpen(false)}
								>
									Cerrar
								</Button>
							</DialogFooter>
						</div>
					)}
				</DialogContent>
			</Dialog>
			<LinkedInConnectDialog
				isOpen={showLinkedInConnect}
				onClose={() => setShowLinkedInConnect(false)}
			/>
		</div>
	);
});
