import axios from "axios";
import type { ApiResponse } from "types/api";
import type {
	Automation,
	AutomationMessage,
	CreateAutomationPayload,
} from "types/automations";
interface MessageApiResponse {
	id: number;
	status: string;
	scheduled_for: string;
	sent_at?: string;
	automation_run_id: number;
	receiverId: number;
	receiver: string;
	text: string;
	error?: string;
	receiverFirstName: string;
	receiverLastName: string;
	profilePictureUrl: string;
}

export const createAutomation = async (
	payload: CreateAutomationPayload,
): Promise<Automation> => {
	const { data } = await axios.post("/api/automations/messages", payload);
	return data;
};

export const getOrganizationAutomations = async (
	organizationId: string,
): Promise<ApiResponse<Automation[]>> => {
	const { data } = await axios.get(
		`/api/automations?organizationId=${organizationId}`,
	);
	return {
		data: data.data,
		error: data.message,
	};
};

export const deleteAutomation = async (
	automationId: number,
): Promise<ApiResponse<void>> => {
	const response = await axios.delete(`/api/automations/${automationId}`);
	if (response.status !== 204) {
		return {
			data: undefined,
			error: response.data.message,
		};
	}

	return { data: undefined };
};

export const getAutomation = async (
	automationId: number,
): Promise<ApiResponse<Automation>> => {
	const { data } = await axios.get(`/api/automations/${automationId}`);
	return {
		data: data.data,
		error: data.message,
	};
};

export const getAutomationMessages = async (
	automationId: number,
): Promise<ApiResponse<AutomationMessage[]>> => {
	const { data } = await axios.get(`/api/automations/${automationId}/messages`);
	return {
		error: data.message,
		data: data.data.map((message: MessageApiResponse) => {
			return {
				id: message.id,
				status: message.status,
				scheduledFor: message.scheduled_for,
				sentAt: message.sent_at ?? undefined,
				automationRunId: message.automation_run_id,
				receiverId: message.receiverId,
				receiver: message.receiver,
				text: message.text,
				error: message.error ?? undefined,
				receiverFirstName: message.receiverFirstName,
				receiverLastName: message.receiverLastName,
				profilePictureUrl: message.profilePictureUrl,
			};
		}),
	};
};

export const runAutomation = async (
	automationId: number,
): Promise<ApiResponse<boolean>> => {
	try {
		const response = await axios.post(`/api/automations/${automationId}/run`);
		return {
			data: response.status === 200,
			error: response.data.success ? undefined : response.data.message,
			errorCode: response.data.errorCode,
			message: response.data.message,
		};
	} catch (error) {
		if (axios.isAxiosError(error)) {
			return {
				data: false,
				error: error.response?.data?.message || error.message,
				errorCode: error.response?.data?.errorCode,
				message: error.response?.data?.message,
			};
		}
		throw error;
	}
};

export const cancelAutomationMessage = async (
	automationMessageId: number,
): Promise<ApiResponse<boolean>> => {
	const response = await axios.post(
		`/api/automations/messages/${automationMessageId}/cancel`,
	);
	return {
		data: response.status === 200,
		error: response.data.success ? undefined : response.data.message,
	};
};
