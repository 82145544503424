import { LeadsByAudiencePieChart } from "./leads-by-audience-pie-chart";
import { MetricPieChart } from "./metric-pie-chart";
import type { DashboardMetrics } from "./types";

export interface LinkedinMetricsPieChartsProps {
	metrics: DashboardMetrics;
}

export function LinkedinMetricsPieCharts(props: LinkedinMetricsPieChartsProps) {
	const { metrics } = props;
	return (
		<div className="flex flex-wrap justify-around gap-8 w-full">
			<div className="w-[200px] flex-grow-0 flex-shrink-0">
				<MetricPieChart
					metrics={metrics.collaboratorMetrics}
					platform="linkedin"
					metricKey="posts"
					title="Posts"
					description="Distribution of posts among contributors"
					key="pie-posts"
				/>
			</div>
			<div className="w-[200px] flex-grow-0 flex-shrink-0">
				<MetricPieChart
					metrics={metrics.collaboratorMetrics}
					platform="linkedin"
					metricKey="impressions"
					title="Impressions"
					description="Distribution of impressions among contributors"
					key="pie-impressions"
				/>
			</div>
			<div className="w-[200px] flex-grow-0 flex-shrink-0">
				<MetricPieChart
					metrics={metrics.collaboratorMetrics}
					platform="linkedin"
					metricKey="interactions"
					title="Interactions"
					description="Distribution of interactions among contributors"
					key="pie-interactions"
				/>
			</div>
			<div className="w-[200px] flex-grow-0 flex-shrink-0">
				<MetricPieChart
					metrics={metrics.collaboratorMetrics}
					platform="linkedin"
					metricKey="comments"
					title="Comments"
					description="Distribution of comments among contributors"
					key="pie-comments"
				/>
			</div>
			<div className="w-[200px] flex-grow-0 flex-shrink-0">
				<LeadsByAudiencePieChart metrics={metrics.leadsMetrics} />
			</div>
		</div>
	);
}
