/***
 *
 *   APP LAYOUT
 *   Main application layout containing the navigation
 *   and header (title, secondary nav and user)
 *
 *   PROPS
 *   children: will be passed from router > view > here (component(s), required)
 *   title: title of the view for the header (string, required)
 *
 **********/

import { AuthContext, Header, User, useTranslation } from "components/lib";
import {
	SidebarInset,
	SidebarProvider,
	SidebarTrigger,
} from "components/ui/sidebar";
import type React from "react";
import { AppSidebar } from "./app-sidebar";
import Style from "./app.tailwind";

interface AppLayoutProps {
	children: React.ReactNode;
	title: string;
}

export function AppLayout({
	children,
	title,
}: AppLayoutProps): React.ReactElement {
	// context & style
	const { t } = useTranslation();

	return (
		<SidebarProvider>
			<div className="flex h-screen w-full overflow-hidden">
				<AppSidebar />
				<SidebarInset className={Style.app}>
					<Header title={t(title)} />
					<div className="w-full flex-1 overflow-auto p-4">{children}</div>
				</SidebarInset>
			</div>
		</SidebarProvider>
	);
}
