"use client";

import { AudienceForm } from "components/forms/AudienceForm";
import { useToast } from "components/hooks/use-toast";
import { AuthContext } from "components/lib";
import { Card } from "components/ui/card";
import { motion } from "framer-motion";
import { useCreateAudience } from "hooks/use-create-audience";
import { useOrganizationAudiences } from "hooks/use-organization-audiences";
import { useOrganizationICPs } from "hooks/use-organization-icps";
import { useUpdateAudience } from "hooks/use-update-audience";
import { Building2 } from "lucide-react";
import { useContext, useState } from "react";
import type { Audience } from "types/audience";
import type { AuthContextType } from "types/authContext";
import type { OrganizationICP } from "types/organization-icp";
import { ICPForm } from "./components/ICPForm";
import { Sidebar } from "./components/Sidebar";

const formVariants = {
	hidden: { opacity: 0, x: -20 },
	visible: {
		opacity: 1,
		x: 0,
		transition: {
			type: "spring",
			stiffness: 300,
			damping: 30,
		},
	},
};

type Props = {
	t: (key: string) => string;
};

export const CreateICP = ({ t }: Props) => {
	const auth = useContext<AuthContextType>(AuthContext);
	const organizationId = auth.user?.organization_id;

	const [selectedForm, setSelectedForm] = useState<"icp" | "audience" | null>(
		null,
	);
	const [selectedAudience, setSelectedAudience] = useState<Audience | null>(
		null,
	);
	const [selectedICP, setSelectedICP] = useState<OrganizationICP | null>(null);

	const {
		icps,
		isFetching: isFetchingICPs,
		error: icpsError,
	} = useOrganizationICPs(organizationId ?? "");

	const {
		audiences,
		isFetching: isFetchingAudiences,
		error: audiencesError,
	} = useOrganizationAudiences({
		organization_id: organizationId ?? "",
	});

	const handleAudienceSelect = (audience: Audience) => {
		setSelectedAudience(audience);
		setSelectedForm("audience");
	};

	const handleICPSelect = (icp: OrganizationICP) => {
		setSelectedICP(icp);
		setSelectedForm("icp");
	};

	const handleSelectForm = (form: "icp" | "audience") => {
		setSelectedAudience(null);
		setSelectedICP(null);
		setSelectedForm(form);
	};

	const handleFormSuccess = () => {
		setSelectedForm(null);
		setSelectedAudience(null);
	};

	return (
		<div className="flex h-full flex-1">
			<Sidebar
				onSelectForm={handleSelectForm}
				onSelectAudience={handleAudienceSelect}
				onSelectICP={handleICPSelect}
				icps={icps}
				audiences={audiences}
				isFetchingICPs={isFetchingICPs}
				isFetchingAudiences={isFetchingAudiences}
				t={t}
			/>
			<div className="flex-1 p-6">
				{selectedForm === "icp" && (
					<motion.div
						key={selectedICP?.id ?? "new"}
						variants={formVariants}
						initial="hidden"
						animate="visible"
					>
						<ICPForm
							onCancel={() => {
								setSelectedForm(null);
								setSelectedICP(null);
							}}
							icpToEdit={selectedICP}
							t={t}
						/>
					</motion.div>
				)}

				{selectedForm === "audience" && (
					<motion.div
						key={selectedAudience?.id ?? "new-audience"}
						variants={formVariants}
						initial="hidden"
						animate="visible"
					>
						<Card className="mx-auto max-w-3xl p-6">
							<AudienceForm
								onCancel={() => {
									setSelectedForm(null);
									setSelectedAudience(null);
								}}
								onSuccess={handleFormSuccess}
								icps={icps}
								audienceToEdit={selectedAudience}
								t={t}
								organizationId={organizationId}
							/>
						</Card>
					</motion.div>
				)}

				{!selectedForm && (
					<div className="flex h-full items-start justify-center">
						<div className="text-center">
							<Building2 className="mx-auto h-12 w-12 text-muted-foreground" />
							<h3 className="mt-4 text-lg font-medium">
								{t("icp.empty.title")}
							</h3>
							<p className="mt-2 text-sm text-muted-foreground">
								{t("icp.empty.description")}
							</p>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default CreateICP;
