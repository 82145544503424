import { LeadLens } from "views/lead-lens/lead-lens";
import { LeadLensV2 } from "views/lead-lens/lead-lens-v2";

const routes = [
	{
		path: "/lead-lens",
		view: LeadLens,
		layout: "app",
		permission: "user",
		title: "LeadLens",
	},
	{
		path: "/lead-lens/v2",
		view: LeadLensV2,
		layout: "app",
		permission: "user",
		title: "LeadLens V2",
	},
];

export default routes;
