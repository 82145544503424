"use client";

import { AuthContext } from "components/lib";
import { Button } from "components/ui/button";
import {
	Collapsible,
	CollapsibleContent,
	CollapsibleTrigger,
} from "components/ui/collapsible";
import { ScrollArea } from "components/ui/scroll-area";
import { Separator } from "components/ui/separator";
import { Skeleton } from "components/ui/skeleton";
import { motion } from "framer-motion";
import type { TFunction } from "i18next";
import { Building2, ChevronDown, ChevronUp, Plus, Users } from "lucide-react";
import { useContext, useState } from "react";
import type { Audience } from "types/audience";
import type { AuthContextType } from "types/authContext";
import type { OrganizationICP } from "types/organization-icp";
import { AudienceCard } from "./AudienceCard";
import { ICPCard } from "./ICPCard";

interface SidebarProps {
	onSelectForm: (form: "icp" | "audience") => void;
	onSelectAudience?: (audience: Audience) => void;
	onSelectICP?: (icp: OrganizationICP) => void;
	icps: OrganizationICP[];
	audiences: Audience[];
	isFetchingICPs: boolean;
	isFetchingAudiences: boolean;
	t: (key: string) => string;
}

const containerVariants = {
	hidden: { opacity: 0 },
	show: {
		opacity: 1,
		transition: {
			staggerChildren: 0.1,
		},
	},
};

const itemVariants = {
	hidden: { opacity: 0, y: -10 },
	show: { opacity: 1, y: 0 },
};

export const Sidebar = ({
	onSelectForm,
	onSelectAudience,
	onSelectICP,
	icps,
	audiences,
	isFetchingICPs,
	isFetchingAudiences,
	t,
}: SidebarProps) => {
	const [isICPOpen, setIsICPOpen] = useState(true);
	const [isAudienceOpen, setIsAudienceOpen] = useState(true);
	const auth = useContext<AuthContextType>(AuthContext);

	return (
		<div className="flex w-96 flex-col border-r">
			<Collapsible
				defaultOpen
				open={isICPOpen}
				onOpenChange={setIsICPOpen}
				className="flex flex-col"
			>
				<CollapsibleTrigger asChild>
					<Button
						variant="ghost"
						className="flex w-full items-center justify-between p-4"
					>
						<div className="flex items-center gap-2">
							<Building2 className="h-4 w-4" />
							<span>{t("icp.sidebar.icps")}</span>
						</div>
						{isICPOpen ? (
							<ChevronUp className="h-4 w-4" />
						) : (
							<ChevronDown className="h-4 w-4" />
						)}
					</Button>
				</CollapsibleTrigger>
				<div className="p-2">
					<Button
						onClick={() => onSelectForm("icp")}
						variant="outline"
						disabled={
							auth.user?.permission !== "admin" &&
							auth.user?.permission !== "owner"
						}
						className="w-full bg-white text-black"
					>
						{t("icp.sidebar.addNewICP")}
					</Button>
				</div>
				<CollapsibleContent>
					<motion.div
						variants={containerVariants}
						initial="hidden"
						animate="show"
						className="space-y-2 p-2"
					>
						{isFetchingICPs ? (
							<LoadingCards count={2} />
						) : (
							icps.map((icp) => (
								<motion.div
									key={icp.id}
									variants={itemVariants}
									onClick={() => onSelectICP?.(icp)}
								>
									<ICPCard icp={icp} />
								</motion.div>
							))
						)}
					</motion.div>
				</CollapsibleContent>
			</Collapsible>

			<Separator />

			<Collapsible
				defaultOpen
				open={isAudienceOpen}
				onOpenChange={setIsAudienceOpen}
				className="flex flex-col flex-1"
			>
				<CollapsibleTrigger asChild>
					<Button
						variant="ghost"
						className="flex w-full items-center justify-between p-4"
					>
						<div className="flex items-center gap-2">
							<Users className="h-4 w-4" />
							<span>{t("icp.sidebar.audiences")}</span>
						</div>
						{isAudienceOpen ? (
							<ChevronUp className="h-4 w-4" />
						) : (
							<ChevronDown className="h-4 w-4" />
						)}
					</Button>
				</CollapsibleTrigger>
				<div className="p-2">
					<Button
						onClick={() => onSelectForm("audience")}
						variant="outline"
						className="w-full bg-white text-black"
					>
						{t("icp.sidebar.addNewAudience")}
					</Button>
				</div>
				<CollapsibleContent className="flex-1">
					<motion.div
						variants={containerVariants}
						initial="hidden"
						animate="show"
						className="space-y-2 p-2"
					>
						{isFetchingAudiences ? (
							<LoadingCards count={2} />
						) : (
							audiences.map((audience) => (
								<motion.div
									key={audience.id}
									variants={itemVariants}
									onClick={() => onSelectAudience?.(audience)}
								>
									<AudienceCard
										audience={audience}
										matchingICP={icps.find(
											(icp) => icp.id === audience.organization_icp_id,
										)}
									/>
								</motion.div>
							))
						)}
					</motion.div>
				</CollapsibleContent>
			</Collapsible>
		</div>
	);
};

const LoadingCards = ({ count }: { count: number }) => (
	<div className="space-y-2 px-2">
		{Array.from({ length: count }).map((_, i) => (
			<Skeleton key={`skeleton-${i + 1}`} className="h-[72px] w-full" />
		))}
	</div>
);
