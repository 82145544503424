import { zodResolver } from "@hookform/resolvers/zod";
import { defineStepper } from "@stepperize/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { AudienceSelector } from "components/audience/AudienceSelector";
import { useToast } from "components/hooks/use-toast";
import { AuthContext, Header, User } from "components/lib";
import { Button } from "components/ui/button";
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "components/ui/form";
import { Input } from "components/ui/input";
import { ScrollArea } from "components/ui/scroll-area";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "components/ui/select";
import { Steps } from "components/ui/steps";
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "components/ui/tooltip";
import { useUserAudiences } from "hooks/use-user-audiences";
import { linkedinOAuthUrl } from "lib/utils";
import { Loader } from "lucide-react";
import { HelpCircle } from "lucide-react";
import type React from "react";
import { useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useSearchParams } from "react-router-dom";
import type { AuthContextType } from "types/authContext";
import { LinkedInPostCard } from "views/content-creation/_components/tools/ProfilePersonaCreation/components/LinkedInPostCard";
import type { LinkedInPost } from "views/content-creation/_components/tools/ProfilePersonaCreation/types";
import { useExtensionInstalled } from "views/dashboard/_hooks/use-extension-installed";
import { useLinkedinProfileId } from "views/dashboard/_hooks/use-linkedin-profile-id";
import { z } from "zod";
import { initialLinkedinUserPostStoringProcess } from "../../../views/dashboard/utils";

interface Step {
	id: string;
	title: string;
}

const steps: Step[] = [
	{ id: "extension", title: "Install the Extension" },
	{ id: "linkedin", title: "Connect to LinkedIn" },
	{ id: "sync", title: "Sync Account" },
	{ id: "targetAudience", title: "Add Target Audience" },
	{ id: "profilePersona", title: "Profile Persona" },
];

const { useStepper } = defineStepper(...steps);

const profilePersonaSchema = z.object({
	phoneNumber: z
		.string()
		.regex(
			/^\+[1-9]\d{1,14}$/,
			"El número debe comenzar con + y el código de país",
		)
		.refine(
			(phone) => {
				// Remove any spaces or special characters for validation
				const cleanPhone = phone.replace(/\s+/g, "");

				// Check if it's an Argentina number
				if (cleanPhone.startsWith("+54")) {
					// Must be exactly 13 digits and include 9 after country code
					return /^\+54 ?9 ?\d{10}$/.test(phone);
				}

				// Check if it's a Mexico number
				if (cleanPhone.startsWith("+52")) {
					// Must include 1 after country code
					return /^\+52 ?1 ?\d{10}$/.test(phone);
				}

				// General validation for other countries
				// - Must start with +
				// - Must have country code (1-3 digits)
				// - Total length between 10-15 digits
				return /^\+[1-9]\d{1,2} ?\d{6,12}$/.test(phone);
			},
			(phone) => ({
				message: !phone.startsWith("+")
					? "El número debe comenzar con +"
					: phone.startsWith("+54")
						? "Los números de Argentina deben seguir el formato: +549XXXXXXXXXX"
						: phone.startsWith("+52")
							? "Los números de México deben seguir el formato: +521XXXXXXXXXX"
							: "Formato de número internacional inválido. Ejemplo: +12345678900",
			}),
		),
	publicationFrequency: z
		.string()
		.min(1, "Se requiere seleccionar una frecuencia de publicación"),
	linkedinPosts: z
		.array(z.any())
		.min(1, "Se requiere al menos una publicación de LinkedIn"),
});

interface Props {
	t: (key: string) => string;
}

export function Onboarding({ t }: Props): React.ReactElement {
	const stepper = useStepper();
	const queryClient = useQueryClient();

	const { extensionInstalled, loading: loadingExtension } =
		useExtensionInstalled();
	const { profileId, loading: loadingProfile } = useLinkedinProfileId();
	const [isConnectingLinkedIn, setIsConnectingLinkedIn] =
		useState<boolean>(false);
	const { user } = useContext<AuthContextType>(AuthContext);
	const [searchParams] = useSearchParams();
	const code = searchParams.get("code");
	const hasCalled = useRef<boolean>(false);
	const hasCheckedInitialAudiences = useRef<boolean>(false);
	const skipAutoNavigation = useRef<boolean>(false);
	const [isSyncingLinkedIn, setIsSyncingLinkedIn] = useState<boolean>(false);
	const [linkedInConnected, setLinkedInConnected] = useState<boolean>(false);
	const [selectedPosts, setSelectedPosts] = useState<LinkedInPost[]>([]);
	const [linkedinProfileCreated, setLinkedinProfileCreated] =
		useState<boolean>(false);
	const { userAudiences, isFetching: isLoadingUserAudiences } =
		useUserAudiences(user?.id);
	const hasExistingAudiences = userAudiences.length > 0;
	const [page, setPage] = useState(1);
	const [totalPosts, setTotalPosts] = useState<LinkedInPost[]>([]);
	const [selectedAudiences, setSelectedAudiences] = useState<number[]>([]);

	const { toast } = useToast();

	const form = useForm<z.infer<typeof profilePersonaSchema>>({
		resolver: zodResolver(profilePersonaSchema),
		defaultValues: {
			phoneNumber: "",
			publicationFrequency: "",
			linkedinPosts: [],
		},
	});

	const { data: userSyncStatus, isLoading: userSyncStatusLoading } = useQuery({
		queryKey: ["userSyncStatus", user?.id],
		queryFn: async () => {
			const res = await axios.get(
				`/api/analytics/user/${user?.id}/sync-status`,
			);
			return res.data;
		},
		enabled: !!user?.id,
		retry: false,
	});

	const storeLinkedinCallbackMutation = useMutation({
		mutationFn: async () => {
			const response = await axios.post("/api/oauth/linkedin/callback", {
				code,
				email: user?.email,
			});
			return response.data;
		},
		onSuccess: (result) => {
			localStorage.setItem("linkedinOauthExpiresIn", result.expiresIn);
			toast({
				title: t("onboarding.toast.linkedin.success"),
				description: t("onboarding.toast.linkedin.successDescription"),
			});
			setIsConnectingLinkedIn(false);

			if (extensionInstalled && profileId) {
				handleLinkedinSync();
			}
		},
		onError: (error) => {
			console.error("Error storing LinkedIn callback:", error);
			setIsConnectingLinkedIn(false);
			toast({
				title: t("onboarding.toast.linkedin.error"),
				description: t("onboarding.toast.linkedin.errorDescription"),
				variant: "destructive",
			});
		},
	});

	const createLinkedinProfileMutation = useMutation({
		// biome-ignore lint/suspicious/noExplicitAny: <We need to type this in the future>
		mutationFn: async (posts: any) => {
			console.log(
				"Creating LinkedIn profile",
				posts,
				profileId,
				user?.organization_id,
				user?.id,
			);
			const response = await axios.post("/api/user/createLinkedinProfile", {
				posts,
				profile_id: profileId,
				organization_id: user?.organization_id,
				user_id: user?.id,
			});
			return response.data;
		},
		onSuccess: () => {
			const storedUser = localStorage.getItem("user");
			if (storedUser) {
				const parsedUser = JSON.parse(storedUser);
				parsedUser.onboarded = true;
				localStorage.setItem("user", JSON.stringify(parsedUser));
			}
			setIsSyncingLinkedIn(false);
			queryClient.invalidateQueries({ queryKey: ["userPosts"] });
		},
		onError: (error) => {
			console.error("Error syncing LinkedIn data:", error);
			toast({
				title: t("onboarding.toast.profile.error"),
				description: t("onboarding.toast.profile.errorDescription"),
				variant: "destructive",
			});
		},
		onSettled: () => {
			setIsSyncingLinkedIn(false);
		},
	});

	const createProfilePersonaMutation = useMutation({
		mutationFn: async (values: z.infer<typeof profilePersonaSchema>) => {
			const payload = {
				email: user?.email || "",
				userId: user?.id || "",
				organizationId: user?.organization_id || "",
				phoneNumber: values.phoneNumber,
				publicationFrequency: values.publicationFrequency,
				linkedinPosts: selectedPosts.map((post) => ({
					content: post.content || "",
				})),
				metadata: "",
			};

			const response = await axios.post("/api/chatbot-user/create", payload);
			return response.data;
		},
		onSuccess: async () => {
			const storedUser = localStorage.getItem("user");
			if (storedUser) {
				const parsedUser = JSON.parse(storedUser);
				parsedUser.onboarded = true;
				localStorage.setItem("user", JSON.stringify(parsedUser));
			}
			await axios.post("/api/user/onboard", {
				id: user?.id,
				value: true,
			});
			toast({
				title: t("onboarding.toast.profile.success"),
				description: t("onboarding.toast.profile.successDescription"),
			});
			window.location.href = "/dashboard";
		},
		onError: (error) => {
			console.error("Error creating profile persona:", error);
			toast({
				title: t("onboarding.toast.profile.error"),
				description: t("onboarding.toast.profile.errorDescription"),
				variant: "destructive",
			});
		},
	});

	const skipProfilePersonaMutation = useMutation({
		mutationFn: async () => {
			const response = await axios.post("/api/user/onboard", {
				id: user?.id,
				value: true,
			});
			return response.data;
		},
		onSuccess: () => {
			const storedUser = localStorage.getItem("user");
			if (storedUser) {
				const parsedUser = JSON.parse(storedUser);
				parsedUser.onboarded = true;
				localStorage.setItem("user", JSON.stringify(parsedUser));
			}
			toast({
				title: t("onboarding.toast.profile.success"),
				description: t("onboarding.toast.profile.successDescription"),
			});
			window.location.href = "/dashboard";
		},
		onError: (error) => {
			console.error("Error skipping profile persona:", error);
			toast({
				title: "Error",
				description: "An error occurred while skipping profile creation.",
				variant: "destructive",
			});
		},
	});

	const {
		data: userPosts,
		isLoading: isLoadingPosts,
		isFetching: isFetchingPosts,
		isRefetching: isRefetchingPosts,
		refetch: refetchPosts,
	} = useQuery({
		queryKey: ["userPosts", user?.id, page],
		queryFn: async () => {
			const response = await axios.get<LinkedInPost[]>(
				`/api/analytics/user/${user?.id}/top-linkedin-posts?limit=5&page=${page}`,
			);
			return response.data;
		},
		enabled: !!user?.id && (!!userSyncStatus || !!linkedinProfileCreated),
	});

	useEffect(() => {
		if (user) {
			if (user.onboarded === true) {
				window.location.href = "/dashboard";
				return;
			}

			if (skipAutoNavigation.current) {
				return;
			}

			if (code && !hasCalled.current) {
				hasCalled.current = true;
				storeLinkedinCallback();
			} else if (
				!hasCheckedInitialAudiences.current &&
				hasExistingAudiences &&
				!isLoadingUserAudiences
			) {
				hasCheckedInitialAudiences.current = true;
				stepper.goTo("profilePersona");
			} else if (linkedinProfileCreated || userSyncStatus) {
				stepper.goTo("targetAudience");
			} else if (
				extensionInstalled &&
				profileId &&
				localStorage.getItem("linkedinOauthExpiresIn")
			) {
				stepper.goTo("sync");
			} else if (extensionInstalled) {
				stepper.goTo("linkedin");
				checkLinkedInConnection();
			}
		}
	}, [
		user,
		extensionInstalled,
		profileId,
		stepper,
		code,
		linkedinProfileCreated,
		userSyncStatus,
		hasExistingAudiences,
		isLoadingUserAudiences,
	]);

	useEffect(() => {
		if (userPosts) {
			const newPosts = userPosts.filter(
				(post) =>
					!selectedPosts.some(
						(selected) => selected.linkedin_id === post.linkedin_id,
					) &&
					!totalPosts.some((total) => total.linkedin_id === post.linkedin_id),
			);

			if (newPosts.length > 0) {
				setTotalPosts((prevPosts) => [...prevPosts, ...newPosts]);

				// Seleccionar automáticamente los primeros 3 posteos si no hay ninguno seleccionado
				if (selectedPosts.length === 0 && page === 1) {
					const firstThreePosts = newPosts.slice(0, 3);
					setSelectedPosts(firstThreePosts);
					form.setValue("linkedinPosts", firstThreePosts);
				}
			}
		}
	}, [userPosts, selectedPosts, totalPosts, page, form]);

	const checkLinkedInConnection = (): void => {
		const interval = setInterval(() => {
			const expiresIn = localStorage.getItem("linkedinOauthExpiresIn");
			if (expiresIn) {
				clearInterval(interval);
				setLinkedInConnected(true);
				if (extensionInstalled && profileId) {
					stepper.goTo("sync");
				}
			}
		}, 1000);

		setTimeout(() => clearInterval(interval), 300000);
	};

	const storeLinkedinCallback = async (): Promise<void> => {
		setIsConnectingLinkedIn(true);
		await storeLinkedinCallbackMutation.mutateAsync();
	};

	const handleLinkedinSync = async (): Promise<void> => {
		try {
			setIsSyncingLinkedIn(true);
			const posts = await initialLinkedinUserPostStoringProcess();
			await createLinkedinProfileMutation.mutateAsync(posts);
			setLinkedinProfileCreated(true);
		} catch (error) {
			console.error("Error syncing LinkedIn data:", error);
			toast({
				title: t("onboarding.toast.sync.error"),
				description: t("onboarding.toast.sync.errorDescription"),
				variant: "destructive",
			});
		} finally {
			setIsSyncingLinkedIn(false);
		}
	};

	const handleExtensionInstall = (): void => {
		window.open(
			"https://chromewebstore.google.com/detail/inbound-tools/lmdidkfogbencnidkgoohehjlmagaplk",
			"_blank",
		);
	};

	const handleLinkedInConnect = (): void => {
		setIsConnectingLinkedIn(true);
		window.location.href = linkedinOAuthUrl;
	};

	const handleSyncAccount = async (): Promise<void> => {
		try {
			setIsSyncingLinkedIn(true);
			await handleLinkedinSync();
		} catch (error) {
			console.error("Error syncing account:", error);
			toast({
				title: t("onboarding.toast.sync.error"),
				description: t("onboarding.toast.sync.errorDescription"),
				variant: "destructive",
			});
		} finally {
			setIsSyncingLinkedIn(false);
		}
	};

	const handlePostSelect = (post: LinkedInPost) => {
		setSelectedPosts((prev) => {
			const isSelected = prev.some((p) => p.linkedin_id === post.linkedin_id);
			const newSelectedPosts = isSelected
				? prev.filter((p) => p.linkedin_id !== post.linkedin_id)
				: prev.length >= 5
					? prev
					: [...prev, post];

			form.setValue("linkedinPosts", newSelectedPosts);

			return newSelectedPosts;
		});
	};

	const handleRetryFetch = () => {
		refetchPosts();
	};

	if (!user) {
		return <Navigate to="/signin" />;
	}

	return (
		<div className="flex flex-col min-h-screen pb-10">
			<Header title={t("onboarding.title")} />
			<div className="container mx-auto px-4 flex-grow">
				<div className="flex flex-col gap-4 w-full max-w-4xl mx-auto">
					<Steps steps={steps} currentStep={steps.indexOf(stepper.current)} />
					{stepper.when("extension", () => (
						<div className="flex flex-col gap-4">
							<Button
								size="lg"
								className="w-full"
								onClick={handleExtensionInstall}
							>
								{t("onboarding.extension.button")}
							</Button>
						</div>
					))}

					{stepper.when("linkedin", () => (
						<div className="flex flex-col gap-4">
							<Button
								size="lg"
								onClick={handleLinkedInConnect}
								className="w-full"
								disabled={
									isConnectingLinkedIn || isSyncingLinkedIn || linkedInConnected
								}
							>
								{isConnectingLinkedIn
									? t("onboarding.linkedin.connecting")
									: isSyncingLinkedIn
										? t("onboarding.linkedin.syncing")
										: linkedInConnected
											? t("onboarding.linkedin.connected")
											: t("onboarding.linkedin.connect")}
							</Button>
						</div>
					))}

					{stepper.when("sync", () => (
						<div className="flex flex-col gap-4">
							<Button
								size="lg"
								onClick={handleSyncAccount}
								className="w-full"
								disabled={isSyncingLinkedIn}
							>
								{isSyncingLinkedIn ? (
									<span className="flex items-center gap-2">
										{t("onboarding.sync.syncing")}{" "}
										<Loader className="animate-spin" />
									</span>
								) : (
									t("onboarding.sync.button")
								)}
							</Button>
						</div>
					))}

					{stepper.when("targetAudience", () => (
						<div className="flex flex-col gap-4 pt-4">
							<div className="flex flex-col gap-1 mb-4">
								<h1 className="font-semibold">
									{t("onboarding.targetAudience.title")}
								</h1>
								<p className="text-lg text-muted-foreground">
									{t("onboarding.targetAudience.subtitle")}
								</p>
							</div>

							<AudienceSelector
								t={t}
								showTitle={false}
								onAudienceChange={(audienceIds) => {
									setSelectedAudiences(audienceIds);
								}}
							/>

							<div className="flex gap-2 mt-6">
								<Button
									type="button"
									variant="outline"
									size="lg"
									onClick={() => {
										hasCheckedInitialAudiences.current = true;
										skipAutoNavigation.current = true;
										stepper.goTo("profilePersona");
									}}
								>
									{t("onboarding.targetAudience.skip")}
								</Button>
								<Button
									type="button"
									size="lg"
									className="flex-1"
									disabled={selectedAudiences.length === 0}
									onClick={() => {
										hasCheckedInitialAudiences.current = true;
										skipAutoNavigation.current = true;
										stepper.goTo("profilePersona");
									}}
								>
									{t("onboarding.targetAudience.continue")}
								</Button>
							</div>
						</div>
					))}

					{stepper.when("profilePersona", () => (
						<Form {...form}>
							<form
								onSubmit={form.handleSubmit((values) =>
									createProfilePersonaMutation.mutateAsync(values),
								)}
								className="flex flex-col gap-4 pt-4"
							>
								<div className="flex flex-col gap-1 mb-4">
									<h1 className="font-semibold">
										{t("onboarding.profilePersona.description.title")}
									</h1>
									<p className="text-lg text-muted-foreground">
										{t("onboarding.profilePersona.description.subtitle")}
									</p>
								</div>

								<FormField
									control={form.control}
									name="phoneNumber"
									render={({ field, fieldState }) => (
										<FormItem>
											<FormLabel className="flex items-center">
												{t("onboarding.profilePersona.phone.label")}
												<TooltipProvider>
													<Tooltip>
														<TooltipTrigger asChild>
															<HelpCircle className="w-4 h-4 ml-2 cursor-pointer" />
														</TooltipTrigger>
														<TooltipContent className="bg-white text-black border">
															<p>
																{t(
																	"onboarding.profilePersona.phone.tooltip.text",
																)}
															</p>
															<p>
																{t(
																	"onboarding.profilePersona.phone.tooltip.example",
																)}
															</p>
															<a
																href="https://faq.whatsapp.com/1294841057948784"
																target="_blank"
																rel="noopener noreferrer"
																className="text-blue-500 hover:underline"
															>
																{t(
																	"onboarding.profilePersona.phone.tooltip.more_info",
																)}
															</a>
															<br />
															<a
																href="https://b_fQwtbAfrP1o.v0.build/"
																target="_blank"
																rel="noopener noreferrer"
																className="text-blue-500 hover:underline"
															>
																{t(
																	"onboarding.profilePersona.phone.tooltip.convert",
																)}
															</a>
														</TooltipContent>
													</Tooltip>
												</TooltipProvider>
											</FormLabel>
											<FormControl>
												<Input
													type="tel"
													placeholder={t(
														"onboarding.profilePersona.phone.placeholder",
													)}
													{...field}
												/>
											</FormControl>
											<FormMessage>
												{fieldState.error?.message &&
													t(fieldState.error.message)}
											</FormMessage>
										</FormItem>
									)}
								/>

								<FormField
									control={form.control}
									name="publicationFrequency"
									render={({ field, fieldState }) => (
										<FormItem>
											<FormLabel className="flex items-center">
												{t(
													"onboarding.profilePersona.publicationFrequency.label",
												)}
												<TooltipProvider>
													<Tooltip>
														<TooltipTrigger asChild>
															<HelpCircle className="w-4 h-4 ml-2 cursor-pointer" />
														</TooltipTrigger>
														<TooltipContent className="bg-white text-black border">
															<p>
																{t(
																	"onboarding.profilePersona.publicationFrequency.tooltip.text",
																)}
															</p>
														</TooltipContent>
													</Tooltip>
												</TooltipProvider>
											</FormLabel>
											<FormControl>
												<Select
													onValueChange={field.onChange}
													defaultValue={field.value}
												>
													<SelectTrigger>
														<SelectValue
															placeholder={t(
																"onboarding.profilePersona.publicationFrequency.placeholder",
															)}
														/>
													</SelectTrigger>
													<SelectContent>
														<SelectItem value="once_per_week">
															{t(
																"onboarding.profilePersona.publicationFrequency.once_per_week",
															)}
														</SelectItem>
														<SelectItem value="twice_per_week">
															{t(
																"onboarding.profilePersona.publicationFrequency.twice_per_week",
															)}
														</SelectItem>
														<SelectItem value="three_times_per_week">
															{t(
																"onboarding.profilePersona.publicationFrequency.three_times_per_week",
															)}
														</SelectItem>
														<SelectItem value="daily">
															{t(
																"onboarding.profilePersona.publicationFrequency.daily",
															)}
														</SelectItem>
													</SelectContent>
												</Select>
											</FormControl>
											<FormMessage>
												{fieldState.error?.message &&
													t(fieldState.error.message)}
											</FormMessage>
										</FormItem>
									)}
								/>

								<FormField
									control={form.control}
									name="linkedinPosts"
									render={({ field, fieldState }) => (
										<FormItem>
											<FormLabel className="flex items-center">
												{t("onboarding.profilePersona.posts.label")}
												<TooltipProvider>
													<Tooltip>
														<TooltipTrigger asChild>
															<HelpCircle className="w-4 h-4 ml-2 cursor-pointer" />
														</TooltipTrigger>
														<TooltipContent className="bg-white text-black border">
															<p>
																{t(
																	"onboarding.profilePersona.posts.tooltip.text",
																)}
															</p>
															<p>
																{t(
																	"onboarding.profilePersona.posts.tooltip.example",
																)}
															</p>
														</TooltipContent>
													</Tooltip>
												</TooltipProvider>
											</FormLabel>
											<ScrollArea className="h-[450px] rounded-md border p-4">
												{(isLoadingPosts && page === 1) || isRefetchingPosts ? (
													<div className="flex items-center justify-center h-full">
														<Loader className="animate-spin" />
													</div>
												) : totalPosts?.length ? (
													<div className="flex flex-col justify-between min-h-[400px]">
														<div className="grid grid-cols-2 gap-2">
															{totalPosts.map((post) => (
																<LinkedInPostCard
																	key={post.linkedin_id}
																	post={post}
																	isSelected={selectedPosts.some(
																		(p) => p.linkedin_id === post.linkedin_id,
																	)}
																	onSelect={() => handlePostSelect(post)}
																	disabled={
																		selectedPosts.length >= 5 &&
																		!selectedPosts.some(
																			(p) => p.linkedin_id === post.linkedin_id,
																		)
																	}
																/>
															))}
														</div>

														{userPosts?.length === 5 && (
															<div className="flex justify-center">
																<Button
																	type="button"
																	variant="outline"
																	onClick={() => setPage((prev) => prev + 1)}
																	disabled={isFetchingPosts}
																>
																	{isFetchingPosts ? (
																		<span className="flex items-center gap-2">
																			{t(
																				"onboarding.profilePersona.posts.loading",
																			)}{" "}
																			<Loader className="animate-spin" />
																		</span>
																	) : (
																		t(
																			"onboarding.profilePersona.posts.load_more",
																		)
																	)}
																</Button>
															</div>
														)}
													</div>
												) : (
													<div className="flex flex-col items-center justify-center h-full gap-4">
														<p className="text-sm text-muted-foreground text-center">
															{t("onboarding.profilePersona.posts.no_posts")}
														</p>
														<Button
															variant="outline"
															onClick={handleRetryFetch}
															disabled={isRefetchingPosts}
														>
															{t("onboarding.profilePersona.posts.retry")}
														</Button>
													</div>
												)}
											</ScrollArea>
											<FormMessage>
												{fieldState.error?.message &&
													t(fieldState.error.message)}
											</FormMessage>
										</FormItem>
									)}
								/>

								<div className="flex gap-2">
									<Button
										type="submit"
										size="lg"
										className="flex-1"
										disabled={createProfilePersonaMutation.isPending}
									>
										{createProfilePersonaMutation.isPending ? (
											<span className="flex items-center gap-2">
												{t("onboarding.profilePersona.submit.loading")}{" "}
												<Loader className="animate-spin" />
											</span>
										) : (
											t("onboarding.profilePersona.submit.button")
										)}
									</Button>
									<Button
										type="button"
										variant="outline"
										size="lg"
										onClick={() => skipProfilePersonaMutation.mutateAsync()}
										disabled={skipProfilePersonaMutation.isPending}
									>
										{skipProfilePersonaMutation.isPending
											? t("onboarding.profilePersona.skipping")
											: t("onboarding.profilePersona.skip")}
									</Button>
								</div>
							</form>
						</Form>
					))}
				</div>
			</div>
		</div>
	);
}
