import {
	Card,
	CardContent,
	CardHeader,
	CardTitle,
} from "components/ui/card.tsx";
import { CalendarIcon, Wrench } from "lucide-react";
import type { AutomationConfig } from "types/automations.ts";

export interface AutomationConfigurationProps {
	config: AutomationConfig;
	t: (key: string) => string;
}

export function AutomationConfiguration(props: AutomationConfigurationProps) {
	const { config, t } = props;

	return (
		<div className="flex flex-col items-center justify-center gap-3">
			<Card className={"mb w-[100%]"}>
				<CardHeader>
					<CardTitle className="text-2xl font-medium flex items-center gap-2">
						<Wrench className="w-6 h-6" />
						{t("automations.view.configuration.title")}
					</CardTitle>
				</CardHeader>
				<CardContent>
					<div className="mb-3">
						<h3 className="text-sm font-semibold">
							{t("automations.view.configuration.sender")}
						</h3>
						<p className="text-sm text-muted-foreground">
							{config.messageSender}
						</p>
					</div>
					<div className={"mb-3"}>
						<h3 className="text-sm font-semibold">
							{t("automations.view.configuration.excludedCompanies")}
						</h3>
						<p className="text-sm text-muted-foreground">
							{config.excludedCompanies.join(", ")}
						</p>
					</div>
					<div>
						<h3 className="text-sm font-semibold">
							{t("automations.view.configuration.messageTemplate")}
						</h3>
						<p className="text-sm text-muted-foreground">
							{config.messageVariants?.join(", ")}
						</p>
					</div>
				</CardContent>
			</Card>
			<Card className={"mb-3 w-[100%]"}>
				<CardHeader>
					<CardTitle className="text-2xl font-medium flex items-center gap-2">
						<CalendarIcon className="w-6 h-6" />
						{t("automations.view.configuration.schedulingConfiguration")}
					</CardTitle>
				</CardHeader>
				<CardContent>
					<div className="mb-3">
						<h3 className="text-sm font-semibold">
							{t("automations.view.configuration.scheduledDaysOfWeek")}
						</h3>
						<p className="text-sm text-muted-foreground">
							{config.daysOfWeek?.join(", ")}
						</p>
					</div>
					<div className="mb-3">
						<h3 className="text-sm font-semibold">
							{t("automations.view.configuration.scheduledTimeWindow")}
						</h3>
						<p className="text-sm text-muted-foreground">
							{config.hourWindow?.start} - {config.hourWindow?.end}
						</p>
					</div>
				</CardContent>
			</Card>
		</div>
	);
}
