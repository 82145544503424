import axios from "axios";
import { AuthContext } from "components/lib";
import { Button } from "components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "components/ui/card";
import { Input } from "components/ui/input";
import { Switch } from "components/ui/switch";
import { TooltipProvider } from "components/ui/tooltip";
import { useUnipileAuth } from "contexts/unipile-auth.context";
import { AnimatePresence, motion } from "framer-motion";
import { useDebounce } from "hooks/use-debounce";
import { List, Users } from "lucide-react";
import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import type { AuthContextType } from "types/authContext";
import type { AudienceMatch } from "types/lead";
import { useLeadTrackingStateV2 } from "views/lead-lens/_hooks/use-lead-tracking-state-v2";
import { useUserLeadsV2 } from "views/lead-lens/_hooks/use-user-leads-v2";
import { useUserPosts } from "../../_hooks/use-user-posts";
import { useLeadLensEvents } from "../../events";
import { leadLensApi } from "../../services/lead-lens-api";
import type { Post } from "../../types";
import { ErrorState } from "../error-state";
import { LeadsPerPostTable } from "../leads-per-post-table";
import { LinkedInConnectDialog } from "../linkedin-connect-dialog";
import { PostLeadsDialog } from "../post-leads-dialog";
import { ActionButtonsV2 } from "./action-buttons-v2";
import { EngagementFilter } from "./lead-table-helpers";
import { MatchFilter } from "./lead-table-helpers";
import { LeadTableV2 } from "./lead-table-v2";

interface LeadTrackingTableProps {
	selectedUserId?: string;
	onLeadFetchFailure?: () => void;
}

interface MessageResponse {
	success: boolean;
	error?: string;
}

export const LeadTrackingTableV2 = ({
	selectedUserId,
	onLeadFetchFailure,
}: LeadTrackingTableProps) => {
	const auth = useContext<AuthContextType>(AuthContext);
	const unipileAuth = useUnipileAuth();
	const state = useLeadTrackingStateV2();
	const events = useLeadLensEvents();
	const { user } = useContext<AuthContextType>(AuthContext);
	const navigate = useNavigate();

	const [audienceIdFilter, setAudienceIdFilter] = useState<number | undefined>(
		undefined,
	);
	const [engagementFilter, setEngagementFilter] = useState<EngagementFilter>(
		EngagementFilter.ALL,
	);
	const [matchFilter, setMatchFilter] = useState<MatchFilter>(MatchFilter.ALL);

	const [postsSortBy, setPostsSortBy] = useState("creation_time");
	const [leadsSortBy, setLeadsSortBy] = useState("createdAt");
	const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");

	const [postSearchTerm, setPostSearchTerm] = useState("");
	const debouncedPostSearchTerm = useDebounce(postSearchTerm, 300);

	const [leadSearchTerm, setLeadSearchTerm] = useState("");
	const debouncedLeadSearchTerm = useDebounce(leadSearchTerm, 300);

	const [showLinkedInConnect, setShowLinkedInConnect] = useState(false);

	const sortBy = state.showLeadsPerPost ? postsSortBy : leadsSortBy;

	// Use selectedUserId if provided, otherwise fallback to current user
	const userIdToUse = selectedUserId || auth?.user?.id;

	const {
		data: postsData,
		loading: postsLoading,
		error: postsError,
		refetch: postsRefetch,
	} = useUserPosts(
		userIdToUse,
		state.page,
		state.limit,
		postsSortBy,
		sortDirection,
		debouncedPostSearchTerm,
	);

	// Memoize the postIds array to prevent unnecessary re-renders
	const memoizedPostIds = useMemo(
		() => postsData?.posts?.map((post) => post.linkedin_id) || [],
		[postsData?.posts],
	);

	const {
		data: leadsData,
		loading: leadsLoading,
		error: leadsError,
		refetch: leadsRefetch,
	} = useUserLeadsV2(userIdToUse, {
		page: state.page,
		limit: state.limit,
		sortBy: leadsSortBy,
		sortDirection,
		searchTerm: debouncedLeadSearchTerm,
		matchFilter: matchFilter,
		audienceId: audienceIdFilter,
		engagementFilter,
	});

	// Track the currently visible page locally instead of relying on the global state
	const [currentPage, setCurrentPage] = useState(1);

	// Ensure local and global state stay in sync
	useEffect(() => {
		if (state.page !== currentPage) {
			state.setPage(currentPage);
		}
	}, [currentPage, state]);

	useEffect(() => {
		// Only check LinkedIn account for the currently authenticated user, not for selected users
		// Also, only check when authentication state changes or on first load
		const checkAccount = async () => {
			// Skip this check if we're viewing another user's data
			if (selectedUserId && selectedUserId !== auth.user?.id) {
				return;
			}

			console.log("[LeadTrackingTable] Estado de autenticación:", {
				userId: auth.user?.id,
				isAuthenticated: unipileAuth.isAuthenticated,
				isLoading: unipileAuth.isLoading,
				error: unipileAuth.error,
			});

			if (!auth.user?.id) {
				console.log("[LeadTrackingTable] No hay usuario autenticado");
				return;
			}

			if (unipileAuth.isLoading) {
				console.log("[LeadTrackingTable] Esperando estado de autenticación");
				return;
			}

			// Only proceed with profile ID check if not authenticated and no error
			if (!unipileAuth.isAuthenticated && !unipileAuth.error) {
				console.log(
					"[LeadTrackingTable] Intentando encontrar cuenta conectada",
				);

				try {
					const profileId = await leadLensApi.getProfileIdByUserId({
						userId: auth.user?.id || "",
					});

					await unipileAuth.findMatchingAccount(profileId.data.urn);
				} catch (error) {
					console.error("[LeadTrackingTable] Error al obtener perfil:", error);
				}
			}
		};

		checkAccount();
	}, [
		auth.user?.id,
		unipileAuth.isAuthenticated,
		unipileAuth.isLoading,
		unipileAuth.error,
		unipileAuth.findMatchingAccount,
		selectedUserId,
	]);

	const handleGenerateIcebreaker = async (linkedinId: string) => {
		try {
			state.setSyncingProfiles((prev) => new Set(prev).add(linkedinId));
			events.trackIcebreakerGeneration({
				linkedin_public_profile_id: Number.parseInt(linkedinId),
				linkedin_profile_id: auth.user?.id || "",
			});
			await leadLensApi.generateIcebreaker({
				linkedinId,
				userId: auth.user?.id || "",
				token: auth.user?.token || "",
			});
			await leadsRefetch();
		} catch (error) {
			console.error("Error generating icebreaker:", error);
		} finally {
			state.setSyncingProfiles((prev) => {
				const newSet = new Set(prev);
				newSet.delete(linkedinId);
				return newSet;
			});
		}
	};

	const handleSendMessage = async (
		linkedinId: string,
		message: string,
	): Promise<void> => {
		if (!auth.user?.id || !auth.user?.token) {
			throw new Error("Usuario no autenticado");
		}

		if (!unipileAuth.accountId) {
			throw new Error("No hay cuenta de LinkedIn conectada");
		}

		try {
			console.log(auth.user.id, "User ID");
			console.log(auth.user.organization_id, "Organization ID");
			console.log(linkedinId, "Linkedin ID");
			const response = await axios.post<MessageResponse>(
				"/api/messaging/send",
				{
					recipientId: linkedinId,
					message,
					accountId: unipileAuth.accountId,
					userId: auth.user.id,
					organizationId: auth.user.organization_id,
				},
			);

			if (!response.data.success) {
				throw new Error(response.data.error || "Error al enviar mensaje");
			}
		} catch (error) {
			console.error("Error al enviar mensaje:", error);
			throw error instanceof Error
				? error
				: new Error("Error desconocido al enviar mensaje");
		}
	};

	const handleSyncLeads = async (postId: string) => {
		try {
			state.addSyncingPost(postId);
			events.trackPostSync({
				linkedin_id: postId,
				content: "", // Add content if available
				last_lead_sync: new Date().toISOString(),
			});
			const result = await leadLensApi.syncPostInteractions({
				postId,
				userId: auth.user?.id || "",
				token: auth.user?.token || "",
			});
			if (result?.data) {
				await postsRefetch();
				await leadsRefetch();
			}
		} catch (error) {
			console.error("Error syncing post interactions:", error);
		} finally {
			state.removeSyncingPost(postId);
		}
	};

	const handleViewToggle = (checked: boolean) => {
		events.trackViewToggle({
			view_type: checked ? "leads_per_post" : "all_leads",
		});
		state.setShowLeadsPerPost(checked);
		state.setPage(1);
		if (checked) {
			setLeadSearchTerm("");
			postsRefetch();
		} else {
			setPostSearchTerm("");
			leadsRefetch();
		}
	};

	const handleRowClick = (item: Post | AudienceMatch) => {
		if ("qualifiedLeads" in item) {
			state.setSelectedPost(item as Post);
		} else if ("profile" in item) {
			const lead = item as AudienceMatch;
			events.trackProfileView({
				linkedin_id: lead.profile.linkedinId,
				first_name: lead.profile.firstName,
				last_name: lead.profile.lastName,
				company: lead.profile.company || "",
				title: lead.profile.title || "",
				icp_score: lead.score || 0,
				is_qualified: false,
			});
			state.setSelectedLead(lead);
		}
	};

	const handlePageSizeChange = (newSize: number) => {
		events.trackPageSizeChange({
			old_size: state.limit,
			new_size: newSize,
			view_type: state.showLeadsPerPost ? "leads_per_post" : "all_leads",
		});
		state.setLimit(newSize);
		state.setPage(1);
	};

	const handleSortChange = (
		newSortBy: string,
		newSortDirection: "asc" | "desc",
	) => {
		// If clicking on the same column, toggle direction
		// If clicking on a new column, use the provided direction (usually 'desc' by default)
		const isNewColumn = sortBy !== newSortBy;
		const direction = isNewColumn ? "desc" : newSortDirection;

		events.trackTableSort({
			sort_by: newSortBy,
			sort_direction: direction,
			view_type: state.showLeadsPerPost ? "leads_per_post" : "all_leads",
		});

		if (state.showLeadsPerPost) {
			setPostsSortBy(newSortBy);
		} else {
			setLeadsSortBy(newSortBy);
		}

		setSortDirection(direction);
		state.setPage(1);

		// Trigger immediate refetch with new sort parameters
		if (state.showLeadsPerPost) {
			postsRefetch();
		} else {
			leadsRefetch();
		}
	};

	// Update filter-related state and refetch data
	const updateFilters = (
		type: string,
		value: EngagementFilter | MatchFilter | "all" | number,
	) => {
		if (type === "audienceId") {
			setAudienceIdFilter(value as number);
		} else if (type === "engagement") {
			setEngagementFilter(value as EngagementFilter);
		} else if (type === "match") {
			setMatchFilter(value as MatchFilter);
		}

		// Reset to first page and refetch when filters change
		if (!state.showLeadsPerPost) {
			state.setPage(1);
			setCurrentPage(1);
			leadsRefetch();
		}
	};

	const handleFilterChange = (
		filterType: string,
		value: EngagementFilter | MatchFilter | "all" | number,
	) => {
		updateFilters(filterType, value);
	};

	// Add search event tracking
	useEffect(() => {
		if (debouncedLeadSearchTerm || debouncedPostSearchTerm) {
			events.trackLeadSearch({
				search_term: debouncedLeadSearchTerm || debouncedPostSearchTerm,
				results_count: state.showLeadsPerPost
					? postsData?.total || 0
					: leadsData?.totalItems || 0,
				view_type: state.showLeadsPerPost ? "leads_per_post" : "all_leads",
			});
		}
	}, [
		debouncedLeadSearchTerm,
		debouncedPostSearchTerm,
		events,
		postsData?.total,
		leadsData?.totalItems,
		state.showLeadsPerPost,
	]);

	// Setup debuggers for pagination state
	useEffect(() => {
		console.log("Current page in LeadTrackingTable:", state.page);
	}, [state.page]);

	const handlePageChange = (newPage: number) => {
		console.log("Page change requested to:", newPage);

		// Update local page state first
		setCurrentPage(newPage);

		events.trackPageSizeChange({
			old_size: state.limit,
			new_size: state.limit,
			view_type: state.showLeadsPerPost ? "leads_per_post" : "all_leads",
		});
	};

	if (postsError || leadsError) {
		// If there's a leads error and we have a callback for fetch failure, call it
		if (leadsError && onLeadFetchFailure) {
			// Call the callback to select first user
			onLeadFetchFailure();
		}

		return (
			<ErrorState
				message={
					postsError?.message || leadsError?.message || "Failed to load data"
				}
				onRetry={() => {
					postsRefetch();
					leadsRefetch();
				}}
			/>
		);
	}

	const leadTableProps = {
		leads: Array.isArray(leadsData?.leads)
			? (leadsData?.leads as AudienceMatch[])
			: [],
		onLeadClick: handleRowClick,
		onGenerateIcebreaker: handleGenerateIcebreaker,
		onSendMessage: handleSendMessage,
		syncingProfiles: state.syncingProfiles,
		currentPage: currentPage,
		totalPages: leadsData?.totalPages || 1,
		onPageChange: handlePageChange,
		pageSize: state.limit,
		onPageSizeChange: handlePageSizeChange,
		totalItems: leadsData?.totalItems || 0,
		sortBy: sortBy,
		sortDirection: sortDirection,
		onSortChange: handleSortChange,
		engagementFilter: engagementFilter,
		matchFilter: matchFilter,
		audienceId: audienceIdFilter,
		onFilterChange: handleFilterChange,
		isLoading: leadsLoading,
		error: leadsError,
		onRetry: leadsRefetch,
	};

	return (
		<TooltipProvider>
			<Card className="bg-white">
				<CardHeader>
					<div className="flex justify-between items-center">
						<Input
							type="text"
							placeholder={
								state.showLeadsPerPost ? "Search posts..." : "Search leads..."
							}
							value={state.showLeadsPerPost ? postSearchTerm : leadSearchTerm}
							onChange={(e) => {
								if (state.showLeadsPerPost) {
									setPostSearchTerm(e.target.value);
								} else {
									setLeadSearchTerm(e.target.value);
								}
							}}
							className="w-[400px] transition-all duration-200 focus:ring-2 focus:ring-blue-500"
						/>
						<div className="flex items-center gap-4">
							<div className="flex items-center gap-2">
								<div
									className={`flex items-center gap-1 ${!state.showLeadsPerPost ? "text-blue-600" : "text-gray-500"}`}
								/>
							</div>
							<div className="flex flex-col items-center justify-center">
								<Button
									className=""
									onClick={() => {
										navigate("/icp/audiences");
									}}
								>
									Configuración de Audiencia
								</Button>
							</div>
							<ActionButtonsV2
								selectedUserId={selectedUserId}
								postIds={memoizedPostIds}
								userName={auth.user?.email || ""}
								onRefresh={() => {
									if (state.showLeadsPerPost) {
										postsRefetch();
									} else {
										leadsRefetch();
									}
								}}
								onExportCsv={() => {
									// TODO: Implement CSV export
									console.log("Export CSV");
								}}
								onOpenDialog={() => {
									// TODO: Implement dialog open
									console.log("Open dialog");
								}}
								authToken={auth.user?.token}
							/>
						</div>
					</div>
				</CardHeader>

				<CardContent>
					<motion.div
						className="rounded-md border"
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						transition={{ duration: 0.3 }}
					>
						<AnimatePresence mode="wait">
							{state.showLeadsPerPost ? (
								<motion.div
									key="posts"
									initial={{ opacity: 0, x: -20 }}
									animate={{ opacity: 1, x: 0 }}
									exit={{ opacity: 0, x: 20 }}
									transition={{ duration: 0.2 }}
								>
									<LeadsPerPostTable
										posts={postsData?.posts || []}
										onPostClick={handleRowClick}
										onSyncLeads={handleSyncLeads}
										currentPage={currentPage}
										totalPages={postsData?.totalPages || 1}
										totalItems={postsData?.totalItems || 0}
										onPageChange={handlePageChange}
										pageSize={state.limit}
										onPageSizeChange={handlePageSizeChange}
										syncingPosts={state.syncingPosts}
										sortBy={sortBy}
										sortDirection={sortDirection}
										onSortChange={handleSortChange}
										isLoading={postsLoading}
									/>
								</motion.div>
							) : (
								<motion.div
									key="leads"
									initial={{ opacity: 0, x: 20 }}
									animate={{ opacity: 1, x: 0 }}
									exit={{ opacity: 0, x: -20 }}
									transition={{ duration: 0.2 }}
								>
									<LeadTableV2 {...leadTableProps} />
								</motion.div>
							)}
						</AnimatePresence>
					</motion.div>
				</CardContent>

				<AnimatePresence>
					{state.selectedPost && (
						<motion.div
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							exit={{ opacity: 0 }}
							transition={{ duration: 0.2 }}
						>
							<PostLeadsDialog
								post={state.selectedPost}
								onGenerateIcebreaker={handleGenerateIcebreaker}
								onClose={() => state.setSelectedPost(null)}
								refetchPosts={postsRefetch}
								syncingProfiles={state.syncingProfiles}
								setSyncingProfiles={state.setSyncingProfiles}
								selectedUserId={userIdToUse}
							/>
						</motion.div>
					)}
				</AnimatePresence>

				<AnimatePresence>
					{showLinkedInConnect && (
						<motion.div
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							exit={{ opacity: 0 }}
							transition={{ duration: 0.2 }}
						>
							<LinkedInConnectDialog
								isOpen={showLinkedInConnect}
								onClose={() => setShowLinkedInConnect(false)}
							/>
						</motion.div>
					)}
				</AnimatePresence>
			</Card>
		</TooltipProvider>
	);
};
