import { Button } from "components/ui/button.tsx";
import { EmptyCard } from "components/ui/empty-card.tsx";
import { Loader2, PlayIcon, Settings2Icon } from "lucide-react";

export interface EmptyAutomationProps {
	t: (key: string) => string;
	onRunAutomation: () => void;
	isRunAutomationLoading: boolean;
}

export function EmptyAutomation(props: EmptyAutomationProps) {
	const { t, onRunAutomation } = props;
	return (
		<EmptyCard
			title={t("automations.view.modal.messages.table.empty.title")}
			description={t("automations.view.modal.messages.table.empty.description")}
			icon={Settings2Icon}
			className={"h-[300px]"}
			action={
				<Button onClick={onRunAutomation} className="mt-4">
					{props.isRunAutomationLoading ? (
						<Loader2 className="mr-2 h-4 w-4 animate-spin" />
					) : (
						<PlayIcon className="mr-2 h-4 w-4" />
					)}
					{t("automations.view.modal.messages.table.empty.button")}
				</Button>
			}
		/>
	);
}
