import { useQueryClient } from "@tanstack/react-query";
import { createAutomation } from "api/automation";
import { useAxiosMutation } from "hooks/useAxiosMutation";
import type { CreateAutomationPayload } from "types/automations";

export const useCreateAutomation = (organization_id: string) => {
	const queryClient = useQueryClient();

	const mutation = useAxiosMutation({
		mutationFn: (data: CreateAutomationPayload) =>
			createAutomation({
				...data,
				organizationId: organization_id,
			}),
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ["automations"],
			});
		},
	});

	return {
		createAutomation: mutation.mutateAsync,
		isCreating: mutation.isPending,
		error: mutation.error,
	};
};
