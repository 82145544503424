import { useQuery } from "@tanstack/react-query";
import { getAutomation, getAutomationMessages } from "api/automation.ts";
import { getLinkedinInvitesByAutomationId } from "api/linkedin-invites";

export const useAutomation = (automationId: number) => {
	const {
		data: automation,
		isFetching,
		error,
	} = useQuery({
		queryKey: ["automation", automationId],
		queryFn: async () => {
			const automationResponse = await getAutomation(automationId);
			if (automationResponse.error) {
				throw new Error("Failed fetching automation");
			}
			const automationMessagesResponse =
				await getAutomationMessages(automationId);
			if (automationMessagesResponse.error) {
				throw new Error("Failed fetching automation messages");
			}
			const automationInvitesResponse =
				await getLinkedinInvitesByAutomationId(automationId);
			if (automationInvitesResponse.error) {
				throw new Error("Failed fetching automation invites");
			}
			return {
				...automationResponse.data,
				messages: automationMessagesResponse.data,
				invites: automationInvitesResponse.data,
			};
		},
	});

	return {
		automation: automation ?? null,
		loadingAutomation: isFetching,
		error,
	};
};
