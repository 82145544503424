import type { AudienceFormValues } from "../schemas/audience-schemas";
import type { Audience } from "../types/audience";

export const convertToNumber = (value: string | undefined) =>
	value ? Number(value) : undefined;

export const getAudienceFormValues = (
	audienceToEdit?: Audience | null,
): AudienceFormValues => {
	return {
		name: audienceToEdit?.name || "",
		organization_icp_id: audienceToEdit?.organization_icp_id?.toString() || "",
		targeting_criteria: {
			description: audienceToEdit?.targeting_criteria.description || "",
			location: audienceToEdit?.targeting_criteria.location || "",
			currentRole: audienceToEdit?.targeting_criteria.currentRole || "",
			education: audienceToEdit?.targeting_criteria.education || "",
			languages: audienceToEdit?.targeting_criteria.languages || "",
			minFollowerCount:
				audienceToEdit?.targeting_criteria.minFollowerCount?.toString() || "",
			maxFollowerCount:
				audienceToEdit?.targeting_criteria.maxFollowerCount?.toString() || "",
			skills: audienceToEdit?.targeting_criteria.skills || "",
			postsTopics: audienceToEdit?.targeting_criteria.postsTopics || "",
			minAverageInteractionPerPostCount:
				audienceToEdit?.targeting_criteria.minAverageInteractionPerPostCount?.toString() ||
				"",
			maxAverageInteractionPerPostCount:
				audienceToEdit?.targeting_criteria.maxAverageInteractionPerPostCount?.toString() ||
				"",
		},
	};
};

export const convertAudienceFormToPayload = (data: AudienceFormValues) => {
	console.log("data", data);
	return {
		name: data.name,
		organization_icp_id: data.organization_icp_id
			? Number(data.organization_icp_id)
			: null,
		targeting_criteria: {
			description: data.targeting_criteria.description,
			location: data.targeting_criteria.location,
			currentRole: data.targeting_criteria.currentRole,
			education: data.targeting_criteria.education,
			languages: data.targeting_criteria.languages,
			minFollowerCount: convertToNumber(
				data.targeting_criteria.minFollowerCount,
			),
			maxFollowerCount: convertToNumber(
				data.targeting_criteria.maxFollowerCount,
			),
			skills: data.targeting_criteria.skills,
			postsTopics: data.targeting_criteria.postsTopics,
			minAverageInteractionPerPostCount: convertToNumber(
				data.targeting_criteria.minAverageInteractionPerPostCount,
			),
			maxAverageInteractionPerPostCount: convertToNumber(
				data.targeting_criteria.maxAverageInteractionPerPostCount,
			),
		},
	};
};

export const hasAdvancedAudienceFields = (audience: Audience): boolean => {
	const tc = audience.targeting_criteria;
	return !!(
		tc.minFollowerCount ||
		tc.maxFollowerCount ||
		tc.skills ||
		tc.minAverageInteractionPerPostCount ||
		tc.maxAverageInteractionPerPostCount
	);
};
