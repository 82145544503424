import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Animate, AuthContext } from "components/lib";
import { Badge } from "components/ui/badge";
import { Button } from "components/ui/button";
import { Card } from "components/ui/card";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectLabel,
	SelectTrigger,
	SelectValue,
} from "components/ui/select";
import { UnipileAuthProvider } from "contexts/unipile-auth.context";
import { Download } from "lucide-react";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import type { AuthContextType } from "types/authContext";
import { appConfig } from "views/content-creation/config";
import { SkeletonSelect } from "views/dashboard/_components/skeleton-select";
import {
	User,
	useOrganizationUsers,
} from "views/stats/_hooks/use-organization-users";
import { LeadTrackingTable } from "./_components/lead-tracking-table";
import { useLeadLensEvents } from "./events";

interface OrganizationUser {
	id: string;
	name?: string;
	email?: string;
}

// Direct API call to fetch organization users as a fallback
const fetchOrganizationUsers = async (
	organizationId: string | undefined,
): Promise<OrganizationUser[]> => {
	if (!organizationId) {
		return [];
	}

	try {
		const { data } = await axios.get<{ data: OrganizationUser[] }>(
			`/api/organization/${organizationId}/users`,
		);
		return data.data || [];
	} catch (error) {
		console.error("Error fetching organization users:", error);
		return [];
	}
};

// Function to download CSV of all leads for a user
const downloadLeadsCSV = async (
	userId: string,
	userName: string,
	authToken: string,
) => {
	try {
		if (!authToken) {
			console.error("No auth token found");
			return;
		}

		// Show loading state by returning a promise
		const response = await axios.get(
			`${appConfig.API_MAIN_URL}/api/lead-lens/leads/${userId}/export`,
			{
				headers: {
					Authorization: `Bearer ${authToken}`,
				},
				responseType: "blob",
			},
		);

		// Create a blob from the response
		const blob = new Blob([response.data], { type: "text/csv" });

		// Create a link element to download the file
		const url = window.URL.createObjectURL(blob);
		const link = document.createElement("a");
		link.href = url;
		link.setAttribute("download", `${userName.replace(/\s+/g, "_")}_leads.csv`);
		document.body.appendChild(link);
		link.click();

		// Cleanup
		window.URL.revokeObjectURL(url);
		document.body.removeChild(link);
	} catch (error) {
		console.error("Error downloading leads CSV:", error);
		alert("Failed to download leads CSV. Please try again later.");
	}
};

export const LeadLens = () => {
	const auth: AuthContextType = useContext(AuthContext);
	const events = useLeadLensEvents();
	const [selectedUserId, setSelectedUserId] = useState<string | undefined>(
		auth?.user?.id,
	);
	const organizationId = auth?.user?.organization_id;

	// Calculate date range for last 30 days
	const dateRange = useMemo(() => {
		const endDate = new Date();
		const startDate = new Date();
		startDate.setDate(startDate.getDate() - 30);
		return {
			startDate: startDate.toISOString(),
			endDate: endDate.toISOString(),
		};
	}, []);

	const { data: fallbackUsers, isLoading: isLoadingFallbackUsers } = useQuery({
		queryKey: ["organizationUsers", organizationId],
		queryFn: () => fetchOrganizationUsers(organizationId),
		enabled: !!organizationId,
		staleTime: 5 * 60 * 1000, // 5 minutes
	});

	// Combine results from both sources
	const users = useMemo(() => {
		if (fallbackUsers && fallbackUsers.length > 0) {
			// Transform fallback users to match the expected format
			return fallbackUsers.map((user: OrganizationUser) => ({
				user_id: user.id,
				user_name: user.name || user.email || user.id,
				linkedin: {},
				wordpress: {},
			}));
		}

		return [];
	}, [fallbackUsers]);

	const loading = isLoadingFallbackUsers;

	useEffect(() => {
		if (auth?.user) {
			events.trackPageView({
				user_id: auth.user.id,
				source: window.location.pathname,
			});
		}
	}, [auth?.user, events]);

	const handleUserChange = (newUserId: string) => {
		setSelectedUserId(newUserId);
	};

	// Handle lead fetch failure by selecting the first user in the list
	const handleLeadFetchFailure = useCallback(() => {
		// Only proceed if we have users and the current selection is not working
		if (users.length > 0 && selectedUserId) {
			const firstUserId = users[0].user_id;
			// Only change if we're not already on the first user
			if (firstUserId !== selectedUserId) {
				console.log(
					"Lead fetch failed, selecting first user:",
					users[0].user_name,
				);
				setSelectedUserId(firstUserId);
			}
		}
	}, [users, selectedUserId]);

	if (!auth?.user) return null;

	// Show debug information
	console.log("Users data:", {
		fallbackUsers: fallbackUsers || "none",
		combinedUsers: users.length,
		loading,
	});

	return (
		<Animate type="pop">
			<div className="mx-auto max-w-6xl py-6 space-y-4">
				<Card className="p-6 bg-white">
					<div className="flex justify-between items-center">
						{selectedUserId && selectedUserId !== auth?.user?.id && (
							<Badge
								variant="outline"
								className="text-amber-600 border-amber-600"
							>
								Viewing as{" "}
								{users.find((user) => user.user_id === selectedUserId)
									?.user_name || "another user"}
							</Badge>
						)}
						<div
							className={`flex-shrink-0 ${selectedUserId && selectedUserId !== auth?.user?.id ? "" : "ml-auto"}`}
						>
							{loading ? (
								<SkeletonSelect />
							) : (
								<div className="flex-shrink-0 flex items-center gap-2">
									<Select
										value={selectedUserId}
										onValueChange={handleUserChange}
										disabled={users.length === 0}
									>
										<SelectTrigger className="max-w-60 bg-white">
											<SelectValue>
												{users.find((user) => user.user_id === selectedUserId)
													?.user_name || "Select User"}
											</SelectValue>
										</SelectTrigger>
										<SelectContent>
											<SelectGroup>
												<SelectLabel>Users</SelectLabel>
												{users.map((user) => (
													<SelectItem key={user.user_id} value={user.user_id}>
														{user.user_name}
													</SelectItem>
												))}
											</SelectGroup>
										</SelectContent>
									</Select>

									{selectedUserId && (
										<Button
											variant="outline"
											size="sm"
											className="flex items-center gap-1"
											onClick={() => {
												if (!auth?.user?.token) {
													alert("You need to be logged in to download leads");
													return;
												}
												const selectedUser = users.find(
													(user) => user.user_id === selectedUserId,
												);
												const userName = selectedUser?.user_name || "User";
												downloadLeadsCSV(
													selectedUserId,
													userName,
													auth.user.token,
												);
											}}
										>
											<Download className="h-4 w-4" />
											<span>Export CSV</span>
										</Button>
									)}
								</div>
							)}
						</div>
					</div>
				</Card>
				<UnipileAuthProvider>
					<LeadTrackingTable
						selectedUserId={selectedUserId}
						onLeadFetchFailure={handleLeadFetchFailure}
					/>
				</UnipileAuthProvider>
			</div>
		</Animate>
	);
};
