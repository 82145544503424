/***
 *
 *   PASSWORD
 *   Update the users password
 *
 *   if the user has a password show old and new inputs
 *   if no existing password (eg. in case of social signin)
 *   then allow the user to set a password on their organization
 *
 **********/

import {
	Animate,
	AuthContext,
	Card,
	Form,
	Message,
	OrganizationNav,
} from "components/lib";
import { Fragment, useContext, useState } from "react";

export function Password(props) {
	const context = useContext(AuthContext);
	const [done, setDone] = useState(false);

	return (
		<Fragment>
			<OrganizationNav />
			<Animate>
				<Card
					title={props.t("organization.password.subtitle")}
					restrictWidth
					className={props.className}
				>
					{done ? (
						<Message
							type="success"
							title={props.t("organization.password.success_message.title")}
							text={props.t("organization.password.success_message.text")}
						/>
					) : (
						<Form
							url="/api/user/password"
							method="PUT"
							buttonText={props.t("organization.password.form.button")}
							inputs={{
								...(context.user?.has_password && {
									oldpassword: {
										label: props.t(
											"organization.password.form.old_password.label",
										),
										type: "password",
										required: true,
									},
									has_password: {
										type: "hidden",
										value: true,
									},
								}),
								newpassword: {
									label: props.t(
										"organization.password.form.new_password.label",
									),
									type: "password",
									required: true,
									complexPassword: true,
								},
							}}
							callback={() => {
								setDone(true);
								context.update({ has_password: true });
							}}
						/>
					)}
				</Card>
			</Animate>
		</Fragment>
	);
}
