import {
	Card,
	CardContent,
	CardDescription,
	CardHeader,
	CardTitle,
} from "components/ui/card";
import {
	ChartContainer,
	ChartTooltip,
	ChartTooltipContent,
} from "components/ui/chart";
import { MoveRight, TrendingDown, TrendingUp } from "lucide-react";
import type React from "react";
import { useMemo } from "react";
import { Label, Pie, PieChart } from "recharts";
import {
	calculateTrend,
	formatNumber,
	generateBlueHues,
} from "views/dashboard/utils";
import { emptyChartData, generateChartConfig } from "./metric-pie-chart";
import type { LeadsMetrics } from "./types";

export interface LeadsByAudiencePieChartProps {
	metrics: LeadsMetrics;
}

interface ChartData {
	name: string;
	value: number;
	fill: string;
}

function calculateChartLeadsData(metrics: LeadsMetrics): ChartData[] {
	const TOP_N = 5;
	const hues = generateBlueHues(TOP_N);

	// Sort audiences by leads count in descending order
	const sortedAudiences = [...metrics.leadsByAudience].sort(
		(a, b) => b.leadsCount - a.leadsCount,
	);

	// Get top N audiences
	const topEntries = sortedAudiences.slice(0, TOP_N).map((audience, index) => ({
		name: audience.audienceName,
		value: audience.leadsCount,
		fill: `hsl(${hues[index]}, 70%, 50%)`,
	}));

	// If there are more than TOP_N audiences, combine the rest into an "Others" category
	if (sortedAudiences.length > TOP_N) {
		const othersAudiences = sortedAudiences.slice(TOP_N);
		const othersValue = othersAudiences.reduce(
			(sum, audience) => sum + audience.leadsCount,
			0,
		);

		const othersEntry = {
			name: "Others",
			value: othersValue,
			fill: "hsl(215, 25%, 35%)",
		};

		return [...topEntries, othersEntry];
	}

	return topEntries;
}

export function LeadsByAudiencePieChart(props: LeadsByAudiencePieChartProps) {
	const { metrics } = props;

	console.log("leads metrics are", metrics);
	const chartData = useMemo(() => calculateChartLeadsData(metrics), [metrics]);
	const total = metrics.leadsByAudience.reduce(
		(acc, lead) => acc + lead.leadsCount,
		0,
	);

	const chartConfig = generateChartConfig("Leads");
	const trend = Math.round(calculateTrend(total, metrics.previousPeriodLeads));
	const trendIcon =
		trend > 0 ? (
			<TrendingUp className="h-4 w-4 text-green-500" />
		) : trend < 0 ? (
			<TrendingDown className="h-4 w-4 text-red-500" />
		) : (
			<MoveRight className="h-4 w-4" />
		);

	return (
		<Card className="flex flex-col bg-white w-[220px]">
			<CardHeader className="items-center pb-0">
				<CardTitle className="text-lg">Leads</CardTitle>
				<CardDescription>
					<div className="flex items-center gap-2 font-medium leading-none whitespace-nowrap text-sm">
						{metrics.previousPeriodLeads === 0 ? (
							<>
								<span>All leads are from this period</span>
							</>
						) : trend !== 0 ? (
							<>
								<span>{trendIcon}</span>
								<span
									className={`font-bold ${trend > 0 ? "text-green-500" : "text-red-500"}`}
								>
									{trend}%
								</span>
								<span>this period</span>
							</>
						) : (
							<>No change during this period</>
						)}
					</div>
				</CardDescription>
			</CardHeader>
			<CardContent className="flex-1 py-2">
				<ChartContainer config={chartConfig}>
					<PieChart>
						{total && (
							<ChartTooltip
								cursor={false}
								content={
									<ChartTooltipContent
										hideLabel
										formatter={(value, name, item, index) => (
											<>
												<div
													className="h-2.5 w-2.5 shrink-0 rounded-[2px] bg-[--color-bg]"
													style={
														{
															"--color-bg": `${item.payload.fill}`,
														} as React.CSSProperties
													}
												/>
												{chartConfig[name as keyof typeof chartConfig]?.label ||
													name}
												<div className="ml-auto flex items-baseline gap-0.5 font-mono font-medium tabular-nums text-foreground">
													{formatNumber(Number(value))}
												</div>
											</>
										)}
									/>
								}
							/>
						)}
						<Pie
							data={total === 0 ? emptyChartData : chartData}
							dataKey="value"
							nameKey="name"
							innerRadius={45}
							outerRadius={60}
							strokeWidth={4}
							startAngle={180}
							endAngle={0}
							paddingAngle={2}
							animationBegin={250}
							cy={80}
						>
							<Label
								content={({ viewBox }) => {
									if (viewBox && "cx" in viewBox && "cy" in viewBox) {
										return (
											<text
												x={viewBox.cx}
												y={viewBox.cy}
												textAnchor="middle"
												dominantBaseline="middle"
											>
												<tspan
													x={viewBox.cx}
													y={(viewBox.cy || 0) - 15}
													className="fill-foreground text-2xl font-bold"
												>
													{formatNumber(total)}
												</tspan>
												<tspan
													x={viewBox.cx}
													y={viewBox.cy || 0}
													className="fill-muted-foreground text-xs"
												>
													Leads
												</tspan>
											</text>
										);
									}
								}}
							/>
						</Pie>
					</PieChart>
				</ChartContainer>
			</CardContent>
		</Card>
	);
}
