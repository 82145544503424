import { getOrganizationUsers } from "api/organization";
import { useAxiosQuery } from "hooks/useAxiosQuery";

// TODO: type this when we stop using the old linkedin profile
export const useOrganizationUsers = (organization_id: string) => {
	const {
		data: users,
		isFetching,
		error,
	} = useAxiosQuery({
		queryKey: ["users"],
		queryFn: () => getOrganizationUsers(organization_id),
		enabled: !!organization_id,
	});

	return {
		users: users ?? [],
		isFetching,
		error,
	};
};
