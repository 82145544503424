import { AuthContext, Image, ViewContext } from "components/lib";
import { Avatar, AvatarFallback, AvatarImage } from "components/ui/avatar";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuGroup,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import { Input } from "components/ui/input";
import { useOldLinkedinProfile } from "hooks/use-old-linkedin-profile";
import { cn } from "lib/utils";
import {
	Bell,
	Building,
	Check,
	ChevronsUpDown,
	CreditCard,
	HelpCircle,
	Lock,
	LogOut,
	Search,
	Settings,
	Shield,
	Sliders,
	User as UserIcon,
} from "lucide-react";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import type { AuthContextType } from "types/authContext";

import {
	SidebarMenu,
	SidebarMenuButton,
	SidebarMenuItem,
	useSidebar,
} from "components/ui/sidebar";
import { Skeleton } from "components/ui/skeleton";

interface UserProps {
	className?: string;
}

interface ConfigItem {
	path: string;
	label: string;
	icon: React.ReactNode;
	permissionRequired?: string | string[];
}

export function User({ className }: UserProps = {}): React.ReactElement {
	const { t } = useTranslation();
	const authContext = useContext<AuthContextType>(AuthContext);
	// biome-ignore lint/suspicious/noExplicitAny: <we should later define view context type>
	const viewContext: any = useContext(ViewContext);
	const navigate = useNavigate();
	const { isMobile } = useSidebar();
	const [open, setOpen] = useState<boolean>(false);
	const [searchQuery, setSearchQuery] = useState<string>("");
	const { profile, isFetching: isFetchingProfile } = useOldLinkedinProfile(
		authContext.user?.id,
	);
	const isOwnerOrAdmin =
		authContext.user?.permission === "owner" ||
		authContext.user?.permission === "admin";

	// Configuration menu items
	const configItems: ConfigItem[] = [
		{
			path: "/organization/profile",
			label: t("organization.profile.title", "Profile"),
			icon: <UserIcon className="h-4 w-4" />,
		},
		{
			path: "/organization/password",
			label: t("organization.password.title", "Password"),
			icon: <Lock className="h-4 w-4" />,
		},
		{
			path: "/organization/2fa",
			label: t("organization.2fa.title", "Two-Factor Auth"),
			icon: <Shield className="h-4 w-4" />,
		},
		{
			path: "/organization/notifications",
			label: t("organization.notifications.title", "Notifications"),
			icon: <Bell className="h-4 w-4" />,
		},
		{
			path: "/organization/user-settings",
			label: t("organization.user_settings.title", "User Settings"),
			icon: <Sliders className="h-4 w-4" />,
		},
	];

	const currentOrganization = authContext.user?.organizations?.find(
		(x) => x.id === authContext.user?.organization_id,
	);

	const filteredOrganizations =
		authContext.user?.organizations
			?.filter((org) =>
				org.name.toLowerCase().includes(searchQuery.toLowerCase()),
			)
			.slice(0, 5) || [];

	const handleOrganizationChange = (organizationId: string): void => {
		viewContext.setLoading(true);
		authContext.switchOrganization(organizationId);
		setOpen(false);
	};

	const handleSignout = async (): Promise<void> => {
		viewContext.setLoading(true);
		try {
			const urlToSignin = await authContext.signout();
			navigate(urlToSignin);
		} catch (error) {
			viewContext.handleError(error);
		} finally {
			viewContext.setLoading(false);
		}
	};

	// Helper function to check if user has required permission
	const hasPermission = (required?: string | string[]): boolean => {
		if (!required) return true;

		if (Array.isArray(required)) {
			return required.includes(authContext.user?.permission || "");
		}

		return authContext.user?.permission === required;
	};

	return (
		<SidebarMenu className={className}>
			<SidebarMenuItem>
				<DropdownMenu open={open} onOpenChange={setOpen}>
					<DropdownMenuTrigger asChild>
						<SidebarMenuButton
							size="lg"
							className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
						>
							<Avatar className="h-8 w-8 rounded-lg">
								<AvatarImage
									src={profile?.profile_picture}
									alt={profile?.name || authContext.user?.name}
								/>
								<AvatarFallback>
									{profile?.name?.slice(0, 2).toUpperCase() ||
										authContext.user?.name?.slice(0, 2).toUpperCase()}
								</AvatarFallback>
							</Avatar>

							<div className="grid flex-1 text-left text-sm leading-tight">
								<span className="truncate font-semibold">
									{profile?.name || authContext.user?.name}
								</span>
								{currentOrganization && (
									<span className="truncate text-xs text-slate-500 dark:text-slate-400">
										{currentOrganization.name}
									</span>
								)}
							</div>
							{authContext.user?.organizations &&
								authContext.user.organizations.length > 1 && (
									<ChevronsUpDown className="ml-auto h-4 w-4 opacity-50" />
								)}
						</SidebarMenuButton>
					</DropdownMenuTrigger>
					<DropdownMenuContent
						className="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg"
						side={isMobile ? "bottom" : "right"}
						align="end"
						sideOffset={4}
					>
						<DropdownMenuLabel className="p-0 font-normal">
							<div className="flex items-center gap-2 px-1 py-1.5 text-left text-sm">
								<Avatar className="h-8 w-8 rounded-lg">
									<AvatarImage
										src={profile?.profile_picture}
										alt={profile?.name || authContext.user?.name}
									/>
									<AvatarFallback>
										{profile?.name?.[0] || authContext.user?.name?.[0]}
									</AvatarFallback>
								</Avatar>
								<div className="grid flex-1 text-left text-sm leading-tight">
									<span className="truncate font-semibold">
										{profile?.name || authContext.user?.name}
									</span>

									<span className="truncate text-xs text-slate-500 dark:text-slate-400">
										{authContext.user?.email}
									</span>
								</div>
							</div>
						</DropdownMenuLabel>
						<DropdownMenuSeparator />

						{/* User Configuration Options */}
						<DropdownMenuGroup>
							{configItems.map(
								(item) =>
									hasPermission(item.permissionRequired) && (
										<DropdownMenuItem
											key={item.path}
											className="flex items-center gap-2 px-2 py-1.5 text-sm cursor-pointer"
											onClick={() => navigate(item.path)}
										>
											{item.icon}
											{item.label}
										</DropdownMenuItem>
									),
							)}
						</DropdownMenuGroup>
						<DropdownMenuSeparator />

						{/* Organizations Section */}
						{authContext.user?.organizations &&
							authContext.user.organizations.length > 1 && (
								<>
									<DropdownMenuGroup>
										<DropdownMenuLabel>My Organizations</DropdownMenuLabel>
										<div className="px-2 py-1.5">
											<Input
												prefixIcon={<Search className="h-4 w-4" />}
												placeholder="Search organization..."
												value={searchQuery}
												onChange={(e) => setSearchQuery(e.target.value)}
												className="h-8"
												onClick={(e) => e.stopPropagation()}
												onKeyDown={(e) => {
													e.stopPropagation();
												}}
											/>
										</div>
										<DropdownMenuSeparator />
										{filteredOrganizations.length === 0 ? (
											<DropdownMenuItem disabled>
												No organization found.
											</DropdownMenuItem>
										) : (
											filteredOrganizations.map((organization) => (
												<DropdownMenuItem
													key={organization.id}
													onClick={() =>
														handleOrganizationChange(organization.id)
													}
													className="flex items-center gap-2 px-2 py-1.5 text-sm cursor-pointer"
												>
													<div className="flex h-6 w-6 items-center justify-center rounded-md border bg-sidebar-accent">
														<Building className="h-3 w-3" />
													</div>
													<span>{organization.name}</span>
													{organization.id ===
														authContext.user?.organization_id && (
														<Check className="ml-auto h-4 w-4 text-green-500" />
													)}
												</DropdownMenuItem>
											))
										)}
									</DropdownMenuGroup>
									<DropdownMenuSeparator />
								</>
							)}

						{/* Logout Option */}
						<DropdownMenuItem
							className="flex items-center gap-2 px-2 py-1.5 text-sm cursor-pointer"
							onClick={handleSignout}
						>
							<LogOut className="h-4 w-4" />
							{t("nav.signout")}
						</DropdownMenuItem>
					</DropdownMenuContent>
				</DropdownMenu>
			</SidebarMenuItem>
		</SidebarMenu>
	);
}
