import { Button } from "components/ui/button";
import { TableCell, TableRow } from "components/ui/table";
import { AlertCircle, Database, FileX } from "lucide-react";
import { useNavigate } from "react-router-dom";

interface EmptyStateProps {
	error: Error | null;
	isEmpty: boolean;
	errorMessage?: string;
	emptyMessage?: string;
	onRetry?: () => void;
}

export const LeadTableEmptyState = ({
	error,
	isEmpty,
	errorMessage = "Ha ocurrido un error al cargar los datos.",
	emptyMessage = "No hay leads que coincidan con los criterios actuales.",
	onRetry,
}: EmptyStateProps) => {
	const isError = error !== null;

	const navigate = useNavigate();

	return (
		<TableRow>
			<TableCell colSpan={8} className="h-64 text-center">
				<div className="flex flex-col items-center justify-center gap-4 py-10">
					{isError ? (
						<div className="rounded-full bg-red-100 p-3">
							<AlertCircle className="h-10 w-10 text-red-600" />
						</div>
					) : isEmpty ? (
						<div className="rounded-full bg-gray-100 p-3">
							<FileX className="h-10 w-10 text-gray-500" />
						</div>
					) : (
						<div className="rounded-full bg-gray-100 p-3">
							<Database className="h-10 w-10 text-gray-500" />
						</div>
					)}

					<div className="space-y-2">
						<h3 className="text-xl font-semibold">
							{isError ? "Error de carga" : "No hay datos disponibles"}
						</h3>
						<p className="text-gray-500 max-w-md">
							{isError ? `${errorMessage} ${error?.message}` : emptyMessage}
						</p>
					</div>

					{isError && onRetry && (
						<Button onClick={onRetry} variant="outline" className="mt-4">
							Intentar nuevamente
						</Button>
					)}
					<div className="flex flex-col items-center justify-center">
						<Button
							className=""
							onClick={() => {
								navigate("/icp/audiences");
							}}
						>
							Configuración de Audiencia
						</Button>
					</div>
				</div>
			</TableCell>
		</TableRow>
	);
};
