import { useMutation, useQueryClient } from "@tanstack/react-query";
import { cancelAutomationMessage } from "api/automation";
import { useToast } from "components/hooks/use-toast";
import { useState } from "react";

export const useCancelAutomationMessage = (automationId: number) => {
	const [loading, setLoading] = useState<boolean>(false);
	const queryClient = useQueryClient();
	const { toast } = useToast();

	const mutation = useMutation({
		mutationFn: async (messageId: number) => {
			try {
				setLoading(true);
				const response = await cancelAutomationMessage(messageId);
				if (response.error) {
					throw new Error("Failed to delete message");
				}
				return response;
			} catch (error) {
				console.log(error);
				toast({
					title: "Error",
					description: "Failed to cancel message",
					variant: "destructive",
				});
			} finally {
				setLoading(false);
			}
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ["automation", automationId] });
		},
	});

	return {
		cancelMessage: async (messageId: number) => {
			await mutation.mutateAsync(messageId);
		},
		loading,
	};
};
