import { useQueryClient } from "@tanstack/react-query";
import { updateAudience } from "api/audience";
import { useAxiosMutation } from "hooks/useAxiosMutation";
import type { UpdateAudiencePayload } from "types/audience";

export const useUpdateAudience = () => {
	const queryClient = useQueryClient();

	const mutation = useAxiosMutation({
		mutationFn: (params: { id: number; data: UpdateAudiencePayload }) =>
			updateAudience(params.id, params.data),
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ["audiences"],
			});
		},
	});

	return {
		updateAudience: (id: number, data: UpdateAudiencePayload) =>
			mutation.mutateAsync({ id, data }),
		isUpdating: mutation.isPending,
		error: mutation.error,
	};
};
