import { useMutation } from "@tanstack/react-query";
import { runAutomation } from "api/automation.ts";
import { useToast } from "components/hooks/use-toast.ts";

export interface UseRunAutomationResult {
	onRunAutomation: (automationId: number) => Promise<void>;
	isRunAutomationLoading: number | undefined;
}

export const useRunAutomation = (): UseRunAutomationResult => {
	const { toast } = useToast();

	const mutation = useMutation({
		mutationFn: async (automationId: number) => {
			const apiResponse = await runAutomation(automationId);
			console.log("after run automation", apiResponse);
			if (apiResponse.error) {
				throw new Error(apiResponse.errorCode ?? "Failed to run automation");
			}
			return apiResponse;
		},
		onSuccess: () => {
			toast({
				title: "Automation run successfully",
				description: "Automation was run with success!",
				variant: "default",
			});
		},
		onError: () => {
			toast({
				title: "Error",
				description: "Failed to run automation",
				variant: "destructive",
			});
		},
	});

	return {
		onRunAutomation: async (automationId: number) => {
			await mutation.mutateAsync(automationId);
		},
		isRunAutomationLoading: mutation.isPending ? 1 : undefined,
	};
};
